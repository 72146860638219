import { BillyTsForm } from '@/components/BillyTSForm/BillyTsForm'
import { DateSchema, OptionalDateSchema } from '@/components/BillyTSForm/fields/DateField'
import { SelectSchema } from '@/components/BillyTSForm/fields/SelectField'
import { useErrorHandler } from '@/components/ErrorHandler/ErrorHandler'
import { useSnackbarHandler } from '@/components/SnackbarHandler/SnackbarHandler'
import ActionButton from '@/components/button/actionButton'
import { WithModalParams, useModal, useModalsContext } from '@/components/state/context/modalsContext'
import { JotaiForm } from '@/components/state/useJotaiForm'
import {
  ActionType,
  Cycle,
  LineItemFragment,
  OrderDetailFragment,
  OrderStartDateType,
  OrderType,
} from '@/generated/graphql'
import { NewOrderFormData } from '@/pageComponents/orders/EditOrderPage/NewOrderPage'
import { RampSettingView } from '@/pageComponents/orders/EditOrderPage/cards/EditOrderTimelineCard/RampSettingView'
import buildLogger from '@/util/logger'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material'
import { isInteger } from 'lodash'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { RampIntervalSelectId, useRampIntervalReducer } from '../../hooks/useRampIntervalReducer'
import { getIsTimelineDirty } from './utils'
import { InfoTooltip } from '@/components/typography/InfoTooltip'
import { useIsUpdateOrderStartDateEnabled } from '@/pageComponents/orders/useIsUpdateOrderStartDateEnabled'
import { deepMutable } from '@/components/SchemaForm/DeepMutable'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
const logger = buildLogger('EditTimelineDialog')

export const enum DisplayTermType {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  END_DATE = 'END_DATE',
}
export const enum DisplayStartDateType {
  FIXED = 'FIXED',
  EXECUTION_DATE = 'EXECUTION_DATE',
}
export const EditTimelineDialogSchema = z
  .object({
    startDateType: SelectSchema.describe('Order Start Date Type'),
    termType: SelectSchema.describe('Term Type'),
    duration: z.number().describe('Duration'),
    startDate: DateSchema.describe('Start Date'),
    endDate: OptionalDateSchema.describe('End Date'),
  })
  .superRefine(({ duration, startDate, endDate, termType }, ctx) => {
    if (termType === DisplayTermType.END_DATE) {
      if (!endDate || (endDate && endDate < startDate)) {
        ctx.addIssue({
          code: 'invalid_date',
          path: ['endDate'],
          message: 'Must be after start date',
        })
      }
    } else {
      if (!isInteger(duration)) {
        ctx.addIssue({
          code: 'custom',
          path: ['duration'],
          message: 'Must be an integer',
        })
      }
      if (duration < 1) {
        ctx.addIssue({
          code: 'custom',
          path: ['duration'],
          message: 'Must be greater than 0',
        })
      }
      if (duration > 100) {
        ctx.addIssue({
          code: 'custom',
          path: ['duration'],
          message: 'Must be less than or equal to 100',
        })
      }
    }
  })

export type EditTimelineDialogProps = {
  jotaiForm: JotaiForm<NewOrderFormData>
  onSubmit?: (data: z.infer<typeof EditTimelineDialogSchema>, intervals: number[]) => Promise<void> | void
  rampType?: RampType
  orderType?: OrderType
  defaults: {
    startDateType: DisplayStartDateType | OrderStartDateType
    termType: DisplayTermType | Cycle
    startDate: number
    endDate?: number
    duration: number
    rampIntervalType: RampIntervalSelectId
    intervals: number[]
  }
  isUpdateOrderStartDateEnabled?: boolean | null
  lineItem?: LineItemFragment
  disableWarning?: boolean
}

export type RampType = 'LINE_LEVEL' | 'ORDER_LEVEL'
type DialogProps = Omit<WithModalParams, 'onSubmit'> & EditTimelineDialogProps
const formID = 'EditTimelineDialog'

function EditTimelineDialog({
  open,
  onClose,
  onModalClose,
  onSubmit,
  defaults,
  rampType,
  orderType,
  isUpdateOrderStartDateEnabled,
  disableWarning,
}: DialogProps) {
  const errorHandler = useErrorHandler()
  const snackbarHandler = useSnackbarHandler()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const isRampLineLevel = rampType === 'LINE_LEVEL'

  const form = useForm<z.infer<typeof EditTimelineDialogSchema>>({
    resolver: zodResolver(EditTimelineDialogSchema),
  })

  const { startDateType, termType, hasError, ...rampIntervalProps } = useRampIntervalReducer({
    open,
    form,
    defaults,
  })

  const [contentType, setContentType] = useState<'form' | 'confirm'>('form')
  const isTimelineDirty = getIsTimelineDirty({ form, defaults })

  /**
   * FORM SUBMIT
   */
  const onFormSubmit = (data: z.infer<typeof EditTimelineDialogSchema>) => {
    async function doAsync() {
      setIsSubmitting(true)
      await onSubmit?.(data, rampIntervalProps.intervals)
      setIsSubmitting(false)
      onClose?.()
    }
    function onExecute() {
      doAsync().catch((error) => {
        setIsSubmitting(false)
        errorHandler(error)
      })
    }
    function onHandleDirtyForm() {
      setContentType('confirm')
    }
    if (isTimelineDirty && !disableWarning) {
      if (contentType === 'confirm') {
        onExecute()
      }
      if (contentType === 'form') {
        onHandleDirtyForm()
      }
    } else {
      onExecute()
    }
  }

  const handleSubmit = async () => {
    const intervals = rampIntervalProps.intervals
    const areIntervalsValid = intervals.every((interval) => !!interval)

    if (!hasError && areIntervalsValid) {
      await form.handleSubmit(onFormSubmit)()
    }
    if (!areIntervalsValid) {
      snackbarHandler.pushAlert('Ramp intervals cannot be empty', 'error')
    }
  }

  const disabledExplanation = isRampLineLevel
    ? "Can't be changed at the line level"
    : orderType === OrderType.Amendment
    ? "Can't be changed for amendment orders"
    : ''

  const startDateDisabledExplanation = orderType === OrderType.Renewal ? "Can't be changed for renewal orders" : ''
  const endDateDisabledExplanation = termType !== DisplayTermType.END_DATE ? 'Pick date range to modify end date' : ''
  const formId = `${formID}-${rampType}`

  return (
    <Dialog open={!!open} onClose={onModalClose} maxWidth={'md'} scroll={'paper'} fullWidth>
      <DialogTitle>Edit Timeline</DialogTitle>
      <DialogContent dividers hidden={contentType !== 'form'}>
        <Stack rowGap={2}>
          <BillyTsForm
            form={form}
            formProps={{ id: formId }}
            key={`${formID}-${rampType}`}
            onSubmit={handleSubmit}
            schema={EditTimelineDialogSchema}
            defaultValues={defaults}
            props={{
              duration: {
                hidden: termType === DisplayTermType.END_DATE,
                disabled: isRampLineLevel,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      {termType === DisplayTermType.MONTH && 'Month(s)'}
                      {termType === DisplayTermType.YEAR && 'Year(s)'}
                    </InputAdornment>
                  ),
                },
                layout: {
                  xs: 3,
                },
              },
              endDate: {
                hidden: termType !== DisplayTermType.END_DATE,
                disabledExplanation: endDateDisabledExplanation || disabledExplanation,
              },
              startDate: {
                disabledExplanation: startDateDisabledExplanation || disabledExplanation,
              },
              termType: {
                disabledExplanation,
                items: [
                  {
                    id: DisplayTermType.MONTH,
                    label: 'Number of Months',
                  },
                  {
                    id: DisplayTermType.YEAR,
                    label: 'Number of Years',
                  },
                  {
                    id: DisplayTermType.END_DATE,
                    label: 'Date Range',
                  },
                ].filter(
                  (item) =>
                    startDateType !== DisplayStartDateType.EXECUTION_DATE ||
                    (startDateType === DisplayStartDateType.EXECUTION_DATE && item.id === DisplayTermType.YEAR)
                ),
                layout: {
                  xs: 3,
                },
              },
              startDateType: {
                hidden: !isUpdateOrderStartDateEnabled || orderType !== OrderType.New,
                disabledExplanation,
                items: [
                  {
                    id: DisplayStartDateType.FIXED,
                    label: 'Fixed',
                  },
                  {
                    id: DisplayStartDateType.EXECUTION_DATE,
                    label: 'Order Execution Date',
                  },
                ],
                layout: {
                  xs: 3,
                },
              },
            }}
          >
            {({ startDate, endDate, ...rest }) => (
              <>
                {Object.values(rest)}
                {startDateType === DisplayStartDateType.EXECUTION_DATE ? (
                  <Grid container item xs={3}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 0.5 }}>
                      {startDate}
                      <InfoTooltip description="Start date will change to order execution date when this order is executed" />
                    </Box>
                  </Grid>
                ) : (
                  <Grid container item xs={3}>
                    {startDate}
                  </Grid>
                )}
                <Grid container item xs={3}>
                  {endDate}
                </Grid>
                <RampSettingView {...rampIntervalProps} startDateType={startDateType as DisplayStartDateType} />
              </>
            )}
          </BillyTsForm>
        </Stack>
      </DialogContent>
      <DialogContent dividers hidden={contentType !== 'confirm'}>
        <Typography>
          Order timeline has been modified. New ramp intervals will be saved and applied to the order.
        </Typography>
      </DialogContent>
      <DialogActions aria-label={`${formId}-actions`}>
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          buttonData={{
            label: 'Cancel',
            onClick: onClose,
            color: 'inherit',
            buttonProps: {
              variant: 'outlined',
              'aria-label': `${formId}-cancel-button`,
            },
          }}
        />
        <ActionButton
          buttonData={{
            label: 'Save',
            loading: isSubmitting,
            buttonProps: { type: 'submit', form: formId, 'aria-label': `${formId}-save-button` },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

function EditTimelineDialogWrapper(modalProps: DialogProps) {
  if (modalProps.open) return <EditTimelineDialog {...modalProps} />
  return null
}

export function useEditTimelineDialog(props: DialogProps) {
  const [, , toggleModal] = useModalsContext()
  const rampFormId = `${formID}-ORDER_LEVEL`
  const isUpdateOrderStartDateEnabled = useIsUpdateOrderStartDateEnabled()
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()

  const formProps = jotaiForm.useSelect(
    useCallback((form): Partial<EditTimelineDialogProps> => {
      return {
        disableWarning: !form.orderDetail.lineItems.filter((li) => li.isRamp).length,
      }
    }, [])
  )

  useModal<DialogProps>({
    component: EditTimelineDialogWrapper,
    schema: {
      key: rampFormId,
      modalProps: { ...props, rampType: 'ORDER_LEVEL', isUpdateOrderStartDateEnabled, ...formProps },
    },
  })
  const toggleEditTimelineDialog = useCallback(() => {
    toggleModal(rampFormId)
  }, [toggleModal, rampFormId])

  return [toggleEditTimelineDialog]
}

export function useLineLevelEditTimelineDialog(props: DialogProps) {
  const [, , toggleModal] = useModalsContext()
  const [lineItem, setLineItem] = useState<LineItemFragment | undefined>()
  const rampFormId = `${formID}-LINE_LEVEL`
  const isUpdateOrderStartDateEnabled = useIsUpdateOrderStartDateEnabled()
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()

  const formProps = jotaiForm.useSelect(
    useCallback(
      (form): Partial<EditTimelineDialogProps> => {
        const selectedCharge =
          form.orderDetail.orderType === OrderType.Restructure
            ? form.orderDetail.lineItems.filter(
                (li) => lineItem?.charge.id === li.charge.id && li.action !== ActionType.Remove
              )
            : form.orderDetail.lineItems.filter((li) => lineItem?.charge.id === li.charge.id)
        const selectedChargeIntervals = selectedCharge.map((li) => li.effectiveDate as number) || []
        return {
          defaults: { ...props.defaults, intervals: lineItem ? selectedChargeIntervals : props.defaults.intervals },
        }
      },
      [lineItem, props.defaults]
    )
  )

  useModal<DialogProps>({
    component: EditTimelineDialogWrapper,
    schema: {
      key: rampFormId,
      modalProps: {
        ...props,
        isUpdateOrderStartDateEnabled,
        onSubmit: useCallback(
          (data, intervals) => {
            if (lineItem) {
              jotaiForm.set((draft) => {
                const lineItems = [...draft.orderDetail.lineItems]
                const currentLineItemIndex = lineItems.findIndex(
                  // ramp can only be enabled if there is one instance of the charge present
                  (li) => li.plan?.id === lineItem.plan?.id && li.charge.id === lineItem.charge.id
                )
                const currentCharge = lineItems.filter((li) => li.charge.id === lineItem.charge.id)
                if (currentCharge.length > 0) {
                  intervals?.forEach((interval, i) => {
                    const newLineItem: OrderDetailFragment['lineItems'][0] = {
                      action: ActionType.Add,
                      charge: lineItem.charge,
                      chargeDetail: lineItem.chargeDetail,
                      listUnitPrice: lineItem.charge.isCustom ? lineItem.listUnitPrice ?? 0 : null,
                      effectiveDate: interval,
                      plan: lineItem.plan,
                      predefinedDiscounts: lineItem.predefinedDiscounts,
                      quantity: lineItem.quantity,
                      subscriptionChargeId: lineItem.subscriptionChargeId,
                      listPriceOverrideRatio: lineItem.listPriceOverrideRatio,
                      attributeReferences: lineItem.attributeReferences,
                      isRamp: true,
                    }
                    lineItems?.splice(
                      currentLineItemIndex + i,
                      currentCharge.length - i - 1 >= 0 ? 1 : 0,
                      deepMutable(newLineItem)
                    )
                  })
                }
                if (currentCharge.length > intervals.length) {
                  if (intervals.length > 0) {
                    lineItems.splice(currentLineItemIndex + intervals.length, currentCharge.length - intervals.length)
                  } else {
                    // Line Level Ramp Deal Interval is `None`
                    lineItems.splice(currentLineItemIndex + 1, currentCharge.length - 1)
                    const newLineItem: OrderDetailFragment['lineItems'][0] = { ...currentCharge?.[0], isRamp: false }
                    lineItems.splice(currentLineItemIndex, 1, deepMutable(newLineItem))
                  }
                }
                if (props.orderType === OrderType.Restructure) {
                  const oldLineItems = [...draft.orderDetail.lineItems]
                  const missingLineItems = oldLineItems.filter(
                    (li) => lineItem.plan?.id === li.plan?.id && li.charge.id !== lineItem.charge.id
                  )
                  draft.orderDetail.lineItems = [...lineItems, ...missingLineItems]
                } else {
                  draft.orderDetail.lineItems = lineItems
                }
              })
              void props.onSubmit?.(data, intervals)
            }
          },
          [jotaiForm, lineItem, props]
        ),
        rampType: 'LINE_LEVEL',
        lineItem,
        ...formProps,
      },
    },
  })
  const toggleLineLevelEditTimelineDialog = useCallback(
    (li?: LineItemFragment) => {
      setLineItem(li)
      toggleModal(rampFormId)
    },
    [toggleModal, rampFormId]
  )

  return [toggleLineLevelEditTimelineDialog]
}
