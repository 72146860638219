import { LABORATORY_ROUTES } from '@/laboratoryComponents/LaboratoryPage'
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import { Feature, Role } from '../../../generated/graphql'
import { canMutateCreditMemo, canViewBankTransaction, canViewPayment, canViewReports } from '../../../util/roleUtils'
import { useUserTenantSession } from '../../UserTenantSessionProvider/UserTenantSessionContext'
import { NavLink, NavMenu, isTypeOfNavMenu } from './SideDrawerNav'
import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'
import { NewReleasesOutlined } from '@mui/icons-material'

export function useSideDrawerLinks() {
  const userTenantSession = useUserTenantSession()
  const userRole = userTenantSession.currentUser.role
  const isLaboratoryEnabled = useDynamicFeatureFlag(Feature.UiLaboratory)
  const isBankTransactionEnabled = useDynamicFeatureFlag(Feature.BankTransactions)

  const router = useRouter()
  const isUnderRoute = useCallback(
    (route: string): boolean =>
      route === '' ? router.pathname === `/${route}` : router.pathname.startsWith(`/${route}`),
    [router]
  )

  const links = useMemo(
    () => buildNavLinks({ userRole, isUnderRoute, isBankTransactionEnabled, isLaboratoryEnabled }),
    [userRole, isUnderRoute, isLaboratoryEnabled, isBankTransactionEnabled]
  )
  return links
}

const shouldShow = (item: NavLink | NavMenu) => {
  if (item.show === undefined) return true
  return !!item.show
}

export function buildNavLinks({
  userRole,
  isUnderRoute,
  isBankTransactionEnabled,
  isLaboratoryEnabled,
}: {
  userRole: Role
  isUnderRoute?: (route: string) => boolean
  isBankTransactionEnabled?: boolean
  isLaboratoryEnabled: boolean
}) {
  const navItems: (NavLink | NavMenu)[] = [
    {
      icon: <Image src="/icons/home.svg" width={24} height={24} />,
      text: 'Home',
      link: '',
    },
    {
      icon: <Image src="/icons/identification.svg" width={24} height={24} />,
      text: 'Accounts',
      link: 'accounts',
    },
    {
      icon: <Image src="/icons/inbox-in.svg" width={24} height={24} />,
      text: 'Orders',
      menu: [
        {
          text: 'All Orders',
          link: 'orders',
        },
        {
          text: 'Composite Orders',
          link: 'compositeOrders',
        },
      ],
    },
    {
      icon: <Image src="/icons/subscriptions.svg" width={24} height={24} />,
      text: 'Subscriptions',
      link: 'subscriptions',
      show: true,
    },

    {
      icon: <Image src="/icons/item-document.svg" width={24} height={24} />,
      text: 'Billing',
      menu: [
        {
          text: 'Invoices',
          link: 'invoices',
        },
        {
          text: 'Payments',
          link: 'payments',
          show: canViewPayment(userRole),
        },
        {
          text: 'Credit Memos',
          link: 'creditmemos',
          show: canMutateCreditMemo(userRole),
        },
        {
          text: 'Bank Transactions',
          link: 'bank-transactions',
          show: canViewBankTransaction(userRole),
        },
      ],
      show: isBankTransactionEnabled,
    },
    {
      icon: <Image src="/icons/item-document.svg" width={24} height={24} />,
      text: 'Invoices',
      link: 'invoices',
      show: !isBankTransactionEnabled,
    },
    {
      icon: <Image src="/icons/calculator.svg" width={24} height={24} />,
      text: 'Accounting',
      link: 'accounting',
    },
    {
      icon: <Image src="/icons/currency-dollar.svg" width={24} height={24} />,
      text: 'Revenue Recognition',
      link: 'revrec',
    },
    {
      icon: <Image src="/icons/chart-square-bar.svg" width={24} height={24} />,
      text: 'Reports',
      link: 'reports',
      show: canViewReports(userRole),
    },
    {
      icon: <Image src="/icons/tag.svg" width={24} height={24} />,
      text: 'Products',
      link: 'products',
      menu: [
        {
          text: 'Products',
          link: 'products',
        },
        {
          text: 'Plans',
          link: 'products/plans',
        },
      ],
    },
    {
      icon: <Image src="/icons/cog.svg" width={24} height={24} />,
      text: 'Settings',
      link: 'settings',
      show: userRole === Role.Admin || userRole === Role.BillyAdmin,
    },
    {
      icon: <ScienceOutlinedIcon width={24} height={24} color={'primary'} />,
      text: 'Laboratory',
      show: isLaboratoryEnabled,
      menu: [
        {
          text: 'All Experiments',
          link: 'laboratory',
        },
        ...LABORATORY_ROUTES.filter(
          //TODO: don't show any FFed routes in sidebar, we can use tenant to filter later
          (route) => !Object.prototype.hasOwnProperty.call(route, 'feature')
        ).map((route) => ({
          text: route.title,
          link: route.url ?? '',
        })),
      ],
    },
    {
      icon: <Image src="/icons/settings.svg" width={24} height={24} />,
      text: 'Admin',
      link: 'admin',
      show: userRole === Role.BillyAdmin || userRole === Role.BillyEngineer,
    },
    {
      icon: <NewReleasesOutlined width={24} height={24} color={'primary'} />,
      text: 'Release Notes',
      link: 'https://www.subskribe.com/release-notes',
      isExternal: true,
    },
  ]

  return navItems.filter(shouldShow).map((item) => {
    if (isTypeOfNavMenu(item)) {
      const selected = item.menu.some((subItem) => isUnderRoute?.(subItem.link))
      const menu: NavMenu = {
        ...item,
        selected,
        menu: item.menu.filter(shouldShow).map((subItem) => ({
          ...subItem,
          selected: isUnderRoute?.(subItem.link),
          inset: true,
          show: subItem.show ?? shouldShow(item),
        })),
      }
      return menu
    } else {
      const link: NavLink = {
        ...item,
        selected: isUnderRoute?.(item.link),
      }
      return link
    }
  })
}
