import React from 'react'
import { Box, CardContent, Grid } from '@mui/material'
import { OrderDetailFragment } from '../../generated/graphql'
import { billableCurrencyFormat } from '../../util/currencyUtil'
import Big from 'big.js'

const OrderTotalListPriceCardContent: React.FC<React.PropsWithChildren<{ orderDetail: OrderDetailFragment }>> = ({
  orderDetail,
}) => {
  const totalListAmountBeforeOverride = orderDetail.totalListAmountBeforeOverride ?? 0
  return (
    <>
      {!!orderDetail && (
        <>
          <CardContent>
            <Grid container direction={'column'}>
              <Grid item display="flex">
                <Box sx={{ fontWeight: 'bold' }}>Total List Price:&nbsp;</Box>
                <Box>
                  {billableCurrencyFormat({
                    currency: orderDetail.currency,
                    value: orderDetail.totalListAmount ?? 0,
                  })}
                </Box>
              </Grid>
              <Grid item display="flex">
                <Box sx={{ fontWeight: 'bold' }}>Total List Price Before Overrides:&nbsp;</Box>
                <Box>
                  {billableCurrencyFormat({
                    currency: orderDetail.currency,
                    value: totalListAmountBeforeOverride,
                  })}
                </Box>
              </Grid>
              <Grid item display="flex">
                <Box sx={{ fontWeight: 'bold' }}>Order Discount:&nbsp;</Box>
                <Box>
                  {Big(orderDetail.totalDiscountPercent ?? 0).toFixed(2)}
                  &nbsp;%
                </Box>
              </Grid>
              <Grid item display="flex">
                <Box sx={{ fontWeight: 'bold' }}>Recurring Discount:&nbsp;</Box>
                <Box>
                  {Big(orderDetail.recurringDiscountPercent ?? 0).toFixed(2)}
                  &nbsp;%
                </Box>
              </Grid>
              <Grid item display="flex">
                <Box sx={{ fontWeight: 'bold' }}>Non Recurring Discount:&nbsp;</Box>
                <Box>
                  {Big(orderDetail.nonRecurringDiscountPercent ?? 0).toFixed(2)}
                  &nbsp;%
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </>
      )}
    </>
  )
}

export default OrderTotalListPriceCardContent
