import { getSizeByRatio } from '@/components/table/baseTable'
import BillyDataGridPro from '@/components/table/billyDataGridPro'
import { useTenantTimeZone } from '@/components/UserTenantSessionProvider/useTenantTimeZone'
import { LineItemFragment } from '@/generated/graphql'
import buildLogger from '@/util/logger'
import { Grid } from '@mui/material'
import { GridInputRowSelectionModel } from '@mui/x-data-grid-pro'
import { isNumber } from 'lodash'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
const logger = buildLogger('FindAndReplaceChargeView')

export type FindAndReplaceChargeViewProps = {
  children?: React.ReactNode
  lineItems?: LineItemFragment[]
  selections?: string[]
  onChange?: (selections: string[]) => void
}

export function FindAndReplaceChargeView({
  selections,
  lineItems = [],
  onChange,
  children,
}: FindAndReplaceChargeViewProps): JSX.Element {
  const [selectModel, setSelectModel] = useState<GridInputRowSelectionModel | undefined>(undefined)
  useEffect(() => {
    setSelectModel(selections)
  }, [selections])
  const tenantTZ = useTenantTimeZone()
  return (
    <Grid item xs={12}>
      {children}

      <BillyDataGridPro
        columns={[
          {
            headerName: 'Charge',
            field: 'charge',
            ...getSizeByRatio(3),
          },
          {
            headerName: 'Plan',
            field: 'planName',
            ...getSizeByRatio(3),
          },
          {
            headerName: 'Quantity',
            field: 'quantity',
            ...getSizeByRatio(1),
          },
          {
            headerName: 'Start Date',
            field: 'startDate',
            type: 'date',
            valueGetter: ({ value }) => DateTime.fromSeconds(value).setZone(tenantTZ),
            valueFormatter: ({ value }) => value.toFormat('yyyy-MM-dd'),
            ...getSizeByRatio(2),
          },
          {
            headerName: 'End Date',
            field: 'endDate',
            type: 'date',
            valueGetter: ({ value }) => DateTime.fromSeconds(value).setZone(tenantTZ),
            valueFormatter: ({ value }) => value.toFormat('yyyy-MM-dd'),
            ...getSizeByRatio(2),
          },
        ]}
        rows={lineItems?.map((lineItem) => ({
          id: lineItem.id,
          charge: lineItem.charge.name,
          quantity: lineItem.quantity,
          startDate: lineItem.effectiveDate,
          endDate: lineItem.endDate,
          planName: lineItem.plan?.name,
        }))}
        disableRowSelectionOnClick={true}
        disableMultipleRowSelection={false}
        checkboxSelection
        rowSelectionModel={selectModel}
        onRowSelectionModelChange={(model) => {
          logger.debug({ msg: 'onRowModesModelChange', model })
          onChange?.(model.map((id) => (isNumber(id) ? id.toString() : id)))
        }}
      />
    </Grid>
  )
}
