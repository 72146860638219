import { TableInCardWithSearchDGP } from '@/components/table/tableInCardWithSearchDGP'
import { FuzzySearchProps } from '../search/useFuzzySearch'
import { BaseTableProps, BillyColumn } from './baseTable'

export type TableInCardWithSearchProps = Pick<
  BaseTableProps<Record<string, unknown>>,
  'rowActionType' | 'columnSort' | 'onChangeColumnSort'
> & {
  action?: JSX.Element
  columns: ReadonlyArray<BillyColumn>
  hidden?: boolean
  idField: string
  renderEmptyMessage?: string
  select?: FuzzySearchProps['where']
  term?: FuzzySearchProps['term']
  sortField?: FuzzySearchProps['sortField']
  table_name?: FuzzySearchProps['tableName']
  title: string
  urlPath: string
  tenantId?: string
  subHeader?: JSX.Element
}

function TableInCardWithSearch(props: TableInCardWithSearchProps): JSX.Element {
  return (
    <>
      <TableInCardWithSearchDGP
        {...props}
        defaultSortField={typeof props.sortField === 'string' ? props.sortField : props.columnSort?.key}
      />
    </>
  )
}

export default TableInCardWithSearch
