import BillyCard from '@/components/card/billyCard'
import BillyGridCell, { GridCellProps } from '@/components/grid/billyGridCell'
import {
  PriceAttributeFragment,
  PriceTableFragment,
  useGetPriceAttributesQuery,
  useGetRateCardQuery,
} from '@/generated/graphql'
import { unitPriceFormatter } from '@/util/currencyUtil'
import { CardContent, Grid } from '@mui/material'
import { Box } from '@mui/system'
import { DataGridPro, GridColDef, GridSingleSelectColDef, GridToolbar, ValueOptions } from '@mui/x-data-grid-pro'
import { RATE_CARD_PRICE_MAX_FRACTION_DIGITS } from './constants'

export function createPriceTableRowsAndColumns({
  priceTable,
  priceAttributes,
  currency = 'USD',
}: {
  priceTable?: ReadonlyArray<PriceTableFragment>
  priceAttributes: ReadonlyArray<PriceAttributeFragment>
  currency?: string
}) {
  const columns: GridColDef<Record<string, string | number>>[] = []
  const rows =
    priceTable?.map((row, index) => {
      const valueRow: Record<string, string | number> & { price: number; id: number } = {
        price: row.price,
        id: index,
      }
      row.attributeReferences.forEach((attributeReference) => {
        const priceAttribute = priceAttributes.find(
          (attribute) => attribute.id === attributeReference.attributeDefinitionId
        )
        if (priceAttribute?.name) {
          valueRow[priceAttribute?.name] = attributeReference.attributeValue
          const column = columns.find((column) => column.field === priceAttribute?.name)
          if (!column) {
            columns.push({
              field: priceAttribute?.name,
              headerName: priceAttribute?.name,
              width: 200,
              minWidth: 100,
              valueOptions: [attributeReference.attributeValue],
              type: 'singleSelect',
              colSpan: 1,
            })
          } else {
            const valueOptions = ((column as GridSingleSelectColDef).valueOptions as ValueOptions[]) || []
            if (!valueOptions.find((valueOption) => valueOption === attributeReference.attributeValue)) {
              ;(column as GridSingleSelectColDef).valueOptions = [...valueOptions, attributeReference.attributeValue]
            }
          }
        }
      })
      return valueRow
    }) || []
  columns.push({
    field: 'price',
    headerName: 'Price',
    width: 120,
    align: 'right',
    headerAlign: 'right',
    colSpan: 1,
    valueFormatter: (params) => {
      return unitPriceFormatter({
        value: params.value as number,
        currency,
        maximumFractionDigits: RATE_CARD_PRICE_MAX_FRACTION_DIGITS,
      })
    },
  })

  return { rows, columns }
}

type FilteredGridCell = GridCellProps & { hide?: boolean }

export function RateCardView({ rateCardId }: { rateCardId: string }) {
  const [rateCardResponse] = useGetRateCardQuery({ variables: { id: rateCardId }, pause: !rateCardId })
  const [priceAttributesResponse] = useGetPriceAttributesQuery()
  const rateCard = rateCardResponse.data?.rateCard
  const priceAttributes = priceAttributesResponse.data?.priceAttributes || []

  const rateCardCells: readonly FilteredGridCell[] = [
    {
      label: 'Name',
      description: <Box sx={{ wordBreak: 'break-all' }}>{rateCard?.name || ''}</Box>,
      sm: 6,
    },
    {
      label: 'Currency',
      description: rateCard?.currencyCode || 'USD',
      sm: 6,
    },
    {
      label: 'Description',
      description: <Box sx={{ wordBreak: 'break-all' }}>{rateCard?.description || ''}</Box>,
      sm: 12,
      hide: !rateCard?.description,
    },
  ].filter((cell: FilteredGridCell) => !cell.hide)

  const priceTable = rateCard?.priceTable

  const { rows, columns } = createPriceTableRowsAndColumns({
    priceTable,
    priceAttributes,
    currency: rateCardResponse.data?.rateCard.currencyCode,
  })

  return (
    <Grid container direction="column" wrap="nowrap" spacing={3}>
      <Grid item spacing={3}>
        {!rateCardResponse.fetching && (
          <BillyCard>
            <CardContent>
              <Grid container rowSpacing={3} columnSpacing={2}>
                {rateCardCells.map((cell, index) => (
                  <BillyGridCell {...cell} key={index} />
                ))}
              </Grid>
              <Box mt={3}>
                <div style={{ height: '100%', width: '100%', paddingTop: '.5rem' }}>
                  <DataGridPro
                    disableColumnMenu={false}
                    disableMultipleRowSelection={false}
                    disableColumnResize={false}
                    disableColumnReorder={false}
                    autoHeight={true}
                    rows={rows}
                    columns={columns}
                    slots={{ toolbar: GridToolbar }}
                    density="compact"
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                        printOptions: { disableToolbarButton: true },
                      },
                    }}
                    unstable_ignoreValueFormatterDuringExport={true}
                  />
                </div>
              </Box>
            </CardContent>
          </BillyCard>
        )}
      </Grid>
    </Grid>
  )
}
