import { Chip, Grid, TextFieldProps } from '@mui/material'
import { createUniqueFieldSchema, useDescription, useTsController } from '@ts-react/form'
import { isArray } from 'lodash'
import { z } from 'zod'
import { BillyMuiSelect, OptionalItemsType } from '../../input/JotaiMuiSelect'
import { DisabledProps, LayoutProps } from '../BillyTsForm'

export type SelectFieldProps = TextFieldProps & LayoutProps & OptionalItemsType & DisabledProps

export const SelectSchema = createUniqueFieldSchema(z.string(), 'select')
export const OptionalSelectSchema = createUniqueFieldSchema(z.string().optional(), 'optional-select')
export const SelectMultiSchema = createUniqueFieldSchema(
  z.array(z.string()).min(1, 'Please select at least one entry'),
  'select-multi'
)
export const OptionalSelectMultiSchema = createUniqueFieldSchema(
  z.array(z.string()).optional(),
  'optional-select-multi'
)

export function SelectField({ layout, items: schemaItems, hidden, ...rest }: SelectFieldProps) {
  const {
    field: { value, onChange },
    error,
    formState: { isLoading, isSubmitting },
  } = useTsController<string>()
  const { label, placeholder } = useDescription()

  const { SelectProps, ...textFieldProps } = rest
  const items = schemaItems ?? []

  if (hidden) return <></>

  return (
    <Grid container item xs {...layout} hidden={hidden}>
      <BillyMuiSelect
        items={items}
        isLoading={isLoading || isSubmitting}
        error={error?.errorMessage}
        disabledExplanation={rest.disabledExplanation}
        textFieldProps={{
          value: value ?? '',
          label,
          'aria-label': label,
          role: 'combobox',
          placeholder: placeholder,
          onChange: (event) => onChange(event.target.value),
          SelectProps: {
            ...SelectProps,
          },
          ...textFieldProps,
        }}
      />
    </Grid>
  )
}

export function MultiSelectField({ layout, items: schemaItems, ...rest }: SelectFieldProps) {
  const {
    field: { value, onChange },
    error,
    formState: { isLoading, isSubmitting },
  } = useTsController<string>()
  const { label, placeholder } = useDescription()

  const { SelectProps, ...textFieldProps } = rest
  const items = schemaItems ?? []

  return (
    <Grid container item xs {...layout}>
      <BillyMuiSelect
        items={items}
        isLoading={isLoading || isSubmitting}
        error={error?.errorMessage}
        textFieldProps={{
          value: value ?? [],
          label,
          placeholder: placeholder,
          onChange: (event) => onChange(event.target.value),
          SelectProps: {
            multiple: true,
            renderValue(value: unknown) {
              if (isArray(value)) {
                return (
                  <Grid gap={0.5}>
                    {value.map((id, index) => (
                      <>
                        {items.findIndex((item) => item.id === id) > -1 && (
                          <Chip variant="outlined" label={items.find((item) => item.id === id)?.label} key={index} />
                        )}
                      </>
                    ))}
                  </Grid>
                )
              }
            },
            ...SelectProps,
          },

          ...textFieldProps,
        }}
      />
    </Grid>
  )
}
