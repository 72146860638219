import BillyGridCell from '@/components/grid/billyGridCell'
import BillyLink from '@/components/link/billyLink'
import { LineItemCellLoadingIndicator } from '@/pageComponents/orders/LineItemsEditTable/Cells/LineItemCellLoadingIndicator'
import { useShouldRecalculateTotals } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { billableCurrencyFormat } from '@/util/currencyUtil'
import { Grid, Typography, useTheme } from '@mui/material'
import { CommonOrderDrawerState, discountDrawerDetails } from '@/pageComponents/orders/OrderDrawer/CommonOrderDrawer'

function NewOrderPageDrawer({
  orderDetail: {
    currency,
    totalListAmount,
    totalAmount,
    orderMetrics,
    resoldBy,
    totalDiscount,
    totalDiscountPercent,
    totalRecurringDiscount,
    totalRecurringDiscountPercent,
    totalNonRecurringDiscount,
    totalNonRecurringDiscountPercent,
  },
}: CommonOrderDrawerState): JSX.Element {
  const shouldRecalculateTotals = useShouldRecalculateTotals()
  const theme = useTheme()

  const newOrderPageDrawerData = [
    {
      label: 'Total List Amount',
      description: billableCurrencyFormat({
        currency,
        value: totalListAmount ?? 0,
      }),
    },
    ...discountDrawerDetails({
      currency,
      totalDiscount,
      totalDiscountPercent,
      totalRecurringDiscount,
      totalRecurringDiscountPercent,
      totalNonRecurringDiscount,
      totalNonRecurringDiscountPercent,
    }),
    {
      label: 'Total Order Amount',
      description: shouldRecalculateTotals ? (
        <LineItemCellLoadingIndicator />
      ) : (
        billableCurrencyFormat({
          currency,
          value: totalAmount ?? 0,
        })
      ),
    },
    {
      label: 'Entry ARR',
      description: billableCurrencyFormat({
        currency,
        value: orderMetrics?.entryArr ?? 0,
      }),
      hide: !orderMetrics?.entryArr,
    },
    {
      label: 'Exit ARR',
      description: billableCurrencyFormat({
        currency,
        value: orderMetrics?.exitArr ?? 0,
      }),
      hide: !orderMetrics?.exitArr,
    },
    {
      label: 'Average ARR',
      description: billableCurrencyFormat({
        currency,
        value: orderMetrics?.averageArr ?? 0,
      }),
      hide: !orderMetrics?.averageArr,
    },
    {
      label: 'Resold By',
      description: (
        <BillyLink nextProps={{ href: `/accounts/${resoldBy?.id}` }}>
          <Typography>{resoldBy?.name}</Typography>
        </BillyLink>
      ),
      hide: !resoldBy?.name,
    },
  ]

  const getCellOrder = (length: number, index: number, alwaysLastRow?: boolean): number => {
    const getLastRowOrder = (length: number): number => {
      return length - (length % 4) + 2
    }
    return alwaysLastRow ? getLastRowOrder(length) : index + 1
  }

  return (
    <>
      <Typography variant="overline" fontWeight={theme.typography.fontWeightRegular}>
        NEW
      </Typography>
      <Typography variant="h6" fontWeight={theme.typography.fontWeightMedium}>
        Order Summary
      </Typography>
      <Grid container rowGap={2} pt={2}>
        {newOrderPageDrawerData.map((cell, index) => (
          <BillyGridCell gridRow={1} {...cell} key={index} order={getCellOrder(newOrderPageDrawerData.length, index)} />
        ))}
      </Grid>
    </>
  )
}

export default NewOrderPageDrawer
