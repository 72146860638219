import { GridCellProps } from '@/components/grid/billyGridCell'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { Cycle, LineItemFragment, OrderDetailFragment, OrderType } from '@/generated/graphql'
import {
  DisplayStartDateType,
  DisplayTermType,
  EditTimelineDialogProps,
  RampType,
  useEditTimelineDialog,
  useLineLevelEditTimelineDialog,
} from '@/pageComponents/orders/EditOrderPage/cards/EditOrderTimelineCard/EditTimelineDialog'
import { RampScheduleView } from '@/pageComponents/orders/EditOrderPage/cards/EditOrderTimelineCard/RampScheduleView'
import { useDryRunActions } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { notEmpty } from '@/util/array'
import { toTitleCase } from '@/util/string'
import { Grid } from '@mui/material'
import { useCallback } from 'react'
import { useTenantTimeZone } from '../../../../components/UserTenantSessionProvider/useTenantTimeZone'
import {
  addOneDayToUnixDate,
  subtractOneDayFromUnixDate,
  termLengthToDuration,
  unixTimeSecondsToDateStr,
  unixToLuxonWithTz,
} from '../../../../util/datetime/luxon/dateUtil'
import { NewOrderFormData } from '../NewOrderPage'
import { isTypeOfCycle, isTypeOfStartDateType, logger } from '../cards/EditOrderTimelineCard/OrderTimelineCard'
import { reapplyRampOnLineItems } from '@/pageComponents/orders/EditOrderPage/utils'
import { useIsUpdateOrderStartDateEnabled } from '../../useIsUpdateOrderStartDateEnabled'

export const useEditOrderTimelineReducer = () => {
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  const tenantTZ = useTenantTimeZone()
  const { queueDryRun } = useDryRunActions()

  const { orderType, hasLineItems } = jotaiForm.useSelect(
    useCallback(
      (form) => ({ orderType: form.orderDetail?.orderType, hasLineItems: form.orderDetail.lineItems.length > 0 }),
      []
    )
  )
  const isAmendmentOrder = orderType === OrderType.Amendment

  const onEditTimeline: EditTimelineDialogProps['onSubmit'] = (data, intervals) => {
    jotaiForm.set((draft) => {
      logger.trace({ intervals, data })
      if (draft.orderDetail.billingCycle.cycle === Cycle.Custom) {
        const previousSchedules = draft.invoiceTriggerSchedules ? [...draft.invoiceTriggerSchedules] : []
        draft.invoiceTriggerSchedules = previousSchedules?.map((schedule) => ({
          ...schedule,
          triggerInstant:
            schedule.triggerInstant && data.startDate > schedule.triggerInstant
              ? data.startDate
              : schedule.triggerInstant,
        }))
      }
      draft.orderDetail.rampInterval = []
      draft.orderDetail.rampInterval = intervals
      draft.orderDetail.billingAnchorDate = undefined
      draft.orderDetail.startDate = data.startDate
      if (isTypeOfCycle(data.termType) && !isAmendmentOrder) {
        draft.orderDetail.termLength = {
          cycle: data.termType,
          step: data.duration,
        }
        draft.orderDetail.endDate = undefined
      } else {
        draft.orderDetail.endDate = addOneDayToUnixDate(data.endDate) //End dates from BE are non-inclusive, so it always needs to be handled with -1 day
        draft.orderDetail.termLength = undefined
      }
      if (isTypeOfStartDateType(data.startDateType)) {
        draft.orderDetail.startDateType = data.startDateType
      }
      reapplyRampOnLineItems(draft)
    })
    if (hasLineItems) {
      queueDryRun()
    }
  }

  const onLineLevelEditTimeline: EditTimelineDialogProps['onSubmit'] = () => {
    queueDryRun()
  }

  const defaults = jotaiForm.useSelect(
    useCallback((form): EditTimelineDialogProps['defaults'] => {
      const intervals = form.orderDetail.rampInterval?.filter(notEmpty).filter((interval) => !!interval) ?? []
      return {
        intervals,
        startDate: form.orderDetail.startDate,
        rampIntervalType: intervals.length ? 'specifiedDate' : 'none',
        termType: form.orderDetail.termLength?.cycle ?? DisplayTermType.END_DATE,
        duration: form.orderDetail.termLength?.step ?? 1,
        endDate: form.orderDetail.endDate ? subtractOneDayFromUnixDate(form.orderDetail.endDate) : undefined,
        startDateType: form.orderDetail.startDateType ?? DisplayStartDateType.FIXED,
      }
    }, [])
  )

  const [toggleEditTimelineDialog] = useEditTimelineDialog({
    onSubmit: onEditTimeline,
    jotaiForm,
    defaults,
    orderType,
  })

  const [toggleLineLevelEditTimelineDialog] = useLineLevelEditTimelineDialog({
    onSubmit: onLineLevelEditTimeline,
    jotaiForm,
    orderType,
    defaults,
  })

  const toggleRampDialog = (rampType: RampType) => (lineItem?: LineItemFragment) => {
    if (rampType === 'LINE_LEVEL') toggleLineLevelEditTimelineDialog(lineItem)
    else toggleEditTimelineDialog()
  }

  const isUpdateOrderStartDateEnabled = useIsUpdateOrderStartDateEnabled()

  const timelineDetailsSelector = useCallback(
    (form: NewOrderFormData) => {
      const rampInterval = form.orderDetail.rampInterval?.filter(notEmpty).filter((interval) => !!interval) ?? []
      const cells: GridCellProps[] = [
        {
          label: 'Order Start Date Type',
          description: toTitleCase(form.orderDetail.startDateType ?? 'Fixed'),
          hide: !isUpdateOrderStartDateEnabled || orderType !== OrderType.New,
          xs: 12,
        },
        {
          label: 'Term Type',
          description: toTitleCase(form.orderDetail.termLength?.cycle ?? 'Date Range'),
          xs: 3,
        },
        {
          label: 'Duration',
          description: formatTermLengthDuration(form.orderDetail.termLength),
          hide: !form.orderDetail.termLength?.cycle,
          xs: 3,
        },
        {
          label: 'Start Date',
          description: unixTimeSecondsToDateStr(form.orderDetail.startDate, tenantTZ) ?? '',
          xs: 3,
        },
        {
          label: 'End Date',
          description: form.orderDetail.endDate
            ? //End dates from BE are non-inclusive, so it always needs to be handled with -1 day
              unixTimeSecondsToDateStr(subtractOneDayFromUnixDate(form.orderDetail.endDate), tenantTZ)
            : unixToLuxonWithTz(subtractOneDayFromUnixDate(form.orderDetail.startDate), tenantTZ)
                .plus(termLengthToDuration(form.orderDetail.termLength))
                .toISODate(),
          xs: 3,
        },
        {
          label: 'Ramp Deal Intervals',
          description: rampInterval?.length ? (
            <Grid item>
              <RampScheduleView intervals={rampInterval} />
            </Grid>
          ) : (
            'None'
          ),
          xs: 12,
        },
      ]
      return cells
    },
    [isUpdateOrderStartDateEnabled, orderType, tenantTZ]
  )

  return { toggleRampDialog, timelineDetailsSelector }
}

function formatTermLengthDuration(termLength?: OrderDetailFragment['termLength']) {
  if (!termLength) return ''
  return `${(termLength.step ?? 0).toString()} ${cycleToSuffix(termLength.cycle)}`
}

function cycleToSuffix(cycle: Cycle) {
  if (!cycle) return ''
  return cycle === Cycle.Month ? 'Month(s)' : 'Year(s)'
}
