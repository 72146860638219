import { Chip, ChipProps, Tooltip } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import {
  AccountingPeriodStatus,
  AttachmentStatus,
  BulkInvoiceRunStatus,
  IntegrationStatus,
  InvoiceStatus,
  OrderStatus,
  PaymentAttemptState,
  PaymentAttemptUserFriendlyState,
  PaymentRefundStatus,
  PaymentState,
  PlanStatus,
  SubscriptionState,
  ElectronicSignatureStatus,
  ElectronicSignatureAuditLogStatus,
  ImportStatus,
} from '../../../generated/graphql'
import { BulkInvoiceDisplayStatus } from '../../../pageComponents/invoices/bulk/bulkInvoiceStatusChip'
import { toTitleCase } from '../../../util/string'
import { LineItemState } from '@/pageComponents/orders/LineItemsViewTable/LineItemsViewTable'
import { SettlementApplicationStatusChip } from '@/util/billing'
import { EmailStatus } from '@/pageComponents/invoices/InvoiceEmailActivityTable'

type StatusChipProps = {
  chipProps?: Omit<ChipProps, 'color'>
  color?: ChipProps['color'] | 'disabled'
  tooltip?: string
  value: unknown
}

export enum StatusChipCustomStatus {
  InUse = 'In Use',
}

const useStyles = makeStyles()((theme: Theme) => ({
  chip: {
    borderRadius: '2px',
    border: `1px solid`,
    textTransform: 'capitalize',
  },
  secondary: {
    background: theme.customPalette.statusChipBackgrounds.secondary,
    borderColor: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
  },
  warning: {
    background: theme.customPalette.statusChipBackgrounds.warning,
    borderColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
  },
  error: {
    background: theme.customPalette.statusChipBackgrounds.error,
    borderColor: theme.palette.error.light,
    color: theme.palette.error.dark,
  },
  success: {
    background: theme.customPalette.statusChipBackgrounds.success,
    borderColor: theme.palette.success.light,
    color: theme.palette.success.dark,
  },
  info: {
    background: theme.customPalette.statusChipBackgrounds.info,
    borderColor: theme.palette.info.light,
    color: theme.palette.info.dark,
  },
  disabled: {
    background: theme.palette.background.default,
    borderColor: theme.customPalette.disabledBorder,
    color: theme.palette.text.primary,
  },
}))

export { useStyles as statusChipStyles }

const StatusChip = ({ value, color, tooltip, chipProps }: StatusChipProps) => {
  const { classes, cx } = useStyles()

  function statusChipColors(value: string, color: StatusChipProps['color']): string {
    if (color) {
      switch (color) {
        //override the default color based on the value
        case 'secondary':
          return classes.secondary
        case 'warning':
          return classes.warning
        case 'success':
          return classes.success
        case 'error':
          return classes.error
        case 'disabled':
          return classes.disabled
        default:
          return classes.info
      }
    }
    switch (value) {
      case OrderStatus.Approved:
      case InvoiceStatus.Converted:
      case BulkInvoiceRunStatus.Processing:
      case StatusChipCustomStatus.InUse:
      case ElectronicSignatureAuditLogStatus.Viewed:
        return classes.secondary

      case OrderStatus.Submitted:
      case SubscriptionState.PendingCancellation:
      case InvoiceStatus.Posted:
      case BulkInvoiceDisplayStatus.Review:
      case AccountingPeriodStatus.CloseInProgress:
      case 'PARTIAL': // for an Invoice
      case PlanStatus.Grandfathered:
      case BulkInvoiceRunStatus.Running:
      case PaymentRefundStatus.Partial:
      case AttachmentStatus.Uploading:
      case 'Incomplete': // for hubspot integration
      case 'Unbilled':
      case PaymentAttemptState.Confirmed:
      case PaymentAttemptUserFriendlyState.Initiated:
      case PaymentState.Initiated:
      case ElectronicSignatureStatus.PartiallySigned:
      case ElectronicSignatureAuditLogStatus.Signed:
      case LineItemState.Removed:
      case EmailStatus.Attempted:
      case ImportStatus.PartiallySuccessful:
      case 'Replacement Plan Available':
        return classes.warning

      case OrderStatus.Executed:
      case InvoiceStatus.Paid:
      case SubscriptionState.Active:
      case BulkInvoiceDisplayStatus.Complete:
      case AccountingPeriodStatus.Open:
      case PaymentRefundStatus.Paid:
      case AttachmentStatus.Uploaded:
      case PaymentAttemptState.Succeeded:
      case PaymentAttemptUserFriendlyState.Successful:
      case PaymentState.Succeed:
      case 'SUCCESSFUL':
      case SettlementApplicationStatusChip.Applied:
      case EmailStatus.Delivered:
      case 'Matched':
        return classes.success

      case OrderStatus.Cancelled:
      case SubscriptionState.Cancelled:
      case IntegrationStatus.Deleted:
      case BulkInvoiceDisplayStatus.Failed: // BulkInvoiceDisplayStatus
      case EmailStatus.Bounced:
        return classes.error

      case AccountingPeriodStatus.Upcoming:
      case SubscriptionState.Pending:
      case OrderStatus.Draft:
      case BulkInvoiceRunStatus.Created:
      case PaymentRefundStatus.Refunded:
      case ElectronicSignatureStatus.Sent:
      case ElectronicSignatureAuditLogStatus.Sent:
      case LineItemState.New:
      case LineItemState.Duplicate:
      case ImportStatus.Validated:
      case 'Replacement Plan':
        return classes.info

      case PlanStatus.Deprecated:
      case SubscriptionState.Expired: // for a Subscription
      case AccountingPeriodStatus.Closed: // for an Accounting Period
        return classes.disabled
      //use grey for disabled/default/unhandled
      default:
        return classes.disabled
    }
  }
  if (!value) {
    return <></>
  }
  return (
    <Tooltip title={tooltip || ''}>
      <Chip
        className={cx(classes.chip, statusChipColors(value as string, color))}
        label={toTitleCase(value as string)}
        {...chipProps}
      />
    </Tooltip>
  )
}

export default StatusChip
