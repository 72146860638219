import { useErrorHandler } from '@/components/ErrorHandler/ErrorHandler'
import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'
import TableEmptyContent from '@/components/table/tableEmptyContent'
import { Feature } from '@/generated/graphql'
import buildLogger from '@/util/logger'
import { Save } from '@mui/icons-material'
import { Box, Button, Tooltip } from '@mui/material'
import {
  DataGridPro,
  GridApiPro,
  GridColDef,
  GridRowId,
  GridToolbar,
  GridValueFormatterParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { DataGridProProps } from '@mui/x-data-grid-pro/models/dataGridProProps'
import { LicenseInfo } from '@mui/x-license-pro'
import React, { useEffect, useLayoutEffect } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useGridApiRefWithPersistState } from './useGridApiRefWithPersistState'
import { useSnackbarHandler } from '@/components/SnackbarHandler/SnackbarHandler'
const logger = buildLogger('BillyDataGridPro')

LicenseInfo.setLicenseKey(
  '3ec006e74f4be823f30a33ea48a2ed52Tz03MzIyOSxFPTE3MjQzNTExMjQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)

type BillyDataGridProStyleParams = {
  hasError: boolean
  hideBorderX: boolean
  hideBorderY: boolean
}
const useStyles = makeStyles<BillyDataGridProStyleParams>()((_theme, { hasError, hideBorderX, hideBorderY }) => ({
  root: {
    borderLeft: hideBorderX ? 'none' : undefined,
    borderRight: hideBorderX ? 'none' : undefined,
    borderTop: hideBorderY ? 'none' : undefined,
    borderBottom: hideBorderY ? 'none' : undefined,
  },
  cell: hasError
    ? {
        alignItems: 'start!important',
        paddingTop: '0.5rem!important',
        lineHeight: '2.5rem',
      }
    : {},
}))

/**
 * - card is used in most situations, wrapped with BillyCard
 * - stacked is used when stacked with other fields, or standalone
 */
export type BillyDataGridProVariant =
  | 'card'
  | 'stacked'
  | 'dgp-es-table-card'
  | 'line-items-edit-table'
  | 'add-plan-table'
  | 'line-items-interval-edit-table'

export type BillyDataGridProProps = DataGridProProps & {
  errorIDs?: GridRowId[]
  variant?: BillyDataGridProVariant
  renderEmptyMessage?: string
}

const BillyDGPButtons = ({ apiRef, tableId }: { apiRef: React.MutableRefObject<GridApiPro>; tableId: string }) => {
  const isSavingTableStateEnabled = useDynamicFeatureFlag(Feature.UiSaveDgpTableState)
  const showSaveButton = isSavingTableStateEnabled
  const { saveSnapshot } = useGridApiRefWithPersistState({ isEnabled: isSavingTableStateEnabled, tableId, apiRef })
  const snackbarHandler = useSnackbarHandler()

  const onSave = () => {
    if (showSaveButton) {
      saveSnapshot()
      snackbarHandler.successAlert('Saved Table Layout Successfully')
    } else {
      snackbarHandler.pushAlert('Save Table Layout is disabled', 'warning')
    }
  }

  return (
    <>
      {!!showSaveButton && (
        <Box gap={0.5} px={0.5}>
          <Button startIcon={<Save />} onClick={onSave} size="small">
            Save Table Layout
          </Button>
        </Box>
      )}
    </>
  )
}

export const BillyDataGridPro: React.FC<React.PropsWithChildren<BillyDataGridProProps>> = ({
  errorIDs,
  variant = 'card',
  classes: propClasses,
  renderEmptyMessage,
  ...dataGridProProps
}) => {
  const { classes } = useStyles({
    hasError: !!errorIDs?.length,
    hideBorderX: ['card', 'dgp-es-table-card', 'line-items-edit-table', 'line-items-interval-edit-table'].includes(
      variant
    ),
    hideBorderY: ['card', 'dgp-es-table-card', 'line-items-edit-table', 'line-items-interval-edit-table'].includes(
      variant
    ),
  })
  const { slots, slotProps, columns, ...restProps } = dataGridProProps
  const filteredColumns = mapOutGridColDefExcelFormula(columns)
  const tableId = filteredColumns.map((col) => col.field).join('-')
  const shouldPersistState =
    ['dgp-es-table-card', 'line-items-edit-table', 'add-plan-table'].includes(variant) && !!tableId

  const disableColumnResizing = ['line-items-edit-table', 'line-items-interval-edit-table'].includes(variant)
  const disableDensitySelector = ['line-items-edit-table', 'line-items-interval-edit-table'].includes(variant)
  const disableCsvExport = ['dgp-es-table-card', 'line-items-edit-table', 'line-items-interval-edit-table'].includes(
    variant
  )
  const disableColumnFilter = ['dgp-es-table-card', 'line-items-edit-table', 'line-items-interval-edit-table'].includes(
    variant
  )
  const disableQuickFilter = ['line-items-edit-table', 'line-items-interval-edit-table'].includes(variant)
  const shouldHaveHeaderFilter = ['dgp-es-table-card', 'add-plan-table'].includes(variant)
  const disableRowSelectionOnClick = !['add-plan-table'].includes(variant)
  const disableColumnSelector = ['line-items-interval-edit-table'].includes(variant)
  const disableDGPButtons = ['line-items-interval-edit-table'].includes(variant)
  const shouldAutoFitColumns = false
  const errorHandler = useErrorHandler()
  const apiRef = useGridApiRef()
  const { loadSnapshot } = useGridApiRefWithPersistState({ isEnabled: shouldPersistState, tableId, apiRef })

  async function autoFitColumnsAsync() {
    await apiRef.current.autosizeColumns()
  }

  useLayoutEffect(() => {
    loadSnapshot()
    // only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (dataGridProProps.rows.length && shouldAutoFitColumns) {
      autoFitColumnsAsync().catch(errorHandler)
    }
    // only trigger on new set of rows
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGridProProps.rows])

  return (
    <>
      {!disableDGPButtons && <BillyDGPButtons apiRef={apiRef} tableId={tableId} />}
      <DataGridPro
        unstable_headerFilters={shouldHaveHeaderFilter}
        apiRef={apiRef}
        autoHeight={true}
        disableColumnSelector={disableColumnSelector}
        disableColumnResize={disableColumnResizing}
        disableColumnFilter={disableColumnFilter}
        classes={{
          root: classes.root,
          cell: classes.cell,
          ...propClasses,
        }}
        slots={{
          toolbar: GridToolbar,
          noRowsOverlay: TableEmptyContent,
          noResultsOverlay: TableEmptyContent,
          ...slots,
        }}
        slotProps={{
          noResultsOverlay: {
            message: renderEmptyMessage,
          },
          noRowsOverlay: {
            message: renderEmptyMessage,
          },
          ...slotProps,
          /**
           * Can not be overridden by slotProps
           */
          toolbar: {
            showQuickFilter: !disableQuickFilter,
            quickFilterProps: {
              ...dataGridProProps.slotProps?.toolbar?.quickFilterProps,
            },
            // This is disabled due to a bug in DataGridPro that throws an error when the print is canceled
            printOptions: { disableToolbarButton: true },

            csvOptions: { disableToolbarButton: disableCsvExport, escapeFormulas: true },
          },
        }}
        getRowHeight={(params) => (errorIDs?.includes(params.id) ? 80 : undefined)}
        {...restProps}
        columns={filteredColumns}
        disableDensitySelector={disableDensitySelector}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
      />
    </>
  )
}

// generic data massage for excel formula
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function mapOutGridColDefExcelFormula<T extends object = any>(columns: readonly GridColDef<T>[]) {
  const filtered: GridColDef<T>[] = columns.map((col) => ({
    ...col,
    valueFormatter: withExcelFormulaTrimmed(col.valueFormatter),
    renderCell: col.renderCell
      ? col.renderCell
      : (params) => (
          <Tooltip title={params.formattedValue || params.value}>
            <span className="MuiDataGrid-cellContent" role="presentation">
              {params.formattedValue || params.value}
            </span>
          </Tooltip>
        ),
  }))
  return filtered
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function withExcelFormulaTrimmed(fn?: (...args: any[]) => any) {
  return (params: GridValueFormatterParams) => {
    try {
      let original = params.value ? `${params.value}` : ``
      if (fn) {
        original = fn(params) ?? ''
      }
      let formatted = original
      // replace excel formula strings with tab in front of it
      if (typeof formatted === 'string') {
        formatted = original.replace(/^=/g, "'=").replace(/^\+/g, "'+").replace(/^-/g, "'-").replace(/^@/g, "'@")
      }
      return formatted
    } catch (err) {
      logger.trace('Error in withExcelFormulaTrimmed', err)
    }
  }
}

export default BillyDataGridPro
