import { FuzzySearchProps, sanitizeSearchString } from '@/components/search/useFuzzySearch'
import { GridFilterModel } from '@mui/x-data-grid-pro'

function mapFilterItemsToEsQuery(items?: GridFilterModel['items']) {
  return items
    ?.filter((item) => !!item.value)
    .map((item) => {
      return {
        key: item.field,
        value: sanitizeSearchString(item.value),
        operator: item.operator,
      }
    })
}

export const stringOperators = ['contains', 'startsWith', 'endsWith', 'equals', 'isEmpty', 'isNotEmpty'] as const
type StringOperator = (typeof stringOperators)[number]
export function isTypeOfSupportedStringOperators(op?: string): op is StringOperator {
  return stringOperators.includes(op as StringOperator)
}

export function itemOperatorToEsOperatorBuilder(operator?: string) {
  switch (operator) {
    case 'contains':
      return function containsStringEsBuilder(text: string) {
        return `*${text}*`
      }
    case 'startsWith':
      return function startsWithStringEsBuilder(text: string) {
        return `${text}*`
      }
    case 'endsWith':
      return function endsWithStringEsBuilder(text: string) {
        return `*${text}`
      }
    case 'equals':
      return function equalsStringEsBuilder(text: string) {
        return `${text}`
      }

    default:
      return function defaultEsBuilder(text: string) {
        return `${text}`
      }
  }
}

export const numberOperators = ['>', '>=', '<', '<='] as const
export type NumberOperators = (typeof numberOperators)[number]

export const isTypeofSupportedNumberOperators = (op?: string): op is NumberOperators => {
  return numberOperators.includes(op as NumberOperators)
}
export function mapNumberOperatorToEsOperator(operator: NumberOperators) {
  switch (operator) {
    case '>':
      return 'gt'
    case '>=':
      return 'gte'
    case '<':
      return 'lt'
    case '<=':
      return 'lte'
    default:
      return 'gte'
  }
}

export function mapFilterModelToEsQuery(filterModel: GridFilterModel | undefined): Partial<FuzzySearchProps> {
  const matchItems = filterModel?.items.filter(
    (item) => isTypeOfSupportedStringOperators(item.operator) || isTypeofSupportedNumberOperators(item.operator)
  )
  const notItems = filterModel?.items.filter((item) => item.operator === 'isEmpty')
  const mustItems = filterModel?.items.filter((item) => item.operator === 'isNotEmpty')

  switch (filterModel?.logicOperator) {
    default:
      return {
        strictFilter: mapFilterItemsToEsQuery(matchItems),
        isEmptyAccessors: notItems?.map((item) => item.field),
        isNotEmptyAccessors: mustItems?.map((item) => item.field),
        operator: filterModel?.logicOperator,
      }
  }
}
