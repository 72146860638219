import { PageContainer } from '@/components/PageContainer/PageContainer'
import {
  alignEffectiveDateToRampPeriodOrOrderStart,
  lineItemInAmendmentPeriod,
} from '@/pageComponents/orders/AmendmentOrderPage/utils'
import { EditOrderCustomFieldsCard } from '@/pageComponents/orders/EditOrderPage/cards/EditOrderCustomFieldsCard/EditOrderCustomFieldsCard'
import { OrderEditItemsCard } from '@/pageComponents/orders/EditOrderPage/cards/OrderEditItemsCard'
import { useDryRunActions } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { useDryRunError } from '@/pageComponents/orders/useDryRunError'
import { Stack } from '@mui/material'
import { isEqual } from 'lodash'
import Head from 'next/head'
import { useMemo } from 'react'
import { DeepMutable, deepMutable } from '../../../components/SchemaForm/DeepMutable'
import { useBillyRouter } from '../../../components/route/useBillyRouter'
import JotaiFormProvider from '../../../components/state/jotaiFormProvider'
import useJotaiForm from '../../../components/state/useJotaiForm'
import { ActionType, Cycle, InputCustomBillingPeriodInput, LineItemFragment } from '../../../generated/graphql'
import buildLogger from '../../../util/logger'
import { getDefaultOrderDetail } from '../../../util/order'
import { CommonOrderFormPageState } from '../EditOrderPage/CommonOrderFormPageState'
import { IncludedTermsCard } from '../IncludedTerms/IncludedTermsCard'
import DocumentMasterTemplateCard from '../documentMasterTemplateCard'
import OrderDiscounts from '../orderDiscounts'
import { AmendmentBillingCard } from './AmendmentBillingCard'
import { AmendmentOrderDetailsCard } from './AmendmentOrderDetailsCard'
import { AmendmentOrderLayout } from './AmendmentOrderLayout'

export const logger = buildLogger('AmendSubscriptionPage')

export interface AmendSubscriptionPageState extends CommonOrderFormPageState {
  shipToBilling: boolean
}

function AmendmentOrderPage({
  children,
  invoiceTriggerSchedules,
}: {
  children: React.ReactNode
  invoiceTriggerSchedules?: InputCustomBillingPeriodInput[]
}): JSX.Element {
  const router = useBillyRouter()
  const subscriptionId = router.query.subscriptionId as string | undefined
  const { queueDryRun } = useDryRunActions()

  const jotaiForm = useJotaiForm<AmendSubscriptionPageState>(
    useMemo(
      () => ({
        defaultValue: {
          isReset: false,
          orderDetail: deepMutable({ ...getDefaultOrderDetail(), subscriptionId, termLength: undefined }),
          approvalSegments: [],
          orderPlans: [],
          orderPlansForTerms: [],
          isLineItemDialogOpen: false,
          shipToBilling: true,
          shouldRecalculateTotals: false,
          _dangerousInputCounterWhichCausesRerender: 0,
          templateList: [],
          documentMasterTemplates: [],
          purchaseOrderNumber: '',
          purchaseOrderRequiredForInvoicing: false,
          hasHubSpotIntegration: false,
          hasSalesforceIntegration: false,
          prepaidRampEnabled: false,
        },
        onSet: (oldValue, newValue, draft) => {
          // Calculate the plans which have been added to the order from the line items
          if (oldValue.orderDetail.lineItems !== newValue.orderDetail.lineItems) {
            draft.orderPlans = newValue.orderDetail.lineItems.reduce((result, lineItem) => {
              const { plan } = lineItem
              if (plan) {
                if (!result.some((resultPlan) => resultPlan?.id === plan.id)) {
                  deepMutable(result).push(plan)
                }
              }
              return result
            }, [] as DeepMutable<NonNullable<LineItemFragment['plan']>[]>)
            // For amendments, only add plans from line items with an action of neither None nor Remove
            draft.orderPlansForTerms = newValue.orderDetail.lineItems
              .filter((lineItem) => lineItem.action !== ActionType.None && lineItem.action !== ActionType.Remove)
              .reduce((result, lineItem) => {
                const { plan } = lineItem
                if (plan) {
                  if (!result.some((resultPlan) => resultPlan?.id === plan.id)) {
                    deepMutable(result).push(plan)
                  }
                }
                return result
              }, [] as DeepMutable<NonNullable<LineItemFragment['plan']>[]>)
          }
          if (draft.orderDetail.billingCycle.cycle === Cycle.Custom) {
            const doesInvoiceTriggerScheduleForRemovedLineItemExist = draft.invoiceTriggerSchedules?.some(
              (schedule) =>
                !!invoiceTriggerSchedules?.filter(
                  (scheduleForRemovedLineItem) =>
                    scheduleForRemovedLineItem.amount &&
                    scheduleForRemovedLineItem.amount < 0 &&
                    scheduleForRemovedLineItem.amount === schedule.amount &&
                    scheduleForRemovedLineItem.triggerInstant === schedule.triggerInstant
                )
            )
            if (!doesInvoiceTriggerScheduleForRemovedLineItemExist) {
              draft.invoiceTriggerSchedules = [
                ...(invoiceTriggerSchedules || []),
                ...(newValue.invoiceTriggerSchedules || []),
              ]
            }
          }

          // Do totals need to be recalculated?
          if (!newValue.isReset) {
            if (
              !isEqual(oldValue.orderDetail.startDate, newValue.orderDetail.startDate) ||
              !isEqual(oldValue.orderDetail.predefinedDiscounts, newValue.orderDetail.predefinedDiscounts)
            ) {
              queueDryRun()
            }
          }

          // Update line item effective dates when order start date changes
          if (oldValue.orderDetail.startDate !== newValue.orderDetail.startDate) {
            // Remove new line items which fall outside amendment dates
            draft.orderDetail.lineItems = draft.orderDetail.lineItems.filter(
              (li) => li.action !== ActionType.Add || lineItemInAmendmentPeriod(li, newValue.orderDetail.startDate)
            )

            // Update start date for new line items
            draft.orderDetail.lineItems
              .filter((li) => li.action === ActionType.Add)
              .forEach((lineItem) => {
                // only adjust effective date if effective date matched previous order start date
                // or if the effective date is earlier than the new start date
                if (
                  lineItem.effectiveDate === oldValue.orderDetail.startDate ||
                  (lineItem.effectiveDate && lineItem.effectiveDate < newValue.orderDetail.startDate)
                ) {
                  lineItem.effectiveDate = alignEffectiveDateToRampPeriodOrOrderStart(
                    lineItem.effectiveDate,
                    draft.orderDetail.rampInterval,
                    newValue.orderDetail.startDate
                  )
                }
              })
          }
        },
      }),
      [subscriptionId, queueDryRun, invoiceTriggerSchedules]
    )
  )

  useDryRunError({ jotaiForm }, { silent: true })

  return (
    <JotaiFormProvider form={jotaiForm}>
      <Head>
        <title>Amend Subscription | Subskribe</title>
      </Head>
      {children}
    </JotaiFormProvider>
  )
}

export default AmendmentOrderPage

export function AmendmentOrderContent() {
  return (
    <PageContainer
      slot={{
        Layout: AmendmentOrderLayout,
      }}
    >
      <AmendmentOrderCardStack />
    </PageContainer>
  )
}
export function AmendmentOrderCardStack() {
  return (
    <Stack spacing={3}>
      <AmendmentOrderDetailsCard parentLocator="order::amendment::" />
      <OrderDiscounts />
      <EditOrderCustomFieldsCard />
      <OrderEditItemsCard orderType={'AMENDMENT'} parentLocator="order::amendment::" />
      <AmendmentBillingCard />
      <DocumentMasterTemplateCard />
      <IncludedTermsCard />
    </Stack>
  )
}
