import { useCallback } from 'react'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { CommonOrderFormPageState } from '../CommonOrderFormPageState'
import { CancelAndRestructureOrder, OrderType } from '@/generated/graphql'
import { CancelAndRestructureFormData } from '../../CancelAndRestructureOrderForm'

export const useGetInvoiceTriggerSchedules = () => {
  const jotaiForm = useJotaiFormContext<CommonOrderFormPageState & CancelAndRestructureFormData>()

  const {
    billingCycle,
    customBillingEligibleLineItems,
    customOrderLineTotalAmount,
    totalInvoiceAmount,
    currencyCode,
    orderType,
  } = jotaiForm.useSelect(
    useCallback((form: CommonOrderFormPageState) => {
      const eligibleOrderLines = form.orderDetail.lineItems?.filter((li) =>
        form.orderDetail.customBillingEligibleOrderLineIds?.includes(li.id ?? '')
      )

      let customOrderLineTotalAmount = 0

      if (
        form.orderDetail.customBillingEligibleOrderLineIds &&
        form.orderDetail.customBillingEligibleOrderLineIds.length > 0
      ) {
        customOrderLineTotalAmount =
          form.orderDetail.orderType === OrderType.Amendment
            ? form.orderDetail.lineItemsNetEffect?.reduce(
                (acc, li) =>
                  form.orderDetail.customBillingEligibleOrderLineIds?.includes(li.id ?? '')
                    ? acc + (li.amount ?? 0)
                    : acc,
                0
              ) ?? 0
            : form.orderDetail.orderType === OrderType.Restructure
            ? eligibleOrderLines?.reduce(
                (acc, li) => acc + ((li as CancelAndRestructureOrder['lineItems'][0])?.restructureAmount ?? 0),
                0
              ) ?? 0
            : eligibleOrderLines?.reduce((acc, li) => acc + (li.amount ?? 0), 0) ?? 0
      }
      const totalInvoiceAmount = form.invoiceTriggerSchedules?.reduce(
        (acc, schedule) => (schedule.amount != null && schedule.amount < 0 ? acc : acc + (schedule.amount ?? 0)),
        0
      )

      return {
        billingCycle: form.orderDetail.billingCycle.cycle,
        customBillingEligibleLineItems: eligibleOrderLines,
        customOrderLineTotalAmount,
        invoiceTriggerSchedules: form.invoiceTriggerSchedules || [],
        currencyCode: form.orderDetail.currency,
        orderType: form.orderDetail.orderType,
        totalInvoiceAmount: totalInvoiceAmount ?? 0,
      }
    }, [])
  )
  const remainingAmount = customOrderLineTotalAmount - totalInvoiceAmount

  return {
    currencyCode,
    customBillingEligibleLineItems,
    customOrderLineTotalAmount,
    remainingAmount,
    billingCycle,
    orderType,
  }
}
