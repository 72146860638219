import { formatDurationBetweenDates, formatUnixDate } from '@/util/datetime/luxon/dateUtil'
import Big from 'big.js'
import { ActionType, LineItemFragment } from '../../../generated/graphql'
import { billableCurrencyFormat, unitPriceFormatter } from '../../../util/currencyUtil'
import { NewOrderFormData } from '../EditOrderPage/NewOrderPage'
import { OrderItemRowProps, makeRampDisabledExplanation } from './orderChargeRow'

export function reduceLineItem({
  orderDetail,
  lineItemIndex,
  orderType,
  currency,
}: Pick<OrderItemRowProps, 'currency' | 'lineItemIndex' | 'orderType'> & {
  orderDetail: NewOrderFormData['orderDetail']
}) {
  const lineItems = orderDetail.lineItems
  const lineItem: LineItemFragment = lineItems[lineItemIndex] || {
    action: ActionType.None,
    isRamp: false,
    chargeDetail: {
      id: '',
    },
    charge: {},
    plan: {},
  }

  const charge = lineItem.charge

  const effectiveDate = lineItem?.effectiveDate
  const isRamp = lineItem?.isRamp || false
  const startDate = orderDetail.startDate
  const rampIntervalLength = orderDetail.rampInterval?.length || 0

  const amendmentLineInAmendmentPeriod =
    orderType === 'AMENDMENT' && !!lineItem?.endDate && lineItem?.endDate <= startDate
  const isMinimumCommitCharge = lineItems.some((li) => li.charge?.minimumCommitBaseChargeId === lineItem?.charge?.id)
  let instances = lineItems.filter((li) => li.plan?.id === lineItem?.plan?.id && li.charge?.id === lineItem?.charge?.id)
  if (orderType === 'RESTRUCTURE') {
    instances = instances.filter((li) => li.action !== ActionType.Remove)
  }
  const isOnlyInstanceOfCharge = instances.length === 1
  const isChargeRamped = instances.some((li) => li.isRamp)
  const rampDisabledExplanation = charge
    ? makeRampDisabledExplanation(isRamp, startDate, charge, rampIntervalLength, isOnlyInstanceOfCharge, orderType)
    : undefined

  const showDate = isChargeRamped || (!!lineItem?.effectiveDate && !!lineItem?.endDate)

  const isDiscountEnabled = lineItem.charge.isDiscount
    ? false
    : lineItem?.action
    ? [ActionType.Add, ActionType.Renewal, ActionType.Restructure].includes(lineItem.action)
    : false

  const isCustomListPriceEditEnabled =
    orderType !== 'CANCELLATION' &&
    charge?.isCustom &&
    (lineItem?.action === ActionType.Add ||
      lineItem?.action === ActionType.Restructure ||
      lineItem?.action === ActionType.Renewal)

  const isListPriceOverrideEnabled =
    orderType !== 'CANCELLATION' &&
    charge?.isListPriceEditable &&
    (lineItem?.action === ActionType.Add ||
      lineItem?.action === ActionType.Restructure ||
      lineItem?.action === ActionType.Renewal)

  const sellUnitPrice =
    lineItem?.sellUnitPrice === null ? 'N/A' : unitPriceFormatter({ currency, value: lineItem?.sellUnitPrice })

  const isDiscountLine = lineItem?.charge.isDiscount ?? false

  const discount =
    lineItem?.listUnitPrice === null
      ? 'N/A'
      : unitPriceFormatter({
          currency,
          value: lineItem?.discountAmount ?? 0,
        })

  const discountPercentage =
    lineItem?.listUnitPrice === null
      ? 'N/A'
      : !lineItem?.discountAmount || !lineItem?.listAmount
      ? '0%'
      : `${Big(lineItem?.discountAmount / lineItem?.listAmount)
          .times(100)
          .toFixed(2)}%`

  const unitDiscount =
    lineItem?.listUnitPrice === null
      ? 'N/A'
      : unitPriceFormatter({
          currency,
          value:
            lineItem?.listUnitPrice && lineItem?.sellUnitPrice ? lineItem?.listUnitPrice - lineItem?.sellUnitPrice : 0,
        })

  const listPrice =
    lineItem?.listUnitPrice == null
      ? 'N/A'
      : unitPriceFormatter({
          currency,
          value: lineItem?.listUnitPrice ?? charge?.amount ?? 0,
        })

  const showRemovedRowStyle = lineItem?.action === ActionType.Remove && orderType !== 'CANCELLATION'
  const resetToQuantity = (() => {
    const charge = orderDetail.currentSubscription?.charges.find(
      (charge) => charge?.id === lineItem?.subscriptionChargeId
    )

    return charge?.quantity
  })()

  const canEditBeToggled = orderType === 'AMENDMENT'
  const showResetQuantity = (() => {
    const updatedQuantity = orderDetail.lineItems[lineItemIndex]?.quantity ?? 0
    return canEditBeToggled && (!!resetToQuantity || resetToQuantity === 0) && resetToQuantity !== updatedQuantity
  })()

  const filteredLineItems = lineItems.filter((li) => li.plan?.id === lineItem?.plan?.id)

  const allDuplicateCharges = filteredLineItems.filter((li) => li.charge?.id === lineItem?.charge?.id)

  const isChargeDuplicated =
    allDuplicateCharges.length > 1 &&
    allDuplicateCharges[0]?.id !== lineItem?.id &&
    (lineItem?.action ? [ActionType.Add, ActionType.Restructure].includes(lineItem.action) : false) &&
    !lineItem?.isRamp

  const showTotalEdit =
    (orderType === 'CANCELLATION' || orderType === 'AMENDMENT') &&
    lineItem.action === ActionType.Remove &&
    lineItem.isCreditable

  const creditableAmountIndex = orderDetail.creditableAmounts?.findIndex((item) =>
    item ? item.subscriptionChargeId === lineItem.subscriptionChargeId : false
  )

  const billingCycle = orderDetail.billingCycle.cycle

  return {
    lineItem,
    isRamp,
    startDate,
    rampIntervalLength,
    amendmentLineInAmendmentPeriod,
    isMinimumCommitCharge,
    isOnlyInstanceOfCharge,
    isChargeRamped,
    rampDisabledExplanation,
    showDate,
    isDiscountEnabled,
    sellUnitPrice,
    isCustomListPriceEditEnabled,
    isListPriceOverrideEnabled,
    isDiscountLine,
    discount,
    unitDiscount,
    listPrice,
    showRemovedRowStyle,
    canEditBeToggled,
    resetToQuantity,
    showResetQuantity,
    charge,
    chargeName: charge?.name,
    formattedDate:
      formatUnixDate(Number(effectiveDate)) +
      (lineItem?.endDate ? ` - ${formatUnixDate(Number(lineItem?.endDate) - 1)}` : ''),
    formattedPeriod: formatDurationBetweenDates(
      Number(effectiveDate),
      lineItem?.endDate ? Number(lineItem?.endDate) : 0
    ),
    formattedCurrency: billableCurrencyFormat({ currency, value: lineItem?.amount }),
    isChargeDuplicated,
    discountPercentage,
    showTotalEdit,
    creditableAmountIndex,
    creditableAmount:
      creditableAmountIndex !== undefined ? orderDetail.creditableAmounts?.[creditableAmountIndex] : undefined,
    billingCycle,
  }
}
