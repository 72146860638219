import { useModal, useModalsContext, WithModalParams } from '@/components/state/context/modalsContext'
import { z } from 'zod'
import { useErrorHandler } from '@/components/ErrorHandler/ErrorHandler'
import {
  AccountContactFragment,
  InputOrderAttributesUpdateRequest,
  ListAccountContactsQuery,
  OpportunityFragment,
  OrderDetailFragment,
  OrderStatus,
  useGetOpportunitiesByHubSpotCompanyIdQuery,
  useGetOpportunitiesBySalesforceAccountIdQuery,
  useListAccountContactsQuery,
  useUpdateOrderAttributesMutation,
} from '@/generated/graphql'
import { BillyTsForm } from '@/components/BillyTSForm/BillyTsForm'
import React, { useCallback, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material'
import ActionButton from '@/components/button/actionButton'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useSnackbarHandler } from '@/components/SnackbarHandler/SnackbarHandler'
import { AutocompleteSchema, OptionalAutocompleteSchema } from '@/components/BillyTSForm/fields/AutocompleteSelect'
import { opportunitiesCompareByAlphabeticalStage } from '@/util/ai/opportunity'
import { notEmpty } from '@/util/array'

const OrderAttributesEditSchema = z.object({
  name: z.string().describe('Order Name').optional(),
  shippingContact: AutocompleteSchema.describe('Shipping Contact'),
  billingContact: AutocompleteSchema.describe('Billing Contact'),
  crmOpportunity: OptionalAutocompleteSchema.describe('CRM Opportunity'),
})

type OrderAttributesDialogProps = {
  orderDetail: OrderDetailFragment | undefined
  refresh?: () => void
  opportunities?: OpportunityFragment[]
  hasSalesforceIntegration?: boolean
  hasHubSpotIntegration?: boolean
  accountContacts?: ListAccountContactsQuery['accountContacts']
  billingAccountContacts?: ListAccountContactsQuery['accountContacts']
}
type DialogProps = WithModalParams & OrderAttributesDialogProps

const formId = 'OrderAttributesEditDialog'

function OrderAttributesEditDialog({
  open = false,
  onClose,
  refresh,
  orderDetail,
  onSubmit,
  hasSalesforceIntegration,
  hasHubSpotIntegration,
  opportunities,
  accountContacts = [],
  billingAccountContacts = [],
}: DialogProps) {
  const errorHandler = useErrorHandler()
  const snackbarHandler = useSnackbarHandler()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const hasCrmIntegration = hasSalesforceIntegration || hasHubSpotIntegration

  const onFormSubmit = (data: z.infer<typeof OrderAttributesEditSchema>) => {
    async function doAsync() {
      setIsSubmitting(true)
      await onSubmit?.(data)
      setIsSubmitting(false)
      onClose?.()
      snackbarHandler.successAlert('Order Details updated successfully')
      refresh?.()
    }
    doAsync().catch((error) => {
      setIsSubmitting(false)
      errorHandler(error)
    })
  }

  const displayName = (contact: AccountContactFragment) => `${contact.firstName} ${contact.lastName ?? ''}`

  const form = useForm<z.infer<typeof OrderAttributesEditSchema>>({
    resolver: zodResolver(OrderAttributesEditSchema),
  })
  const handleSubmitCallback = async () => await form.handleSubmit(onFormSubmit)()

  return (
    <Dialog open={!!open} onClose={onClose} fullWidth>
      <DialogTitle>Update Order Details</DialogTitle>
      <Divider />
      <DialogContent>
        <BillyTsForm
          form={form}
          formProps={{ id: formId }}
          schema={OrderAttributesEditSchema}
          onSubmit={handleSubmitCallback}
          defaultValues={{
            name: orderDetail?.name || '',
            crmOpportunity: orderDetail?.sfdcOpportunityId
              ? {
                  id: orderDetail?.sfdcOpportunityId ?? '',
                  label: orderDetail?.sfdcOpportunityName ?? '',
                }
              : undefined,
            shippingContact: orderDetail?.shippingContact
              ? {
                  id: orderDetail?.shippingContact?.id ?? '',
                  label: displayName(orderDetail.shippingContact),
                }
              : undefined,
            billingContact: orderDetail?.billingContact
              ? {
                  id: orderDetail.billingContact.id ?? '',
                  label: displayName(orderDetail.billingContact),
                }
              : undefined,
          }}
          props={{
            name: {
              layout: { xs: 12 },
            },
            shippingContact: {
              layout: { xs: 6 },
              disabledExplanation:
                orderDetail?.status === OrderStatus.Executed
                  ? 'Contacts cannot be updated for executed orders'
                  : undefined,
              items:
                accountContacts?.map((contact) => {
                  return {
                    id: contact.id ?? '',
                    label: displayName(contact),
                  }
                }) ?? [],
            },
            billingContact: {
              layout: { xs: 6 },
              disabledExplanation:
                orderDetail?.status === OrderStatus.Executed
                  ? 'Contacts cannot be updated for executed orders'
                  : undefined,
              items:
                billingAccountContacts?.map((contact) => {
                  return {
                    id: contact.id ?? '',
                    label: displayName(contact),
                  }
                }) ?? [],
            },
            crmOpportunity: {
              layout: { xs: 12 },
              // todo: add role based validation
              disabledExplanation: !hasCrmIntegration
                ? 'No CRM integration configured'
                : orderDetail?.compositeOrderId
                ? 'Order is part of a composite order'
                : undefined,
              items:
                opportunities?.map((opportunity) => {
                  return {
                    id: opportunity.crmId ?? '',
                    label: opportunity.name ?? '',
                  }
                }) ?? [],
            },
          }}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          key={'Cancel'}
          buttonData={{
            label: 'Cancel',
            onClick: onClose,
            color: 'inherit',
            buttonProps: { variant: 'outlined' },
          }}
        />
        <ActionButton
          key={'Save'}
          buttonData={{
            label: 'Save',
            loading: isSubmitting,
            buttonProps: { type: 'submit', form: formId },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export function useOrderAttributesEditDialog(modalProps: DialogProps) {
  const [, , toggleModal] = useModalsContext()
  const { orderDetail, hasSalesforceIntegration, hasHubSpotIntegration } = modalProps

  const crmId = orderDetail?.accountDetail.crmId ?? ''

  // null values get ignored in patch, so we should send empty if we have an existing opportunity id
  const opportunityValue = useCallback(
    (value: string | null | undefined) => {
      return value ?? (orderDetail?.sfdcOpportunityId ? '' : undefined)
    },
    [orderDetail?.sfdcOpportunityId]
  )

  const [sfdcOpportunitiesResult] = useGetOpportunitiesBySalesforceAccountIdQuery({
    variables: { sfdcAccountId: crmId },
    pause: !crmId || !hasSalesforceIntegration || !!orderDetail?.compositeOrderId,
  })

  const [hubspotOpportunitiesResult] = useGetOpportunitiesByHubSpotCompanyIdQuery({
    variables: { companyId: crmId },
    pause: !crmId || !hasHubSpotIntegration || !!orderDetail?.compositeOrderId,
  })
  const opportunities = (
    sfdcOpportunitiesResult.data?.opportunitiesBySalesforceAccountId ??
    hubspotOpportunitiesResult.data?.opportunitiesByHubSpotCompanyId ??
    []
  )
    .filter(notEmpty)
    .sort(opportunitiesCompareByAlphabeticalStage)

  const [accountContactsResponse] = useListAccountContactsQuery({
    variables: { accountId: orderDetail?.account.id || '' },
  })

  const [billingAccountContactsResponse] = useListAccountContactsQuery({
    variables: { accountId: orderDetail?.resoldBy?.id || orderDetail?.account.id || '' },
  })

  const [, updateOrderAttributes] = useUpdateOrderAttributesMutation()
  const onSubmit = useCallback(
    async (data: z.infer<typeof OrderAttributesEditSchema>) => {
      const selectedOpportunity = data.crmOpportunity?.id
        ? opportunities.find((opp) => opp.crmId === data.crmOpportunity.id)
        : undefined
      const orderAttributes: InputOrderAttributesUpdateRequest = {
        name: data.name,
        crmOpportunityId: opportunityValue(selectedOpportunity?.crmId),
        crmOpportunityName: opportunityValue(selectedOpportunity?.name),
        crmOpportunityStage: opportunityValue(selectedOpportunity?.stage),
        crmOpportunityType: opportunityValue(selectedOpportunity?.type),
        shippingContactId: data.shippingContact?.id ?? undefined,
        billingContactId: data.billingContact?.id ?? undefined,
      }
      await updateOrderAttributes({
        orderId: orderDetail?.id as string,
        updateRequest: orderAttributes,
      })
    },
    [updateOrderAttributes, orderDetail?.id, opportunities, opportunityValue]
  )

  useModal<DialogProps>({
    component: OrderAttributesEditDialog,
    schema: {
      key: formId,
      modalProps: {
        ...modalProps,
        onSubmit,
        opportunities,
        accountContacts: accountContactsResponse.data?.accountContacts ?? [],
        billingAccountContacts: billingAccountContactsResponse.data?.accountContacts ?? [],
      },
    },
  })
  return useCallback(() => {
    toggleModal(formId)
  }, [toggleModal])
}
