import { Grid, GridProps, Typography, TypographyProps } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { CopyButton } from '../button/CopyButton'

export const useBillyGridCellStyles = makeStyles()((theme, _params, _classes) => ({
  label: {
    alignSelf: 'bottom',
    color: theme.customPalette.textDark,
    fontWeight: 700,
    display: 'flex',
    wordWrap: 'break-word',
  },
  description: {
    alignSelf: 'bottom',
    color: theme.customPalette.textDark,
    fontWeight: 500,
    display: 'flex',
    wordWrap: 'break-word',
  },

  copyButton: {
    height: 'min-content',
    marginLeft: theme.spacing(0.5),
  },
}))

export const GRID_CELL_VARIANTS = ['default', 'copy-description'] as const
type GridCellVariantType = (typeof GRID_CELL_VARIANTS)[number]
export interface GridCellProps extends GridProps {
  label?: string | JSX.Element
  labelProps?: TypographyProps
  /**
   * 8 px padding between label. Description is populated as aria-description if string is used
   */
  description?: string | JSX.Element
  descriptionProps?: TypographyProps
  variant?: GridCellVariantType
  hide?: boolean
  /**
   * 16 px padding between label
   */
  children?: JSX.Element
}

const BillyGridCell: React.FC<React.PropsWithChildren<GridCellProps>> = ({
  label,
  labelProps,
  description,
  descriptionProps,
  children,
  variant = 'default',
  hide,
  ...props
}) => {
  const { classes } = useBillyGridCellStyles()
  const showDescriptionCopyButton = variant === 'copy-description' && typeof description === 'string'
  if (hide) {
    return null
  }
  return (
    <Grid
      item
      xs={12}
      role="gridcell"
      aria-label={typeof label === 'string' ? label : undefined}
      aria-describedby={typeof description === 'string' ? description : undefined}
      {...props}
    >
      {label && (
        <Typography
          className={classes.label}
          paddingBottom={1}
          variant="body2"
          component={typeof label === 'string' ? 'span' : 'div'}
          {...labelProps}
        >
          {label}
        </Typography>
      )}
      {description && (
        <Typography
          className={classes.description}
          variant="body1"
          component={typeof label === 'string' ? 'span' : 'div'}
          {...descriptionProps}
        >
          {description}
          {showDescriptionCopyButton && (
            <CopyButton className={classes.copyButton} value={description} message="Copy to clipboard" />
          )}
        </Typography>
      )}
      {!!children && <Box sx={{ pt: 1 }}>{children}</Box>}
    </Grid>
  )
}

export default BillyGridCell
