import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useCallback } from 'react'
import ActionButton from '../../components/button/actionButton'
import { WithModalParams, useModal, useModalsContext } from '../../components/state/context/modalsContext'
import { ElectronicSignatureStatus, Feature } from '@/generated/graphql'
import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'

type ConfirmVoidEsignEmailDialogProps = {
  onVoid?: () => void
  esignStatus?: ElectronicSignatureStatus
}

type DialogProps = WithModalParams & ConfirmVoidEsignEmailDialogProps
const formID = 'ConfirmVoidEsignEmailDialog'
function ConfirmVoidEsignEmailDialog({ open, onClose, onVoid, esignStatus }: DialogProps): JSX.Element {
  const handleVoid = useCallback(() => {
    onVoid?.()
    onClose?.()
  }, [onVoid, onClose])

  const shouldShowAllSignedDocuments = useDynamicFeatureFlag(Feature.ShowAllEsignedDocumentsOnUi)

  return (
    <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'} fullWidth>
      <DialogTitle>
        {esignStatus && esignStatus === ElectronicSignatureStatus.Completed && shouldShowAllSignedDocuments
          ? 'Void Signed Order Form?'
          : 'Void Sent Order Form?'}
      </DialogTitle>
      <DialogContent dividers>
        {esignStatus && esignStatus === ElectronicSignatureStatus.Completed && shouldShowAllSignedDocuments ? (
          <>
            <Typography>
              Voiding the signed order form will allow you to reinitiate e-signature process with your customers.
            </Typography>
            <Typography sx={{ mt: 2 }} variant="body2">
              Note: Signed order form will be available in the “Signed Documents” section on this order.
            </Typography>
          </>
        ) : (
          <Typography>The document will not be available to the customer anymore.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          key={'Cancel'}
          buttonData={{
            label: 'Cancel',
            onClick: onClose,
            color: 'inherit',
            buttonProps: { variant: 'outlined' },
          }}
        />
        <ActionButton
          key={'Void'}
          buttonData={{
            label: 'Void',
            onClick: handleVoid,
            color: 'error',
            buttonProps: { variant: 'contained' },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export function useConfirmVoidEsignEmailDialog(modalProps: DialogProps = {}) {
  const [, , toggleModal] = useModalsContext()

  useModal<DialogProps>({
    component: ConfirmVoidEsignEmailDialog,
    schema: {
      key: formID,
      modalProps,
    },
  })
  return useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])
}
