import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { CommonOrderFormPageState } from '@/pageComponents/orders/EditOrderPage/CommonOrderFormPageState'
import { Grid } from '@mui/material'
import JotaiMuiDateField from '../../../../../components/input/JotaiMuiDateField'

export function ExpiresOnDateField() {
  const jotaiForm = useJotaiFormContext<CommonOrderFormPageState>()
  return (
    <Grid item xs={4}>
      <JotaiMuiDateField
        atomSelector={(form) => form.orderDetail.expiresOn}
        atomUpdater={(value, draft) => {
          draft.orderDetail.expiresOn = value ?? undefined
        }}
        datePickerProps={{
          label: 'Expires on',
          disablePast: true,
        }}
        errorPath="orderDetail.expiresOn"
        form={jotaiForm}
      />
    </Grid>
  )
}
