import ActionButton from '@/components/button/actionButton'
import { WithModalParams, useModal, useModalsContext } from '@/components/state/context/modalsContext'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { NewOrderFormData } from '@/pageComponents/orders/EditOrderPage/CancelAndRestructureOrderPage'
import {
  PlanReplacementCompareView,
  PlanReplacementCompareViewProps,
} from '@/pageComponents/orders/PlanReplacement/PlanReplacementCompareView'
import { getReplacementPropsSelector } from '@/util/charge'
import buildLogger from '@/util/logger'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useCallback, useState } from 'react'
const logger = buildLogger('ViewPlanReplacementDialog')

type DialogProps = WithModalParams & PlanReplacementCompareViewProps
const formID = 'ViewPlanReplacementDialog'
function ViewPlanReplacementDialog({ open, onClose, originalPlan, replacementPlan }: DialogProps) {
  return (
    <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'} fullWidth>
      <DialogTitle>Replacement Plan</DialogTitle>
      <DialogContent dividers>
        <PlanReplacementCompareView originalPlan={originalPlan} replacementPlan={replacementPlan} />
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          key={'Cancel'}
          buttonData={{
            label: 'Cancel',
            onClick: onClose,
            color: 'inherit',
            buttonProps: { variant: 'outlined' },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export function useViewPlanReplacementDialog() {
  const [, , toggleModal] = useModalsContext()
  const [planIndex, setPlanIndex] = useState(0)
  const key = `${formID}`
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  const { originalPlan, replacementPlan } = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const plan = form.orderPlans[planIndex]

        return {
          plan,
          ...getReplacementPropsSelector(form, plan),
        }
      },
      [planIndex]
    )
  )

  useModal<DialogProps>({
    component: ViewPlanReplacementDialog,
    schema: {
      key,
      modalProps: {
        originalPlan,
        replacementPlan,
      },
    },
  })
  const toggleViewPlanReplacementDialog = useCallback(
    (planIndex: number) => {
      setPlanIndex(planIndex)
      toggleModal(key)
    },
    [toggleModal, key]
  )

  return [toggleViewPlanReplacementDialog]
}
