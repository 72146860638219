import { Cycle } from '@/generated/graphql'
import {
  DisplayStartDateType,
  DisplayTermType,
  EditTimelineDialogProps,
  EditTimelineDialogSchema,
} from '@/pageComponents/orders/EditOrderPage/cards/EditOrderTimelineCard/EditTimelineDialog'
import { calculateRampIntervals } from '@/pageComponents/orders/EditOrderPage/hooks/useJotaiApplyRampProps'
import { addDurationToUnixDate, addOneDayToUnixDate } from '@/util/datetime/luxon/dateUtil'
import buildLogger from '@/util/logger'
import { DateTime, Duration } from 'luxon'
import { useCallback, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { BRAND, z } from 'zod'
const logger = buildLogger('useRampIntervalReducer')

export const RampIntervalSelectIds = ['none', 'everyYear', 'specifiedDate'] as const

export type RampIntervalSelectId = (typeof RampIntervalSelectIds)[number]
export type RampSettingViewProps = {
  rampIntervalType: RampIntervalSelectId
  intervals: number[]
  intervalProps: { error?: string }[]
  startDate?: number
  endDate?: number
  disableSelect?: boolean

  deleteInterval?: (index: number) => void
  addInterval?: () => void
  updateInterval?: (index: number, newInterval: number) => void
  updateIntervalType?: (value: RampIntervalSelectId) => void
  disableRamp?: boolean
  startDateType?: DisplayStartDateType
}
export function formatRampIntervalType(rampIntervalType?: RampIntervalSelectId) {
  switch (rampIntervalType) {
    case 'everyYear':
      return 'Every Year'
    case 'specifiedDate':
      return 'Specified Dates'
    default:
      return 'None'
  }
}

export function useRampIntervalReducer({
  open,
  form,
  defaults,
  onChange,
}: {
  open?: boolean
  form: UseFormReturn<z.infer<typeof EditTimelineDialogSchema>>
  defaults: EditTimelineDialogProps['defaults']
  onChange?: (newValues: { intervals: number[]; rampIntervalType: RampIntervalSelectId }) => void
}) {
  const startDateType = form.watch('startDateType')
  const termType = form.watch('termType')
  const startDate = form.watch('startDate') || 0
  const endDate = (() => {
    const duration = form.watch('duration') ?? 0
    if (termType === DisplayTermType.MONTH) {
      return addDurationToUnixDate(startDate, Duration.fromObject({ months: duration }))
    }
    if (termType === DisplayTermType.YEAR) {
      return addDurationToUnixDate(startDate, Duration.fromObject({ years: duration }))
    }

    //End dates from BE are non-inclusive, so it always needs to be handled with -1 day
    return addOneDayToUnixDate(form.watch('endDate') || startDate || 0)
  })()

  const cycle = termType === DisplayTermType.MONTH ? Cycle.Month : Cycle.Year

  const [rampIntervalType, setRampIntervalType] = useState<RampIntervalSelectId>(defaults.rampIntervalType ?? 'none')
  const [intervals, setIntervals] = useState<number[]>(defaults.intervals ?? [])

  const intervalProps = intervals.map((interval, index) => {
    const isOutOfRange = !!startDate && !!endDate && (interval >= endDate || interval < startDate)
    let error = isOutOfRange ? 'Interval is out of range' : ''
    if (index === 0) {
      // first interval is always disabled so no error should be shown
      error = ''
    }
    if (intervals.findIndex((_interval) => interval === _interval) !== index) {
      error = 'Interval cannot be duplicated'
    }
    return {
      error,
    }
  })

  const addInterval = () => {
    const lastInterval = intervals[intervals.length - 1]
    setIntervals((intervals) => {
      if (intervals.length > 0 && lastInterval !== undefined) {
        return [...intervals, DateTime.fromSeconds(lastInterval).plus({ days: 1 }).toUnixInteger()]
      } else {
        return [startDate]
      }
    })
  }

  const updateInterval = (index: number, newInterval: number) => {
    logger.info({ index, newInterval })
    setIntervals((intervals) =>
      intervals.map((interval, i) => {
        return i === index ? newInterval : interval
      })
    )
  }

  const deleteInterval = (index: number) => {
    setIntervals((intervals) => intervals.filter((_, i) => i !== index))
  }

  const clearIntervals = () => {
    setIntervals([])
  }

  const [yearlyInterval, setYearlyIntervals] = useState<number[]>(
    calculateRampIntervals(startDate, null, { cycle: cycle ?? Cycle.Year, step: 1 }, endDate)
  )

  const updateIntervalType = useCallback(
    (value: RampIntervalSelectId) => {
      setRampIntervalType(value)
      switch (value) {
        case 'everyYear':
          setIntervals(yearlyInterval)
          setRampIntervalType('everyYear')
          break
        case 'specifiedDate':
          setIntervals((intervals) => {
            return intervals.length === 0 ? yearlyInterval : intervals
          })
          setRampIntervalType('specifiedDate')
          break
        default:
          setRampIntervalType('none')
          clearIntervals()
          break
      }
    },
    [yearlyInterval]
  )

  /**
   * SIDE EFFECTS
   */
  useEffect(() => {
    const newYearly = calculateRampIntervals(startDate, null, { cycle: cycle ?? Cycle.Year, step: 1 }, endDate)
    setYearlyIntervals(newYearly)
    if (rampIntervalType === 'everyYear') {
      setIntervals(newYearly)
    } else if (rampIntervalType === 'specifiedDate') {
      setIntervals((prev) => [startDate, ...prev.slice(1)])
    }

    // only watch for the dependencies that required by interval calculation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, cycle])

  useEffect(() => {
    onChange?.({
      rampIntervalType,
      intervals,
    })
  }, [rampIntervalType, intervals, onChange])

  useEffect(() => {
    if (startDateType === DisplayStartDateType.EXECUTION_DATE) {
      setRampIntervalType('none')
      setIntervals([])
      form.setValue('termType', DisplayTermType.YEAR as string & BRAND<'select'>)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateType])

  useEffect(() => {
    if (open) {
      form.reset({
        startDate: defaults.startDate,
        endDate: defaults.endDate,
        duration: defaults.duration,
        termType: defaults.termType,
        startDateType: defaults.startDateType,
      })
      const newYearly = calculateRampIntervals(
        defaults.startDate,
        null,
        {
          cycle: defaults.termType === DisplayTermType.MONTH ? Cycle.Month : Cycle.Year,
          step: defaults.duration,
        },
        defaults.endDate ? addOneDayToUnixDate(defaults.endDate) : undefined
      )
      const intervalHasYearlyGap = newYearly.join() === defaults.intervals.join()
      const newRampIntervalType = intervalHasYearlyGap ? 'everyYear' : defaults.rampIntervalType ?? 'none'
      setRampIntervalType(newRampIntervalType)
      setIntervals(defaults.intervals ?? [])
    }
  }, [open, defaults, form])

  return {
    /**
     * VISUALS
     */
    disableRamp: !startDate || startDateType === DisplayStartDateType.EXECUTION_DATE,
    hasError: intervalProps.some((props) => !!props.error),

    /**
     * DISPLAY VALUES
     */
    startDateType,
    termType,
    startDate,
    endDate,

    /**
     * ACTIONS
     */
    rampIntervalType,
    intervals,
    intervalProps,
    updateIntervalType,
    updateInterval,
    deleteInterval,
    addInterval,
  }
}
