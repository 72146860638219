import { JotaiForm } from '@/components/state/useJotaiForm'
import { getLineItemCondition } from '@/util/charge'
import { Grid, InputAdornment } from '@mui/material'
import { Box } from '@mui/system'
import Big from 'big.js'
import { useCallback } from 'react'
import JotaiMuiNumberField from '../../../components/input/JotaiMuiNumberField'
import { ActionType } from '../../../generated/graphql'
import { currencySymbol, getOverrideRatio, unitPriceFormatter } from '../../../util/currencyUtil'
import { LineItemPriceOverrideDialogState } from './LineItemPriceOverrideDialog'
import { LineItemsTableOrderType } from '@/pageComponents/orders/LineItemsEditTable/LineItemsEditTable'
import { Draft } from 'immer'

export interface SinglePriceOverrideContentProps<F extends LineItemPriceOverrideDialogState> {
  jotaiForm: JotaiForm<F>
  orderType: LineItemsTableOrderType
  lineItemIndex: number
}

export const SinglePriceOverrideContent = <F extends LineItemPriceOverrideDialogState>({
  jotaiForm,
  orderType,
  lineItemIndex,
}: SinglePriceOverrideContentProps<F>): JSX.Element => {
  const action = jotaiForm.useSelect(
    useCallback((form) => form.orderDetail.lineItems[lineItemIndex]?.action || ActionType.Add, [lineItemIndex])
  )

  const currency = jotaiForm.useSelect(useCallback((form) => form.orderDetail.currency ?? 'USD', []))

  const [, isPriceOverrideDisabled] = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const lineItem = form?.orderDetail?.lineItems?.[lineItemIndex]
        const { allowDiscount, allowGoalSeeking } = getLineItemCondition(lineItem)
        return [!allowDiscount, !allowGoalSeeking]
      },
      [lineItemIndex]
    )
  )

  const baseUnitPrice = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const lineItem = form?.orderDetail?.lineItems?.[lineItemIndex]
        return lineItem?.listUnitPriceBeforeOverride ?? lineItem?.listUnitPrice ?? 0.0
      },
      [lineItemIndex]
    )
  )

  const disabledExplanation = 'Add a charge quantity to set a price override'

  if (
    !(orderType === 'NEW' || orderType === 'RESTRUCTURE' || (orderType === 'AMENDMENT' && action === ActionType.Add))
  ) {
    return <></>
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} role="gridcell">
        <Box display="flex">
          <Box>
            <JotaiMuiNumberField
              atomSelector={(form: F) => {
                const listPriceOverrideRatio = form.orderDetail.lineItems[lineItemIndex]?.listPriceOverrideRatio
                if (!listPriceOverrideRatio && listPriceOverrideRatio !== 0) {
                  return 1.0
                }
                return listPriceOverrideRatio
              }}
              atomUpdater={(value, draft: Draft<F>) => {
                const lineItem = draft.orderDetail.lineItems[lineItemIndex]
                if ((value || value === 0.0) && value < +1000.0 && value >= +0.0) {
                  lineItem.listPriceOverrideRatio = +value.toString()
                  lineItem.arrOverride = undefined
                }
                if (value === 1.0) {
                  lineItem.listPriceOverrideRatio = undefined
                  lineItem.arrOverride = undefined
                }
              }}
              errorPath={`orderDetail.lineItems[${lineItemIndex}].listPriceOverrideRatio`}
              form={jotaiForm}
              percent={true}
              unrestrictedPercentRange={true}
              maxDecimalPlaces={8}
              textFieldProps={{
                'aria-label': 'Price Override Ratio',
                placeholder: '0',
                size: 'small',
                label: 'Override',
                disabled: isPriceOverrideDisabled,
                'aria-disabled': isPriceOverrideDisabled,
                inputProps: {
                  sx: { height: '2.5rem', textAlign: 'right' },
                },
                helperText: 'Define an increase or decrease in the list price',
              }}
              disabledExplanation={isPriceOverrideDisabled && disabledExplanation}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} style={{ marginBottom: '1rem' }} role="gridcell">
        <Grid item display="flex">
          <Box
            sx={{
              flexFlow: 'row',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box>
              <JotaiMuiNumberField
                atomSelector={(form: F) => {
                  const lineItem = form.orderDetail.lineItems?.[lineItemIndex]
                  const overrideRatio = lineItem.listPriceOverrideRatio ?? 1.0
                  const overridePrice = Big(baseUnitPrice).times(Big(overrideRatio))
                  return +overridePrice.toFixed(5, 1)
                }}
                atomUpdater={(overrideListUnitPrice, draft: Draft<F>) => {
                  const lineItem = draft.orderDetail.lineItems?.[lineItemIndex]
                  if (!overrideListUnitPrice && overrideListUnitPrice !== 0.0) {
                    lineItem.listPriceOverrideRatio = undefined
                    lineItem.arrOverride = undefined
                    return
                  }
                  const ratio = getOverrideRatio(overrideListUnitPrice, baseUnitPrice)
                  if ((ratio || ratio === 0.0) && +ratio < 1000.0 && +ratio >= 0.0) {
                    lineItem.listPriceOverrideRatio = +ratio
                    lineItem.arrOverride = undefined
                  }
                }}
                errorPath={`orderDetail.lineItems[${lineItemIndex}].listPriceOverrideRatio`}
                form={jotaiForm}
                textFieldProps={{
                  'aria-label': 'Price Override',
                  placeholder: 'Price Override',
                  size: 'small',
                  label: 'Price Override',
                  disabled: isPriceOverrideDisabled,
                  'aria-disabled': isPriceOverrideDisabled,
                  inputProps: {
                    startAdornment: (
                      <InputAdornment position="start">{currencySymbol(currency ?? 'USD')}</InputAdornment>
                    ),
                    sx: { height: '2.5rem', textAlign: 'right' },
                  },
                  helperText: `List price is ${unitPriceFormatter({
                    currency,
                    value: baseUnitPrice,
                  })}`,
                }}
                disabledExplanation={isPriceOverrideDisabled && disabledExplanation}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}
