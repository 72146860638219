import {
  DateFormatStr,
  DateStr,
  IDate,
  ISO8601DateTimeStr,
  TDateTime,
  TimeZoneStr,
  UnixtimeSecondsAsNumber,
} from '../datetimeTypes'
import { DateTime, DateTimeUnit, Duration, DurationLikeObject, Settings } from 'luxon'
import buildLogger from '../../logger'
import { ForwardedSchemaFormLuxonDatePickerProps } from '../../../components/SchemaForm/types/DateType'
import { BillingCycleFragment, Cycle, OrderDetailFragment } from '../../../generated/graphql'

const logger = buildLogger('luxon/dateUtil')

export const DEFAULT_MONTH_FORMAT = 'MMM yyyy'
export const FULL_MONTH_FORMAT = 'MMMM yyyy'
export const DEFAULT_DATE_FORMAT = 'MMM d yyyy'
export const DEFAULT_TIME_FORMAT = 'MMM d yyyy h:mm a z'
export const DEFAULT_TIME_FORMAT_WITHOUT_TZ = 'MMM d yyyy h:mm a'
export const DEFAULT_DATE_PICKER_FORMAT = 'MM/dd/yyyy'
export const DEFAULT_DATE_TIME_PICKER_FORMAT = 'MM/dd/yyyyTHH:mm'
export const DEFAULT_TIMEZONE = 'UTC-8'
export const DEFAULT_DATETIME_FORMAT = 'yyyy-MM-dd HH:mm:ss'

let timeZone = DEFAULT_TIMEZONE

//https://moment.github.io/luxon/#/zones?id=changing-the-default-zone
// https://github.com/moment/luxon/issues/1089
Settings.defaultZone = DEFAULT_TIMEZONE

export function setTimeZone(newTimeZone: string) {
  timeZone = newTimeZone
  Settings.defaultZone = newTimeZone
}

export function getTimeZone() {
  return timeZone
}

export const datePickerFormatToUnixDate = (dateString?: string | null): number | null => {
  if (!dateString) {
    return null
  }

  try {
    const unix = DateTime.fromFormat(dateString, DEFAULT_DATE_PICKER_FORMAT, {
      zone: getTimeZone(),
    }).toUnixInteger()
    return unix
  } catch (err) {
    return null
  }
}

export const formatISODate = (dateStr: DateStr | null | undefined, format?: string, timezone?: string): string => {
  if (!dateStr) {
    return ''
  }
  const dateTime = DateTime.fromISO(dateStr, { zone: timezone || getTimeZone() })

  if (!dateTime.isValid) {
    return ''
  }
  return dateTime.toFormat(format || DEFAULT_DATE_FORMAT)
}

export const defaultZoneToUTCOffset = (zone: string) => {
  const date = DateTime.fromSeconds(0, { zone: zone })
  if (date.isValid) {
    return 'UTC' + date.toFormat('Z')
  }
  return 'UTC'
}

export const deriveDateTimeFromUnixWithTZ = ({
  unixtime,
  timezone,
}: {
  unixtime?: number | null
  timezone?: string
}) => {
  {
    const pickerDateTime = unixtime
      ? DateTime.fromSeconds(unixtime, { zone: timezone ?? getTimeZone() }).startOf('day')
      : null
    return pickerDateTime
  }
}

export function computeDateFromSystemTZtoTargetTZ(
  newDateAsUnixtime: UnixtimeSecondsAsNumber,
  targetTimeZone: TimeZoneStr,
  systemTimeZone: TimeZoneStr
): UnixtimeSecondsAsNumber {
  const dateFromUnixtime = dateStrFromUnixTimeWithTimezone(newDateAsUnixtime, systemTimeZone)

  return unixTimeSecondsFromDateStr(dateFromUnixtime, targetTimeZone)
}

export function formatLuxonDatePickerProps(props?: ForwardedSchemaFormLuxonDatePickerProps) {
  const { maxDate, minDate, disableFuture, disablePast, ...restDatePickerProps } = props ?? {}
  return {
    ...restDatePickerProps,
    maxDate: disableFuture
      ? DateTime.now().startOf('day')
      : maxDate
      ? unixTimeSecondsToLuxon(unixTimeSecondsFromISO8601(maxDate))
      : undefined,
    minDate: disablePast
      ? DateTime.now().startOf('day')
      : minDate
      ? unixTimeSecondsToLuxon(unixTimeSecondsFromISO8601(minDate))
      : undefined,
  }
}

export function dateStrToObject(date: DateStr, template?: string): IDate | undefined {
  const parsed = DateTime.fromFormat(date, template ?? 'yyyy-MM-dd')
  if (parsed.invalidReason) {
    return undefined
  }
  return { year: +parsed.year.toString(), month: +parsed.month.toString(), day: +parsed.day.toString() }
}

export function dateStrFromUnixTimeWithTimezone(
  unixtime: UnixtimeSecondsAsNumber,
  timezone: TimeZoneStr,
  opt?: { startOf?: DateTimeUnit }
): DateStr {
  const { startOf } = opt || {}
  const dateWithTimezone = DateTime.fromSeconds(unixtime).setZone(timezone)
  return dateStrFromLuxon(startOf ? dateWithTimezone.startOf(startOf) : dateWithTimezone)
}

export function dateStrFromUnixTime(unixtime: UnixtimeSecondsAsNumber): DateStr {
  const dateWithTimezone = unixTimeSecondsToLuxon(unixtime)
  return dateStrFromLuxon(dateWithTimezone)
}

export function dateStrFromLuxon(dateTime: DateTime): DateStr {
  return dateTime.toISODate()
}

export function unixTimeSecondsFromLuxon(dateTime: DateTime) {
  return dateTime.toUnixInteger()
}

export function unixTimeSecondsFromDate(date: Date) {
  return Math.floor(date.getTime() / 1000)
}

export function unixTimeSecondsFromISO8601(datetime: ISO8601DateTimeStr) {
  return DateTime.fromISO(datetime).toUnixInteger()
}

/**
 * Take a date representation and convert it to a unixtime.
 */
export function unixTimeSecondsFromDateStr(date: DateStr, timezone: TimeZoneStr): UnixtimeSecondsAsNumber {
  const parsedDate = dateStrToObject(date)
  if (!parsedDate) return 0
  const adjustedDate = DateTime.fromObject(parsedDate, { zone: timezone }).startOf('day')

  return unixTimeSecondsFromLuxon(adjustedDate)
}

export function unixTimeSecondsToLuxon(unixtime: UnixtimeSecondsAsNumber) {
  if (!unixtime) {
    return DateTime.now()
  }
  return DateTime.fromSeconds(unixtime)
}

export function unixTimeSecondsToDate(unixtime: UnixtimeSecondsAsNumber): Date {
  return new Date(unixtime * 1000)
}

export function unixTimeSecondsToISO(
  unixtime: UnixtimeSecondsAsNumber,
  timezone: TimeZoneStr,
  opt?: { startOf?: DateTimeUnit }
) {
  const { startOf } = opt ?? {}
  const dateTimeWithTZ = unixTimeSecondsToLuxon(unixtime).setZone(timezone)
  const dayStart = startOf ? dateTimeWithTZ.startOf(startOf) : dateTimeWithTZ
  if (dayStart.isValid) {
    return dayStart.setZone(timezone).toISO()
  } else {
    return ''
  }
}

export function unixTimeSecondsToDateStr(unixtime: UnixtimeSecondsAsNumber, timezone: TimeZoneStr): DateStr {
  const dateWithTimezone = unixTimeSecondsToLuxon(unixtime).setZone(timezone)

  return dateWithTimezone.toISODate() as DateStr
}

export function unixTimeSecondsToDateTimeStr(unixtime: UnixtimeSecondsAsNumber) {
  const dateTime = unixTimeSecondsToLuxon(unixtime)
  return dateTime.toFormat(DEFAULT_DATETIME_FORMAT)
}

export function unixTimeSecondsNow(): UnixtimeSecondsAsNumber {
  return Math.floor(Date.now() / 1000)
}

export const currentDateTimeInTenantTz = (tenantZone?: string) => {
  const dateTime = DateTime.now().setZone(tenantZone ?? getTimeZone())
  return dateTime
}

export const utcStringToLuxon = (utcString: string) => {
  const validFormat = [
    "yyyy-MM-dd'T'HH:mm:ss.SSSSSS",
    "yyyy-MM-dd'T'HH:mm:ss",
    'yyyy-MM-dd',
    'yyyy MM dd HH:mm:ss.SSSSSS',
    'yyyy MM dd',
    'yyyy MM dd HH:mm:ss',
  ]

  let standardISO = utcString
  if (standardISO.split(' ').length === 2 && !standardISO.includes('T')) {
    standardISO = standardISO.replace(' ', 'T')
  }
  const parsedDate = DateTime.fromISO(standardISO, { zone: 'UTC' })
  if (parsedDate.isValid) {
    return parsedDate
  } else {
    for (const format of validFormat) {
      const parseDate = DateTime.fromFormat(standardISO, format, { zone: 'UTC' })
      if (parseDate.isValid) {
        return parseDate
      }
    }
    return undefined
  }
}
export const utcStringToUnix = (utcString?: string | null): number => {
  if (!utcString) {
    return 0
  }
  const dateTime = utcStringToLuxon(utcString)
  if (dateTime?.isValid) {
    return dateTime.toUnixInteger()
  } else {
    return 0
  }
}

export function dateStrToLuxonWithTZ(dateStr: DateStr | undefined | null, timezone: string): DateTime | undefined {
  if (!dateStr) return undefined
  const date = DateTime.fromISO(dateStr, { zone: timezone })
  if (!date.isValid) return undefined
  return date
}

export function unixTimeSecondsDayStartWithTZ(unixTimeSeconds: number, timezone: string) {
  if (!unixTimeSeconds) {
    return DateTime.now().startOf('day')
  }
  return DateTime.fromSeconds(unixTimeSeconds, { zone: timezone }).startOf('day')
}

//TODO: make timezone required
export const unixTimeSecondsToLuxonWithTz = (unixTime: number | undefined | null, timezone?: string) => {
  if (!unixTime) return DateTime.fromSeconds(0, { zone: timezone ?? getTimeZone() })
  return DateTime.fromSeconds(unixTime, { zone: timezone ?? getTimeZone() })
}
export function luxonDayStart(unixTimeSeconds: number) {
  const unix = DateTime.fromSeconds(unixTimeSeconds).startOf('day').toUnixInteger()
  return {
    toUnixInteger: () => unix,
    unix: () => unix,
  }
}

export function luxonDayEnd(unixTimeSeconds: number) {
  const unix = DateTime.fromSeconds(unixTimeSeconds).endOf('day').toUnixInteger()
  return {
    toUnixInteger: () => unix,
    unix: () => unix,
  }
}

export const formatUtcString = (
  utcString?: string | null,
  formatTemplate: string = DEFAULT_DATE_FORMAT,
  isEndDate = false
) => {
  if (!utcString) return ''
  const dateTime = utcStringToLuxon(utcString)?.setZone(timeZone)
  if (dateTime?.isValid) {
    // Date ranges are left inclusive right exclusive. For a 1 year duration from Jan 1, 2022 00:00:00, the end date is Jan 1, 2023 00:00:00
    // isEndDate indicates this function is called for end of the range display so remove 1 second from the end to produce left and right inclusive date range
    // In the above example this should show the day before (Dec 31, 2022 23:59:59)
    const retDateTime = isEndDate ? dateTime.minus(Duration.fromObject({ second: 1 })) : dateTime
    return retDateTime.toFormat(formatTemplate ?? DEFAULT_DATE_FORMAT)
  }
}

export const subtractOneDayFromUnixDate = (unixTime: number): number => {
  if (!unixTime) {
    return DateTime.now().toUnixInteger()
  }
  return DateTime.fromSeconds(unixTime)
    .minus(Duration.fromObject({ day: 1 }))
    .toUnixInteger()
}

export const addOneDayToUnixDate = (unixTime: number): number => {
  if (!unixTime) {
    return DateTime.now().toUnixInteger()
  }
  return DateTime.fromSeconds(unixTime)
    .plus(Duration.fromObject({ day: 1 }))
    .toUnixInteger()
}

export const addOneYearToUnixDate = (unixTime: number): number => {
  if (!unixTime) {
    return DateTime.now().toUnixInteger()
  }
  return DateTime.fromSeconds(unixTime)
    .plus(Duration.fromObject({ year: 1 }))
    .toUnixInteger()
}

export const addDurationToUnixDate = (unixTime: number, duration: Duration): number => {
  if (!unixTime) {
    return DateTime.now().toUnixInteger()
  }
  return DateTime.fromSeconds(unixTime).plus(duration).toUnixInteger()
}

export const unixToUtcString = (unixTime?: number | null): string => {
  if (!unixTime) {
    return ''
  }
  return DateTime.fromSeconds(unixTime, { zone: 'UTC' }).toISO()
}

export const formatUnixDate = (unixTime?: number | null | undefined, format?: string, timeZone?: string): string => {
  if (!unixTime) {
    return ''
  }
  const dateTime = DateTime.fromSeconds(unixTime, { zone: timeZone })
  if (dateTime.isValid) {
    return dateTime.toFormat(format ?? DEFAULT_DATE_FORMAT)
  } else {
    return ''
  }
}

export const formatDurationBetweenDates = (
  start?: number | null | undefined,
  end?: number | null | undefined
): string => {
  if (!start || !end) {
    return ''
  }

  const startDateStr = DateTime.fromSeconds(start, { zone: timeZone })
  const endDateStr = DateTime.fromSeconds(end, { zone: timeZone })

  const duration = endDateStr.diff(startDateStr, ['years', 'months', 'days'])
  const formattedDuration =
    (duration.years ? `${duration.years} year${duration.years > 1 ? 's' : ''}` : '') +
    (duration.months ? ` ${duration.months} month${duration.months > 1 ? 's' : ''}` : '') +
    (duration.days ? ` ${duration.days} day${duration.days > 1 ? 's' : ''}` : '')

  return formattedDuration
}

export const formatISOTime = (dateString: string | null | undefined, format?: string): string => {
  return formatISODate(dateString, format ?? DEFAULT_TIME_FORMAT)
}

// to format right-exclusive end date, exclude the last second and then format the date
// eg: 2022-02-01T08:00:00Z to 2022-02-01T08:00:00Z
// should be displayed as Jan 1 2022 to Jan 31 2022
export const formatISOEndDate = (dateString?: string | null): string | null => {
  if (!dateString) {
    return null
  }

  try {
    return formatUnixDate(utcStringToUnix(dateString) - 1)
  } catch (err) {
    return null
  }
}

export const unixToLuxonWithTz = unixTimeSecondsToLuxonWithTz

export function TDateTimeToJSDate(dateTime: TDateTime): Date {
  if (typeof dateTime === 'string') {
    return new Date(dateTime)
  }

  if (typeof dateTime === 'number') {
    return new Date(dateTime * 1000)
  }

  return dateTime
}

export function formatTDateTimeWithTimezone(date: TDateTime, timezone: TimeZoneStr, format: DateFormatStr): string {
  return DateTime.fromJSDate(TDateTimeToJSDate(date), { zone: timezone }).toFormat(format)
}

export function dateStringToCreditCardFormat(dateString: string): string {
  const date = DateTime.fromISO(dateString)
  if (date.isValid) {
    return date.toFormat('MM/yy')
  } else {
    return ''
  }
}

export function billingCycleToLuxonDuration(billingCycle: BillingCycleFragment): DurationLikeObject {
  switch (billingCycle.cycle) {
    case Cycle.Month:
      return { month: 1 * billingCycle.step }
    case Cycle.Quarter:
      return { quarter: 1 * billingCycle.step }
    case Cycle.SemiAnnual:
      return { month: 6 * billingCycle.step }
    case Cycle.Year:
      return { year: 1 * billingCycle.step }
    default:
      return { day: 0 }
  }
}

export function termLengthToDuration(termLength?: OrderDetailFragment['termLength']): Duration {
  switch (termLength?.cycle) {
    case Cycle.Month:
      return Duration.fromObject({
        month: termLength.step ?? 0,
      })
    case Cycle.Year:
      return Duration.fromObject({
        year: termLength.step ?? 0,
      })
    default:
      return Duration.fromObject({})
  }
}

export function formatLuxonDate(args: {
  date?: DateTime | null | undefined
  format: string
  timezone?: string
}): string {
  const { date, format, timezone } = args
  return date?.setZone(timezone).toFormat(format) ?? ''
}

export const Dates = {
  dateStrToObject,
  dateStrFromUnixTimeWithTimezone,
  dateStrFromUnixTime,
  dateStrFromLuxon,
}

export const UnixtimeSeconds = {
  fromLuxon: unixTimeSecondsFromLuxon,
  fromDate: unixTimeSecondsFromDate,
  fromISO8601: unixTimeSecondsFromISO8601,
  fromDateStr: unixTimeSecondsFromDateStr,
  toLuxon: unixTimeSecondsToLuxon,
  toDate: unixTimeSecondsToDate,
  toISO: unixTimeSecondsToISO,
  toDateStr: unixTimeSecondsToDateStr,
  toDateTimeStr: unixTimeSecondsToDateTimeStr,
  now: unixTimeSecondsNow,
}
