import { AxiosError } from 'axios'
import { Api } from '../../generated/rest'

export const REST_BASE_URL = '/api/backend'
export const NEXT_API_BASE_URL = '/api'

export const billyRestClient = new Api({
  baseURL: REST_BASE_URL,
})

export const nextApiClient = new Api({
  baseURL: NEXT_API_BASE_URL,
})

export function isApiError(error: unknown): error is AxiosError<{
  code: number
  message: string
}> {
  return error instanceof AxiosError
}
