import { useShouldRecalculateTotals } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import Big from 'big.js'
import { useCallback } from 'react'
import { useJotaiFormContext } from '../../../components/state/jotaiFormProvider'
import { billableCurrencyFormat } from '../../../util/currencyUtil'
import { NewOrderFormData } from '../EditOrderPage/NewOrderPage'
import { LineItemCellLoadingIndicator } from './Cells/LineItemCellLoadingIndicator'

export function ChargeTotal({
  lineItemIndexes,
  currency,
}: {
  lineItemIndexes: number[]
  currency?: string
}): JSX.Element {
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  const shouldRecalculateTotals = useShouldRecalculateTotals()

  const { formattedTotal } = jotaiForm.useSelect(
    useCallback(
      (form: NewOrderFormData) => {
        return {
          formattedTotal: billableCurrencyFormat({
            currency,
            value: (
              form.orderDetail.lineItems
                .filter((lineItem, index) => {
                  return lineItemIndexes.includes(index)
                })
                .reduce((total, lineItem) => {
                  return Big(lineItem.amount || 0).add(total)
                }, Big(0)) || Big(0)
            ).toNumber(),
          }),
        }
      },
      [lineItemIndexes, currency]
    )
  )
  return <div>{shouldRecalculateTotals ? <LineItemCellLoadingIndicator /> : formattedTotal}</div>
}
