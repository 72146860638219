import {
  AutocompleteMultiSelect,
  AutocompleteSchema,
  AutocompleteSelect,
  OptionalAutocompleteMultiSchema,
  OptionalAutocompleteSchema,
} from '@/components/BillyTSForm/fields/AutocompleteSelect'
import { RadioGroupField, RadioGroupSchema } from '@/components/BillyTSForm/fields/RadioGroupField'
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, RegularBreakpoints } from '@mui/material'
import { createTsForm } from '@ts-react/form'
import type { RTFFormSchemaType } from '@ts-react/form/lib/src/createSchemaForm'
import React, { ReactNode } from 'react'
import { z } from 'zod'
import { WithBaseModalParams } from '../state/context/modalsContext'
import { AutocompleteWithCreateField, AutocompleteWithCreateSchema } from './fields/AutocompleteWithCreateField'
import { BooleanField } from './fields/BooleanField'
import {
  DateField,
  DateSchema,
  EndOfDateField,
  OptionalDateSchema,
  OptionalEndOfDateFieldSchema,
} from './fields/DateField'
import {
  EsAutocompleteField,
  EsAutocompleteSchema,
  EsMultiAutocompleteField,
  EsMultiAutocompleteSchema,
} from './fields/EsAutocompleteField'
import { MultiValueCreateField, MultiValueCreateSchema } from './fields/MultiValueCreateField'
import { NumberField } from './fields/NumberField'
import { PriceTableCsvUploadField, PriceTableCsvUploadFieldSchema } from './fields/PriceTableCsvUploadField'
import {
  MultiSelectField,
  OptionalSelectMultiSchema,
  OptionalSelectSchema,
  SelectField,
  SelectMultiSchema,
  SelectSchema,
} from './fields/SelectField'
import { StringField } from './fields/StringField'

// create the mapping
const mapping = [
  [z.string(), StringField],
  [z.number(), NumberField],
  [z.boolean(), BooleanField],
  [DateSchema, DateField],
  [OptionalDateSchema, DateField],
  [OptionalEndOfDateFieldSchema, EndOfDateField],
  [SelectSchema, SelectField],
  [OptionalSelectSchema, SelectField],
  [SelectMultiSchema, MultiSelectField],
  [OptionalSelectMultiSchema, MultiSelectField],
  [EsAutocompleteSchema, EsAutocompleteField],
  [EsMultiAutocompleteSchema, EsMultiAutocompleteField],
  [AutocompleteWithCreateSchema, AutocompleteWithCreateField],
  [PriceTableCsvUploadFieldSchema, PriceTableCsvUploadField],
  [MultiValueCreateSchema, MultiValueCreateField],
  [AutocompleteSchema, AutocompleteSelect],
  [OptionalAutocompleteSchema, AutocompleteSelect],
  [OptionalAutocompleteMultiSchema, AutocompleteMultiSelect],
  [RadioGroupSchema, RadioGroupField],
] as const // 👈 `as const` is necessary

export interface LayoutProps {
  layout?: RegularBreakpoints
  hidden?: boolean
}

export interface DisabledProps {
  disabledExplanation?: string
}

function FormContainer({ onSubmit, children, id }: { onSubmit: () => void; children: ReactNode; id?: string }) {
  return (
    <form id={id} onSubmit={onSubmit}>
      {children}
    </form>
  )
}

export const BillyTsFormWrapper = createTsForm(mapping, { FormComponent: FormContainer })

export type BillyTsFormParams<SchemaType extends RTFFormSchemaType> = Parameters<
  typeof BillyTsFormWrapper<SchemaType>
>[0] & {
  ignoreGrid?: boolean
}

export function BillyTsForm<SchemaType extends RTFFormSchemaType>(props: BillyTsFormParams<SchemaType>) {
  const { ignoreGrid, children } = props
  return (
    <BillyTsFormWrapper {...props}>
      {(props) => {
        const { ...fields } = props
        if (ignoreGrid) {
          return <>{children ? children(props) : Object.values(fields)}</>
        } else
          return (
            <Grid container rowSpacing={2} columnSpacing={1} pt={1} pb={1}>
              {children ? children(props) : Object.values(fields)}
            </Grid>
          )
      }}
    </BillyTsFormWrapper>
  )
}

export type BillyTsDialogFormParams<SchemaType extends RTFFormSchemaType> = WithBaseModalParams &
  Parameters<typeof BillyTsFormWrapper<SchemaType>>[0] & {
    dialogTitle: string
    CustomDialogActions: () => JSX.Element
    renderAfterForm?: JSX.Element
    renderBeforeForm?: JSX.Element
  }

/**
 * @deprecated
 */
export function BillyTsDialogForm<SchemaType extends RTFFormSchemaType>(props: BillyTsDialogFormParams<SchemaType>) {
  const { open, onClose, CustomDialogActions, dialogTitle, renderAfterForm, renderBeforeForm } = props
  return (
    <Dialog open={!!open} onClose={onClose} fullWidth>
      <BillyTsForm {...props} ignoreGrid={true}>
        {({ ...fields }) => {
          return (
            <>
              <DialogTitle>{dialogTitle}</DialogTitle>
              <Divider />
              <DialogContent>
                {renderBeforeForm}
                <Grid container rowSpacing={2} columnSpacing={1} pt={1} pb={1}>
                  {Object.values(fields)}
                </Grid>
                {renderAfterForm}
              </DialogContent>
              <Divider />
              <DialogActions>
                <CustomDialogActions />
              </DialogActions>
            </>
          )
        }}
      </BillyTsForm>
    </Dialog>
  )
}
