import { BillyMuiNumberField } from '@/components/input/JotaiMuiNumberField'
import { MutableObject } from '@/components/SchemaForm/DeepMutable'
import { useCurrencyContext } from '@/components/state/context/CurrencyContext'
import { JotaiForm } from '@/components/state/useJotaiForm'
import { PriceTier, PricingOverrideFragment } from '@/generated/graphql'
import { LineItemsTableOrderType } from '@/pageComponents/orders/LineItemsEditTable/LineItemsEditTable'
import { PriceTiersTable } from '@/pageComponents/orders/PriceTiersTable'
import { AddCircleOutlineOutlined, CancelOutlined as CancelOutlinedIcon } from '@mui/icons-material'
import { Box, Button, Grid, IconButton } from '@mui/material'
import { WritableDraft } from 'immer/dist/internal'
import { pick } from 'lodash'
import { useCallback } from 'react'
import { LineItemPriceOverrideDialogState } from './LineItemPriceOverrideDialog'
import { usePriceTierOverrideReducer } from './usePriceTierOverrideReducer'
export interface TieredPriceOverrideContentProps<F extends LineItemPriceOverrideDialogState> {
  jotaiForm: JotaiForm<F>
  orderType: LineItemsTableOrderType
  lineItemIndex: number
  readonly?: boolean
}

export const TieredPriceOverrideFormId = 'TieredPriceOverrideForm'
export const TieredPriceOverrideContent = <F extends LineItemPriceOverrideDialogState>({
  jotaiForm,
  lineItemIndex,
  readonly = false,
}: TieredPriceOverrideContentProps<F>): JSX.Element => {
  const [currency] = useCurrencyContext()
  const { initial, original } = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const lineItem = form.orderDetail.lineItems[lineItemIndex]
        const original = pick(lineItem?.charge, ['priceTiers', 'minQuantity', 'maxQuantity'])
        return {
          initial: (lineItem?.pricingOverride
            ? pick(lineItem?.pricingOverride, ['priceTiers', 'minQuantity', 'maxQuantity'])
            : original) as PricingOverrideFragment,
          original,
        }
      },
      [lineItemIndex]
    )
  )

  const { tiers, errors, hasError, override, addTier, deleteTier, updateTier, revertTiers } =
    usePriceTierOverrideReducer(initial, original)

  const handleSave = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      if (!hasError) {
        jotaiForm.set((draft) => {
          draft.orderDetail.lineItems[lineItemIndex].pricingOverride = override as WritableDraft<
            MutableObject<PricingOverrideFragment>
          >
        })
      }
    },
    [hasError, jotaiForm, lineItemIndex, override]
  )

  if (readonly) {
    return (
      <PriceTiersTable
        priceTiers={(override.priceTiers ?? []) as PriceTier[]}
        currency={currency ?? 'USD'}
        minQuantity={override.minQuantity}
        maxQuantity={override.maxQuantity}
      />
    )
  }

  return (
    <form onSubmit={handleSave} onReset={revertTiers} id={TieredPriceOverrideFormId}>
      {tiers.map((tier, index) => (
        <Grid container spacing={2} key={index} sx={{ mt: 1 }}>
          <Grid item xs={4}>
            <BillyMuiNumberField
              error={errors[index].from}
              textFieldProps={{
                value: parseInt(tier.from),
                onChange: (value) => updateTier(index, { from: value, to: tier.to }),
                label: index === 0 ? 'From (optional)' : 'From',
                size: 'small',
                placeholder: '0',
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <BillyMuiNumberField
              error={errors[index].to}
              textFieldProps={{
                value: parseInt(tier.to),
                onChange: (value) => updateTier(index, { from: tier.from, to: value }),
                label: index === tiers.length - 1 ? 'To (optional)' : 'To',
                size: 'small',
                placeholder: tier.untilQuantity === 'INF' ? 'No maximum' : '',
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <BillyMuiNumberField
              error={errors[index].amount}
              textFieldProps={{
                value: tier.amount,
                onChange: (value) => updateTier(index, { amount: value || 0, from: tier.from, to: tier.to }),
                label: 'Price',
                size: 'small',
                placeholder: '0',
              }}
            />
          </Grid>
          <Grid item xs={1}>
            {index > 0 && (
              <IconButton onClick={() => deleteTier(index)} size="small">
                <CancelOutlinedIcon color="primary" />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ))}
      <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
        <Button onClick={addTier} color="primary" startIcon={<AddCircleOutlineOutlined />}>
          Add Tier
        </Button>
      </Box>
    </form>
  )
}
