import { Dialog } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import JotaiUrqlErrors from '../../components/error/jotaiUrqlErrors'
import { WithModalParams, useModal, useModalsContext } from '../../components/state/context/modalsContext'
import { JotaiForm } from '../../components/state/useJotaiForm'
import buildLogger from '../../util/logger'
import ChargeDialogBodyWithQuery, { ChargeDialogBodyProps } from './chargeDialogBody'
import { LineItemsTableOrderType } from './LineItemsEditTable/LineItemsEditTable'
import { NewOrderFormData } from './EditOrderPage/NewOrderPage'
import { WithUrql } from '../../components/state/useJotaiUrqlQuery'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'

const logger = buildLogger('LineItemDialog')

export type LineItemDialogForm = Pick<NewOrderFormData, 'orderDetail' | 'dialogLineItemIndex'> & WithUrql

type LineItemDialogProps<F extends LineItemDialogForm> = WithModalParams & LineItemDialogModalProps<F>

type LineItemDialogModalProps<F extends LineItemDialogForm> = {
  jotaiForm: JotaiForm<F>
  orderType?: LineItemsTableOrderType
  chargeDialogBodyProps?: ChargeDialogBodyProps
}

export const LineItemDialog = <F extends LineItemDialogForm>({
  jotaiForm,
  open,
  onClose,
  chargeDialogBodyProps,
}: LineItemDialogProps<F>) => {
  return (
    <>
      <Dialog open={!!open} onClose={onClose} maxWidth={'md'} scroll={'paper'}>
        <JotaiUrqlErrors jotaiForm={jotaiForm} />
        {!!chargeDialogBodyProps && !!open && (
          <ChargeDialogBodyWithQuery {...chargeDialogBodyProps} onClose={onClose} />
        )}
      </Dialog>
    </>
  )
}

export function useNewOrderLineItemDialog<F extends LineItemDialogForm>(
  modalProps: Omit<LineItemDialogModalProps<F>, 'lineItemIndex' | 'jotaiForm'>
) {
  const [, , toggleModal] = useModalsContext()
  const [lineItemIndex, setLineItemIndex] = useState<number>(0)

  const jotaiForm = useJotaiFormContext<F>()
  const chargeDialogBodyProps = jotaiForm.useSelect(
    useCallback(
      (form: F) => {
        const lineItem = form.orderDetail.lineItems[lineItemIndex]
        const chargeDialogBodyProps: ChargeDialogBodyProps = {
          chargeId: lineItem?.charge.id ?? '',
          planId: lineItem?.plan?.id ?? '',
          currency: form.orderDetail.account.currency ?? undefined,
          lineItem,
        }
        return chargeDialogBodyProps
      },
      [lineItemIndex]
    )
  )

  const key = 'LineItemDialog'
  useModal<LineItemDialogProps<F>>(
    useMemo(
      () => ({
        component: LineItemDialog,
        schema: {
          key,
          modalProps: {
            ...modalProps,
            jotaiForm,
            chargeDialogBodyProps,
          },
        },
      }),
      [modalProps, key, chargeDialogBodyProps, jotaiForm]
    )
  )
  return useCallback(
    (lineItemIndex: number) => {
      setLineItemIndex(lineItemIndex)
      toggleModal(key)
    },
    [toggleModal]
  )
}
export default LineItemDialog
