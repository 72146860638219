import * as yup from 'yup'
import {
  ActionType,
  Charge,
  CreditableAmountFragment,
  DiscountStatus,
  RefundPreviewDetailFragment,
} from '../generated/graphql'
import buildLogger from './logger'
import { CURRENCY, REGEX_ZIPCODE } from './regex'
import { chargeHasNoQuantity } from '../pageComponents/orders/LineItemsEditTable/orderChargeRow'
import { getPaymentTermInputValue } from './paymentTermUtil'
import Big from 'big.js'
import {
  WithCustomizationLocator,
  WithCustomizationContextInput,
  withYupSchemaWithCustomization,
} from '@/components/state/context/customizationContext'

const logger = buildLogger('validationSchemas')
// length are based on database post here https://stackoverflow.com/questions/20958/list-of-standard-lengths-for-database-fields

export const MAX_PRICE_INPUT_VALUE = 1_000_000_000_000
export const MAX_PRICE_INPUT_SCALE = 5

export const addressSchema = yup.object({
  streetAddressLine1: yup
    .string()
    .trim()
    .max(255, 'Must be 255 characters or less')
    .label('Street Address 1')
    .nullable()
    .required(),
  streetAddressLine2: yup
    .string()
    .trim()
    .max(255, 'Must be 255 characters or less')
    .label('Street Address 2')
    .nullable(),
  city: yup.string().trim().max(35, 'Must be less than 35 characters').label('City').nullable().required(),
  country: yup.string().trim().max(56, 'Must be less than 56 characters').label('Country').required(),
  state: yup
    .string()
    .trim()
    .max(20, 'Must be 20 characters or less')
    .label('Region')
    .when('country', { is: 'US', then: yup.string().trim().length(2).label('State').required() })
    .when('country', { is: 'CA', then: yup.string().trim().length(2).label('Province').required() })
    .nullable()
    .required(),
  zipcode: yup
    .string()
    .trim()
    .max(11, 'Must be 11 characters or less')
    .label('Postal Code')
    .required()
    .when('country', {
      is: 'US',
      then: yup.string().trim().matches(REGEX_ZIPCODE, 'Not a valid zip code').label('ZIP Code'),
    }),
})

export const MIN_PHONE_LENGTH = 5
export const MAX_PHONE_LENGTH = 50

export const emailSchema = yup.string().nullable().email().trim()
export const phoneNumberSchema = yup
  .string()
  .nullable()
  .trim()
  .transform((val) => (val === '' ? null : val))
  .min(MIN_PHONE_LENGTH, `Must be ${MIN_PHONE_LENGTH} characters or more`)
  .max(MAX_PHONE_LENGTH, `Must be ${MAX_PHONE_LENGTH} characters or less`)
  .label('Phone Number')

export const companyContactSchema = yup.object({
  tenantDetails: yup.object({
    name: yup.string().trim().max(100, 'Must be 100 characters or less').nullable().required(),
    email: yup.string().trim().max(255, 'Must be 255 characters or less').label('Email').nullable().email().required(),
    phoneNumber: phoneNumberSchema,
    address: addressSchema,
  }),
  accountReceivableContactSetting: yup.object({
    firstName: yup.string().trim().max(50, 'Must be 50 characters or less').nullable().required(),
    lastName: yup.string().trim().max(50, 'Must be 50 characters or less').nullable(),
    email: yup.string().trim().max(255, 'Must be 255 characters or less').label('Email').email().required(),
    phoneNumber: phoneNumberSchema,
    address: addressSchema,
  }),
})

export const requiredDateSchema = yup
  .number()
  .required()
  .transform((value) => (isNaN(value) ? undefined : value))
  .moreThan(0, 'Invalid')

export const optionalDateSchema = yup
  .number()
  .required()
  .transform((value) => (isNaN(value) ? undefined : value))
  .moreThan(0, 'Invalid')

export const schemaMessage = {
  positiveValue: 'Enter a positive dollar value',
  exceedsDecimal: 'Only 2 decimal places allowed',
  exceedsBalance: 'Amount exceeds balance',
  exceedsAvailableAmount: 'Exceeds available amount',
  endDateNotLessThanStartDate: 'End date must be greater than or equal to start date',
}

export const newCreditMemoWithEntitySchema = yup
  .object({
    creditMemo: yup.object({
      accountId: yup.string().required(),
      billingContactId: yup.string().required(),
      startDate: requiredDateSchema,
      endDate: requiredDateSchema.test(
        'endDate-not-less-than-startDate',
        schemaMessage.endDateNotLessThanStartDate,
        (value, context) => {
          if (value) {
            return context.parent.startDate <= value
          } else {
            return false
          }
        }
      ),
      entityId: yup.string().required(),
      amount: yup.number().required(),
      note: yup.string().nullable().max(255, 'Must be 255 characters or less'),
      lineItems: yup.array(
        yup.object({
          startDate: optionalDateSchema,
          endDate: optionalDateSchema,
          amount: yup.number(),
          chargeId: yup.string(),
        })
      ),
    }),
  })
  .defined()

export const newCreditMemoSchema = yup
  .object({
    creditMemo: yup.object({
      accountId: yup.string().required(),
      billingContactId: yup.string().required(),
      startDate: requiredDateSchema,
      endDate: requiredDateSchema.test(
        'endDate-not-less-than-startDate',
        schemaMessage.endDateNotLessThanStartDate,
        (value, context) => {
          if (value) {
            return context.parent.startDate <= value
          } else {
            return false
          }
        }
      ),
      amount: yup.number().required(),
      note: yup.string().nullable().max(255, 'Must be 255 characters or less'),
      lineItems: yup.array(
        yup.object({
          startDate: optionalDateSchema,
          endDate: optionalDateSchema,
          amount: yup.number(),
          chargeId: yup.string(),
        })
      ),
    }),
  })
  .defined()

export const applyCreditMemoSchema = yup.object({
  applyCreditMemoRequests: yup.array(
    yup.object({
      invoiceNumber: yup.string().required(),
      creditMemoNumber: yup.string().required(),
      invoiceAmount: yup.string().required(),
      creditMemoInputAmount: yup
        .number()
        .typeError(schemaMessage.positiveValue)
        .positive(schemaMessage.positiveValue)
        .test('is-decimal', schemaMessage.exceedsDecimal, (value) => !!`${value ?? ''}`.match(CURRENCY))
        .test('invoiceAmount', schemaMessage.exceedsBalance, function (value) {
          return (parseFloat(this.parent.creditMemoBalance) || 0) >= (value ?? 0)
        })
        .required(),
      creditMemoNote: yup.string(),
    })
  ),
})
export const addAliasSchema = yup.object({
  aliasDraft: yup.object({
    aliasId: yup
      .string()
      .trim()
      .min(5, 'Must be 5 characters or more')
      .max(100, 'Must be 100 characters or less')
      .required(),
  }),
})

export const accountingMessage = {
  accountPeriodNotDefined: 'Define accounting period.',
}

export const recordRefundSchema = yup
  .object({
    refundRequest: yup.object({
      amount: yup
        .number()
        .typeError(schemaMessage.positiveValue)
        .positive(schemaMessage.positiveValue)
        .test('is-decimal', schemaMessage.exceedsDecimal, (value) => !!`${value ?? ''}`.match(CURRENCY))
        .test('refundAvailable', schemaMessage.exceedsAvailableAmount, function (value, context) {
          const contextGeneric = context as unknown as { from: { value: Record<string, unknown> }[] }
          const refundDetails = contextGeneric.from[1].value.refundDetails as RefundPreviewDetailFragment
          return (refundDetails?.refundAvailable ?? 0) >= (value ?? 0)
        })
        .moreThan(0, 'Must be greater than 0')
        .required(),
      notes: yup.string().trim().max(100, 'Must be 100 characters or less'),
      refundDate: requiredDateSchema,
      paymentMethodType: yup.string().required(),
      paymentId: yup.string().required(),
    }),
    refundDetails: yup.object({ refundAvailable: yup.number().required() }),
  })
  .defined()

export const OrderSchemaMessages = {
  NotNegative: 'Must not be negative',
  NotPositive: 'Must not be positive',
  GreaterThanZero: 'Must be greater than 0',
  IntegerNumber: 'Decimals are not allowed',
}

const optionalContactSchema = yup.object().nullable()
export const getOrderSchema = ({
  customizations,
  parentLocator,
}: WithCustomizationContextInput & WithCustomizationLocator = {}) => {
  const orderDetailSchema = yup.object({
    account: yup.object({
      id: yup.string().required(),
    }),
    billingContact: optionalContactSchema,
    billingCycle: yup.object({ cycle: yup.string().required() }),
    billingTerm: yup.string(),
    sfdcOpportunityId: yup.string().trim().nullable(),
    purchaseOrderNumber: yup.string().max(255, 'Maximum 255 characters allowed').nullable(),
    lineItems: yup
      .array(
        yup.object({
          quantity: yup
            .number()
            .when('charge', {
              is: (charge: Charge) => {
                return !chargeHasNoQuantity(charge)
              },
              then: (schema) =>
                schema
                  .integer(OrderSchemaMessages.IntegerNumber)
                  .moreThan(-1, OrderSchemaMessages.NotNegative)
                  .nullable()
                  .required(),
            })
            .test({
              name: 'min quantity test',
              test: function (quantity) {
                if (quantity == null) {
                  return true
                }
                const minQuantity: number | null | undefined = this.parent.pricingOverride
                  ? this.parent.pricingOverride.minQuantity
                  : this.parent.charge.minQuantity
                if (
                  (minQuantity || minQuantity === 0) &&
                  quantity < minQuantity &&
                  this.parent.action !== ActionType.Remove
                ) {
                  return this.createError({ message: `Min: ${minQuantity}` })
                }
                return true
              },
            })
            .test({
              name: 'max quantity test',
              test: function (quantity) {
                if (quantity == null) {
                  return true
                }
                const maxQuantity: number | null | undefined = this.parent.pricingOverride
                  ? this.parent.pricingOverride.maxQuantity
                  : this.parent.charge.maxQuantity
                if ((maxQuantity || maxQuantity === 0) && quantity > maxQuantity) {
                  return this.createError({ message: `Max: ${maxQuantity}` })
                }
                return true
              },
            }),
          listUnitPrice: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .when('charge', {
              is: (charge: Charge) => {
                return charge.isCustom
              },
              then: (schema) => schema.nullable().required(),
            })
            .when('charge', {
              is: (charge: Charge) => {
                return charge.isCustom && charge.isDiscount
              },
              then: (schema) => schema.nullable().required().lessThan(1, OrderSchemaMessages.NotPositive),
            }),
          predefinedDiscounts: yup
            .array(
              yup.object({
                status: yup.string().test('is-not-deprecated', 'Deprecated', function (status) {
                  return status !== DiscountStatus.Deprecated
                }),
              })
            )
            .nullable(),
        })
      )
      .min(1, 'Must have at least one line item'),
    shippingContact: optionalContactSchema,
    startDate: yup.number().required().moreThan(0, 'Invalid'),
    expiresOn: yup.number().moreThan(0, 'Invalid').nullable(),
    termUnit: yup.string(),
    termLength: yup
      .object({
        step: yup
          .number()
          .nullable()
          .positive(OrderSchemaMessages.GreaterThanZero)
          .integer(OrderSchemaMessages.IntegerNumber),
      })
      .nullable(),
    name: yup.string().trim().max(255, 'Maximum 255 characters allowed').nullable(),
    paymentTerm: yup
      .string()
      .nullable()
      .required('Valid payment term required')
      .test(
        'payment-term-between-0-365',
        'Must be between Net 0 and Net 365',
        function (value: string | null | undefined) {
          const paymentTermInputValue = getPaymentTermInputValue(value ?? undefined)
          if (paymentTermInputValue === '') {
            return false
          }
          const paymentTermNumber = Big(paymentTermInputValue).toNumber()
          return paymentTermNumber >= 0 && paymentTermNumber <= 365
        }
      ),
  })
  return yup
    .object({
      orderDetail: withYupSchemaWithCustomization({ customizations, schema: orderDetailSchema, parentLocator }),
    })
    .defined()
}

const creditAmountSchema = yup.object({
  creditableAmount: yup
    .number()
    .test('creditableAmount-min-max', 'Must be between min and max', function (value, context) {
      logger.info({
        msg: 'creditableAmount-min-max',
        context,
      })
      const contextGeneric = context as unknown as { parent: CreditableAmountFragment }
      const creditableAmount = contextGeneric.parent
      const maxCreditableAmount = Math.max(creditableAmount?.maxCreditableAmount ?? 0, 0)
      const minCreditableAmount = Math.min(creditableAmount?.maxCreditableAmount ?? 0, 0)
      logger.info({
        msg: 'creditableAmount-min-max',
        context,
        value,
        creditableAmount,
        minCreditableAmount,
        maxCreditableAmount,
      })
      if (value !== undefined) {
        const ret = value >= minCreditableAmount && value <= maxCreditableAmount
        if (!ret) {
          return this.createError({
            message: `Must be between ${minCreditableAmount} and ${maxCreditableAmount}`,
          })
        }
      }
      return true
    }),
})

export const getAmendmentOrderSchema = ({
  customizations,
  parentLocator,
}: WithCustomizationContextInput & WithCustomizationLocator = {}) => {
  const amendmentOrderDetailSchema = yup.object({
    billingContact: yup.object().nullable().required(),
    startDate: yup.number().required().moreThan(0, 'Invalid'),
    expiresOn: yup.number().moreThan(0, 'Invalid').nullable(),
    name: yup.string().trim().max(255, 'Maximum 255 characters allowed').nullable(),
    purchaseOrderNumber: yup.string().max(255, 'Maximum 255 characters allowed').nullable(),
    sfdcOpportunityId: yup.string().trim().nullable(),
    lineItems: yup.array(
      yup.object({
        quantity: yup
          .number()
          .when('charge', {
            is: (charge: Charge) => {
              return !chargeHasNoQuantity(charge)
            },
            then: (schema) =>
              schema
                .integer(OrderSchemaMessages.IntegerNumber)
                .moreThan(-1, OrderSchemaMessages.NotNegative)
                .nullable()
                .required(),
          })
          .test({
            name: 'min quantity test',
            test: function (quantity) {
              if (quantity == null || quantity === 0) {
                return true
              }
              const minQuantity: number | null | undefined = this.parent.pricingOverride
                ? this.parent.pricingOverride.minQuantity
                : this.parent.charge.minQuantity
              if (
                (minQuantity || minQuantity === 0) &&
                quantity < minQuantity &&
                this.parent.action !== ActionType.None
              ) {
                return this.createError({ message: `Min: ${minQuantity}` })
              }
              return true
            },
          })
          .test({
            name: 'max quantity test',
            test: function (quantity) {
              if (quantity == null) {
                return true
              }
              const maxQuantity: number | null | undefined = this.parent.pricingOverride
                ? this.parent.pricingOverride.maxQuantity
                : this.parent.charge.maxQuantity
              if (
                (maxQuantity || maxQuantity === 0) &&
                quantity > maxQuantity &&
                this.parent.action !== ActionType.None
              ) {
                return this.createError({ message: `Max: ${maxQuantity}` })
              }
              return true
            },
          }),
        listUnitPrice: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable()
          .when('charge', {
            is: (charge: Charge) => {
              return charge.isCustom
            },
            then: (schema) => schema.nullable().required(),
          }),
      })
    ),
    creditableAmounts: yup.array().of(creditAmountSchema),
  })

  return yup
    .object({
      orderDetail: withYupSchemaWithCustomization({
        customizations,
        schema: amendmentOrderDetailSchema,
        parentLocator,
      }),
    })
    .defined()
}

export const getCancelOrderSchema = ({
  customizations,
  parentLocator,
}: WithCustomizationContextInput & WithCustomizationLocator = {}) => {
  const cancelOrderDetailSchema = yup.object({
    billingContact: yup.object().nullable().required(),
    startDate: yup.number().required().moreThan(0, 'Invalid'),
    expiresOn: yup.number().moreThan(0, 'Invalid').nullable(),
    name: yup.string().trim().max(255, 'Maximum 255 characters allowed').nullable(),
    sfdcOpportunityId: yup.string().trim().nullable(),
    creditableAmounts: yup.array().of(creditAmountSchema),
  })

  return yup
    .object({
      orderDetail: withYupSchemaWithCustomization({ customizations, schema: cancelOrderDetailSchema, parentLocator }),
    })
    .defined()
}

export const getCancelAndRestructureOrderSchema = () =>
  yup
    .object({
      orderDetail: yup.object({
        billingContact: yup.object().nullable().required(),
        startDate: yup.number().required().moreThan(0, 'Invalid'),
        expiresOn: yup.number().moreThan(0, 'Invalid').nullable(),
        name: yup.string().trim().max(255, 'Maximum 255 characters allowed').nullable(),
        purchaseOrderNumber: yup.string().max(255, 'Maximum 255 characters allowed').nullable(),
        lineItems: yup.array(
          yup.object({
            quantity: yup
              .number()
              .when('charge', {
                is: (charge: Charge) => {
                  return !chargeHasNoQuantity(charge)
                },
                then: (schema) =>
                  schema
                    .integer(OrderSchemaMessages.IntegerNumber)
                    .moreThan(-1, OrderSchemaMessages.NotNegative)
                    .nullable()
                    .required(),
              })
              .test({
                name: 'min quantity test',
                test: function (quantity) {
                  if (quantity == null || quantity === 0) {
                    return true
                  }
                  const minQuantity: number | null | undefined = this.parent.pricingOverride
                    ? this.parent.pricingOverride.minQuantity
                    : this.parent.charge.minQuantity
                  if ((minQuantity || minQuantity === 0) && quantity < minQuantity) {
                    return this.createError({ message: `Min: ${minQuantity}` })
                  }
                  return true
                },
              })
              .test({
                name: 'max quantity test',
                test: function (quantity) {
                  if (quantity == null) {
                    return true
                  }
                  const maxQuantity: number | null | undefined = this.parent.pricingOverride
                    ? this.parent.pricingOverride.maxQuantity
                    : this.parent.charge.maxQuantity
                  if ((maxQuantity || maxQuantity === 0) && quantity > maxQuantity) {
                    return this.createError({ message: `Max: ${maxQuantity}` })
                  }
                  return true
                },
              }),
            listUnitPrice: yup
              .number()
              .transform((value) => (isNaN(value) ? undefined : value))
              .nullable()
              .when('charge', {
                is: (charge: Charge) => {
                  return charge.isCustom
                },
                then: (schema) => schema.nullable().required(),
              }),
          })
        ),
      }),
    })
    .defined()
