import ActionButton from '@/components/button/actionButton'
import BillyGridCell, { GridCellProps } from '@/components/grid/billyGridCell'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { Cycle, OrderStartDateType } from '@/generated/graphql'
import { useShouldRecalculateTotals } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import buildLogger from '@/util/logger'
import { CardContent, CardHeader, Grid } from '@mui/material'
import BillyCard from '../../../../../components/card/billyCard'
import JotaiReadValue from '../../../../../components/state/jotaiReadValue'
import { NewOrderFormData } from '../../NewOrderPage'
import { useEditOrderTimelineReducer } from '../../hooks/useEditOrderTimelineReducer'
export const logger = buildLogger('RampSettingView')

export function OrderTimelineCard({ readonly }: { readonly?: boolean }): JSX.Element {
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  const { toggleRampDialog, timelineDetailsSelector } = useEditOrderTimelineReducer()
  const shouldRecalculateTotals = useShouldRecalculateTotals()
  return (
    <BillyCard>
      <CardHeader
        title="Timeline Details"
        action={
          readonly ? undefined : (
            <ActionButton
              buttonData={{
                label: 'Edit Timeline',
                onClick: () => toggleRampDialog('ORDER_LEVEL')(),
                disabledExplanation: shouldRecalculateTotals ? 'Recalculating' : undefined,
                buttonProps: {
                  variant: 'outlined',
                },
              }}
            />
          )
        }
      />
      <CardContent>
        <JotaiReadValue
          atomSelector={timelineDetailsSelector}
          form={jotaiForm}
          render={(cells: GridCellProps[]) => (
            <Grid container rowGap={2} columnSpacing={1}>
              {cells.map((cell, index) => (
                <BillyGridCell key={index} {...cell} />
              ))}
            </Grid>
          )}
        />
      </CardContent>
    </BillyCard>
  )
}

export const isTypeOfCycle = (value: string): value is Cycle => {
  return value === Cycle.Month || value === Cycle.Year
}

export const isTypeOfStartDateType = (value: string): value is OrderStartDateType => {
  return value === OrderStartDateType.Fixed || value === OrderStartDateType.ExecutionDate
}
