import JotaiMuiEsAutocomplete from '@/components/input/JotaiMuiEsAutocomplete'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { CommonOrderFormPageState } from '@/pageComponents/orders/EditOrderPage/CommonOrderFormPageState'
import { Grid } from '@mui/material'
import { Draft } from 'immer'
import { useCallback } from 'react'
import JotaiMuiAutocomplete from '../../../../../components/input/JotaiMuiAutocomplete'
import { deepMutable } from '../../../../../components/SchemaForm/DeepMutable'
import { OrderType } from '../../../../../generated/graphql'
import { NewOrderFormData } from '../../NewOrderPage'
import buildLogger from '@/util/logger'
import { useDryRunActions } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
const logger = buildLogger('AccountAutocomplete')

const getAccountValue = (form: NewOrderFormData) => {
  return form.orderDetail.account
}

const updateAccountValue = (value, draft) => {
  if (value) {
    draft.orderDetail.account = value
  }
  draft.orderDetail.billingContact = null
  draft.orderDetail.shippingContact = null
  draft.contactList = undefined
  if (!draft.orderDetail.resoldBy?.id) {
    draft.billingContactList = undefined
  }
  draft.orderDetail.sfdcOpportunityId = null
  draft.orderDetail.sfdcOpportunityName = null
  draft.orderDetail.sfdcOpportunityStage = null
  draft.orderDetail.sfdcOpportunityType = null
}

const getAccountOptions = (form: NewOrderFormData) => deepMutable(form.accountList)
const autocompleteProps = {
  getOptionLabel: (option) => option.name,
  disableClearable: true,
}

export function AccountAutocomplete() {
  const { queueDryRun } = useDryRunActions()
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  const { disabledExplanation } = jotaiForm.useSelect(
    useCallback((form: CommonOrderFormPageState) => {
      const { orderType } = form.orderDetail
      const isRenewal = orderType === OrderType.Renewal
      return {
        disabledExplanation: isRenewal ? 'Account cannot be changed during renewal' : '',
      }
    }, [])
  )
  return (
    <Grid item xs={4}>
      <JotaiMuiAutocomplete
        atomOptionsSelector={getAccountOptions}
        atomUpdater={(value, draft) => {
          updateAccountValue(value, draft)
          if (draft.orderDetail.lineItems.length) {
            queueDryRun()
          }
        }}
        atomValueSelector={getAccountValue}
        autocompleteProps={autocompleteProps}
        errorPath="orderDetail.account.id"
        form={jotaiForm}
        textFieldProps={{ label: 'Account' }}
        disabledExplanation={disabledExplanation}
      />
    </Grid>
  )
}

export function AccountESAutocomplete() {
  const { queueDryRun } = useDryRunActions()
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  const { disabledExplanation } = jotaiForm.useSelect(
    useCallback((form: CommonOrderFormPageState) => {
      const { orderType } = form.orderDetail
      const isRenewalOrRestructure = [OrderType.Renewal, OrderType.Restructure].includes(orderType)
      return {
        disabledExplanation: isRenewalOrRestructure ? 'Account cannot be changed during renewal or restructure' : '',
      }
    }, [])
  )
  return (
    <Grid item xs={4}>
      <JotaiMuiEsAutocomplete
        atomOptionsSelector={getAccountOptions}
        atomUpdater={(value, draft) => {
          updateAccountValue(value, draft)
          if (value && value.currency) {
            draft.orderDetail.currency = value.currency
          }
          if (draft.orderDetail.lineItems.length) {
            queueDryRun()
          }
        }}
        atomValueSelector={getAccountValue}
        autocompleteProps={autocompleteProps}
        textFieldProps={{
          label: 'Account',
          name: 'account',
          placeholder: 'Search',
        }}
        fuzzySearchProps={{
          columns: [{ accessor: 'name' }],
          tableName: 'account',
          where: [
            {
              key: 'is_deleted',
              value: 'false',
            },
          ],
        }}
        onSearchUpdate={useCallback((values: unknown, draft: Draft<NewOrderFormData>) => {
          draft.accountList = getEsSearchUpdateAccounts(values)
        }, [])}
        errorPath="orderDetail.account.id"
        form={jotaiForm}
        disabledExplanation={disabledExplanation}
        disableStringFilterStartWith
      />
    </Grid>
  )
}

export const getEsSearchUpdateAccounts = (values: unknown) => {
  return (
    values as {
      id: string
      account_id: string
      name: string
      description: string
      crm_id: string
      currency: string
    }[]
  ).map((searchResult) => ({
    id: searchResult.account_id,
    name: searchResult.name,
    description: searchResult.description,
    crmId: searchResult.crm_id,
    currency: searchResult.currency,
  }))
}
