import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'
import { Feature } from '@/generated/graphql'
import { useShouldRecalculateTotals } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { LineItemCellLoadingIndicator } from '@/pageComponents/orders/LineItemsEditTable/Cells/LineItemCellLoadingIndicator'
import { reduceLineItem } from '@/pageComponents/orders/LineItemsEditTable/reduceLineItem'
import { billableCurrencyFormat } from '@/util/currencyUtil'
import { Stack, Typography } from '@mui/material'
import { useCallback } from 'react'
import { useJotaiFormContext } from '../../../../components/state/jotaiFormProvider'
import { CommonOrderFormPageState } from '../../EditOrderPage/CommonOrderFormPageState'
import { NewOrderFormData } from '../../EditOrderPage/NewOrderPage'
import { OrderItemRowProps } from '../orderChargeRow'
import { LineItemTotalEditCell } from '@/pageComponents/orders/LineItemsEditTable/Cells/LineItemTotalEditCell'

export function LineItemTotalCell<F extends CommonOrderFormPageState = NewOrderFormData>({
  lineItemIndex,
  orderType,
  currency,
}: OrderItemRowProps) {
  const shouldRecalculateTotals = useShouldRecalculateTotals()

  const jotaiForm = useJotaiFormContext<F>()
  const { formattedCurrency, formattedAnnualizedAmount, showTotalEdit } = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const reduced = reduceLineItem({
          orderDetail: form.orderDetail,
          lineItemIndex,
          orderType,
          currency,
        })
        return {
          formattedCurrency: reduced.formattedCurrency,
          formattedAnnualizedAmount: billableCurrencyFormat({ currency, value: reduced.lineItem.annualizedAmount }),
          amount: reduced.lineItem.amount,
          showTotalEdit: reduced.showTotalEdit,
        }
      },
      [lineItemIndex, orderType, currency]
    )
  )

  const isOrderAnnualizedAmountEnabled = useDynamicFeatureFlag(Feature.OrderAnnualizedAmount)
  const isOneTimeChargeDebookEnabled = useDynamicFeatureFlag(Feature.OneTimeChargeDebook)

  return shouldRecalculateTotals ? (
    <LineItemCellLoadingIndicator />
  ) : isOneTimeChargeDebookEnabled && showTotalEdit ? (
    <LineItemTotalEditCell lineItemIndex={lineItemIndex} orderType={orderType} currency={currency} />
  ) : (
    <Stack>
      <Typography variant="body2">{formattedCurrency}</Typography>
      {isOrderAnnualizedAmountEnabled && !!formattedAnnualizedAmount && (
        <Typography variant="caption">{`(${formattedAnnualizedAmount})`}</Typography>
      )}
    </Stack>
  )
}
