import { useCallback, useState } from 'react'
import { createParser, type EventSourceMessage } from 'eventsource-parser'

import buildLogger from '@/util/logger'
import { isHandleableError } from '../ErrorHandler/ErrorHandler'
import { fetchStreamingWithTimeout } from './fetchStreamingWithTimeout'

const logger = buildLogger('useEventStream')

export enum eventStreamingStatus {
  ONGOING = 'ongoing',
  COMPLETED = 'completed',
}
export enum streamingEvent {
  DATA = 'td',
  DONE = 'DONE',
}

export type eventStreamingStatusType = eventStreamingStatus | null
const fallbackErrorMessage = 'An error occurred while receiving response. Please try again.'

export const useEventStream = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const [data, setData] = useState<string>('')
  const [streamingStatus, setStreamingStatus] = useState<eventStreamingStatusType>(null)

  const sendRequest = useCallback(async (endpoint: string) => {
    setIsLoading(true)
    setIsError(false)
    setError(undefined)
    setStreamingStatus(null)
    setData('')
    try {
      const { response, abortTimeoutRef } = await fetchStreamingWithTimeout(endpoint)
      // Clear abort-request timeout as soon as the response is received
      if (abortTimeoutRef) {
        clearTimeout(abortTimeoutRef)
      }
      if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText}`)
      }
      if (response.body) {
        logger.info('Connection opened')
        setIsLoading(false)
        setStreamingStatus(eventStreamingStatus.ONGOING)

        const parser = createParser({
          onEvent: (event: EventSourceMessage) => {
            switch (event.event) {
              case streamingEvent.DATA:
                setData((prev) => `${prev}${event.data}`)
                break
              case streamingEvent.DONE:
                logger.info('Connection closed')
                setStreamingStatus(eventStreamingStatus.COMPLETED)
                break
            }
          },
          onError: (error: Error) => {
            logger.warn({ msg: 'Event streaming onerror', error })
            throw new Error(error.message, error)
          },
        })

        const reader = response.body.getReader()
        let done = false
        while (!done) {
          const { done: readerDone, value } = await reader.read() // Read next chunk
          done = readerDone
          if (value) {
            const chunk = new TextDecoder().decode(value, { stream: true })
            parser.feed(chunk)
          }
        }
        // For re-using the parser for a new stream of events,
        // Once current stream of events is done, make sure to reset it
        parser.reset()
      } else {
        throw new Error('Readable stream not available.')
      }
    } catch (error) {
      logger.warn({ msg: 'Event streaming error', error })
      setIsError(true)
      const errorMessage = isHandleableError(error)
        ? error.message
        : typeof error === 'string'
        ? error
        : fallbackErrorMessage
      setError(errorMessage)
      setStreamingStatus(null)
    } finally {
      setIsLoading(false)
    }
  }, [])

  return {
    data,
    error,
    isError,
    isLoading,
    sendRequest,
    streamingStatus,
  }
}
