import { useCallback } from 'react'
import { useJotaiFormContext } from '../../../components/state/jotaiFormProvider'
import { OrderType } from '../../../generated/graphql'
import NewOrderPageDrawer from '../OrderDrawer/NewOrderPageDrawer'
import RenewalOrderPageDrawer from '../OrderDrawer/RenewalOrderPageDrawer'
import { NewOrderFormData } from './NewOrderPage'
import { CommonOrderFormPageState } from './CommonOrderFormPageState'

export function NewOrderRightDrawer() {
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  const { orderDetail, orderType } = jotaiForm.useSelect(
    useCallback((form: CommonOrderFormPageState) => {
      const orderDetail = form.orderDetail
      return {
        orderDetail: {
          currency: orderDetail.currency,
          totalAmount: orderDetail.totalAmount,
          totalListAmount: orderDetail.totalListAmount,
          orderMetrics: orderDetail.orderMetrics,
          resoldBy: orderDetail.resoldBy,
          totalDiscount: orderDetail.totalDiscount,
          totalDiscountPercent: orderDetail.totalDiscountPercent,
          totalRecurringDiscount: orderDetail.totalRecurringDiscount,
          totalRecurringDiscountPercent: orderDetail.totalRecurringDiscountPercent,
          totalNonRecurringDiscount: orderDetail.totalNonRecurringDiscount,
          totalNonRecurringDiscountPercent: orderDetail.totalNonRecurringDiscountPercent,
          renewalForSubscription: orderDetail.renewalForSubscription,
        },
        orderType: form.orderDetail.orderType,
      }
    }, [])
  )

  if (orderType === OrderType.Renewal) {
    return <RenewalOrderPageDrawer orderDetail={orderDetail} />
  }

  return <NewOrderPageDrawer orderDetail={orderDetail} />
}
