import { StepperFormChildren } from '@/components/BillyStepperForm/BillyStepperForm'
import InnerPageContainer from '@/components/layout/innerPageContainer'
import { OrderEditStepperButtons } from '@/pageComponents/orders/EditOrderPage/NewOrderStepperPageNavLayout'
import {
  useDryRunActions,
  useShouldRecalculateTotals,
} from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { projectCancellationOrderUpsertArgs } from '@/pageComponents/orders/EditOrderPage/utils'
import { getCancelOrderSchema } from '@/util/validationSchemas'
import Head from 'next/head'
import Image from 'next/image'
import { useCallback, useEffect, useMemo } from 'react'
import { deepMutable } from '../../../components/SchemaForm/DeepMutable'
import { NavLayout } from '../../../components/layout/navLayout'
import { BreadcrumbDataList } from '../../../components/nav/breadcrumbNav'
import { ButtonDataList } from '../../../components/nav/secondaryBarWithButtons'
import PageLoadingPlaceholder from '../../../components/placeholder/pageLoadingPlaceholder'
import { useBillyRouter } from '../../../components/route/useBillyRouter'
import { useJotaiFormContext } from '../../../components/state/jotaiFormProvider'
import useJotaiOnSubmit from '../../../components/state/useJotaiOnSubmit'
import { useJotaiUrqlMutation } from '../../../components/state/useJotaiUrqlMutation'
import { useJotaiUrqlQuery } from '../../../components/state/useJotaiUrqlQuery'
import {
  DocumentTemplateType,
  DryRunCancellationDocument,
  GetAttachmentsDocument,
  GetAttachmentsQuery,
  GetAttachmentsQueryVariables,
  GetCurrentTenantDocument,
  GetCurrentTenantQuery,
  GetCurrentTenantQueryVariables,
  GetOpportunitiesByAccountCrmIdDocument,
  GetOpportunitiesByAccountCrmIdQuery,
  GetOpportunitiesByAccountCrmIdQueryVariables,
  GetOpportunitiesByHubSpotCompanyIdDocument,
  GetOpportunitiesByHubSpotCompanyIdQuery,
  GetOpportunitiesByHubSpotCompanyIdQueryVariables,
  GetOpportunitiesBySalesforceAccountIdDocument,
  GetOpportunitiesBySalesforceAccountIdQuery,
  GetOpportunitiesBySalesforceAccountIdQueryVariables,
  ListAccountContactsDocument,
  ListAccountContactsQuery,
  ListAccountContactsQueryVariables,
  ListTemplatesByTypeDocument,
  ListTemplatesByTypeQuery,
  ListTemplatesByTypeQueryVariables,
  UpsertCancellationDocument,
} from '../../../generated/graphql'
import usePopulateOrder from '../usePopulateOrder'
import { CancellationOrderPageState, logger } from './CancellationOrderPage'
import { CancellationRightDrawerWithData } from './CancellationRightDrawerWithData'
import { preselectOpportunityByCrmId } from './utils'
import { useCustomizationsContext } from '@/components/state/context/customizationContext'

export const CancellationOrderLayout = ({
  children,
  stepperFormProps,
}: { children?: React.ReactNode } & StepperFormChildren) => {
  const router = useBillyRouter()
  const opportunityCrmId = router.query.opportunityCrmId as string | undefined
  const subscriptionId = router.query.subscriptionId as string | undefined

  const jotaiForm = useJotaiFormContext<CancellationOrderPageState>()

  const customizations = useCustomizationsContext()
  const cancelOrderSchema = useMemo(
    () => getCancelOrderSchema({ customizations, parentLocator: 'order::cancel::' }),
    [customizations]
  )

  const { hasLoaded, orderId, isNew } = usePopulateOrder({ jotaiForm })

  useJotaiUrqlQuery<CancellationOrderPageState, GetCurrentTenantQuery, GetCurrentTenantQueryVariables>({
    document: GetCurrentTenantDocument,
    jotaiForm,
    onData: useCallback((data, draft) => {
      draft.hasSalesforceIntegration = data.currentTenant.hasSalesforceIntegration
      draft.hasHubSpotIntegration = data.currentTenant.hasHubSpotIntegration
    }, []),
  })

  useJotaiUrqlQuery<CancellationOrderPageState, ListAccountContactsQuery, ListAccountContactsQueryVariables>({
    document: ListAccountContactsDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.contactList = deepMutable(data.accountContacts)), []),
    pause: useCallback((form) => !form.orderDetail.account?.id, []),
    variables: useCallback((form) => ({ accountId: form.orderDetail.account?.id || '' }), []),
  })

  useJotaiUrqlQuery<CancellationOrderPageState, ListAccountContactsQuery, ListAccountContactsQueryVariables>({
    document: ListAccountContactsDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.billingContactList = deepMutable(data.accountContacts)), []),
    pause: useCallback((form) => !(form.orderDetail.resoldBy?.id || form.orderDetail.account?.id), []),
    variables: useCallback(
      (form) => ({ accountId: form.orderDetail.resoldBy?.id || form.orderDetail.account?.id || '' }),
      []
    ),
  })

  useJotaiUrqlQuery<CancellationOrderPageState, ListTemplatesByTypeQuery, ListTemplatesByTypeQueryVariables>({
    document: ListTemplatesByTypeDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.templateList = deepMutable(data.documentTemplates)), []),
    variables: useCallback(() => ({ type: DocumentTemplateType.Order }), []),
  })

  useJotaiUrqlQuery<
    CancellationOrderPageState,
    GetOpportunitiesBySalesforceAccountIdQuery,
    GetOpportunitiesBySalesforceAccountIdQueryVariables
  >({
    document: GetOpportunitiesBySalesforceAccountIdDocument,
    jotaiForm,
    onData: useCallback(
      (data, draft) => {
        draft.opportunities = deepMutable(data.opportunitiesBySalesforceAccountId)
        if (data?.opportunitiesBySalesforceAccountId) {
          preselectOpportunityByCrmId(draft, data.opportunitiesBySalesforceAccountId, opportunityCrmId)
        }
      },
      [opportunityCrmId]
    ),
    pause: useCallback((form) => !form.orderDetail.account?.crmId || !form.hasSalesforceIntegration, []),
    variables: useCallback((form) => ({ sfdcAccountId: form.orderDetail.account?.crmId || '' }), []),
  })

  useJotaiUrqlQuery<
    CancellationOrderPageState,
    GetOpportunitiesByHubSpotCompanyIdQuery,
    GetOpportunitiesByHubSpotCompanyIdQueryVariables
  >({
    document: GetOpportunitiesByHubSpotCompanyIdDocument,
    jotaiForm,
    onData: useCallback(
      (data, draft) => {
        draft.opportunities = deepMutable(data.opportunitiesByHubSpotCompanyId)
        if (data?.opportunitiesByHubSpotCompanyId) {
          preselectOpportunityByCrmId(draft, data.opportunitiesByHubSpotCompanyId, opportunityCrmId)
        }
      },
      [opportunityCrmId]
    ),
    pause: useCallback((form) => !form.orderDetail.account?.crmId || !form.hasHubSpotIntegration, []),
    variables: useCallback((form) => ({ companyId: form.orderDetail.account?.crmId || '' }), []),
  })

  useJotaiUrqlQuery<
    CancellationOrderPageState,
    GetOpportunitiesByAccountCrmIdQuery,
    GetOpportunitiesByAccountCrmIdQueryVariables
  >({
    document: GetOpportunitiesByAccountCrmIdDocument,
    jotaiForm,
    onData: useCallback(
      (data, draft) => {
        draft.opportunities = deepMutable(data.opportunitiesByAccountCrmId)
        if (data?.opportunitiesByAccountCrmId) {
          preselectOpportunityByCrmId(draft, data.opportunitiesByAccountCrmId, opportunityCrmId)
        }
      },
      [opportunityCrmId]
    ),
    pause: useCallback(
      (form) => !form.orderDetail.account?.crmId || form.hasHubSpotIntegration || form.hasSalesforceIntegration,
      []
    ),
    variables: useCallback((form) => ({ accountCrmId: form.orderDetail.account?.crmId || '' }), []),
  })

  useJotaiUrqlQuery<CancellationOrderPageState, GetAttachmentsQuery, GetAttachmentsQueryVariables>({
    document: GetAttachmentsDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.attachments = deepMutable(data.attachments)), []),
    pause: useCallback((form) => !form.orderDetail.account?.id, []),
    variables: useCallback((form) => ({ accountId: form.orderDetail.account?.id || '' }), []),
  })

  const breadcrumbs = useMemo(() => {
    const crumbs: BreadcrumbDataList = [
      {
        label: '',
        link: '/',
        icon: <Image src="/icons/home-sm.svg" width={20} height={20} />,
      },
      { label: 'Orders', link: '/orders' },
    ]

    if (isNew) {
      crumbs.push({ label: 'New Cancellation Order' })
    } else {
      crumbs.push({ label: orderId, link: `/orders/${orderId}` })
      crumbs.push({ label: 'Edit Cancellation Order' })
    }

    return crumbs
  }, [isNew, orderId])

  const upsertCancellation = useJotaiUrqlMutation({ document: UpsertCancellationDocument, jotaiForm })

  const dryRunCancellation = useJotaiUrqlMutation({ document: DryRunCancellationDocument, jotaiForm })

  const { clearDryRun } = useDryRunActions()
  const save = useCallback(
    (isDryRun: boolean) => (form: CancellationOrderPageState) => {
      logger.debug({ msg: 'saving', isDryRun, form: Object.entries(form) })

      const mutationOrder = projectCancellationOrderUpsertArgs(form, isNew)

      if (isDryRun) {
        dryRunCancellation({
          variables: { order: mutationOrder },
          onData: (data, draft) => {
            const dryRunOrderDetail = data.upsertAmendment
            draft.orderDetail = deepMutable(dryRunOrderDetail)
            clearDryRun()
          },
        })
      } else {
        upsertCancellation({
          variables: { order: mutationOrder },
          onData: (data) => {
            if (isNew) {
              history.replaceState(null, '', `/orders/${data.upsertAmendment.id}/edit`)
              router.push(`/orders/${data.upsertAmendment.id}`)
            } else {
              router.push(`/orders/${orderId}`)
            }
          },
        })
      }
    },
    [dryRunCancellation, isNew, orderId, router, upsertCancellation, clearDryRun]
  )

  const onSubmit = useJotaiOnSubmit(
    useMemo(
      () => ({
        atom: jotaiForm.atom,
        onSubmit: (value: CancellationOrderPageState) => save(false)(value),
        schema: cancelOrderSchema,
      }),
      [save, jotaiForm.atom, cancelOrderSchema]
    )
  )

  const buttonDataList: ButtonDataList = [
    {
      label: 'Cancel',
      onClick: subscriptionId ? `/subscriptions/${subscriptionId}` : `/orders/${orderId}`,
      color: 'inherit',
      buttonProps: { variant: 'outlined' },
    },
    {
      label: 'Save Draft',
      onClick: onSubmit,
    },
  ]

  const shouldRecalculateTotals = useShouldRecalculateTotals()

  const onDryRun = useJotaiOnSubmit(
    useMemo(
      () => ({
        atom: jotaiForm.atom,
        onSubmit: (value: CancellationOrderPageState) => save(true)(value),
        schema: cancelOrderSchema,
        onError: clearDryRun,
      }),
      [save, jotaiForm.atom, clearDryRun, cancelOrderSchema]
    )
  )

  useEffect(() => {
    logger.trace({ msg: 'should I dry run?', shouldRecalculateTotals })
    if (shouldRecalculateTotals) {
      onDryRun()
    }
  }, [jotaiForm, onDryRun, shouldRecalculateTotals])

  return (
    <div>
      <Head>
        <title>Cancel Subscription | Subskribe</title>
      </Head>
      <NavLayout
        breadcrumbs={breadcrumbs}
        actionButtons={
          stepperFormProps ? (
            <OrderEditStepperButtons buttonDataList={buttonDataList} stepperFormProps={stepperFormProps} />
          ) : (
            buttonDataList
          )
        }
        rightDrawer={CancellationRightDrawerWithData}
      >
        <InnerPageContainer>
          <PageLoadingPlaceholder isLoading={!isNew && !hasLoaded}>{children}</PageLoadingPlaceholder>
        </InnerPageContainer>
      </NavLayout>
    </div>
  )
}
