import { CommonOrderFormPageState } from '@/pageComponents/orders/EditOrderPage/CommonOrderFormPageState'
import { Grid, MenuItem, Typography } from '@mui/material'
import JotaiMuiSelect from '../../../../../components/input/JotaiMuiSelect'
import { useJotaiFormContext } from '../../../../../components/state/jotaiFormProvider'
import { Cycle, Feature, OrderType, Recurrence } from '../../../../../generated/graphql'
import { billableCurrencyFormat } from '@/util/currencyUtil'
import { useGetInvoiceTriggerSchedules } from '../../hooks/useInvoiceTriggerSchedules'
import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'

export function BillingAnchorDateField() {
  const jotaiForm = useJotaiFormContext<CommonOrderFormPageState>()
  const { currencyCode, customOrderLineTotalAmount, remainingAmount, billingCycle, orderType } =
    useGetInvoiceTriggerSchedules()
  const customBillingEnabled = useDynamicFeatureFlag(Feature.CustomBilling)

  return (
    <Grid item xs={2}>
      <JotaiMuiSelect
        atomSelector={(form) => form.orderDetail.billingCycle?.cycle}
        atomUpdater={(value, draft) => {
          if (!draft.orderDetail.billingCycle) {
            draft.orderDetail.billingCycle = {
              step: 1,
              cycle: Cycle.Year,
            }
          }
          if (value === Cycle.Custom && customOrderLineTotalAmount) {
            draft.invoiceTriggerSchedules = [
              {
                amount: customOrderLineTotalAmount,
                triggerInstant: draft.orderDetail.startDate as number,
                recurrenceWithCount: {
                  recurrence: {
                    cycle: Cycle.PaidInFull,
                    step: 1,
                  } as Recurrence,
                  count: 1,
                },
              },
            ]
          }
          draft.orderDetail.billingCycle.cycle = value as Cycle
          draft.orderDetail.billingAnchorDate = undefined
        }}
        errorPath="orderDetail.billingCycle.cycle"
        form={jotaiForm}
        textFieldProps={{
          label: 'Billing Cycle',
        }}
        disabledExplanation={
          orderType === OrderType.Amendment ? 'Billing cycle cannot be changed for amendment orders' : undefined
        }
      >
        <MenuItem value={Cycle.Month}>Monthly</MenuItem>
        <MenuItem value={Cycle.Quarter}>Quarterly</MenuItem>
        <MenuItem value={Cycle.SemiAnnual}>Semi-Annual</MenuItem>
        <MenuItem value={Cycle.Year}>Yearly</MenuItem>
        <MenuItem value={Cycle.PaidInFull}>Upfront</MenuItem>
        {customBillingEnabled && <MenuItem value={Cycle.Custom}>Custom</MenuItem>}
      </JotaiMuiSelect>

      {billingCycle === Cycle.Custom && (
        <>
          <Typography variant="caption" color={remainingAmount ? 'error' : 'inherit'}>
            {billableCurrencyFormat({ currency: currencyCode, value: remainingAmount })} remaining to be scheduled
          </Typography>
        </>
      )}
    </Grid>
  )
}
