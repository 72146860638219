import Big from 'big.js'
import { ChargeModel, ChargeType, LineItemFragment } from '../generated/graphql'
import buildLogger from './logger'
const logger = buildLogger('currencyUtil')

export const currencySymbol = (currency?: string): string => {
  const parts = Intl.NumberFormat('en-US', { style: 'currency', currency: currency || 'USD' }).formatToParts()
  const currencySymbol = parts.find((value) => value['type'] === 'currency')
  return currencySymbol ? currencySymbol['value'] : '$'
}
export const quantityFormat = (value?: number | null): string => {
  if (value === null || value === undefined) {
    return ''
  }

  return Intl.NumberFormat('en-US', {
    maximumSignificantDigits: 3,
  }).format(value)
}

export const billableCurrencyFormat = ({
  currency,
  value,
}: {
  currency?: string | null
  value?: number | null
}): string => {
  if (value === null || value === undefined) {
    return ''
  }

  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
    maximumFractionDigits: 2,
  }).format(value)
}

export const unitPriceFormatter = ({
  currency,
  value,
  maximumFractionDigits = 2,
}: {
  currency?: string | null
  value?: number | null
  maximumFractionDigits?: number | undefined
}): string => {
  if (value === null || value === undefined) {
    return ''
  }

  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
    maximumFractionDigits,
  }).format(value)
}

export const getDiscountRatio = (sellTotal: number | Big, listTotal: number | Big): Big | undefined => {
  return !Big(listTotal).eq(0)
    ? Big(listTotal)
        .minus(Big(sellTotal ?? 0))
        .div(Big(listTotal))
    : undefined
}

export const getDiscountedPrice = (total: number | Big, percent: number | Big): Big => {
  return Big(total).times(Big(1).minus(Big(percent)))
}

export const discountFormat = ({ value }: { value?: number | null }): string => {
  if (value === null || value === undefined) {
    return ''
  }

  return Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 2,
  }).format(value)
}

export const getOverridePrice = (total: number | Big, ratio: number | Big): Big => {
  return Big(total).times(Big(ratio))
}

export const getOverrideRatio = (
  overrideListUnitPrice: number | Big | undefined,
  listUnitPriceBeforeOverride: number | Big
): Big | undefined => {
  if (overrideListUnitPrice === undefined) {
    return undefined
  }
  if (listUnitPriceBeforeOverride === 0) {
    return undefined
  }
  if (Number(overrideListUnitPrice) === Number(listUnitPriceBeforeOverride)) {
    return undefined
  }
  return Big(overrideListUnitPrice).div(Big(listUnitPriceBeforeOverride))
}

export function getLineItemTotalAmount(lineItem: LineItemFragment) {
  const listAmount = lineItem?.listAmount ?? 0
  const chargeUnitAmount = lineItem?.charge?.amount ?? 0
  const ret = Big(
    lineItem?.charge &&
      lineItem?.charge.chargeModel === ChargeModel.PerUnit &&
      lineItem?.charge.type === ChargeType.Usage
      ? chargeUnitAmount
      : listAmount
  )
  return ret
}

export function getOrderLineItemsDiscountData(lineItems: LineItemFragment[]) {
  {
    const totalOrderAmount = lineItems
      .filter((lineItem) => !lineItem.charge.isDiscount)
      .reduce((prev, lineItem) => {
        return prev.add(getLineItemTotalAmount(lineItem))
      }, Big(0))

    const discountLinesTotal = lineItems
      .filter((lineItem) => !!lineItem.charge.isDiscount)
      .reduce((prev, lineItem) => {
        return prev.add(getLineItemTotalAmount(lineItem))
      }, Big(0))

    const lineItemsDiscountTotal = lineItems
      .filter((lineItem) => !lineItem.charge.isDiscount)
      .reduce((prev, lineItem) => {
        const discountRatio = lineItem.discounts?.[0]?.percent ?? 0
        return prev.add(getLineItemTotalAmount(lineItem).times(discountRatio))
      }, Big(0))

    const orderDiscountableTotal = totalOrderAmount.add(discountLinesTotal)

    const totalDiscountRatio =
      getDiscountRatio(totalOrderAmount.minus(lineItemsDiscountTotal), totalOrderAmount) ?? Big(0)
    // todo: add minimum

    return {
      totalDiscountRatio,
      totalDiscountedAmount: lineItemsDiscountTotal,
      totalOrderAmount: totalOrderAmount,
      orderDiscountableTotal,
    }
  }
}

export function getChangeInTotalAmount(
  totalBefore: number | Big | null | undefined,
  totalAfter: number | Big | null | undefined
): Big {
  return Big(totalAfter ?? 0).minus(Big(totalBefore ?? 0))
}
