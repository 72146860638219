import {
  BillyStepperForm,
  StepperFormCallbackHandler,
  StepperFormChildren,
} from '@/components/BillyStepperForm/BillyStepperForm'
import Head from 'next/head'
import JotaiUrqlErrors from '../../../components/error/jotaiUrqlErrors'
import InnerPageContainer from '../../../components/layout/innerPageContainer'
import { NavLayout } from '../../../components/layout/navLayout'
import PageLoadingPlaceholder from '../../../components/placeholder/pageLoadingPlaceholder'
import { JotaiUrqlBillySnackBar } from '../../../components/snackBar/useJotaiUrqlBillySnackBar'
import { useJotaiFormContext } from '../../../components/state/jotaiFormProvider'
import { UpsertOrderDocument, UpsertRenewalOrderDocument } from '../../../generated/graphql'
import { NewOrderFormData } from './NewOrderPage'
import { NewOrderRightDrawer } from './NewOrderRightDrawer'
import { useOrderPageReducer } from './hooks/useOrderPageReducer'
import { ButtonData } from '@/components/button/actionButton'

export function NewOrderStepperPageNavLayout({
  children,
  stepperFormProps,
}: { children?: React.ReactNode } & StepperFormChildren): JSX.Element {
  const { orderTitle, buttonDataList, isLoading, breadcrumbs } = useOrderPageReducer()
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  return (
    <>
      <Head>
        <title>{`${orderTitle} | Subskribe`}</title>
      </Head>
      <NavLayout
        actionButtons={<OrderEditStepperButtons buttonDataList={buttonDataList} stepperFormProps={stepperFormProps} />}
        breadcrumbs={breadcrumbs}
        rightDrawer={NewOrderRightDrawer}
      >
        <InnerPageContainer>
          <JotaiUrqlErrors jotaiForm={jotaiForm} />
          <JotaiUrqlBillySnackBar document={UpsertOrderDocument} jotaiForm={jotaiForm} />
          <JotaiUrqlBillySnackBar document={UpsertRenewalOrderDocument} jotaiForm={jotaiForm} />
          <PageLoadingPlaceholder isLoading={isLoading}>{children}</PageLoadingPlaceholder>
        </InnerPageContainer>
      </NavLayout>
    </>
  )
}

export function OrderEditStepperButtons({
  buttonDataList,
  stepperFormProps,
}: {
  buttonDataList: ButtonData[]
} & StepperFormChildren) {
  const isLastStep = stepperFormProps?.activeStepIndex === (stepperFormProps?.steps.length ?? 0) - 1
  const onClickSaveDraft = buttonDataList.find((buttonData) => buttonData.label === 'Save Draft')?.onClick
  return stepperFormProps ? (
    <BillyStepperForm.Action
      stepperFormProps={{
        ...stepperFormProps,
        handleNext:
          isLastStep && onClickSaveDraft && typeof onClickSaveDraft === 'function'
            ? (onClickSaveDraft as StepperFormCallbackHandler)
            : stepperFormProps.handleNext,
      }}
      moreButtons={buttonDataList}
    />
  ) : (
    <></>
  )
}
