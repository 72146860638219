import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useCallback } from 'react'
import ActionButton from '../../components/button/actionButton'
import { WithModalParams, useModal, useModalsContext } from '../../components/state/context/modalsContext'

type DialogProps = WithModalParams
const formID = 'EsignInProgressDialog'
function EsignInProgressDialog({ open, onClose }: DialogProps): JSX.Element {
  return (
    <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'} fullWidth>
      <DialogTitle>eSignature In Progress</DialogTitle>
      <DialogContent dividers>
        <Typography>
          Cannot revert order to draft while eSignature is in progress. Please void the eSignature request in View
          eSignature Details &gt; Void and try again.
        </Typography>
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          key={'Close'}
          buttonData={{
            label: 'Close',
            onClick: onClose,
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export function useEsignInProgressDialog(modalProps: DialogProps = {}) {
  const [, , toggleModal] = useModalsContext()

  useModal<DialogProps>({
    component: EsignInProgressDialog,
    schema: {
      key: formID,
      modalProps,
    },
  })
  return useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])
}
