import { deepImmutable } from '@/components/SchemaForm/DeepImmutable'
import { PriceTier } from '@/generated/graphql'
import { MappedPricedTier, mapPriceTier } from '@/util/priceTier'
import { Reducer, useCallback, useReducer } from 'react'
import { PricingOverrideFragment } from '@/generated/graphql'
import { Mutable } from '@/components/SchemaForm/SchemaFormEngine'

type PriceTierOverrideState = {
  override: Mutable<PricingOverrideFragment>
  original?: Mutable<PricingOverrideFragment> | null
}

type UpdateTierPayload = Partial<Pick<PriceTier, 'amount'>> & {
  from?: string
  to?: string
}

type PriceTierOverrideAction =
  | { type: 'ADD_TIER' }
  | { type: 'DELETE_TIER'; index: number }
  | { type: 'UPDATE_TIER'; index: number; tier: UpdateTierPayload }
  | { type: 'REVERT'; original?: Mutable<PricingOverrideFragment> | null }
  | { type: 'RESET' }

const initialState: PriceTierOverrideState = {
  override: {
    priceTiers: [],
    minQuantity: undefined,
    maxQuantity: undefined,
  },
  original: null,
}

function reducer(state: PriceTierOverrideState, action: PriceTierOverrideAction): PriceTierOverrideState {
  switch (action.type) {
    case 'ADD_TIER': {
      const newTiers = [...(state.override.priceTiers ?? [])]
      if (!state.override.maxQuantity) {
        state.override.maxQuantity =
          (newTiers[newTiers.length - 2]
            ? parseInt(newTiers[newTiers.length - 2].untilQuantity)
            : state.override.minQuantity ?? 0) + 1
      }
      if (state.override.maxQuantity) {
        newTiers[newTiers.length - 1] = {
          ...newTiers[newTiers.length - 1],
          untilQuantity: state.override.maxQuantity.toString(),
        }
        // clear max quantity
        state.override.maxQuantity = undefined
      }

      newTiers.push({
        amount: state.override.priceTiers?.[state.override.priceTiers.length - 1]?.amount ?? 0,
        untilQuantity: 'INF',
      })

      return {
        ...state,
        override: {
          ...state.override,
          priceTiers: newTiers.map((tier) => ({
            amount: tier.amount,
            untilQuantity: tier.untilQuantity === 'INF' ? 'INF' : tier.untilQuantity.toString(),
          })),
        },
      }
    }
    case 'DELETE_TIER': {
      const newTiers = [...(state.override.priceTiers ?? [])]
      newTiers.splice(action.index, 1)

      return {
        ...state,
        override: {
          ...state.override,
          priceTiers: newTiers,
        },
      }
    }
    case 'UPDATE_TIER': {
      const newTiers = [...(state.override.priceTiers ?? [])]
      let newMaxQuantity = state.override.maxQuantity
      let newMinQuantity = state.override.minQuantity

      if (action.index === 0) {
        if (action.tier.from) {
          newMinQuantity = parseInt(action.tier.from)
        } else {
          newMinQuantity = undefined
        }
      } else {
        if (action.tier.from) {
          // For non-first tiers, update previous tier's untilQuantity
          const fromValue = parseInt(action.tier.from)
          newTiers[action.index - 1] = {
            ...newTiers[action.index - 1],
            untilQuantity: (fromValue - 1).toString(),
          }
        } else {
          newTiers[action.index - 1] = {
            ...newTiers[action.index - 1],
            untilQuantity: '0',
          }
        }
      }

      // If updating 'to', handle both current tier and maxQuantity for last tier
      const isLastTier = action.index === newTiers.length - 1

      if (isLastTier) {
        if (action.tier.to) {
          newMaxQuantity = parseInt(action.tier.to)
        } else {
          newMaxQuantity = undefined
        }
      } else {
        newTiers[action.index] = {
          ...newTiers[action.index],
          untilQuantity: action.tier.to === '' || action.tier.to === undefined ? 'INF' : action.tier.to,
        }
      }

      // amount
      newTiers[action.index] = {
        ...newTiers[action.index],
        amount: action.tier.amount ?? newTiers[action.index].amount,
      }

      return {
        ...state,
        override: {
          ...state.override,
          priceTiers: newTiers.map((tier) => ({
            amount: tier.amount,
            untilQuantity: tier.untilQuantity === 'INF' ? 'INF' : tier.untilQuantity.toString(),
          })),
          minQuantity: newMinQuantity,
          maxQuantity: newMaxQuantity,
        },
      }
    }
    case 'REVERT': {
      if (!action.original) return state
      console.log('revert', action.original)
      return {
        ...state,
        override: action.original,
      }
    }
    case 'RESET':
      return initialState
    default:
      return state
  }
}

export function usePriceTierOverrideReducer(
  initialPriceOverride?: PricingOverrideFragment,
  original?: PricingOverrideFragment | null
): {
  override: PricingOverrideFragment
  original?: PricingOverrideFragment | null
  tiers: readonly MappedPricedTier[]
  addTier: () => void
  deleteTier: (index: number) => void
  updateTier: (index: number, tier: UpdateTierPayload) => void
  revertTiers: () => void
  resetTiers: () => void
  hasChanges: boolean
  hasError: boolean
  errors: {
    from: string
    to: string
    amount: string
  }[]
} {
  const [state, dispatch] = useReducer<Reducer<PriceTierOverrideState, PriceTierOverrideAction>>(reducer, {
    ...initialState,
    override: initialPriceOverride ?? initialState.override,
    original,
  })

  const addTier = useCallback(() => {
    dispatch({ type: 'ADD_TIER' })
  }, [])

  const deleteTier = useCallback((index: number) => {
    dispatch({ type: 'DELETE_TIER', index })
  }, [])

  const updateTier = useCallback((index: number, tier: UpdateTierPayload) => {
    dispatch({ type: 'UPDATE_TIER', index, tier })
  }, [])

  const revertTiers = useCallback(() => {
    if (state.original) {
      dispatch({ type: 'REVERT', original: state.original })
    }
  }, [state.original])

  const resetTiers = useCallback(() => {
    dispatch({ type: 'RESET' })
  }, [])

  const tiers = deepImmutable(
    mapPriceTier(state.override.priceTiers ?? [], state.override.minQuantity, state.override.maxQuantity)
  )

  const errors = tiers.map((tier) => ({
    from: '',
    to: parseInt(tier.to) < parseInt(tier.from) ? `Minimum ${tier.from}` : '',
    amount: '',
  }))

  return {
    override: state.override,
    original: state.original,
    tiers,
    addTier,
    deleteTier,
    updateTier,
    revertTiers,
    resetTiers,
    hasChanges: state.original !== null && JSON.stringify(state.override) !== JSON.stringify(state.original),
    errors,
    hasError: errors.some((error) => !!error.to || !!error.from || !!error.amount),
  }
}
