import {
  ContactType,
  ElectronicSignatureProvider,
  ElectronicSignatureStatus,
  Feature,
  OrderDetailFragment,
  OrderStatus,
  SigningOrder,
  useEmailContactsForAccountQuery,
  useGetAccountQuery,
  useGetElectronicSignatureProviderQuery,
  useGetElectronicSignatureQuery,
  useGetSigningOrderQuery,
  useGetTenantSignatoriesQuery,
  useResendEsignatureDocumentMutation,
  useSendEmailForDocusignMutation,
  useSendEmailForEsignMutation,
  useUpdateOrderStatusMutation,
  useVoidEsignatureDocumentMutation,
} from '@/generated/graphql'
import { SendEsignEmailFormData, useSendEsignEmailDrawer } from './SendEsignEmailDrawer'
import { useCallback, useMemo } from 'react'
import { notEmpty } from '@/util/array'
import { ErrorHandler } from '@/components/ErrorHandler/ErrorHandler'
import { SnackbarHandler } from '@/components/SnackbarHandler/SnackbarHandler'
import { BillyRouter } from '@/components/route/useBillyRouter'
import { useDocusignRefreshTokenExpiredDialog } from './DocusignRefreshTokenExpiredDialog'
import { isTypeOfCombinedError } from '@/util/gqlErrorHandling'
import { GraphQLError } from 'graphql'
import { useEsignInProgressDialog } from './EsignInProgressDialog'
import { useRevertSignedDocumentDialog } from './RevertSignedDocumentDialog'
import { useVoidSignedDocumentForOrder } from '../orders/EditOrderPage/hooks/useVoidSignedDocumentForOrder'
import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'

type SendEmailOrderViewProps = {
  orderId: string
  orderData?: OrderDetailFragment
  errorHandler: ErrorHandler
  snackbarHandler: SnackbarHandler
  router: BillyRouter
}

export function useSendEmailOrderView({
  orderId,
  orderData,
  errorHandler,
  snackbarHandler,
  router,
}: SendEmailOrderViewProps) {
  const [electronicSignatureProviderResponse] = useGetElectronicSignatureProviderQuery()

  const [tenantSignatoriesResponse] = useGetTenantSignatoriesQuery()

  const accountId = orderData?.resoldBy?.id ?? orderData?.account?.id ?? ''
  const [accountResponse] = useGetAccountQuery({
    variables: { id: accountId },
    pause: !accountId,
    handleError: true,
  })

  const isEsignEnabled =
    electronicSignatureProviderResponse?.data?.electronicSignatureProvider === ElectronicSignatureProvider.Pandadoc
  const [, sendEmailForEsign] = useSendEmailForEsignMutation()
  const [, sendEmailForDocusign] = useSendEmailForDocusignMutation()
  const [, updateOrderStatus] = useUpdateOrderStatusMutation()
  const { voidSignedDocumentForOrder } = useVoidSignedDocumentForOrder()

  const shouldShowAllSignedDocuments = useDynamicFeatureFlag(Feature.ShowAllEsignedDocumentsOnUi)

  const [getElectronicSignatureResult, refreshElectronicSignatureResult] = useGetElectronicSignatureQuery({
    variables: { orderId: orderId },
    pause: !electronicSignatureProviderResponse?.data?.electronicSignatureProvider,
  })
  const esignData = getElectronicSignatureResult.data?.electronicSignature

  const handleRefreshESign = useCallback(() => {
    async function doAsync() {
      await router.esDelay()
      refreshElectronicSignatureResult()
    }
    doAsync().catch(errorHandler)
  }, [router, refreshElectronicSignatureResult, errorHandler])

  const [, voidEsignatureDocument] = useVoidEsignatureDocumentMutation()
  const onVoidEsign = useCallback(() => {
    async function doAsync() {
      const progressKey = snackbarHandler.inProgressAlert('Voiding...')
      try {
        const response = await voidEsignatureDocument({ id: esignData?.id || '' })

        if (response.data?.voidEsignatureDocumentV2) {
          snackbarHandler.successAlert('Voided successfully')
        }
      } finally {
        snackbarHandler.dismissAlert(progressKey)
        handleRefreshESign()
      }
    }

    doAsync().catch(errorHandler)
  }, [errorHandler, handleRefreshESign, voidEsignatureDocument, snackbarHandler, esignData?.id])

  const onVoidCompletedEsign = useCallback(() => {
    async function doAsync() {
      const progressKey = snackbarHandler.inProgressAlert('Voiding...')
      try {
        await voidSignedDocumentForOrder(orderId)
      } finally {
        snackbarHandler.dismissAlert(progressKey)
        handleRefreshESign()
      }
    }

    doAsync().catch(errorHandler)
  }, [errorHandler, handleRefreshESign, voidSignedDocumentForOrder, snackbarHandler, orderId])

  const [, resendEsignatureDocument] = useResendEsignatureDocumentMutation()
  const onResendEsign = useCallback(() => {
    async function doAsync() {
      const progressKey = snackbarHandler.inProgressAlert('Resending...')
      try {
        const response = await resendEsignatureDocument({ id: esignData?.id || '' })
        if (response.data?.resendEsignatureDocumentV2) {
          snackbarHandler.successAlert('Resent successfully')
        }
      } finally {
        snackbarHandler.dismissAlert(progressKey)
      }
    }

    doAsync().catch(errorHandler)
  }, [errorHandler, esignData?.id, resendEsignatureDocument, snackbarHandler])

  const [emailContactsForAccountResponse] = useEmailContactsForAccountQuery({
    variables: {
      accountId: accountResponse.data?.accountDetail.id ?? '',
    },
    pause: !accountResponse.data?.accountDetail.id,
  })

  const emailContacts = useMemo(
    () => emailContactsForAccountResponse.data?.emailContactsForAccount.filter(notEmpty) ?? [],
    [emailContactsForAccountResponse.data?.emailContactsForAccount]
  )
  const tenantSignatories = useMemo(
    () => tenantSignatoriesResponse.data?.tenantSignatories.filter(notEmpty) ?? [],
    [tenantSignatoriesResponse.data?.tenantSignatories]
  )

  const [signingOrderResponse] = useGetSigningOrderQuery()
  const signingOrder = signingOrderResponse.data?.signingOrder

  const asyncSubmitEsignV2 = useCallback(
    async (data: SendEsignEmailFormData) => {
      if (isEsignEnabled) {
        const accountSignatory = emailContacts
          .filter((contact) => contact.type === ContactType.AccountContact)
          .find((contact) => contact.id === data.customerSignatory.id)
        const tenantSignatory = tenantSignatories.find(
          (signatory) => signatory.user.id === data.hostSignatory?.id
        )?.user
        const additionalRecipients = data.additionalRecipients
          ?.map((recipient) => emailContacts?.find((contact) => contact.id === recipient.id))
          .filter(notEmpty)

        if (!accountSignatory) {
          throw new Error('Missing customer signatory')
        }

        const tenantSignatoryRequired = signingOrder !== SigningOrder.AccountOnly
        if (tenantSignatoryRequired && !tenantSignatory) {
          throw new Error('Missing host signatory')
        }

        const progressKey = snackbarHandler.inProgressAlert('Sending email...')
        try {
          const sendEmailResponse = await sendEmailForEsign({
            esignRequest: {
              orderId,
              accountSignatory,
              tenantSignatory: tenantSignatory
                ? {
                    name: tenantSignatory.displayName,
                    email: tenantSignatory.email,
                    id: tenantSignatory.id,
                    type: ContactType.User,
                  }
                : undefined,
              additionalRecipients,
              isComposite: !!orderData?.compositeOrderId,
            },
          })

          if (sendEmailResponse.data?.sendEmailForEsign) {
            snackbarHandler.successAlert('Email sent successfully!')
          } else {
            throw new Error('Failed to send email.')
          }
        } finally {
          snackbarHandler.dismissAlert(progressKey)
        }
      } else {
        throw new Error('Feature is not enabled')
      }
    },
    [
      emailContacts,
      isEsignEnabled,
      sendEmailForEsign,
      snackbarHandler,
      tenantSignatories,
      orderId,
      orderData?.compositeOrderId,
      signingOrder,
    ]
  )

  const { toggleDocusignRefreshTokenExpiredDialog, setRedirectUri } = useDocusignRefreshTokenExpiredDialog()
  const asyncSubmitDocusignV2 = useCallback(
    async (data: SendEsignEmailFormData) => {
      const accountSignatory = emailContacts
        .filter((contact) => contact.type === ContactType.AccountContact)
        .find((contact) => contact.id === data.customerSignatory.id)
      const tenantSignatory = tenantSignatories.find((signatory) => signatory.user.id === data.hostSignatory?.id)?.user
      const additionalRecipients = data.additionalRecipients
        ?.map((recipient) => emailContacts?.find((contact) => contact.id === recipient.id))
        .filter(notEmpty)

      if (!accountSignatory) {
        throw new Error('Missing customer signatory')
      }

      const tenantSignatoryRequired = signingOrder !== SigningOrder.AccountOnly
      if (tenantSignatoryRequired && !tenantSignatory) {
        throw new Error('Missing host signatory')
      }

      const progressKey = snackbarHandler.inProgressAlert('Sending email...')
      try {
        const response = await sendEmailForDocusign({
          esignRequest: {
            orderId: orderId,
            accountSignatory,
            tenantSignatory: tenantSignatory
              ? {
                  name: tenantSignatory.displayName,
                  email: tenantSignatory.email,
                  id: tenantSignatory.id,
                  type: ContactType.User,
                }
              : undefined,
            additionalRecipients,
            isComposite: !!orderData?.compositeOrderId,
          },
        })

        if (response.data?.sendEmailForDocusign) {
          snackbarHandler.successAlert('Email sent successfully!')
        } else {
          throw new Error('Failed to send email.')
        }
      } catch (err) {
        if (isTypeOfCombinedError(err)) {
          const graphQLErrors = err.graphQLErrors
          const errorLineWithRefreshTokenExpired = graphQLErrors.find((errItem: GraphQLError) => {
            return (
              errItem.extensions &&
              errItem.extensions?.errorId &&
              errItem.extensions?.errorId === 'docusignRefreshTokenExpired'
            )
          })

          if (errorLineWithRefreshTokenExpired && errorLineWithRefreshTokenExpired.extensions?.redirectUri) {
            setRedirectUri(errorLineWithRefreshTokenExpired.extensions?.redirectUri)
            toggleDocusignRefreshTokenExpiredDialog()
          }
        }
        throw err
      } finally {
        snackbarHandler.dismissAlert(progressKey)
      }
    },
    [
      emailContacts,
      snackbarHandler,
      tenantSignatories,
      sendEmailForDocusign,
      toggleDocusignRefreshTokenExpiredDialog,
      setRedirectUri,
      orderId,
      orderData?.compositeOrderId,
      signingOrder,
    ]
  )

  const toggleSendEsignEmailDialog = useSendEsignEmailDrawer(
    useMemo(
      () => ({
        onSubmit: isEsignEnabled ? asyncSubmitEsignV2 : asyncSubmitDocusignV2,
        onVoid:
          esignData?.status === ElectronicSignatureStatus.Completed && shouldShowAllSignedDocuments
            ? onVoidCompletedEsign
            : esignData?.status !== ElectronicSignatureStatus.Completed
            ? onVoidEsign
            : undefined,
        onResend: onResendEsign,
        eSignData: esignData,
        emailContacts,
        tenantSignatories,
        billingContact: orderData?.billingContact,
        refresh: handleRefreshESign,
        orderId,
        orderEntityId: orderData?.entityId,
      }),
      [
        isEsignEnabled,
        asyncSubmitEsignV2,
        asyncSubmitDocusignV2,
        onVoidEsign,
        shouldShowAllSignedDocuments,
        onVoidCompletedEsign,
        onResendEsign,
        esignData,
        emailContacts,
        tenantSignatories,
        orderData?.billingContact,
        handleRefreshESign,
        orderId,
        orderData?.entityId,
      ]
    )
  )

  const onRevertSignedDocument = useCallback(() => {
    async function doAsync() {
      const key = snackbarHandler.inProgressAlert('Updating order status...')

      try {
        await updateOrderStatus({
          id: orderId,
          status: OrderStatus.Draft,
          statusUpdatedOn: new Date().getTime(),
          adminApprovalFlowBypass: false,
        })
      } finally {
        snackbarHandler.dismissAlert(key)
      }
    }
    doAsync().catch((err) => {
      errorHandler(err)
    })
  }, [orderId, updateOrderStatus, snackbarHandler, errorHandler])

  const isEsignInProgress = !!esignData
  const isEsignCompleted = esignData?.status === ElectronicSignatureStatus.Completed
  const toggleEsignInProgressDialog = useEsignInProgressDialog()

  const toggleRevertSignedDocumentDialog = useRevertSignedDocumentDialog({
    onSubmit: onRevertSignedDocument,
  })

  return useMemo(() => {
    return {
      toggleSendEsignEmailDialog,
      toggleEsignInProgressDialog,
      toggleRevertSignedDocumentDialog,
      esignProvider: electronicSignatureProviderResponse?.data?.electronicSignatureProvider,
      esignStatus: esignData?.status,
      isEsignInProgress,
      isEsignCompleted,
    }
  }, [
    toggleSendEsignEmailDialog,
    toggleEsignInProgressDialog,
    toggleRevertSignedDocumentDialog,
    electronicSignatureProviderResponse?.data?.electronicSignatureProvider,
    esignData?.status,
    isEsignInProgress,
    isEsignCompleted,
  ])
}
