export const REQUEST_TIMEOUT_LIMIT = 1000 * 60 // 1 minute
export const timeoutErrorMessage = 'TimeoutError: The server is taking longer than expected. Please try again later.'

export async function fetchStreamingWithTimeout(
  endpoint: string,
  options: RequestInit = {},
  timeout: number = REQUEST_TIMEOUT_LIMIT
): Promise<{ response: Response; abortTimeoutRef: NodeJS.Timeout }> {
  const controller = new AbortController()

  const abortTimeoutRef = setTimeout(() => {
    controller.abort(timeoutErrorMessage)
  }, timeout)

  const response = await fetch(endpoint, {
    ...options,
    signal: controller.signal,
  })

  return { response, abortTimeoutRef }
}
