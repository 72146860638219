import { Grid, InputAdornment, Typography } from '@mui/material'
import { Box } from '@mui/system'
import Big from 'big.js'
import { useCallback } from 'react'
import { DeepMutable } from '../../../components/SchemaForm/DeepMutable'
import JotaiUrqlErrors from '../../../components/error/jotaiUrqlErrors'
import JotaiMuiNumberField from '../../../components/input/JotaiMuiNumberField'
import { Discount } from '../../../generated/graphql'
import {
  currencySymbol,
  getDiscountRatio,
  getDiscountedPrice,
  getOrderLineItemsDiscountData,
  unitPriceFormatter,
} from '../../../util/currencyUtil'
import buildLogger from '../../../util/logger'
import { OrderDiscountDialogProps, OrderDiscountDialogState } from './OrderDiscountDialog'

const logger = buildLogger('LineItemPriceOverrideDialogContent')

export const OrderDiscountDialogContent = <F extends OrderDiscountDialogState>({
  jotaiForm,
  orderType,
}: OrderDiscountDialogProps<F>): JSX.Element => {
  //TODO: based on charge type/model, determine if price override is allowed
  const { totalDiscountRatio, totalOrderAmount, currency } = jotaiForm.useSelect(
    useCallback((form) => {
      return {
        ...getOrderLineItemsDiscountData(form.orderDetail.lineItems),
        currency: form.orderDetail.currency,
      }
    }, [])
  )

  return (
    <>
      <JotaiUrqlErrors jotaiForm={jotaiForm} />
      <>
        {orderType === 'NEW' && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Order Discount</Typography>
                <Typography variant="body2">Apply a manual discount to this order</Typography>
              </Grid>
              <Grid item xs={12} role="gridcell">
                <Box display="flex">
                  <Box>
                    <JotaiMuiNumberField
                      atomSelector={() => {
                        return totalDiscountRatio.toNumber()
                      }}
                      atomUpdater={(value, draft) => {
                        const updatedLineItems = draft.orderDetail.lineItems.map((lineItem) => {
                          const updatedLineItem = { ...lineItem, arrOverride: undefined }
                          if (updatedLineItem.charge.isDiscount) {
                            return updatedLineItem
                          }
                          if (!updatedLineItem.discounts) {
                            updatedLineItem.discounts = []
                          }
                          if (updatedLineItem.discounts.length === 0) {
                            updatedLineItem.discounts.push({ name: '' })
                          }
                          ;(updatedLineItem.discounts[0] as DeepMutable<Discount>).percent = value || 0

                          return updatedLineItem
                        })

                        draft.orderDetail.lineItems = updatedLineItems
                      }}
                      form={jotaiForm}
                      percent={true}
                      textFieldProps={{
                        'aria-label': 'Charge Discount',
                        placeholder: '0',
                        size: 'small',
                        label: '',
                        style: { maxWidth: 160 },
                        inputProps: {
                          sx: { height: '2.5rem', textAlign: 'right' },
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">Expected Sell Amount</Typography>
                <Typography variant="body2">Apply an expected sell total amount to calculate discount</Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: '1rem' }} role="gridcell">
                <Grid item>
                  <Box
                    sx={{
                      flexFlow: 'row',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box>
                      <JotaiMuiNumberField
                        atomSelector={() => {
                          return +getDiscountedPrice(totalOrderAmount, totalDiscountRatio).toFixed(4)
                        }}
                        atomUpdater={(expectedSellAmount, draft) => {
                          const updatedLineItems = draft.orderDetail.lineItems.map((lineItem) => {
                            const updatedLineItem = { ...lineItem, arrOverride: undefined }
                            if (updatedLineItem.charge.isDiscount) {
                              return updatedLineItem
                            }
                            if (!updatedLineItem.discounts) {
                              updatedLineItem.discounts = []
                            }
                            if (updatedLineItem.discounts.length === 0) {
                              updatedLineItem.discounts.push({ name: '' })
                            }
                            const percent = +(expectedSellAmount === null ||
                            expectedSellAmount === undefined ||
                            !totalOrderAmount
                              ? '1'
                              : getDiscountRatio(expectedSellAmount, totalOrderAmount) ?? '1')
                            const absPercent = percent < 0 ? 0 : percent
                            ;(updatedLineItem.discounts[0] as DeepMutable<Discount>).percent = absPercent || 0
                            return updatedLineItem
                          })

                          draft.orderDetail.lineItems = updatedLineItems
                        }}
                        form={jotaiForm}
                        textFieldProps={{
                          'aria-label': 'Expected Sell Amount',
                          placeholder: 'Sell Amount',
                          size: 'small',
                          label: '',
                          style: { maxWidth: 160 },
                          inputProps: {
                            startAdornment: (
                              <InputAdornment position="start">{currencySymbol(currency ?? 'USD')}</InputAdornment>
                            ),
                            sx: { height: '2.5rem', textAlign: 'right' },
                          },
                        }}
                      />
                    </Box>
                    <Typography variant="body2" fontSize="1rem">
                      &nbsp;/&nbsp;
                    </Typography>
                    <Typography variant="body2" fontSize="1rem">
                      {unitPriceFormatter({
                        currency,
                        value: +Big(totalOrderAmount),
                      })}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </>
    </>
  )
}
