import React, { useCallback } from 'react'
import { Box, Grid, Hidden, Button, Drawer } from '@mui/material'
import { styled } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import TopBar from '../nav/TopBar'
import { BreadcrumbDataList } from '../nav/breadcrumbNav'
import LeftDrawer from '../nav/leftDrawer'
import navCommon from '../nav/navCommon'
import { SideDrawerNav } from '../nav/SideDrawerNav/SideDrawerNav'
import { isTabEmpty } from '../tab/TabBar'
import { TabCardProps } from '../tab/TabCard'
import { WithTabContext, useSetTabValue } from '../tab/useTabContextBuilder'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { useShouldRecalculateTotals } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'

export type LayoutWithNavProps = Pick<TabCardProps, 'tabs' | 'defaultTab'> & {
  breadcrumbs: BreadcrumbDataList
  children?: React.ReactNode
  secondaryBar?: JSX.Element
  rightDrawer?: () => JSX.Element
}
const useStyles = makeStyles<{ hasTabs: boolean; hasSecondaryBar: boolean; hasRightDrawer: boolean }>()(
  (theme, { hasTabs, hasSecondaryBar, hasRightDrawer }) => ({
    root: {
      display: 'flex',
      position: 'relative',
    },
    content: {
      flexGrow: 1,
      minHeight: `calc(100vh - ${
        (navCommon.topBarHeight(theme) + 1) * ((hasTabs ? 1 : 0) + (hasSecondaryBar ? 1 : 0) + 1)
      }px)`,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      marginTop: (navCommon.topBarHeight(theme) + 1) * (hasTabs ? 2 : 1),
      paddingRight: hasRightDrawer ? 44 : 'none',
    },
    rightDrawer: {
      minWidth: 282,
      maxWidth: 282,
      marginTop: 130,
      padding: theme.spacing(3),
      backgroundColor: theme.customPalette.backgroundColor3,
      borderTop: `4px solid ${theme.palette.primary.main}`,
      borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    },
  })
)

const StyledButton = styled(Button)({
  minWidth: 20,
  height: 80,
  marginTop: 65,
  backgroundColor: '#FFFFFF',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  borderRadius: '8px 0 0 8px',

  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.7)',
    },

    '70%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)',
    },

    '100%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
    },
  },
})

const drawerWidth = 240

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  paddingRight: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginTop: 65,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 240,
  }),
  /**
   * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
   * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
   * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
   * proper interaction with the underlying content.
   */
  position: 'relative',
}))

export default function LayoutWithNav({
  breadcrumbs,
  children,
  secondaryBar,
  tabs,
  defaultTab,
  rightDrawer: RightDrawer,
}: LayoutWithNavProps): JSX.Element {
  const hasSecondaryBar = !!secondaryBar || !!breadcrumbs.length
  const hasTabs = isTabEmpty(tabs ?? [])
  const { classes } = useStyles({ hasTabs, hasSecondaryBar, hasRightDrawer: !!RightDrawer })
  const enableTabsContext = !!tabs

  const [tabValue, setTabValue] = useSetTabValue({ tabs, defaultTab, queryParamName: 'tab_bar' })

  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [open, setOpen] = React.useState(false)

  const loading = useShouldRecalculateTotals()

  const toggleDrawer = useCallback((newOpen: boolean) => {
    setOpen(newOpen)
  }, [])

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen)
  }, [mobileOpen])

  return (
    <div className={classes.root}>
      <LeftDrawer mobileOpen={mobileOpen} onClose={handleDrawerToggle}>
        <SideDrawerNav />
      </LeftDrawer>
      <WithTabContext value={tabValue} hide={!enableTabsContext}>
        <TopBar
          handleDrawerToggle={handleDrawerToggle}
          secondaryBar={secondaryBar}
          breadcrumbs={breadcrumbs}
          onTabChange={(_e, newValue) => setTabValue(newValue)}
          tabs={tabs ?? []}
        />
        <div className={navCommon.styles().classes.drawerOffset}>
          <Hidden
            only={
              secondaryBar
                ? undefined
                : breadcrumbs?.length
                ? navCommon.breadCrumbHiddenBreakpoints
                : navCommon.allBreakpoints
            }
          >
            <Box sx={{ pb: (theme) => `${navCommon.topBarHeight(theme) + 1}px` }} />
          </Hidden>
          {RightDrawer ? (
            <>
              <Main open={open}>{children}</Main>
              <Box sx={{ position: 'fixed', top: 65, right: 0 }}>
                <Box sx={{ display: 'flex' }}>
                  <StyledButton
                    onClick={() => toggleDrawer(!open)}
                    style={{ animation: loading ? 'pulse 2s infinite' : '' }}
                  >
                    {open ? <ChevronRight /> : <ChevronLeft />}
                  </StyledButton>
                  <Drawer
                    sx={{
                      width: open ? drawerWidth : 0,
                      flexShrink: 0,
                      '& .MuiDrawer-paper': {
                        width: open ? drawerWidth : 0,
                      },
                      transition: 'width 0.2s',
                    }}
                    variant="persistent"
                    anchor="right"
                    open={open}
                  >
                    <Grid container direction="column" className={classes.rightDrawer}>
                      <RightDrawer />
                    </Grid>
                  </Drawer>
                </Box>
              </Box>
            </>
          ) : (
            <main className={classes.content}>{children}</main>
          )}
        </div>
      </WithTabContext>
    </div>
  )
}
