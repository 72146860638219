import { GRID_CHECKBOX_SELECTION_COL_DEF, GridFilterModel, GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { GetCustomFieldsBatchedQuery, PlanMinimalFragment } from '../../generated/graphql'

import { getPlanGridTableProps } from '@/components/AddPlanDataGrid/utils'
import { useCustomizationProps, WithCustomizationLocator } from '@/components/state/context/customizationContext'
import { useVisibleColumnsProps } from '@/components/table/tableInCardWithSearchDGP'
import { useUserTenantSession } from '@/components/UserTenantSessionProvider/UserTenantSessionContext'
import buildLogger from '@/util/logger'
import { useCallback, useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import BillyDataGridPro from '../table/billyDataGridPro'
import TableEmptyContent from '../table/tableEmptyContent'
import { WithCustomizationSlot } from './AddPlanDataGridDialog'
const logger = buildLogger('AddPlanDataGrid')

export interface AddPlanDataGridProps extends WithCustomizationSlot {
  readonly loading?: boolean
  readonly data: ReadonlyArray<PlanMinimalFragment>
  readonly customFields?: GetCustomFieldsBatchedQuery['customFieldsBatched']
  readonly onSelectionChange?: (selected: ReadonlyArray<PlanMinimalFragment>) => void
}

// flat entry of the plan for use in the grid

export const usePlanDataGridStyle = makeStyles()((theme) => ({
  virtualScroller: {
    height: 'calc(100vh - 351px)',
  },
  overlayWrapper: {
    minHeight: '105px',
  },
}))

export function AddPlanDataGrid(props: AddPlanDataGridProps & WithCustomizationSlot & WithCustomizationLocator) {
  const { onSelectionChange } = props
  const { classes } = usePlanDataGridStyle()
  const { slot } = props
  const customizationProps = useCustomizationProps({
    displayName: 'addPlan',
    parentLocator: props.parentLocator,
  })
  const userTenantSession = useUserTenantSession()

  const defaultFilterModel = slot?.defaultFilterModel

  const { rows, columns } = getPlanGridTableProps({
    data: props.data,
    role: userTenantSession.currentUser.role,
    customFields: props.customFields,
    customizationProps,
  })

  const handleSelection = useCallback(
    (_rows: GridRowSelectionModel) => {
      const selected = rows.filter((current) => _rows.includes(current.id)).map((current) => current.plan)
      onSelectionChange?.(selected)
    },
    [rows, onSelectionChange]
  )

  const [filters, setFilters] = useState<GridFilterModel | undefined>(undefined)
  useEffect(() => {
    if (defaultFilterModel) {
      setFilters(defaultFilterModel)
    }
  }, [defaultFilterModel])

  const visibleColumnsProps = useVisibleColumnsProps({
    columns: columns.map((column) => ({
      accessor: column.field,
      defaultInvisible: customizationProps?.columnDefaultInvisible?.includes(column.field),
    })),
  })

  return (
    <>
      <BillyDataGridPro
        unstable_headerFilters
        classes={classes}
        variant="add-plan-table"
        loading={props.loading}
        onRowSelectionModelChange={handleSelection}
        disableRowSelectionOnClick={true}
        disableMultipleRowSelection={false}
        disableColumnSelector={false}
        disableColumnResize={false}
        checkboxSelection
        rows={rows}
        slots={{
          noRowsOverlay: TableEmptyContent,
          noResultsOverlay: TableEmptyContent,
        }}
        slotProps={{
          noRowsOverlay: { message: 'No Plans.' },
          noResultsOverlay: { message: 'No Plans Found.' },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
          pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
          },
        }}
        columns={columns}
        autoHeight={false}
        filterModel={filters}
        onFilterModelChange={(newFilterModel) => {
          setFilters(newFilterModel)
        }}
        {...visibleColumnsProps}
      />
    </>
  )
}
