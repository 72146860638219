import { OrderAmendOrCancelDate } from '@/pageComponents/orders/AmendmentOrderPage/OrderAmendOrCancelDate'
import { AmendCancelCustomerDetailsSection } from '@/pageComponents/orders/EditOrderPage/cards/BillyOrderDetailsCard/DetailsGridLayout'
import { CardContent, CardHeader, Grid, Typography } from '@mui/material'
import BillyCard from '../../../components/card/billyCard'
import JotaiUrqlErrors from '../../../components/error/jotaiUrqlErrors'
import { JotaiUrqlBillySnackBar } from '../../../components/snackBar/useJotaiUrqlBillySnackBar'
import { useJotaiFormContext } from '../../../components/state/jotaiFormProvider'
import JotaiReadValue from '../../../components/state/jotaiReadValue'
import { UpsertCancellationDocument } from '../../../generated/graphql'
import { OrderDetailsSection } from '../EditOrderPage/cards/BillyOrderDetailsCard/OrderDetailsSection'
import { CancellationOrderPageState } from './CancellationOrderPage'
import { useCancellationOrderStyles } from './useCancellationOrderStyles'
import { OrderCurrencySelect } from '@/pageComponents/orders/EditOrderPage/cards/BillyOrderDetailsCard/OrderCurrencySelect'

export function CancellationOrderDetailsCard() {
  const jotaiForm = useJotaiFormContext<CancellationOrderPageState>()
  const { classes } = useCancellationOrderStyles()
  return (
    <BillyCard aria-label="cancel-order-details-card">
      <JotaiReadValue
        atomSelector={(form: CancellationOrderPageState) => form.orderDetail.account?.name}
        form={jotaiForm}
        render={(accountName) => <CardHeader title={accountName} subheader="Order Details" />}
      />
      <CardContent>
        <JotaiUrqlErrors jotaiForm={jotaiForm} />
        <JotaiUrqlBillySnackBar document={UpsertCancellationDocument} jotaiForm={jotaiForm} />
        <OrderDetailsSection />
        <AmendCancelCustomerDetailsSection />

        <Typography component="h6" className={classes.rowLabel}>
          Subscription Details
        </Typography>
        <Grid container direction="row" spacing={3}>
          <OrderAmendOrCancelDate />
          <OrderCurrencySelect disabledExplanation="Cancellations do not support changing currencies" />
        </Grid>
      </CardContent>
    </BillyCard>
  )
}
