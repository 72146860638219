export enum AI_SUMMARY_OBJECT {
  SUBSCRIPTION = 'subscription',
  ORDER = 'order',
}

export enum aiSummaryType {
  full = 'full',
  short = 'short',
}

export function getSummaryApiEndpoint(objectName: AI_SUMMARY_OBJECT, objectId: string, summaryType?: aiSummaryType) {
  const baseSummaryPath = `/api/backend/ai/summary/${objectName}/${objectId}`
  if (summaryType && summaryType !== aiSummaryType.full) return `${baseSummaryPath}?type=${summaryType}`
  return baseSummaryPath
}

export function getChatMessageApiEndpoint(sessionId: string, messageContent: string) {
  return `/api/backend/ai/agents/session/${sessionId}/chatAsync?userMessage=${messageContent}`
}
