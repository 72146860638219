import JotaiMuiSelect from '@/components/input/JotaiMuiSelect'
import { JotaiForm } from '@/components/state/useJotaiForm'
import { ChargeFragment, LineItemFragment } from '@/generated/graphql'
import { NewOrderFormData } from '@/pageComponents/orders/EditOrderPage/NewOrderPage'
import { notEmpty } from '@/util/array'
import { findPriceTier, formatPriceTier, mapPriceTier, mapTierToQuantity } from '@/util/priceTier'
import { MenuItem, TextFieldProps } from '@mui/material'
import Big from 'big.js'
import { memo, useCallback, useMemo } from 'react'

type JotaiBlockQuantitySelectCellProps = {
  lineItemIndex: number
  jotaiForm: JotaiForm<NewOrderFormData>
  lineItem: Pick<LineItemFragment, 'charge' | 'pricingOverride'>
  charge: Pick<ChargeFragment, 'priceTiers' | 'minQuantity' | 'maxQuantity'>
  textFieldProps?: TextFieldProps
}

function JotaiBlockQuantitySelectCellWithoutMemo({
  lineItemIndex,
  jotaiForm,
  lineItem,
  charge,
  textFieldProps,
  ...props
}: JotaiBlockQuantitySelectCellProps) {
  const tiers = useMemo(() => {
    return lineItem?.pricingOverride
      ? mapPriceTier(
          lineItem?.pricingOverride?.priceTiers ?? [],
          lineItem?.pricingOverride?.minQuantity,
          lineItem?.pricingOverride?.maxQuantity
        )
      : mapPriceTier(charge?.priceTiers?.filter(notEmpty) ?? [], charge?.minQuantity, charge?.maxQuantity)
  }, [lineItem, charge])

  return (
    <div>
      <JotaiMuiSelect
        atomSelector={useCallback(
          (form: NewOrderFormData) => {
            const lineItem = form.orderDetail.lineItems[lineItemIndex]
            return mapTierToQuantity(findPriceTier(tiers, lineItem?.quantity ?? 0))
          },
          [lineItemIndex, tiers]
        )}
        atomUpdater={(value, draft) => {
          const lineItem = draft.orderDetail.lineItems[lineItemIndex]
          if (lineItem) {
            lineItem.quantity = Big(value).toNumber()
            lineItem.arrOverride = undefined
          }
        }}
        errorPath={`orderDetail.lineItems[${lineItemIndex}].quantity`}
        textFieldProps={{
          size: 'small',
          ...textFieldProps,
        }}
        form={jotaiForm}
        {...props}
      >
        {tiers.map((tier) => {
          const content = formatPriceTier(tier)
          const quantity = mapTierToQuantity(tier)
          return (
            <MenuItem key={content} value={quantity}>
              {content}
            </MenuItem>
          )
        })}
      </JotaiMuiSelect>
    </div>
  )
}

export const JotaiBlockQuantitySelectCell = memo(JotaiBlockQuantitySelectCellWithoutMemo)
