import React, { useCallback, useContext, useMemo } from 'react'
import { WarningAmberOutlined } from '@mui/icons-material'
import { CardContent, CardHeader, Grid, ListItemText, useTheme } from '@mui/material'
import BillyAlert from '../../components/alerts/alert'
import BillyCard from '../../components/card/billyCard'
import BillyAddRemoveList, { BillyAddRemoveListProps } from '../../components/list/billyAddRemoveList'
import { JotaiFormContext } from '../../components/state/jotaiFormProvider'
import { JotaiForm } from '../../components/state/useJotaiForm'
import { DiscountFragment, DiscountStatus, Feature, ListDiscountsQuery, OrderType } from '../../generated/graphql'
import { discountFormat } from '../../util/currencyUtil'
import buildLogger from '../../util/logger'
import { predefinedDiscountToLineItemDiscount } from '../../util/typeConverters'
import { CommonOrderFormPageState } from './EditOrderPage/CommonOrderFormPageState'
import { deepMutable } from '../../components/SchemaForm/DeepMutable'
import useDynamicFeatureFlag from '../../components/state/useDynamicFeatureFlag'
import JotaiMuiNumberField from '../../components/input/JotaiMuiNumberField'

const logger = buildLogger('OrderDiscounts')

function OrderDiscounts(): JSX.Element {
  const theme = useTheme()

  const jotaiForm: JotaiForm<CommonOrderFormPageState> =
    useContext<JotaiForm<CommonOrderFormPageState>>(JotaiFormContext)

  const isNewOrRenewal = jotaiForm.useSelect(
    useCallback(
      (form) => form.orderDetail.orderType === OrderType.New || form.orderDetail.orderType === OrderType.Renewal,
      []
    )
  )

  const hasDeprecatedPredefinedDiscounts = jotaiForm.useSelect(
    useCallback(
      (form) =>
        form.yupErrors &&
        Object.keys(form.yupErrors as any).some(function (key) {
          return /orderDetail\.lineItems\[[0-9]+]\.predefinedDiscounts\[[0-9]+]\.status/.test(key)
        }),
      []
    )
  )

  const isAlwaysAllowEditingDiscountPercentEnabled = useDynamicFeatureFlag(Feature.AlwaysAllowEditingDiscountPercent)

  return useMemo(() => {
    const billyAddRemoveListProps: BillyAddRemoveListProps<
      CommonOrderFormPageState,
      ListDiscountsQuery['discounts'][0],
      DiscountFragment | null
    > = {
      addLabel: 'Add Discount',
      emptyLabel: 'No discounts have been added',
      jotaiMuiAutocompleteProps: {
        atomOptionsSelector: (form) =>
          form.discountsList?.filter(
            (discount) =>
              !form.orderDetail.predefinedDiscounts?.some((d) => d?.id === discount.id) &&
              discount.status === DiscountStatus.Active
          ),
        atomUpdater: (value, draft) => {
          if (!draft.orderDetail.predefinedDiscounts) {
            draft.orderDetail.predefinedDiscounts = []
          }
          if (value) {
            draft.orderDetail.predefinedDiscounts.push({
              ...value,
              __typename: 'PredefinedDiscountDetail',
              id: value.id || '',
            })
            draft.orderDetail.lineItems
              .filter((lineItem) => !lineItem.charge.isDiscount)
              .forEach((lineItem) => {
              if (!lineItem.predefinedDiscounts) {
                lineItem.predefinedDiscounts = []
              }
              lineItem.predefinedDiscounts.push(predefinedDiscountToLineItemDiscount(value))
            })
          }
        },
        autocompleteProps: { getOptionLabel: (option) => option.name || '' },
        errorPath: 'discountsList',
        form: jotaiForm,
        textFieldProps: { label: 'Discounts' },
      },
      listDeleteUpdater: (value, draft) => {
        if (draft.orderDetail.predefinedDiscounts) {
          draft.orderDetail.predefinedDiscounts = draft.orderDetail.predefinedDiscounts.filter(
            (discount) => discount?.id !== value?.id
          )
          draft.orderDetail.lineItems.forEach((lineItem) => {
            lineItem.predefinedDiscounts = lineItem.predefinedDiscounts?.filter(
              (predefinedDiscount) => predefinedDiscount?.id !== value?.id
            )
          })
        }
      },
      listItemKey: (value) => value?.id || '',
      listSelector: (form) => deepMutable(form.orderDetail.predefinedDiscounts || []),
      renderListItem: function renderListItem(value) {
        return (
          <>
            <Grid container direction="row" alignItems="center" spacing={1} wrap="nowrap">
              {value?.status === DiscountStatus.Deprecated && (
                <Grid item sx={{ color: '#FAAD14' }}>
                  <WarningAmberOutlined />
                </Grid>
              )}
              <Grid item>
                <ListItemText
                  primary={value?.name || ''}
                  secondary={
                    !isAlwaysAllowEditingDiscountPercentEnabled
                      ? discountFormat({ value: value?.percent || 0 })
                      : undefined
                  }
                  sx={{
                    color:
                      value?.status === DiscountStatus.Deprecated
                        ? theme.palette.text.secondary
                        : theme.palette.text.primary,
                    minWidth: '70%',
                  }}
                />
                {isAlwaysAllowEditingDiscountPercentEnabled && (
                  <JotaiMuiNumberField
                    atomSelector={(form) =>
                      form.orderDetail.predefinedDiscounts?.find((discount) => discount?.id === value?.id)?.percent
                    }
                    atomUpdater={(percentValue, draft) => {
                      const discount = draft.orderDetail.predefinedDiscounts?.find(
                        (discount) => discount?.id === value?.id
                      )
                      if (discount) {
                        discount.percent = percentValue || 0
                      }
                    }}
                    errorPath=""
                    form={jotaiForm}
                    percent={true}
                    textFieldProps={{ size: 'small', style: { minWidth: '20%' } }}
                  />
                )}
              </Grid>
            </Grid>
          </>
        )
      },
    }

    return (
      <BillyCard>
        <CardHeader
          title="Predefined Discounts"
          subheader={'Choose which discounts will be added to all applicable charges.'}
        />
        <CardContent>
          <Grid container>
            {isNewOrRenewal && hasDeprecatedPredefinedDiscounts && (
              <Grid item xs={12}>
                <BillyAlert
                  alertType="warning"
                  title={'Review Discounts'}
                  message={
                    'There are discounts that are no longer available being applied to this order. Please review and remove before saving your order.'
                  }
                  showIcon
                  alertProps={{ style: { marginTop: 0 } }}
                />
              </Grid>
            )}
            <Grid item xs={4}>
              <BillyAddRemoveList {...billyAddRemoveListProps} />
            </Grid>
          </Grid>
        </CardContent>
      </BillyCard>
    )
  }, [
    jotaiForm,
    isNewOrRenewal,
    hasDeprecatedPredefinedDiscounts,
    isAlwaysAllowEditingDiscountPercentEnabled,
    theme.palette.text.secondary,
    theme.palette.text.primary,
  ])
}

export default OrderDiscounts
