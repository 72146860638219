import { BillyTsForm } from '@/components/BillyTSForm/BillyTsForm'
import { useErrorHandler } from '@/components/ErrorHandler/ErrorHandler'
import ActionButton from '@/components/button/actionButton'
import { WithModalParams, useModal, useModalsContext } from '@/components/state/context/modalsContext'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { LineItemFragment } from '@/generated/graphql'
import { NewOrderFormData } from '@/pageComponents/orders/EditOrderPage/CancelAndRestructureOrderPage'
import { useDryRunActions } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { FindAndReplaceChargeView } from '@/pageComponents/orders/LineItemsEditTable/BulkApply/FindAndReplaceChargeView'
import { BulkApplyAttribute } from '@/pageComponents/orders/LineItemsEditTable/LineItemEditContextProvider'
import { chargeQuantityBulkApplicable } from '@/pageComponents/orders/LineItemsEditTable/orderChargeRow'
import buildLogger from '@/util/logger'
import { toTitleCase } from '@/util/string'
import { zodResolver } from '@hookform/resolvers/zod'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { current } from 'immer'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
const logger = buildLogger('SingleBulkApplyDialog')

const SingleBulkApplyDialogSchema = z.object({
  value: z.number().describe('Value'),
  selections: z.array(z.string()),
})

type SingleBulkApplyDialogProps = {
  lineItems?: LineItemFragment[]
  attribute?: BulkApplyAttribute
  onSubmit?: (data: z.infer<typeof SingleBulkApplyDialogSchema>) => Promise<void> | void
}

type DialogProps = WithModalParams & SingleBulkApplyDialogProps
const formID = 'SingleBulkApplyDialog'
function SingleBulkApplyDialog({ attribute, lineItems, open, onClose, onSubmit }: DialogProps) {
  const errorHandler = useErrorHandler()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const form = useForm<z.infer<typeof SingleBulkApplyDialogSchema>>({
    resolver: zodResolver(SingleBulkApplyDialogSchema),
  })

  useEffect(() => {
    if (open) {
      form.reset()
    }
    // only depends on open
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const onFormSubmit = (data: z.infer<typeof SingleBulkApplyDialogSchema>) => {
    async function doAsync() {
      setIsSubmitting(true)
      await onSubmit?.(data)
      setIsSubmitting(false)
      onClose?.()
    }
    doAsync().catch((error) => {
      setIsSubmitting(false)
      errorHandler(error)
    })
  }

  const handleSubmitCallback = async () => await form.handleSubmit(onFormSubmit)()

  return attribute ? (
    <Dialog open={!!open} onClose={onClose} maxWidth={'xl'} scroll={'paper'} fullWidth>
      <DialogTitle>Bulk Apply {toTitleCase(attribute)}</DialogTitle>
      <DialogContent dividers>
        <BillyTsForm
          form={form}
          formProps={{ id: formID }}
          onSubmit={handleSubmitCallback}
          schema={SingleBulkApplyDialogSchema}
          props={{
            value: {
              layout: { xs: 12 },
            },
          }}
        >
          {(fields) => {
            return (
              <>
                {fields['value']}
                <FindAndReplaceChargeView
                  lineItems={lineItems}
                  selections={form.watch('selections')}
                  onChange={(selections) => {
                    logger.debug({ msg: 'useSingleBulkApplyDialog onChange', selections })
                    form.setValue('selections', selections)
                  }}
                />
              </>
            )
          }}
        </BillyTsForm>
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          key={'Cancel'}
          buttonData={{
            label: 'Cancel',
            onClick: onClose,
            color: 'inherit',
            buttonProps: { variant: 'outlined' },
          }}
        />
        <ActionButton
          key={'Save'}
          buttonData={{
            label: 'Save',
            loading: isSubmitting,
            buttonProps: { type: 'submit', form: formID },
          }}
        />
      </DialogActions>
    </Dialog>
  ) : (
    <></>
  )
}

export function useSingleBulkApplyDialog(modalProps: DialogProps = {}) {
  const [, , toggleModal] = useModalsContext()
  const [attribute, setAttribute] = useState<BulkApplyAttribute | undefined>(undefined)
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  const { queueDryRun } = useDryRunActions()

  const lineItems = jotaiForm.useSelect(useCallback((form) => form.orderDetail.lineItems, []))

  useModal<DialogProps>({
    component: SingleBulkApplyDialog,
    schema: {
      key: formID,
      modalProps: useMemo(
        () => ({
          lineItems,
          ...modalProps,
          attribute,
          onSubmit: function ({ value, selections }: z.infer<typeof SingleBulkApplyDialogSchema>) {
            logger.debug({ msg: 'useSingleBulkApplyDialog onSubmit', selections })
            jotaiForm.set((form) => {
              if (attribute === 'quantity') {
                const lineItems = current(form).orderDetail.lineItems
                const ret = lineItems.map((lineItem) => {
                  if (chargeQuantityBulkApplicable(lineItem.charge) && selections.includes(lineItem?.id ?? '')) {
                    return { ...lineItem, quantity: value }
                  }
                  return lineItem
                })
                form.orderDetail.lineItems = ret
              }
            })
            queueDryRun()
          },
        }),
        [attribute, jotaiForm, queueDryRun, modalProps, lineItems]
      ),
    },
  })
  const toggleSingleBulkApplyDialog = useCallback(
    (attribute: BulkApplyAttribute) => {
      toggleModal(formID)
      setAttribute(attribute)
    },
    [toggleModal]
  )

  return [toggleSingleBulkApplyDialog]
}
