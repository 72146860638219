import { OrderEditLineItemPopoverLink } from '@/pageComponents/orders/LineItemsEditTable/LineItemPopoverLink'
import { reduceLineItem } from '@/pageComponents/orders/LineItemsEditTable/reduceLineItem'
import { Box, Tooltip, Typography } from '@mui/material'
import { useCallback } from 'react'
import { useJotaiFormContext } from '../../../../components/state/jotaiFormProvider'
import { NewOrderFormData } from '../../EditOrderPage/NewOrderPage'
import { useLineItemEditActions } from '../LineItemEditContextProvider'
import { OrderItemRowProps, useOrderChargeRowStyles } from '../orderChargeRow'
import { useOrderEditPlanRowStyles } from '../useOrderEditPlanRowStyles'
import StatusChip from '@/components/table/cells/statusChip'
import DiscountIcon from '@mui/icons-material/Discount'

export function LineItemsNameCell({ lineItemIndex, orderType, currency, variant = 'default' }: OrderItemRowProps) {
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  const { classes } = useOrderChargeRowStyles()
  const { classes: planClasses } = useOrderEditPlanRowStyles()
  const { onClickCustomField, onClickChargeName } = useLineItemEditActions()
  const { chargeName, showDate, formattedDate, formattedPeriod, isChargeDuplicated, isDiscountLine } =
    jotaiForm.useSelect(
      useCallback(
        (form) => {
          const reduced = reduceLineItem({
            orderDetail: form.orderDetail,
            lineItemIndex,
            orderType,
            currency,
          })
          return {
            id: reduced.lineItem.id,
            chargeName: reduced.chargeName,
            showDate: variant === 'interval' ? false : reduced.showDate,
            formattedDate: reduced.formattedDate,
            formattedPeriod: reduced.formattedPeriod,
            isChargeDuplicated: reduced.isChargeDuplicated,
            isDiscountLine: reduced.isDiscountLine,
          }
        },
        [lineItemIndex, orderType, currency, variant]
      )
    )

  return (
    <div>
      <Box display="flex" alignItems="center">
        <OrderEditLineItemPopoverLink
          lineItemIndex={lineItemIndex}
          onEdit={() => {
            onClickCustomField?.(lineItemIndex)
          }}
          onClickLink={() => {
            onClickChargeName?.(lineItemIndex)
          }}
          jotaiForm={jotaiForm}
        >
          {chargeName}
        </OrderEditLineItemPopoverLink>
        {isDiscountLine && (
          <div className={planClasses.statusChip}>
            <Tooltip title="Discount Line">
              <DiscountIcon fontSize="small" color="primary" />
            </Tooltip>
          </div>
        )}
        {isChargeDuplicated && (
          <div className={planClasses.statusChip}>
            <StatusChip value={'DUPLICATE'} color="primary" />
          </div>
        )}
      </Box>
      {showDate && (
        <>
          <Typography className={classes.chargeDate}>{formattedDate}</Typography>
          <Typography className={classes.chargePeriod}>({formattedPeriod})</Typography>
        </>
      )}
    </div>
  )
}
