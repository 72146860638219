import { useCallback } from 'react'
import { useJotaiFormContext } from '../../../components/state/jotaiFormProvider'
import AmendmentOrderPageDrawer from '../OrderDrawer/AmendmentOrderPageDrawer'
import { AmendSubscriptionPageState } from './AmendmentOrderPage'

export function AmendmentRightDrawerWithData() {
  const jotaiForm = useJotaiFormContext<AmendSubscriptionPageState>()
  const { orderDetail } = jotaiForm.useSelect(
    useCallback((form) => {
      const orderDetail = form.orderDetail

      return {
        orderDetail: {
          currentSubscription: orderDetail.currentSubscription,
          subscriptionId: orderDetail.subscriptionId,
          currency: orderDetail.currency,
          totalListAmount: orderDetail.totalListAmount,
          totalAmount: orderDetail.totalAmount,
          orderMetrics: orderDetail.orderMetrics,
          resoldBy: orderDetail.resoldBy,
          endDate: orderDetail.currentSubscription?.endDate,
          totalDiscount: orderDetail.totalDiscount,
          totalDiscountPercent: orderDetail.totalDiscountPercent,
          totalRecurringDiscount: orderDetail.totalRecurringDiscount,
          totalRecurringDiscountPercent: orderDetail.totalRecurringDiscountPercent,
          totalNonRecurringDiscount: orderDetail.totalNonRecurringDiscount,
          totalNonRecurringDiscountPercent: orderDetail.totalNonRecurringDiscountPercent,
        },
      }
    }, [])
  )
  return <AmendmentOrderPageDrawer orderDetail={orderDetail} />
}
