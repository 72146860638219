import React from 'react'
import { Checkbox, CheckboxProps, FormControlLabel, FormGroup, Grid, Tooltip } from '@mui/material'
import { useTsController, useDescription } from '@ts-react/form'
import { LayoutProps } from '../BillyTsForm'

export type BooleanFieldProps = CheckboxProps &
  LayoutProps & {
    disabledExplanation?: string
    label?: React.ReactNode
  }

export function BooleanField({ layout, disabledExplanation, label, ...checkBoxProps }: BooleanFieldProps) {
  const {
    field,
    formState: { isLoading, isSubmitting },
  } = useTsController<boolean>()
  const { label: defaultLabel } = useDescription()
  return (
    <Grid container item xs {...layout}>
      <FormGroup data-testid={BooleanField.name} sx={{ mt: 'auto', mb: 'auto' }}>
        <Tooltip title={checkBoxProps.disabled && disabledExplanation}>
          <FormControlLabel
            style={{ userSelect: 'none' }}
            control={
              <Checkbox
                disabled={isLoading || isSubmitting}
                style={{ userSelect: 'none' }}
                checked={!!field.value}
                onChange={(event) => field.onChange(event.target.checked)}
                {...checkBoxProps}
              />
            }
            label={label ?? defaultLabel}
          />
        </Tooltip>
      </FormGroup>
    </Grid>
  )
}
