import { useBillyRouter } from '@/components/route/useBillyRouter'
import { useSnackbarHandler } from '@/components/SnackbarHandler/SnackbarHandler'
import { NextQuestion, useFetchNextQuestion } from '@/generated/rest'
import { QuestionVariant } from '@/laboratoryComponents/experiments/QuoteBuilder/fixtures/utils'
import buildLogger from '@/util/logger'
import { isArray, isNumber } from 'lodash'
import { createContext, useCallback, useContext, useMemo, useRef } from 'react'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

const logger = buildLogger('QuoteBuilderProvider')

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type QuoteBuilderStore = Record<string, any>

export type QuoteBuilderStoreProvider = {
  store?: QuoteBuilderStore
}

type OptionsOnNext = { skip?: boolean }
export type QuoteBuilderProviderProps = {
  nextAsync?: (newData: QuoteBuilderStore) => Promise<NextQuestion>
  onNext?: (
    data: QuoteBuilderStore,
    activeStepIndex: number,
    nextCb?: (results, activeStepIndex) => void,
    completeCb?: (results?: string[]) => void,
    options?: OptionsOnNext
  ) => void | Promise<void>
  onBack?: (data: QuoteBuilderStore) => void | Promise<void>
}

const QuoteBuilderStoreContext = createContext<QuoteBuilderStoreProvider>({
  store: {},
})

const QuoteBuilderContext = createContext<QuoteBuilderProviderProps>({
  onNext: () => {
    console.log('onNext not initialized')
  },
  onBack: () => {
    console.log('onBack not initialized')
  },
})

export const useQuoteBuilderActions = ({
  nextCb,
  completeCb,
}: {
  nextCb?: (results, activeStepIndex) => void
  completeCb?: (results?: string[]) => void
}) => {
  const picked = useContext(QuoteBuilderContext)
  return useMemo(
    () => ({
      ...picked,
      onNext: async (data, activeStepIndex, options?: OptionsOnNext) => {
        await picked.onNext?.(data, activeStepIndex, nextCb, completeCb, options)
      },
    }),
    [picked, nextCb, completeCb]
  )
}

export const useQuoteBuilderStore = () => {
  const { store } = useContext(QuoteBuilderStoreContext)
  return store
}

export const getBuildOrderRequestParams = (store: QuoteBuilderStore) => {
  return Object.keys(store).map((key) => {
    const answer = store[key]
    const answerInList = (isArray(answer) ? answer : [answer]).map((a) => (isNumber(a) ? a.toString() : a))
    return { questionId: key, answer: answerInList }
  })
}

const QUOTE_BUILDER_ORDER_PAYLOAD_LS_KEY = 'quote-builder-order-payload-v1'
export const quoteBuilderOrderAtomWithPersistence = atomWithStorage(QUOTE_BUILDER_ORDER_PAYLOAD_LS_KEY, {})

export function QuoteBuilderProvider({ children }: { children: React.ReactNode }) {
  // use ref instead
  const ref = useRef<QuoteBuilderStoreProvider>({ store: {} })
  const router = useBillyRouter()
  const accountId = router.query.accountId as string
  const usecaseId = router.query.usecaseId as QuestionVariant
  const snackbarHandler = useSnackbarHandler()
  const { mutate: fetchNextQuestion } = useFetchNextQuestion()
  const [, setQuoteBuilderPayload] = useAtom(quoteBuilderOrderAtomWithPersistence)

  const onBack = useCallback(() => {
    logger.info('onBack')
  }, [])

  const nextAsync = useCallback(
    async (newData: QuoteBuilderStore, activeStepIndex: number, options?: OptionsOnNext) => {
      const filteredStore = Object.keys({ ...ref.current.store })
        .slice(0, activeStepIndex + 1)
        .reduce((map, itemKey) => {
          return {
            ...map,
            [itemKey]: ref.current.store?.[itemKey],
          }
        }, {})

      const newStore = {
        ...filteredStore,
        ...newData,
      }
      const data = Object.keys(newStore).map((key) => {
        const answer = newStore[key]
        const answerInList = (isArray(answer) ? answer : [answer]).map((a) => (isNumber(a) ? a.toString() : a))

        if (Object.keys(newData).includes(key) && options?.skip) {
          return {
            questionId: key,
            answer: [],
            skipped: true,
          }
        }
        return { questionId: key, answer: answerInList }
      })
      logger.debug({
        newData,
        msg: 'nextAsync quote',
        store: ref.current.store,
        newStore,
        data,
      })

      ref.current.store = newStore
      const progressKey = snackbarHandler.inProgressAlert('Creating Quote...')
      try {
        const response = await fetchNextQuestion([usecaseId, data])
        return response.data
      } finally {
        snackbarHandler.dismissAlert(progressKey)
      }
    },
    [ref, usecaseId, fetchNextQuestion, snackbarHandler]
  )

  const onNext = useCallback(
    async (
      data: QuoteBuilderStore,
      activeStepIndex: number,
      nextCb?: (results, activeStepIndex) => void,
      completeCb?: (results?: string[]) => void,
      options?: OptionsOnNext
    ) => {
      const results = await nextAsync?.(data, activeStepIndex, options)
      logger.debug({ msg: 'handleNext', results, data })
      const answers = getBuildOrderRequestParams({ ...ref.current.store })
      await setQuoteBuilderPayload({ accountId, usecaseId, answers })
      if (results?.state === 'DONE') {
        router.push(`/orders/new?source=quote-builder`)
      }
      if (results?.state === 'IN_PROGRESS') {
        nextCb?.(results, activeStepIndex + 1)
      }
    },
    [nextAsync, accountId, usecaseId, router, setQuoteBuilderPayload]
  )

  const actions = useMemo(() => ({ onNext, onBack }), [onNext, onBack])

  return (
    <QuoteBuilderContext.Provider value={actions}>
      <QuoteBuilderStoreContext.Provider value={{ store: ref.current.store }}>
        {children}
      </QuoteBuilderStoreContext.Provider>
    </QuoteBuilderContext.Provider>
  )
}
