// directly from shadcn
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

'use client'

import * as React from 'react'
import * as ContextMenuPrimitive from '@radix-ui/react-context-menu'
import CheckIcon from '@mui/icons-material/Check'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CircleIcon from '@mui/icons-material/Circle'

const ContextMenu = ContextMenuPrimitive.Root

const ContextMenuTrigger = ContextMenuPrimitive.Trigger

const ContextMenuGroup = ContextMenuPrimitive.Group

const ContextMenuPortal = ContextMenuPrimitive.Portal

const ContextMenuSub = ContextMenuPrimitive.Sub

const ContextMenuRadioGroup = ContextMenuPrimitive.RadioGroup

const ContextMenuSubTrigger = React.forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.SubTrigger>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.SubTrigger> & {
    inset?: boolean
  }
>(({ inset, children, ...props }, ref) => (
  <ContextMenuPrimitive.SubTrigger
    ref={ref}
    style={{
      display: 'flex',
      cursor: 'default',
      userSelect: 'none',
      alignItems: 'center',
      borderRadius: '2px',
      padding: '6px 8px',
      fontSize: '14px',
      outline: 'none',
      paddingLeft: inset ? '32px' : '8px',
      ...props.style,
    }}
    {...props}
  >
    {children}
    <ChevronRightIcon style={{ marginLeft: 'auto', height: '16px', width: '16px' }} />
  </ContextMenuPrimitive.SubTrigger>
))
ContextMenuSubTrigger.displayName = ContextMenuPrimitive.SubTrigger.displayName

const ContextMenuSubContent = React.forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.SubContent>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.SubContent>
>(({ ...props }, ref) => (
  <ContextMenuPrimitive.SubContent
    ref={ref}
    style={{
      zIndex: 50,
      minWidth: '8rem',
      overflow: 'hidden',
      borderRadius: '6px',
      border: '1px solid rgba(0, 0, 0, 0.1)',
      backgroundColor: 'white',
      padding: '4px',
      color: 'inherit',
      boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
      ...props.style,
    }}
    {...props}
  />
))
ContextMenuSubContent.displayName = ContextMenuPrimitive.SubContent.displayName

const ContextMenuContent = React.forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.Content>
>(({ ...props }, ref) => (
  <ContextMenuPrimitive.Portal>
    <ContextMenuPrimitive.Content
      ref={ref}
      style={{
        zIndex: 50,
        minWidth: '8rem',
        overflow: 'hidden',
        borderRadius: '6px',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        padding: '4px',
        color: 'inherit',
        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
        ...props.style,
      }}
      {...props}
    />
  </ContextMenuPrimitive.Portal>
))
ContextMenuContent.displayName = ContextMenuPrimitive.Content.displayName

const ContextMenuItem = React.forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.Item> & {
    inset?: boolean
  }
>(({ inset, ...props }, ref) => (
  <ContextMenuPrimitive.Item
    ref={ref}
    style={{
      position: 'relative',
      display: 'flex',
      cursor: 'default',
      userSelect: 'none',
      alignItems: 'center',
      borderRadius: '2px',
      padding: '6px 8px',
      fontSize: '14px',
      outline: 'none',
      paddingLeft: inset ? '32px' : '8px',
      ...props.style,
    }}
    {...props}
  />
))
ContextMenuItem.displayName = ContextMenuPrimitive.Item.displayName

const ContextMenuCheckboxItem = React.forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.CheckboxItem>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.CheckboxItem>
>(({ children, checked, ...props }, ref) => (
  <ContextMenuPrimitive.CheckboxItem
    ref={ref}
    style={{
      position: 'relative',
      display: 'flex',
      cursor: 'default',
      userSelect: 'none',
      alignItems: 'center',
      borderRadius: '2px',
      padding: '6px 8px',
      paddingLeft: '32px',
      paddingRight: '8px',
      fontSize: '14px',
      outline: 'none',
      ...props.style,
    }}
    checked={checked}
    {...props}
  >
    <span
      style={{
        position: 'absolute',
        left: '8px',
        display: 'flex',
        height: '14px',
        width: '14px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ContextMenuPrimitive.ItemIndicator>
        <CheckIcon style={{ height: '16px', width: '16px' }} />
      </ContextMenuPrimitive.ItemIndicator>
    </span>
    {children}
  </ContextMenuPrimitive.CheckboxItem>
))
ContextMenuCheckboxItem.displayName = ContextMenuPrimitive.CheckboxItem.displayName

const ContextMenuRadioItem = React.forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.RadioItem>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.RadioItem>
>(({ children, ...props }, ref) => (
  <ContextMenuPrimitive.RadioItem
    ref={ref}
    style={{
      position: 'relative',
      display: 'flex',
      cursor: 'default',
      userSelect: 'none',
      alignItems: 'center',
      borderRadius: '2px',
      padding: '6px 8px',
      paddingLeft: '32px',
      paddingRight: '8px',
      fontSize: '14px',
      outline: 'none',
      ...props.style,
    }}
    {...props}
  >
    <span
      style={{
        position: 'absolute',
        left: '8px',
        display: 'flex',
        height: '14px',
        width: '14px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ContextMenuPrimitive.ItemIndicator>
        <CircleIcon style={{ height: '16px', width: '16px', fill: 'currentColor' }} />
      </ContextMenuPrimitive.ItemIndicator>
    </span>
    {children}
  </ContextMenuPrimitive.RadioItem>
))
ContextMenuRadioItem.displayName = ContextMenuPrimitive.RadioItem.displayName

const ContextMenuLabel = React.forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.Label> & {
    inset?: boolean
  }
>(({ inset, ...props }, ref) => (
  <ContextMenuPrimitive.Label
    ref={ref}
    style={{
      padding: '6px 8px',
      fontSize: '14px',
      fontWeight: 600,
      color: 'inherit',
      paddingLeft: inset ? '32px' : '8px',
      ...props.style,
    }}
    {...props}
  />
))
ContextMenuLabel.displayName = ContextMenuPrimitive.Label.displayName

const ContextMenuSeparator = React.forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.Separator>
>(({ ...props }, ref) => (
  <ContextMenuPrimitive.Separator
    ref={ref}
    style={{
      marginLeft: '-4px',
      marginRight: '-4px',
      marginTop: '4px',
      marginBottom: '4px',
      height: '1px',
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      ...props.style,
    }}
    {...props}
  />
))
ContextMenuSeparator.displayName = ContextMenuPrimitive.Separator.displayName

const ContextMenuShortcut = ({ ...props }: React.HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span
      style={{
        marginLeft: 'auto',
        fontSize: '12px',
        letterSpacing: '0.05em',
        color: 'rgba(0, 0, 0, 0.45)',
        ...props.style,
      }}
      {...props}
    />
  )
}
ContextMenuShortcut.displayName = 'ContextMenuShortcut'

export {
  ContextMenu,
  ContextMenuTrigger,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuCheckboxItem,
  ContextMenuRadioItem,
  ContextMenuLabel,
  ContextMenuSeparator,
  ContextMenuShortcut,
  ContextMenuGroup,
  ContextMenuPortal,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger,
  ContextMenuRadioGroup,
}
