import { WithCustomizationLocator } from '@/components/state/context/customizationContext'
import { CardContent, CardHeader, Grid } from '@mui/material'
import BillyCard from '../../../../../components/card/billyCard'
import { useOrderPageFormStyles } from '../../hooks/useOrderPageFormStyles'
import { BillingAnchorDateField } from './BillingAnchorDateField'
import { NewOrderBillingCycleStartDatePicker } from './NewOrderBillingCycleStartDatePicker'
import { NewOrderPaymentTermSelect } from './NewOrderPaymentTermSelect'
import { PurchaseOrderNumberField } from './PurchaseOrderNumberField'
import { PurchaseOrderRequiredInvoicingField } from './PurchaseOrderRequiredInvoicingField'
import { InvoiceTriggerSchedules } from '../InvoiceTriggerSchedules'
import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'
import { Feature } from '@/generated/graphql'

export function OrderEditBillingCard({ parentLocator }: WithCustomizationLocator) {
  const { classes } = useOrderPageFormStyles()
  const isCustomBillingEnabled = useDynamicFeatureFlag(Feature.CustomBilling)

  return (
    <BillyCard aria-label="order-edit-billing-card">
      <CardHeader title="Billing" />
      <CardContent>
        {isCustomBillingEnabled ? (
          <>
            <Grid container direction="row" spacing={2} className={classes.formRow}>
              <NewOrderPaymentTermSelect />
            </Grid>
            <Grid container direction="row" spacing={2} className={classes.formRow}>
              <PurchaseOrderNumberField />
              <PurchaseOrderRequiredInvoicingField />
            </Grid>
            <Grid container direction="row" spacing={2} className={classes.formRow}>
              <NewOrderBillingCycleStartDatePicker parentLocator={parentLocator} />
              <BillingAnchorDateField />
            </Grid>
            <InvoiceTriggerSchedules />
          </>
        ) : (
          <Grid container direction="row" spacing={2} className={classes.formRow}>
            <BillingAnchorDateField />
            <NewOrderBillingCycleStartDatePicker parentLocator={parentLocator} />
            <NewOrderPaymentTermSelect />
            <PurchaseOrderNumberField />
            <PurchaseOrderRequiredInvoicingField />
          </Grid>
        )}
      </CardContent>
    </BillyCard>
  )
}
