import JotaiMuiNumberField from '@/components/input/JotaiMuiNumberField'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { CommonOrderFormPageState } from '@/pageComponents/orders/EditOrderPage/CommonOrderFormPageState'
import { useDryRunActions } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { NewOrderFormData } from '@/pageComponents/orders/EditOrderPage/NewOrderPage'
import { OrderItemRowProps } from '@/pageComponents/orders/LineItemsEditTable/orderChargeRow'
import { reduceLineItem } from '@/pageComponents/orders/LineItemsEditTable/reduceLineItem'
import { currencySymbol } from '@/util/currencyUtil'
import { availableCreditableAmountSetter } from '@/util/order'
import { isNumber } from 'lodash'
import { useCallback } from 'react'

export function LineItemTotalEditCell<F extends CommonOrderFormPageState = NewOrderFormData>({
  lineItemIndex,
  orderType,
  currency,
}: OrderItemRowProps) {
  const jotaiForm = useJotaiFormContext<F>()

  const { creditableAmount, creditableAmountIndex } = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const reduced = reduceLineItem({
          orderDetail: form.orderDetail,
          lineItemIndex,
          orderType,
          currency,
        })
        return {
          creditableAmountIndex: reduced.creditableAmountIndex,
          creditableAmount: {
            ...reduced.creditableAmount,
            creditableAmount: reduced.creditableAmount?.creditableAmount ?? reduced.lineItem.amount ?? 0,
            subscriptionChargeId:
              reduced.creditableAmount?.subscriptionChargeId ?? reduced.lineItem.subscriptionChargeId ?? '',
          },
        }
      },
      [lineItemIndex, orderType, currency]
    )
  )
  const { queueDryRun, clearDryRun } = useDryRunActions()

  return (
    <div aria-label="creditable-amount-edit">
      <JotaiMuiNumberField
        form={jotaiForm}
        atomSelector={() => creditableAmount.creditableAmount}
        atomUpdater={(value, draft) => {
          if (isNumber(value) && value !== creditableAmount.creditableAmount) {
            availableCreditableAmountSetter({
              form: draft,
              creditableAmount: { ...creditableAmount, creditableAmount: value },
            })
          }
        }}
        textFieldProps={{
          size: 'small',
          prefix: currencySymbol(currency ?? 'USD'),
          onFocus: clearDryRun,
          onBlur: queueDryRun,
        }}
        errorPath={`orderDetail.creditableAmounts[${creditableAmountIndex}].creditableAmount`}
      />
    </div>
  )
}
