import Big from 'big.js'
import { useCallback } from 'react'
import { useJotaiFormContext } from '../../../components/state/jotaiFormProvider'
import { billableCurrencyFormat } from '../../../util/currencyUtil'
import { NewOrderFormData } from '../EditOrderPage/NewOrderPage'
import { LineItemCellLoadingIndicator } from './Cells/LineItemCellLoadingIndicator'
import { PlanRowProps } from './OrderEditPlanRow'
import { useShouldRecalculateTotals } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { ActionType } from '@/generated/graphql'
import { useLineItemTableContext } from '@/pageComponents/orders/LineItemsEditTable/LineItemEditContextProvider'

export function LineItemTotal({
  plan,
  isPlanRampedAndRemoved,
}: Pick<PlanRowProps, 'plan'> & { isPlanRampedAndRemoved?: boolean }): JSX.Element {
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  const shouldRecalculateTotals = useShouldRecalculateTotals()
  const { currency } = useLineItemTableContext()

  const { formattedTotal } = jotaiForm.useSelect(
    useCallback(
      (form: NewOrderFormData) => {
        return {
          formattedTotal: billableCurrencyFormat({
            currency,
            value: (
              form.orderDetail.lineItems
                .filter((lineItem) => {
                  if (isPlanRampedAndRemoved) {
                    return lineItem?.plan?.id === plan.id && lineItem?.action === ActionType.Remove && lineItem.isRamp
                  } else {
                    if (lineItem.plan?.id === plan.id) {
                      return !(lineItem.action === ActionType.Remove && lineItem.isRamp)
                    }
                  }
                })
                .reduce((total, lineItem) => {
                  return Big(lineItem.amount || 0).add(total)
                }, Big(0)) || Big(0)
            ).toNumber(),
          }),
        }
      },
      [isPlanRampedAndRemoved, currency, plan.id]
    )
  )
  return <div>{shouldRecalculateTotals ? <LineItemCellLoadingIndicator /> : formattedTotal}</div>
}
