import { CommonOrderFormPageState } from '@/pageComponents/orders/EditOrderPage/CommonOrderFormPageState'
import { billableCurrencyFormat } from '@/util/currencyUtil'
import Big from 'big.js'

export interface CommonOrderDrawerState {
  orderDetail: Pick<
    CommonOrderFormPageState['orderDetail'],
    | 'currentSubscription'
    | 'subscriptionId'
    | 'currency'
    | 'totalListAmount'
    | 'totalAmount'
    | 'orderMetrics'
    | 'resoldBy'
    | 'renewalForSubscription'
    | 'totalDiscount'
    | 'totalDiscountPercent'
    | 'totalRecurringDiscount'
    | 'totalRecurringDiscountPercent'
    | 'totalNonRecurringDiscount'
    | 'totalNonRecurringDiscountPercent'
  >
}

export const discountDrawerDetails = ({
  currency,
  totalDiscount,
  totalDiscountPercent,
  totalRecurringDiscount,
  totalRecurringDiscountPercent,
  totalNonRecurringDiscount,
  totalNonRecurringDiscountPercent,
}: Pick<
  CommonOrderDrawerState['orderDetail'],
  | 'currency'
  | 'totalDiscount'
  | 'totalDiscountPercent'
  | 'totalRecurringDiscount'
  | 'totalRecurringDiscountPercent'
  | 'totalNonRecurringDiscount'
  | 'totalNonRecurringDiscountPercent'
>) => {
  return [
    {
      label: 'Total Discount Amount (%)',
      description: `${billableCurrencyFormat({ currency, value: +Big(totalDiscount ?? 0) })} (${Big(
        totalDiscountPercent ?? 0
      ).toFixed(2)}%)`,
    },
    {
      label: 'Recurring Discount (%)',
      description: `${billableCurrencyFormat({ currency, value: +Big(totalRecurringDiscount ?? 0) })} (${Big(
        totalRecurringDiscountPercent ?? 0
      ).toFixed(2)}%)`,
    },
    {
      label: 'Non Recurring Discount (%)',
      description: `${billableCurrencyFormat({ currency, value: +Big(totalNonRecurringDiscount ?? 0) })} (${Big(
        totalNonRecurringDiscountPercent ?? 0
      ).toFixed(2)}%)`,
    },
  ]
}
