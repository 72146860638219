import { useCallback, useState } from 'react'
import { billyRestClient, isApiError } from '@/components/data/billyRestClient'

export const useVoidSignedDocumentForOrder = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState<string | undefined>()

  const voidSignedDocumentForOrder = useCallback(async (orderId: string): Promise<string | undefined> => {
    try {
      setIsLoading(true)
      setIsError(false)
      setError(undefined)
      const resp = await billyRestClient.request({
        path: `/esignature/voidSignedDocumentForOrder/${orderId}`,
        method: 'PUT',
      })
      return resp.data
    } catch (err) {
      setIsError(true)
      if (isApiError(err)) {
        setError(err.response?.data?.message)
      }
    } finally {
      setIsLoading(false)
    }
  }, [])

  return {
    voidSignedDocumentForOrder,
    error,
    isError,
    isLoading,
  }
}
