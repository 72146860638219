import { useCustomizationProps, WithCustomizationLocator } from '@/components/state/context/customizationContext'
import { OrderAutoRenewCheckbox } from '@/pageComponents/orders/EditOrderPage/cards/BillyOrderDetailsCard/OrderAutoRenewCheckbox'
import { OrderCurrencySelect } from '@/pageComponents/orders/EditOrderPage/cards/BillyOrderDetailsCard/OrderCurrencySelect'
import { Grid, Typography } from '@mui/material'
import { useOrderPageFormStyles } from '../../hooks/useOrderPageFormStyles'

export function EditOrderSubscriptionDetailsForm({
  parentLocator,
  currencySelectDisableExplanation,
}: {
  currencySelectDisableExplanation?: string
} & WithCustomizationLocator) {
  const { classes } = useOrderPageFormStyles()

  const customizationProps = useCustomizationProps({ displayName: 'autoRenew', parentLocator })

  return (
    <section aria-label="Subscription Details">
      <Typography component="h6" className={classes.rowLabel}>
        Subscription Details
      </Typography>
      <Grid container direction="row" spacing={2} style={{ marginBottom: 24 }}>
        <Grid item container direction="row" spacing={2}>
          <OrderCurrencySelect disabledExplanation={currencySelectDisableExplanation} />
          <OrderAutoRenewCheckbox hidden={customizationProps?.hidden} parentLocator={parentLocator} />
        </Grid>
      </Grid>
    </section>
  )
}
