import {
  BillingCycle,
  BillingTerm,
  ChargeType,
  Cycle,
  OrderInvoiceItemPreviewFragment,
  OrderInvoicePreviewFragment,
  useGetOrderInvoicePreviewQuery,
} from '@/generated/graphql'
import {
  Box,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import DateRangeStart from '@/components/DateRangeStart/DateRangeStart'
import DateRangeEnd from '@/components/DateRangeEnd/DateRangeEnd'
import { formatQuantity } from '@/util/priceTier'
import { billableCurrencyFormat, unitPriceFormatter } from '@/util/currencyUtil'
import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import BillyCard from '@/components/card/billyCard'

export const useOrderInvoicePreviewStyles = makeStyles()((theme, _params, _classes) => ({
  linkText: {
    fontWeight: 550,
    fontSize: '0.875rem',
  },
  cardHeader: {
    display: 'inline-block',
    fontSize: 17,
    fontWeight: 600,
    cursor: 'pointer',
  },
  tableHeader: {
    background: theme.customPalette.backgroundColor1,
    '& *': { fontWeight: 700 },
  },
  rowHeader: {
    fontWeight: 700,
    textAlign: 'right',
  },
  amount: {
    textAlign: 'right',
  },
}))

type OrderInvoicePreviewProps = {
  orderId: string
  currency: string
  orderBillingCycle?: Cycle
  orderStartDate: number
}

type InvoiceItemPreview = {
  targetDate: number
} & OrderInvoiceItemPreviewFragment

type InvoiceItemPreviewTableProps = {
  currency: string
  invoiceItems: InvoiceItemPreview[]
}

type InvoicePreviewRowProps = {
  invoiceName: string
  targetDate: number | undefined
  total: number
  currency: string
  invoiceItems: InvoiceItemPreview[]
}

function InvoiceItemPreviewTable(props: InvoiceItemPreviewTableProps) {
  const { classes } = useOrderInvoicePreviewStyles()
  const { currency, invoiceItems } = props

  return (
    <Box display="flex" flexDirection="column" padding={'10px 24px 10px 24px'}>
      <Table aria-label="order-invoice-item-preview-table">
        <TableHead className={classes.tableHeader}>
          <TableRow aria-label={'invoice-item-preview-header'}>
            <TableCell>Charge</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell className={classes.amount}>Quantity</TableCell>
            <TableCell className={classes.amount}>Unit Price</TableCell>
            <TableCell className={classes.amount}>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoiceItems.map((invoiceItem) => {
            const { amount, sellUnitPrice, quantity, periodStartDate, periodEndDate } = invoiceItem
            return (
              <TableRow
                key={`${invoiceItem.orderLineItemId}-${invoiceItem.periodEndDate}`}
                aria-label={'invoice-item-preview-row'}
              >
                <TableCell>{invoiceItem.charge.name}</TableCell>
                <TableCell>
                  <DateRangeStart datetime={periodStartDate} />
                </TableCell>
                <TableCell>
                  <DateRangeEnd datetime={periodEndDate} />
                </TableCell>
                <TableCell className={classes.amount}>{formatQuantity(quantity)}</TableCell>
                <TableCell className={classes.amount}>
                  {unitPriceFormatter({
                    currency: currency,
                    value: sellUnitPrice,
                  })}
                </TableCell>
                <TableCell className={classes.amount}>
                  {billableCurrencyFormat({
                    currency: currency,
                    value: amount,
                  })}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Box>
  )
}

function InvoicePreviewRow(props: InvoicePreviewRowProps) {
  const { classes } = useOrderInvoicePreviewStyles()
  const { invoiceName, targetDate, currency, total, invoiceItems } = props
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <TableRow key={`${invoiceName}-${targetDate}`} aria-label={'invoice-preview-row'}>
        <TableCell align={'left'} size={'small'} style={{ whiteSpace: 'nowrap' }}>
          <IconButton aria-label="expand-invoice-preview" size="small" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{invoiceName}</TableCell>
        <TableCell>{targetDate ? <DateRangeStart datetime={+targetDate} /> : 'None'}</TableCell>
        <TableCell className={classes.amount}>
          {billableCurrencyFormat({
            currency: currency,
            value: total,
          })}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={4}
          style={{
            padding: 0,
            height: 'unset',
          }}
        >
          <Collapse in={isOpen} collapsedSize={0}>
            <InvoiceItemPreviewTable currency={currency} invoiceItems={invoiceItems} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

function OrderInvoicePreview(props: OrderInvoicePreviewProps) {
  const { orderId, currency, orderBillingCycle, orderStartDate } = props
  const { classes } = useOrderInvoicePreviewStyles()

  const [invoicePreviewResponse] = useGetOrderInvoicePreviewQuery({ variables: { orderId } })
  const invoicePreviews = invoicePreviewResponse.data?.orderInvoicePreview.invoicePreviews

  if (!invoicePreviews?.length) {
    return <></>
  }

  // only 1 invoice preview for now, may change in the future
  const invoicePreview: OrderInvoicePreviewFragment = invoicePreviews[0]
  const allInvoiceItems = invoicePreview.invoiceItems
    .map((item) => {
      const targetDate =
        item.triggerOn ?? (item.charge.billingTerm == BillingTerm.UpFront ? item.periodStartDate : item.periodEndDate)
      return {
        ...item,
        targetDate: targetDate,
      }
    })
    .sort((a, b) => a.targetDate - b.targetDate)

  const filteredInvoiceItems = allInvoiceItems.filter(
    (item) => !item.charge.isEventBased && item.charge.type != ChargeType.Usage
  )

  const totalsByTargetDate = new Map()

  const shouldBillUpfront = (item) =>
    item.charge.billingCycle === BillingCycle.PaidInFull ||
    (item.charge.billingCycle === BillingCycle.Default && orderBillingCycle === Cycle.PaidInFull)
  const upfrontInvoiceItems = filteredInvoiceItems.filter(shouldBillUpfront)
  if (upfrontInvoiceItems.length > 0) {
    totalsByTargetDate.set(orderStartDate, upfrontInvoiceItems)
  }

  filteredInvoiceItems
    .filter((item) => !shouldBillUpfront(item))
    .forEach((item) => {
      const group = totalsByTargetDate.get(item.targetDate) || []
      group.push(item)
      totalsByTargetDate.set(item.targetDate, group)
    })

  const eventBasedInvoiceItems = allInvoiceItems.filter((item) => item.charge.isEventBased)
  const eventBasedTotal = eventBasedInvoiceItems.reduce((total, item) => item.amount + total, 0)

  return (
    <Grid item>
      <BillyCard>
        <CardHeader title="Invoice Previews" />
        <Table aria-label="order-invoice-preview-table">
          <TableHead className={classes.tableHeader}>
            <TableRow aria-label="invoice-preview-header">
              <TableCell colSpan={1} size="small" style={{ whiteSpace: 'nowrap', width: '0%' }} />
              <TableCell>Invoice Event</TableCell>
              <TableCell>Target Date</TableCell>
              <TableCell className={classes.amount}>Total Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(totalsByTargetDate.entries()).map(([targetDate, invoiceItems], index) => {
              const total = invoiceItems.reduce((sum, item) => sum + item.amount, 0)
              return (
                <InvoicePreviewRow
                  key={index}
                  invoiceName={`Invoice ${index + 1}`}
                  targetDate={targetDate}
                  total={total}
                  currency={currency}
                  invoiceItems={invoiceItems}
                />
              )
            })}
            {eventBasedInvoiceItems.length > 0 && (
              <InvoicePreviewRow
                invoiceName={'Unscheduled'}
                targetDate={undefined}
                total={eventBasedTotal}
                currency={currency}
                invoiceItems={eventBasedInvoiceItems}
              />
            )}
            <TableRow aria-label={'invoice-preview-total'}>
              <TableCell colSpan={3}></TableCell>
              <TableCell colSpan={1} className={classes.rowHeader}>
                {billableCurrencyFormat({
                  currency: currency,
                  value: invoicePreview?.total,
                })}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </BillyCard>
    </Grid>
  )
}

export default OrderInvoicePreview
