import JotaiMuiSelect from '@/components/input/JotaiMuiSelect'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { useGetSupportedCurrenciesQuery } from '@/generated/graphql'
import { CommonOrderFormPageState } from '@/pageComponents/orders/EditOrderPage/CommonOrderFormPageState'
import { useDryRunActions } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { useJotaiIsOrderRenewal } from '@/pageComponents/orders/usePopulateOrder'
import { Grid, MenuItem } from '@mui/material'

export function OrderCurrencySelect({ disabledExplanation }: { disabledExplanation?: string }) {
  const jotaiForm = useJotaiFormContext<CommonOrderFormPageState>()
  const [supportedCurrenciesResponse] = useGetSupportedCurrenciesQuery()
  const { queueDryRun } = useDryRunActions()
  const supportedCurrencies =
    supportedCurrenciesResponse.data?.supportedCurrencies.filter(
      ({ supportedByTenant, currencyCode }) => supportedByTenant && currencyCode
    ) ?? []

  const isRenewal = useJotaiIsOrderRenewal({ jotaiForm })
  return (
    <Grid item xs={4} aria-label="currency-select-grid-cell">
      <JotaiMuiSelect
        atomSelector={(form) => form.orderDetail.currency}
        atomUpdater={(value, draft) => {
          draft.orderDetail.currency = value
          if (value && draft.orderDetail.lineItems.length) {
            queueDryRun()
          }
        }}
        form={jotaiForm}
        disabledExplanation={
          disabledExplanation
            ? disabledExplanation
            : isRenewal
            ? 'Renewals do not support changing currencies'
            : undefined
        }
        textFieldProps={{
          label: 'Currency',
          name: 'Currency',
          disabled: !!disabledExplanation || isRenewal,
        }}
        errorPath="orderDetail.currency"
      >
        {supportedCurrencies.map(({ currencyCode }) => (
          <MenuItem key={currencyCode} value={currencyCode}>
            {currencyCode}
          </MenuItem>
        ))}
      </JotaiMuiSelect>
    </Grid>
  )
}
