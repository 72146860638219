import { CardContent, CardHeader, Grid } from '@mui/material'
import BillyCard from '../../../components/card/billyCard'
import { useAmendmentOrderPageStyles } from './useAmendmentOrderPageStyles'
import { PurchaseOrderNumberField } from '@/pageComponents/orders/EditOrderPage/cards/OrderEditBillingCard/PurchaseOrderNumberField'
import { PurchaseOrderRequiredInvoicingField } from '@/pageComponents/orders/EditOrderPage/cards/OrderEditBillingCard/PurchaseOrderRequiredInvoicingField'
import { BillingAnchorDateField } from '../EditOrderPage/cards/OrderEditBillingCard/BillingAnchorDateField'
import { InvoiceTriggerSchedules } from '../EditOrderPage/cards/InvoiceTriggerSchedules'

export function AmendmentBillingCard() {
  const { classes } = useAmendmentOrderPageStyles()
  return (
    <BillyCard>
      <CardHeader title="Billing" />
      <CardContent>
        <Grid container direction="row" spacing={2} className={classes.fieldGridItem}>
          <PurchaseOrderNumberField />
          <PurchaseOrderRequiredInvoicingField />
          <BillingAnchorDateField />
        </Grid>
        <InvoiceTriggerSchedules />
      </CardContent>
    </BillyCard>
  )
}
