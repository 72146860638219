import BillyCard from '@/components/card/billyCard'
import { useOrderPageFormStyles } from '../../hooks/useOrderPageFormStyles'
import { formatUnixDate } from '@/util/datetime/luxon/dateUtil'
import { Grid, Typography } from '@mui/material'
import { CustomBillingScheduleOutput } from '@/generated/rest'
import { unitPriceFormatter } from '@/util/currencyUtil'

export function CustomBillingScheduleView({
  schedules,
  currency,
}: {
  schedules: CustomBillingScheduleOutput['schedules']
  currency: string | null | undefined
}) {
  const { classes } = useOrderPageFormStyles()

  return (
    <BillyCard className={classes.rampDatesCard}>
      <Grid container direction="column">
        {schedules?.map((schedule, index) => (
          <Grid item key={index} className={classes.ramp}>
            <Typography component="span" className={classes.rampIntervalText}>
              {schedule.triggerInstant ? formatUnixDate(schedule.triggerInstant) : ''}
            </Typography>
            <Typography component="span" className={classes.rampDateText}>
              {schedule.amount ? unitPriceFormatter({ currency, value: schedule.amount }) : ''}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </BillyCard>
  )
}
