/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AccountAddress {
  streetAddressLine1?: string
  streetAddressLine2?: string
  addressId?: string
  city?: string
  country?: string
  /** @format int64 */
  createdOn?: number
  /** @format uuid */
  id?: string
  state?: string
  /** @format int64 */
  updatedOn?: number
  zipcode?: string
}

export interface AccountAddressJson {
  /** Address Line 1 of the Contact */
  streetAddressLine1?: string
  /** Address Line 2 of the Contact */
  streetAddressLine2?: string
  /** City of the Contact */
  city?: string
  /** Country Code of the Contact ( ISO 3166 alpha-2 country code). */
  country?: string
  /** State Code of the Contact (ISO 3166-2 state/province code). Currently supported for USA, Canada. For instance, for Arizona (USA), set state as AZ (not US-AZ).For British Columbia (Canada), set as BC (not CA-BC) */
  state?: string
  /** Zip or Postal Code of the Contact */
  zipcode?: string
}

export interface AccountContact {
  accountId?: string
  address?: AccountAddress
  addressId?: string
  contactId?: string
  /** @format int64 */
  createdOn?: number
  /**
   * @minLength 0
   * @maxLength 255
   */
  email?: string
  emailVerified?: boolean
  /**
   * @minLength 0
   * @maxLength 100
   */
  erpId?: string
  /**
   * @minLength 0
   * @maxLength 36
   */
  externalId?: string
  /**
   * @minLength 0
   * @maxLength 255
   */
  firstName?: string
  fullName?: string
  /** @format uuid */
  id?: string
  /**
   * @minLength 0
   * @maxLength 255
   */
  lastName?: string
  /**
   * @minLength 0
   * @maxLength 255
   */
  phoneNumber?: string
  state?: 'ACTIVE' | 'DISABLED' | 'EXPIRED'
  /**
   * @minLength 0
   * @maxLength 255
   */
  title?: string
  /** @format int64 */
  updatedOn?: number
}

export interface AccountContactJson {
  /** Uniquely identifies the Account */
  accountId: string
  address?: AccountAddressJson
  /** Email of the Contact */
  email?: string
  erpId?: string
  externalId?: string
  /** First Name of the Contact */
  firstName?: string
  fullName?: string
  /** This is a system-generated Account Contact ID */
  id?: string
  /** (optional) Last Name of the Contact */
  lastName?: string
  /** (optional) Phone Number of the Contact */
  phoneNumber?: string
  /** (optional) Title of the Contact */
  title?: string
}

export interface AccountJson {
  /** (optional) Address of the Account */
  address?: AccountAddressJson
  /** (optional) CRM ID of the Account */
  crmId?: string
  /** (optional) CRM type */
  crmType?: string
  /** The currency code (ISO 4217 format). If currency is not entered, USD will be applied by default. Possible currencies in Subskribe: AED AUD CAD CHF CZK DKK EUR GBP HKD INR MXN NOK NZD SAR SEK SGD TWD USD */
  currency?: string
  /** (optional) Use this option to create custom fields for the Account object if you need to collect Account-specific information, such as region, age, or gender. */
  customFields?: Record<string, CustomFieldValue>
  /** (optional) Account Description */
  description?: string
  /**
   * (optional) Enter the entity ID where this Account belongs to
   * @uniqueItems true
   */
  entityIds?: string[]
  /** (optional) ERP ID of this account */
  erpId?: string
  /** (optional) Input true, if this account needs to be excluded from Batch Operations like Bulk  Invoice Run, Invoice Generation. Default is false. This is an optional field. */
  excludeFromBatchOperations?: boolean
  /** (optional) Input true, if this account needs to be excluded from Dunning emails. Default is false. This is an optional field. */
  excludeFromDunning?: boolean
  /** (optional) External ID of this account */
  externalId?: string
  /** (optional) Input true, if this Account will have an automatic payment. Default is false */
  hasAutomaticPayment?: boolean
  /** This is a system-generated Account ID */
  id?: string
  /** (optional) Input true, if this is a Reseller Account. Default is false */
  isReseller?: boolean
  /** (optional) Account legal name */
  legalName?: string
  /** Account name */
  name: string
  /** (optional) Phone number of the Account */
  phoneNumber?: string
  /**
   * (optional) Supported payment types for this Account. This field can include various payment types such as ACH, Card, Check, Wire, and Invoice. This is an optional field.
   * @uniqueItems true
   */
  supportedPaymentTypes?: ('ACH' | 'CARD' | 'CHECK' | 'WIRE' | 'INVOICE' | 'DEPOSIT' | 'EXTERNAL')[]
  /** (optional) Indicates Tax Exemption Information. When the account does not qualify for Tax Exemption, add null in the request. When using Anrok Tax Integration, keep this value  null , as Anrok requires to upload Tax Exemption Certificate. */
  taxExemptionUseCode?:
    | 'A'
    | 'B'
    | 'C'
    | 'D'
    | 'E'
    | 'F'
    | 'G'
    | 'H'
    | 'I'
    | 'J'
    | 'K'
    | 'L'
    | 'M'
    | 'N'
    | 'P'
    | 'Q'
    | 'R'
  timezone?: string
  /**
   * (optional) will be auto-populated as today's date
   * @format int64
   */
  updatedOn?: number
}

export interface AccountReceivableContactJson {
  address: AccountAddressJson
  email?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
}

export interface AccountingEvent {
  accountId?: string
  /** @format int64 */
  accountingDate?: number
  entityId?: string
  id?: string
  sourceEventId?: string
  sourceEventMetadata?: Record<string, string>
  sourceEventPayload?: ByteBuffer
  /** @format int64 */
  sourceEventSequenceNumber?: number
  /** @format int64 */
  sourceEventTimestamp?: number
  sourceTransactionId?: string
  sourceTransactionType?:
    | 'INVOICE_POSTED'
    | 'PAYMENT_PROCESSED'
    | 'CREDIT_MEMO_POSTED'
    | 'REVENUE_RECOGNIZED'
    | 'PAYMENT_VOIDED'
    | 'INVOICE_VOIDED'
    | 'REALIZED_GAIN_LOSS_POSTED'
  subscriptionId?: string
}

export interface AccountingEventPaginatedResponse {
  /** @format int32 */
  count?: number
  data?: AccountingEvent[]
  pageToken?: string
}

export interface AccountingPeriod {
  calculation?: AccountingPeriodCalculation
  closedBy?: string
  closedByUser?: UserJson
  /** @format int64 */
  closedOn?: number
  deferredRevenueBalance?: number
  /** @format int64 */
  endDate?: number
  entityId?: string
  id?: string
  openedBy?: string
  openedByUser?: UserJson
  /** @format int64 */
  openedOn?: number
  /** @format int64 */
  startDate?: number
  status?: 'OPEN' | 'CLOSE_IN_PROGRESS' | 'CLOSED' | 'UPCOMING'
  syncStatus?: 'NONE' | 'WAITING' | 'IN_PROGRESS' | 'SUCCEEDED' | 'FAILED'
  synthetic?: boolean
}

export interface AccountingPeriodCalculation {
  additionalRevenue?: number
  deferredRevenueEndingBalance?: number
  deferredRevenueStartingBalance?: number
  recognizedRevenue?: number
  unrecognizedRevenue?: number
  /** @format int32 */
  unrecognizedTransactionCount?: number
}

export interface AnrokIntegrationInput {
  apiKey?: string
}

export interface Answer {
  answer?: string[]
  questionId?: string
  skipped?: boolean
}

export interface ApplyPaymentRequest {
  amount?: number
  bankFee?: number
  invoiceAmount?: string
  invoiceNumber?: string
  note?: string
  paymentBankAccountId?: string
  /** @format int64 */
  paymentDate?: number
  /** @format uuid */
  paymentMethodId?: string
  paymentType?: 'ACH' | 'CARD' | 'CHECK' | 'WIRE' | 'INVOICE' | 'DEPOSIT' | 'EXTERNAL'
}

export interface ApprovalFlowJson {
  description?: string
  /** @uniqueItems true */
  entityIds?: string[]
  id?: string
  isSmartApproval?: boolean
  name?: string
  states?: ApprovalStateJson[]
  status?: 'ACTIVE' | 'INACTIVE'
  transitionRules?: ApprovalTransitionRuleJson[]
}

export interface ApprovalMatrixImportDataJson {
  /** @format int64 */
  createdOn?: number
  fileName?: string
  id?: string
  status?: string
  /** @format int64 */
  updatedOn?: number
  uploadedBy?: string
}

export interface ApprovalMatrixImportPreview {
  approvalRoleSegmentChanges?: ApprovalRoleSegmentChange[]
  id?: string
  segmentsToAdd?: string[]
  segmentsToDelete?: string[]
  uploadedBy?: string
}

export interface ApprovalRoleJson {
  /** @format int64 */
  createdOn?: number
  description?: string
  id?: string
  name?: string
  /** @format int64 */
  updatedOn?: number
}

export interface ApprovalRoleSegmentChange {
  newUserOrGroupName?: string
  previousUserOrGroupName?: string
  roleName?: string
  segmentName?: string
}

export interface ApprovalRuleConditions {
  orderCondition?: string
  orderLineCondition?: string
}

export interface ApprovalSegmentJson {
  /** @format int64 */
  createdOn?: number
  description?: string
  id?: string
  name?: string
  /** @format int64 */
  updatedOn?: number
}

export interface ApprovalStateActionJson {
  emailGroupId?: string
}

export interface ApprovalStateJson {
  action?: ApprovalStateActionJson
  approvalGroupId?: string
  approverId?: string
  approverType?: 'USER' | 'USER_GROUP' | 'ROLE'
  escalationPolicyId?: string
  id?: string
  name?: string
}

export interface ApprovalTransitionRuleJson {
  condition?: string
  fromState?: string
  id?: string
  name?: string
  ruleConditions?: ApprovalRuleConditions
  toState?: string
}

export interface Attachment {
  accountId?: string
  /** @format int64 */
  createdOn: number
  description?: string
  /** @format uuid */
  id: string
  inUse?: boolean
  isDeleted?: boolean
  name: string
  status?: 'UPLOADING' | 'UPLOADED'
  tag?: 'MASTER_SUBSCRIPTION_AGREEMENT' | 'STATEMENT_OF_WORK' | 'ORDER_FORM' | 'OTHER'
  /** @format int64 */
  updatedOn?: number
}

export interface AttributeReference {
  attributeDefinitionId?: string
  attributeValue?: string
}

export interface AuthSamlIntegrationJson {
  attributeMapping?: Record<string, string>
  metadataUrl?: string
  providerName?: string
}

export interface AutomatedInvoiceRule {
  autoEmailInvoice?: boolean
  autoPostInvoice?: boolean
  automatedInvoiceRuleId?: string
  /** @format int64 */
  createdOn?: number
  cronExpression?: string
  cronExpressionMeaning?: string
  description?: string
  enabled?: boolean
  /** @uniqueItems true */
  entityIds?: string[]
  /** @format int64 */
  firstExecutionDate?: number
  /** @format uuid */
  id?: string
  includeNonUsageCharge?: boolean
  includeUsageCharge?: boolean
  /** @format int32 */
  invoiceDuration?: number
  /** @format int64 */
  lastExecutionDate?: number
  name?: string
  /** @format int64 */
  nextExecutionDate?: number
  /** @format int32 */
  targetDuration?: number
  /** @format int64 */
  updatedOn?: number
}

export interface AutomatedInvoiceRuleRequestJson {
  autoEmailInvoice?: boolean
  autoPostInvoice?: boolean
  cronExpression: string
  description?: string
  enabled?: boolean
  /** @uniqueItems true */
  entityIds?: string[]
  /** @format int64 */
  firstExecutionDate: number
  id?: string
  includeNonUsageCharge?: boolean
  includeUsageCharge?: boolean
  /** @format int32 */
  invoiceDuration?: number
  /** @format int64 */
  lastExecutionDate?: number
  name: string
  /** @format int32 */
  targetDuration?: number
}

export interface AvalaraIntegration {
  accountId?: string
  companyCode?: string
  /** @format int64 */
  createdOn?: number
  integrationId?: string
  sandboxEnvironment?: boolean
  shouldCommitDocuments?: boolean
  /** @format int64 */
  updatedOn?: number
}

export interface AvalaraIntegrationInput {
  /**
   * @minLength 0
   * @maxLength 1024
   */
  accountId?: string
  accountLicenseKey?: string
  /**
   * @minLength 0
   * @maxLength 25
   */
  companyCode?: string
  sandboxEnvironment?: boolean
  shouldCommitDocuments?: boolean
}

export interface BackendConfig {
  conditions?: Condition[]
  id?: string
}

export interface BankTransactionPotentialInvoice {
  bankAccountId?: string
  bankAccountName?: string
  bankTransactionId?: string
  /** @format int64 */
  createdOn?: number
  entityId?: string
  externalTransactionId?: string
  /** @format uuid */
  id?: string
  payerName?: string
  paymentType?: string
  potentialInvoices?: PotentialInvoice[]
  referenceNumber?: string
  status?: string
  transactionAmount?: number
  transactionCurrency?: string
  /** @format int64 */
  transactionDate?: number
  transactionType?: string
}

export interface BankTransactionsUploadData {
  bankAccountId?: string
  externalTransactionId?: string
  failed?: boolean
  failureReason?: string
  payerName?: string
  paymentType?: string
  referenceNumber?: string
  transactionAmount?: string
  transactionCurrency?: string
  transactionDate?: string
  transactionType?: string
}

export interface BankTransactionsUploadResult {
  /** @format int32 */
  bankTransactionsCount?: number
  bankTransactionsUploadData?: BankTransactionsUploadData[]
  /** @format int32 */
  failedBankTransactionsCount?: number
  /** @format int32 */
  successfulBankTransactionsCount?: number
}

export interface BillingEventEntry {
  amount?: number
  /** @format int64 */
  createdOn?: number
  id?: string
  /** @format int64 */
  triggerOn?: number
}

export interface BillingEventInput {
  amount: number
  subscriptionChargeId?: string
  subscriptionId?: string
  /** @format int64 */
  triggerOn?: number
}

export interface BillingPeriod {
  /** @format int64 */
  end?: number
  fullPeriodDuration?: Duration
  /** @format int64 */
  fullPeriodEnd?: number
  recurrence?: Recurrence
  /** @format int64 */
  start?: number
}

export interface BulkInvoiceRun {
  automatedInvoiceRuleId?: string
  bulkInvoiceRunId?: string
  chargeInclusionOption?: 'INCLUDE_USAGE' | 'EXCLUDE_USAGE' | 'ONLY_USAGE'
  /** @format int64 */
  createdOn?: number
  description?: string
  entityId?: string
  failureReason?: string
  id?: string
  /** @format int64 */
  invoiceDate?: number
  invoiceSelector?: BulkInvoiceRunSelector
  name?: string
  phase?:
    | 'INVOICE_GENERATION_NOT_STARTED'
    | 'NO_INVOICES_FOUND'
    | 'INVOICES_GENERATING'
    | 'INVOICES_GENERATED'
    | 'INVOICES_POSTING'
    | 'INVOICES_POSTED'
    | 'INVOICES_EMAILING'
    | 'INVOICES_EMAILED'
  status?: 'QUEUED' | 'CREATED' | 'PROCESSING' | 'FAILED' | 'COMPLETED' | 'RUNNING'
  /** @format int64 */
  targetDate?: number
  /** @format int64 */
  updatedOn?: number
}

export interface BulkInvoiceRunExclusions {
  /** @uniqueItems true */
  accountIds?: string[]
  accounts?: AccountJson[]
}

export interface BulkInvoiceRunInput {
  chargeInclusionOption?: 'INCLUDE_USAGE' | 'EXCLUDE_USAGE' | 'ONLY_USAGE'
  description?: string
  entityId?: string
  /** @format int64 */
  invoiceDate?: number
  name?: string
  /** @format int64 */
  targetDate?: number
}

export interface BulkInvoiceRunItem {
  accountId?: string
  accountName?: string
  /** @format int64 */
  createdOn?: number
  draftInvoiceGenerated?: boolean
  draftInvoiceNotGenerated?: boolean
  draftInvoiceNumber?: string
  emailSent?: boolean
  excludedForEmailing?: boolean
  excludedForPosting?: boolean
  failureReason?: string
  /** @format uuid */
  id?: string
  includedForEmailing?: boolean
  includedForPosting?: boolean
  invoiceAmount?: number
  /** @format int64 */
  invoiceCreatedOn?: number
  invoiceCurrencyCode?: string
  invoiceNotPosted?: boolean
  invoicePosted?: boolean
  postedInvoiceNumber?: string
  runId?: string
  subscriptionId?: string
  /** @format int64 */
  updatedOn?: number
}

export interface BulkInvoiceRunSelector {
  exclusions?: BulkInvoiceRunExclusions
}

export interface BulkPaymentUploadData {
  amount?: number
  bankFee?: number
  currencyCode?: string
  failed?: boolean
  failureReason?: string
  invoiceNumber?: string
  newInvoiceBalance?: number
  note?: string
  originalInvoiceBalance?: number
  paymentBankAccountId?: string
  /** @format int64 */
  paymentDate?: number
  paymentMethod?: string
  /** @format uuid */
  paymentMethodId?: string
}

export interface BulkPaymentUploadResult {
  bulkPaymentUploadData?: BulkPaymentUploadData[]
  /** @format int32 */
  failedPaymentsCount?: number
  /** @format int32 */
  paymentsRequestCount?: number
}

export interface BulkRevenueRecognition {
  bulkRevenueRecognitionId?: string
  /** @format int64 */
  createdOn?: number
  description?: string
  entityId?: string
  failureReason?: string
  id?: string
  name?: string
  phase?:
    | 'REVENUE_RECOGNITION_NOT_STARTED'
    | 'NO_ACCOUNTING_PERIODS_FOUND'
    | 'REVENUE_RECOGNIZING'
    | 'REVENUE_RECOGNIZED'
    | 'ACCOUNTING_PERIOD_CLOSING'
    | 'JOURNAL_ENTRIES_CREATING'
    | 'JOURNAL_ENTRIES_CREATED'
    | 'ACCOUNTING_PERIOD_CLOSED'
  status?: 'QUEUED' | 'CREATED' | 'PROCESSING' | 'FAILED' | 'COMPLETED'
  /** @format int64 */
  targetDate?: number
  /** @format int64 */
  updatedOn?: number
}

export interface BulkRevenueRecognitionInput {
  description?: string
  entityId?: string
  name?: string
  /** @format int64 */
  targetDate?: number
}

export interface BulkRevenueRecognitionItem {
  accountingPeriodId?: string
  bulkRevenueRecognitionId?: string
  /** @format int64 */
  createdOn?: number
  failureReason?: string
  /** @format uuid */
  id?: string
  isClosed?: boolean
  isRecognized?: boolean
  journalEntriesGenerated?: boolean
  /** @format int64 */
  updatedOn?: number
}

export interface ByteBuffer {
  char?: string
  direct?: boolean
  /** @format double */
  double?: number
  /** @format float */
  float?: number
  /** @format int32 */
  int?: number
  /** @format int64 */
  long?: number
  readOnly?: boolean
  /** @format int32 */
  short?: number
}

export interface ChargeJson {
  amount?: number
  billingCycle?: 'DEFAULT' | 'PAID_IN_FULL' | 'MONTH' | 'QUARTER' | 'SEMI_ANNUAL' | 'YEAR'
  billingTerm?: 'UP_FRONT' | 'IN_ARREARS'
  chargeModel: 'PER_UNIT' | 'VOLUME' | 'TIERED' | 'FLAT_FEE' | 'BLOCK' | 'RATE_CARD_LOOKUP'
  creditable?: boolean
  custom?: boolean
  customFields?: Record<string, CustomFieldValue>
  /** @format int64 */
  defaultQuantity?: number
  description?: string
  displayName?: string
  drawdown?: boolean
  /** @format int64 */
  durationInMonths?: number
  erpId?: string
  eventBased?: boolean
  externalId?: string
  id?: string
  isCreditable?: boolean
  isCustom?: boolean
  isDiscount?: boolean
  isDrawdown?: boolean
  isEventBased?: boolean
  isListPriceEditable?: boolean
  isRenewable?: boolean
  itemCode?: string
  ledgerAccountMapping?: LedgerAccountMapping
  maxAmount?: number
  /** @format int64 */
  maxQuantity?: number
  minAmount?: number
  /** @format int64 */
  minQuantity?: number
  minimumCommitBaseChargeId?: string
  name?: string
  overageBaseChargeId?: string
  percent?: number
  percentDerivedFrom?: 'LIST_AMOUNT' | 'SELL_AMOUNT'
  planId?: string
  priceTiers?: PriceTierJson[]
  rateCardId?: string
  recognitionRuleId?: string
  recurrence?: RecurrenceJson
  shouldTrackArr?: boolean
  targetPlanIds?: string[]
  /** @format uuid */
  taxRateId?: string
  type: 'ONE_TIME' | 'RECURRING' | 'USAGE' | 'PREPAID' | 'PERCENTAGE_OF'
  /** @format uuid */
  unitOfMeasureId?: string
}

export interface ChargePartialJson {
  creditable?: boolean
  /** @format int64 */
  defaultQuantity?: number
  description?: string
  displayName?: string
  erpId?: string
  externalId?: string
  id?: string
  isCreditable?: boolean
  isListPriceEditable?: boolean
  isRenewable?: boolean
  itemCode?: string
  maxAmount?: number
  /** @format int64 */
  maxQuantity?: number
  minAmount?: number
  /** @format int64 */
  minQuantity?: number
  name?: string
  recognitionRuleId?: string
  /** @format uuid */
  taxRateId?: string
  /** @format uuid */
  unitOfMeasureId?: string
}

export interface CompanyContactJson {
  address: AccountAddressJson
  email?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
}

export interface Condition {
  checks?: ('required' | 'empty')[]
  defaultValue?: string
  key?: string
}

export interface CreditMemoApplicationJson {
  amount: number
  creditMemoNumber: string
  /** @format uuid */
  id?: string
  invoiceAmount?: string
  invoiceNumber?: string
  note?: string
}

export interface CreditMemoBalanceJson {
  accountId?: string
  balance?: number
  /** @format uuid */
  creditMemoId?: string
  /** @format int64 */
  updatedOn?: number
}

export interface CreditMemoJson {
  accountId: string
  amount: number
  createdFrom?: string
  /** @format int64 */
  createdOn: number
  /** @format int64 */
  creditMemoDate?: number
  creditMemoNumber: string
  currencyCode?: string
  erpId?: string
  exchangeRate?: number
  /** @format int64 */
  exchangeRateDate?: number
  exchangeRateId?: string
  functionalAmount?: number
  lineItems: CreditMemoLineItemJson[]
  notes?: string
  /** @format int64 */
  postedDate?: number
  status: 'DRAFT' | 'POSTED' | 'CLOSED'
  /** @format int64 */
  updatedOn: number
}

export interface CreditMemoLineItemJson {
  amount?: number
  chargeId?: string
  /** @format int64 */
  createdOn?: number
  /** @format int64 */
  endDate?: number
  functionalAmount?: number
  functionalTaxAmount?: number
  /** @format int64 */
  startDate?: number
  taxAmount?: number
  tenantId: string
  /** @format int64 */
  updatedOn?: number
}

export interface CreditMemoLineItemRequestJson {
  amount?: number
  chargeId?: string
  /** @format int64 */
  endDate?: number
  /** @format int64 */
  startDate?: number
}

export interface CreditMemoPaginationResponseJson {
  data?: CreditMemoJson[]
  /** @format uuid */
  nextCursor?: string
  /** @format int32 */
  numElements?: number
}

export interface CreditMemoUnapplicationJson {
  creditMemoBalanceAmount?: string
  creditMemoNumber: string
  invoiceBalanceAmount?: string
  invoiceNumber?: string
  note?: string
  /** @format uuid */
  settlementToUnapplyId: string
}

export interface CreditableAmount {
  creditableAmount?: number
  maxCreditableAmount?: number
  subscriptionChargeId?: string
}

export interface CrmAccountImportResponse {
  accountId?: string
  accountUrl?: string
}

export interface CrmFieldMappingImportDataJson {
  /** @format int64 */
  createdOn?: number
  fileName?: string
  id?: string
  status?: string
  /** @format int64 */
  updatedOn?: number
  uploadedBy?: string
}

export interface CrmFieldMappingImportPreview {
  crmFieldMappingChanges?: UpdateCrmFieldMappingDetail[]
  id?: string
  uploadedBy?: string
}

export interface CustomBillingPeriodInput {
  amount?: number
  recurrenceWithCount: CustomBillingRecurrence
  /** @format int64 */
  triggerInstant?: number
}

export interface CustomBillingPeriodOutput {
  amount?: number
  periods?: BillingPeriod[]
  recurrenceWithCount?: CustomBillingRecurrence
  /** @format int64 */
  triggerInstant?: number
}

export interface CustomBillingRecurrence {
  /** @format int32 */
  count?: number
  recurrence?: RecurrenceJson
}

export interface CustomBillingScheduleInput {
  orderId?: string
  orderLines?: string[]
  schedules?: CustomBillingPeriodInput[]
  version: 'V1'
}

export interface CustomBillingScheduleOutput {
  /** @format int64 */
  createdOn?: number
  id?: string
  isAdhocBilling?: boolean
  orderId?: string
  orderLines?: string[]
  schedules?: CustomBillingPeriodOutput[]
  /** @format int64 */
  updatedOn?: number
  version?: 'V1'
}

export interface CustomField {
  entries?: Record<string, CustomFieldValue>
}

export interface CustomFieldDefault {
  selections?: string[]
  value?: string
}

export interface CustomFieldDefinitionCreateInput {
  defaultValue?: CustomFieldDefault
  fieldLabel?: string
  fieldName?: string
  fieldType: 'STRING' | 'PICKLIST' | 'MULTISELECT_PICKLIST'
  options?: string[]
  parentObjectType:
    | 'ACCOUNT'
    | 'ORDER'
    | 'ORDER_ITEM'
    | 'PLAN'
    | 'CHARGE'
    | 'INVOICE'
    | 'SALES_ROOM'
    | 'SUBSCRIPTION'
    | 'SUBSCRIPTION_ITEM'
    | 'OPPORTUNITY'
  required?: boolean
  source?: 'USER' | 'SYSTEM'
}

export interface CustomFieldDefinitionJson {
  /** @format int64 */
  createdOn?: number
  defaultValue?: CustomFieldDefault
  fieldLabel?: string
  fieldName?: string
  fieldType?: 'STRING' | 'PICKLIST' | 'MULTISELECT_PICKLIST'
  id?: string
  options?: string[]
  parentObjectType?:
    | 'ACCOUNT'
    | 'ORDER'
    | 'ORDER_ITEM'
    | 'PLAN'
    | 'CHARGE'
    | 'INVOICE'
    | 'SALES_ROOM'
    | 'SUBSCRIPTION'
    | 'SUBSCRIPTION_ITEM'
    | 'OPPORTUNITY'
  required?: boolean
  /** @format int64 */
  updatedOn?: number
}

export interface CustomFieldDefinitionUpdateInput {
  defaultValue?: CustomFieldDefault
  fieldLabel?: string
  fieldName?: string
  fieldType?: 'STRING' | 'PICKLIST' | 'MULTISELECT_PICKLIST'
  options?: string[]
}

export interface CustomFieldEntry {
  defaultValue?: CustomFieldDefault
  id?: string
  label: string
  name: string
  options?: string[]
  required?: boolean
  selections?: string[]
  source?: 'USER' | 'SYSTEM'
  type?: 'STRING' | 'PICKLIST' | 'MULTISELECT_PICKLIST'
  value?: string
}

export interface CustomFieldUpdateInput {
  selections?: string[]
  value?: string
}

export interface CustomFieldValue {
  defaultValue?: CustomFieldDefault
  label: string
  name: string
  options?: string[]
  required?: boolean
  selections?: string[]
  source?: 'USER' | 'SYSTEM'
  type?: 'STRING' | 'PICKLIST' | 'MULTISELECT_PICKLIST'
  value?: string
}

export interface Customization {
  backend?: BackendConfig
  columnDefaultInvisible?: string[]
  columnOrdering?: string[]
  configType: 'state' | 'form' | 'dgpTable'
  defaultValueSetters?: DefaultValueDefinition[]
  hidden?: boolean
  id?: string
  required?: boolean
}

export interface DataImport {
  /** @format int64 */
  completedOn?: number
  domain?:
    | 'CATALOG'
    | 'ACCOUNT'
    | 'ORDER'
    | 'USAGE'
    | 'BULK_ACCOUNT_UPDATE'
    | 'BULK_ORDER_UPDATE'
    | 'BULK_SUBSCRIPTION_UPDATE'
    | 'BULK_PRODUCT_UPDATE'
    | 'BULK_PLAN_UPDATE'
  /** @uniqueItems true */
  entityIds?: string[]
  fileName?: string
  /** @format int64 */
  fileSizeBytes?: number
  fileType?: 'CSV'
  importId?: string
  importedBy?: string
  importedByUserId?: string
  /** @format int64 */
  importedOn?: number
  operation?: 'CREATE' | 'UPDATE'
  /** @format int64 */
  rowCount?: number
  status?: 'VALIDATED' | 'PROCESSING' | 'SUCCESSFUL' | 'FAILED' | 'PARTIALLY_SUCCESSFUL'
}

export interface DefaultValueDefinition {
  path?: string
  value?: string
}

export interface DiscountDetailJson {
  amount?: number
  discountAmount?: number
  discountedPrice?: number
  name?: string
  percent?: number
  status?: 'ACTIVE' | 'DEPRECATED'
}

export interface DiscountJson {
  discountAmount?: number
  discountedPrice?: number
  name?: string
  percent?: number
  status?: 'ACTIVE' | 'DEPRECATED'
}

export interface DocuSignIntegrationRequestJson {
  clientId?: string
  clientSecret?: string
  environment?: 'DEMO' | 'PRODUCTION'
}

export interface DocuSignIntegrationResponseJson {
  clientId?: string
  environment?: 'DEMO' | 'PRODUCTION'
  integrationId?: string
  isCompleted?: boolean
}

export interface DocumentCustomContent {
  content?: string
  /** @format uuid */
  id?: string
  orderId?: string
  title?: string
}

export interface DocumentSection {
  deleted?: boolean
  /** @uniqueItems true */
  entityIds?: string[]
  /** @format uuid */
  id?: string
  location?: 'BEFORE_SIGNATURE' | 'AFTER_SIGNATURE'
  name?: string
  title?: string
}

export interface DocumentTemplateJson {
  content?: string
  description?: string
  /** @uniqueItems true */
  entityIds?: string[]
  hasNewerVersion?: boolean
  id?: string
  isUserSelectable?: boolean
  name?: string
  sectionUuid?: string
  status?: 'DRAFT' | 'ACTIVE' | 'DEPRECATED'
  type:
    | 'ORDER'
    | 'INVOICE'
    | 'INVOICE_EMAIL'
    | 'CREDIT_MEMO'
    | 'EMAIL'
    | 'UPSELL_EARLY_RENEWAL'
    | 'DUNNING'
    | 'CANCEL_AND_RESTRUCTURE'
    | 'ESIGN'
  /** @format int32 */
  version?: number
}

export interface DocumentTemplateRequestJson {
  content?: string
  description?: string
  /** @uniqueItems true */
  entityIds?: string[]
  id?: string
  isUserSelectable?: boolean
  name?: string
  sectionUuid?: string
  status?: 'DRAFT' | 'ACTIVE' | 'DEPRECATED'
  type:
    | 'ORDER'
    | 'INVOICE'
    | 'INVOICE_EMAIL'
    | 'CREDIT_MEMO'
    | 'EMAIL'
    | 'UPSELL_EARLY_RENEWAL'
    | 'DUNNING'
    | 'CANCEL_AND_RESTRUCTURE'
    | 'ESIGN'
}

export interface DunningSettingJson {
  dunningTypeMap?: Record<string, boolean>
  isEnabled: boolean
}

export interface Duration {
  /** @format int32 */
  nano?: number
  negative?: boolean
  /** @format int64 */
  seconds?: number
  units?: TemporalUnit[]
  zero?: boolean
}

export interface EmailNotifiersList {
  bccIds?: string[]
  ccIds?: string[]
  toIds?: string[]
}

export interface EmailSetting {
  /**
   * @minLength 0
   * @maxLength 255
   */
  ccEmail?: string
  ccEmailType?:
    | 'INVOICE_POSTED'
    | 'CREDIT_MEMO_AVAILABLE'
    | 'HUBSPOT_ERROR'
    | 'DUNNING'
    | 'APPROVAL_FLOW'
    | 'ESIGN'
    | 'LOGIN_LINK'
  /** @format int64 */
  createdOn?: number
  entityId?: string
  /** @format int64 */
  updatedOn?: number
}

export interface Entity {
  accountReceivableContact?: AccountContact
  companyContact?: AccountContact
  displayId?: string
  entityId?: string
  erpId?: string
  functionalCurrency?: string
  /** @format uuid */
  id?: string
  invoiceConfig?: NumberConfig
  invoiceConfigId?: string
  name?: string
  prorationMode?: 'NORMALIZED' | 'EXACT_DAYS' | 'EXACT'
  prorationScheme?: 'FIXED_DAYS' | 'CALENDAR_DAYS'
  tenantId?: string
  timezone?: TimeZone
  wireInstruction?: string
}

export interface EntityJson {
  accountReceivableContact?: AccountReceivableContactJson
  companyContact?: CompanyContactJson
  creditMemoConfig?: NumberConfig
  displayId?: string
  entityId?: string
  erpId?: string
  functionalCurrency?: string
  invoiceConfig?: NumberConfig
  invoiceConfigId?: string
  name?: string
  prorationMode?: 'NORMALIZED' | 'EXACT_DAYS' | 'EXACT'
  prorationScheme?: 'FIXED_DAYS' | 'CALENDAR_DAYS'
  timezone?: string
  wireInstruction?: string
}

export interface EntityRef {
  displayId?: string
  entityId?: string
  name?: string
}

export interface Entry {
  failed?: boolean
  failureReason?: string
  /** @format int64 */
  rowNumber?: number
}

export interface ErpInputJson {
  /**
   * @minLength 0
   * @maxLength 100
   */
  erpId?: string
}

export interface ExternalArrScheduleJson {
  amount: number
  category?:
    | 'OPENING_BALANCE'
    | 'NEW'
    | 'ADD_ON'
    | 'RENEWAL_ADD_ON'
    | 'UPSELL'
    | 'MARKUP'
    | 'RENEWAL_UPSELL'
    | 'RENEWAL_MARKUP'
    | 'DOWNSELL'
    | 'MARKDOWN'
    | 'RENEWAL_DOWNSELL'
    | 'RENEWAL_MARKDOWN'
    | 'TERMINATION'
    | 'EXPIRATION'
    | 'PENDING_RENEWAL'
    | 'REACTIVATION'
    | 'DEBOOK'
  /** @format int64 */
  endDate: number
  externalId?: string
  id?: string
  metadata?: string
  previousScheduleId?: string
  /** @format int64 */
  quantity: number
  /** @format int64 */
  startDate: number
  submittedBy?: string
}

export interface FlatfileWorkbookResponse {
  data?: FlatfileWorkbookResponseData
}

export interface FlatfileWorkbookResponseData {
  environmentId?: string
  spaceId?: string
}

export interface GuidedSellingInput {
  initialBlurb?: string
  messagesSoFar?: Message[]
  usecaseId?: string
}

export interface GuidedSellingUsecase {
  description?: string
  id?: string
  /**
   * @minLength 0
   * @maxLength 100
   */
  name?: string
  questions?: QuestionDefinition[]
}

export type InputStream = object

export interface Integration {
  clientId?: string
  /** @format int64 */
  createdOn?: number
  deleted?: boolean
  environment?: string
  /** @format uuid */
  id?: string
  maskedApiKey?: string
  metadata?: JSONObject
  realmId?: string
  status?: 'PENDING' | 'ACTIVE' | 'DELETED'
  targetService?: 'QUICKBOOKS' | 'XERO' | 'MERGE' | 'NETSUITE' | 'ANROK' | 'THOMSON_REUTERS'
  /** @format int64 */
  updatedOn?: number
}

export interface InvoiceBalanceJson {
  accountId?: string
  balance?: number
  invoiceNumber?: string
  /** @format int64 */
  updatedOn?: number
}

export interface InvoiceBankTransactionMatchResponse {
  bankTransactionIDs?: string[]
  invoiceBalance?: number
  invoiceID?: string
  invoiceStatus?: 'DRAFT' | 'POSTED' | 'PAID' | 'CONVERTED' | 'VOIDED'
}

export interface InvoiceDeletableResponse {
  deletable?: boolean
  message?: string
}

export interface InvoiceItemJson {
  amount?: number
  chargeId?: string
  discountAmount?: number
  /** @format int64 */
  drawdownQuantityRemaining?: number
  /** @format int64 */
  drawdownQuantityUsed?: number
  functionalAmount?: number
  functionalDiscountAmount?: number
  functionalListAmount?: number
  functionalTaxAmount?: number
  id?: string
  isBilled?: boolean
  listAmount?: number
  listUnitPrice?: number
  orderId?: string
  orderLineItemId?: string
  /** @format int64 */
  periodEndDate?: number
  /** @format int64 */
  periodStartDate?: number
  planId?: string
  /** @format int64 */
  quantity?: number
  sellUnitPrice?: number
  subscriptionChargeGroupId?: string
  subscriptionChargeId?: string
  taxAmount?: number
  taxRate?: TaxRateJson
  /** @format int64 */
  triggerOn?: number
}

export interface InvoiceItemPreviewJson {
  amount?: number
  discountAmount?: number
  listAmount?: number
  listUnitPrice?: number
  orderLineItemId?: string
  sellUnitPrice?: number
}

export interface InvoiceJson {
  accountId?: string
  billingContact?: AccountContactJson
  currency?: string
  customFields?: Record<string, CustomFieldValue>
  /** @format int64 */
  dueDate?: number
  emailNotifiersList?: EmailNotifiersList
  erpId?: string
  exchangeRate?: number
  /** @format int64 */
  exchangeRateDate?: number
  exchangeRateId?: string
  functionalSubTotal?: number
  functionalTaxTotal?: number
  functionalTotal?: number
  functionalTotalDiscount?: number
  generatedBy?: string
  generationMethod?:
    | 'USER_INITIATED'
    | 'API_INITIATED'
    | 'BULK_INVOICE_RUN'
    | 'AUTOMATED_INVOICE_JOB'
    | 'RULE_DRIVEN_INVOICE_JOB'
  /** @format int64 */
  invoiceDate?: number
  invoiceItems?: InvoiceItemJson[]
  invoiceNumber?: string
  note?: string
  paymentTerm?: string
  /** @format int64 */
  postedDate?: number
  purchaseOrderNumber?: string
  purchaseOrderRequired?: boolean
  shippingContact?: AccountContactJson
  status?: 'DRAFT' | 'POSTED' | 'PAID' | 'CONVERTED' | 'VOIDED'
  subTotal?: number
  subscriptionId?: string
  taxTotal?: number
  taxTransactionCode?: string
  total?: number
  totalDiscount?: number
  /** @format int64 */
  voidedDate?: number
}

export interface InvoiceJsonPaginationResponse {
  data?: InvoiceJson[]
  /** @format uuid */
  nextCursor?: string
  /** @format int32 */
  numElements?: number
}

export interface InvoiceNumberPrefix {
  prefix?: string
}

export interface InvoicePreviewJson {
  /** @format int64 */
  createdOn?: number
  invoiceItems?: InvoiceItemJson[]
  lineItems?: InvoiceItemPreviewJson[]
  orderId?: string
  total?: number
  totalDiscount?: number
}

export interface JSONObject {
  empty?: boolean
}

export interface LedgerAccount {
  accountCode?: string
  accountType?:
    | 'ACCOUNTS_RECEIVABLE'
    | 'TAX_LIABILITY'
    | 'CASH'
    | 'DEFERRED_REVENUE'
    | 'RECOGNIZED_REVENUE'
    | 'CONTRACT_ASSET'
    | 'REALIZED_GAIN_LOSS'
    | 'EXPENSE'
  default?: boolean
  /**
   * @minLength 0
   * @maxLength 65535
   */
  description?: string
  /** @uniqueItems true */
  entityIds?: string[]
  id?: string
  inUse?: boolean
  isDefault?: boolean
  name?: string
}

export interface LedgerAccountMapping {
  contractAssetAccountId?: string
  deferredRevenueAccountId?: string
  ledgerAccountIds?: string[]
  recognizedRevenueAccountId?: string
  taxLiabilityAccountId?: string
}

export interface MediaType {
  parameters?: Record<string, string>
  subtype?: string
  type?: string
  wildcardSubtype?: boolean
  wildcardType?: boolean
}

export interface Message {
  /** @format int64 */
  createdAt?: number
  message?: string
  role?: string
}

export interface MessagesAndAnswer {
  answers?: Answer[]
  messages?: Message[]
}

export interface MetricsJson {
  arr?: number
  arrTrend?: TimeSeriesAmountJson[]
  averageArr?: number
  deltaArr?: number
  deltaTcv?: number
  entryArr?: number
  exitArr?: number
  nonRecurringTotal?: number
  recurringTotal?: number
  tcv?: number
}

export interface NextQuestion {
  nextQuestion?: Question
  state?: 'IN_PROGRESS' | 'DONE'
}

export interface NotificationTargetAndSubscriptions {
  description?: string
  name?: string
  notificationId?: string
  notificationTarget?: string
  notificationTargetType?: 'SLACK' | 'WEBHOOK'
  subscribedEvents?: (
    | 'INVOICE_POSTED'
    | 'INVOICE_VOIDED'
    | 'SUBSCRIPTION_CREATED'
    | 'SUBSCRIPTION_ACTIVATING'
    | 'SUBSCRIPTION_CHARGE_CHANGE'
    | 'SUBSCRIPTION_CANCELLING'
    | 'SUBSCRIPTION_EXPIRING'
    | 'SUBSCRIPTION_DELETED'
    | 'ORDER_SUBMITTED'
    | 'ORDER_EXECUTED'
    | 'ORDER_APPROVED'
    | 'ESIGNATURE_COMPLETED'
    | 'PAYMENT_PROCESSED'
  )[]
}

export interface NumberConfig {
  configId?: string
  /** @format int32 */
  length?: number
  /** @format int64 */
  nextSequenceNumber?: number
  prefix?: string
  scheme?: 'SEQUENCE' | 'PSEUDO_RANDOM'
}

export interface Opportunity {
  accountId?: string
  /** @format int64 */
  createdOn?: number
  crmId?: string
  customFields?: CustomField
  entityId?: string
  /** @format uuid */
  id?: string
  isClosed?: boolean
  name?: string
  opportunityCrmType?: 'SALESFORCE' | 'HUBSPOT'
  opportunityId?: string
  primaryOrderId?: string
  stage?: string
  type?: string
  /** @format int64 */
  updatedOn?: number
}

export interface OpportunityJson {
  accountId?: string
  /** @format int64 */
  createdOn?: number
  crmId?: string
  customFields?: Record<string, CustomFieldValue>
  entityId?: string
  id?: string
  isClosed?: boolean
  name?: string
  opportunityCrmType?: 'SALESFORCE' | 'HUBSPOT'
  opportunityId?: string
  primaryOrderId?: string
  stage?: string
  type?: string
  /** @format int64 */
  updatedOn?: number
}

export interface OpportunityPaginationResponse {
  data?: OpportunityJson[]
  /** @format uuid */
  nextCursor?: string
  /** @format int32 */
  numElements?: number
}

export interface OpportunityRestJson {
  accountId?: string
  /** @format int64 */
  createdOn?: number
  crmId?: string
  entityId?: string
  id?: string
  isClosed?: boolean
  name?: string
  opportunityCrmType?: 'SALESFORCE' | 'HUBSPOT'
  primaryOrderId?: string
  stage?: string
  type?: string
  /** @format int64 */
  updatedOn?: number
}

export interface OrderAttributesUpdateRequest {
  billingContactId?: string
  crmOpportunityId?: string
  crmOpportunityName?: string
  crmOpportunityStage?: string
  crmOpportunityType?: string
  name?: string
  shippingContactId?: string
}

export interface OrderJson {
  /** ID of the account associated with the order. */
  accountId?: string
  /** Approval segment ID. */
  approvalSegmentId?: string
  /** Attachment ID for any associated files. */
  attachmentId?: string
  /**  Indicates if the order is set to auto-renew. */
  autoRenew?: boolean
  /**
   * Anchor date for billing in Unix timestamp.
   * @format int64
   */
  billingAnchorDate?: number
  /** Billing contact ID for the order. */
  billingContactId?: string
  /** 	The billing cycle. Possible values: MONTH, YEAR, etc. */
  billingCycle?: RecurrenceJson
  /** Billing term for the order. Possible values: UP_FRONT, RECURRING. */
  billingTerm?: 'UP_FRONT' | 'IN_ARREARS'
  /** ID of the Composite order. */
  compositeOrderId?: string
  /** List of credit amount to be applied during amendment and cancellation. */
  creditableAmounts?: CreditableAmount[]
  /** Currency in which the order is billed. Default is USD. */
  currency?: string
  /** List of order line IDs eligible for custom billing. */
  customBillingEligibleOrderLineIds?: string[]
  /** Custom fields for the order. */
  customFields?: CustomFieldEntry[]
  /** ID of the master document template. */
  documentMasterTemplateId?: string
  /**
   * The end date of the order.
   * @format int64
   */
  endDate?: number
  /** Entity ID associated with the order. */
  entityId?: string
  /**
   * The date the order was executed.
   * @format int64
   */
  executedOn?: number
  executedOnFormatted?: string
  /**
   * Expiration date of the order.
   * @format int64
   */
  expiresOn?: number
  /** External identifier for the order. */
  externalId?: string
  /** Uniquely identifies the Order. */
  id?: string
  /** Indicates if the order is the primary order for the Salesforce opportunity. */
  isPrimaryOrderForSfdcOpportunity?: boolean
  /** Line items included in the order. */
  lineItems?: OrderLineItemJson[]
  /** Net effect of line items. */
  lineItemsNetEffect?: OrderLineItemJson[]
  /** Name of the Order. */
  name?: string
  /** CRM type of the opportunity (e.g., SALESFORCE). */
  opportunityCrmType?: 'SALESFORCE' | 'HUBSPOT'
  /** Order form template IDs associated with the order. */
  orderFormTemplateIds?: string[]
  /** Terms and conditions of the order. */
  orderTerms?: OrderTerms[]
  /** Type of the order. Possible values: NEW, AMENDMENT, RENEWAL, CHANGE, CANCEL. */
  orderType: 'NEW' | 'AMENDMENT' | 'CANCEL' | 'RENEWAL' | 'RESTRUCTURE'
  /** Owner ID of the order. */
  ownerId?: string
  /** Payment terms for the order. */
  paymentTerm?: string
  /** List of predefined discounts to be applied. */
  predefinedDiscounts?: TenantDiscountJson[]
  /** Purchase order number. */
  purchaseOrderNumber?: string
  /** Indicates if a purchase order is required for invoicing. */
  purchaseOrderRequiredForInvoicing?: boolean
  /** Ramp interval. */
  rampInterval?: number[]
  /** ID of the subscription being renewed. */
  renewalForSubscriptionId?: string
  /**
   * The version of the subscription being renewed.
   * @format int32
   */
  renewalForSubscriptionVersion?: number
  /** Salesforce opportunity ID associated with the order. */
  sfdcOpportunityId?: string
  /** Salesforce opportunity name. */
  sfdcOpportunityName?: string
  /** Stage of the Salesforce opportunity. */
  sfdcOpportunityStage?: string
  /** Type of Salesforce opportunity. */
  sfdcOpportunityType?: string
  /** Indicates whether the order can be executed in Salesforce. */
  sfdcOrderCanBeExecuted?: boolean
  /** Shipping contact ID for the order. */
  shippingContactId?: string
  /**
   * The start date of the order.
   * @format int64
   */
  startDate: number
  /** Start date type (e.g., FIXED). */
  startDateType?: 'FIXED' | 'EXECUTION_DATE'
  /** Current status of the order. */
  status: 'DRAFT' | 'SUBMITTED' | 'APPROVED' | 'EXECUTED' | 'CANCELLED' | 'EXPIRED'
  /** The associated subscription ID. */
  subscriptionId?: string
  /**
   * Target version of the subscription.
   * @format int32
   */
  subscriptionTargetVersion?: number
  /** Estimated taxes for the order. */
  taxEstimate?: number
  /** The term length for the order. */
  termLength?: RecurrenceJson
  /** Total Amount of the order. */
  totalAmount?: number
  totalListAmount?: number
  totalListAmountBeforeOverride?: number
  /**
   * Timestamp of the last update to the order.
   * @format int64
   */
  updatedOn?: number
}

export interface OrderJsonPaginationResponse {
  data?: OrderJson[]
  /** @format uuid */
  nextCursor?: string
  /** @format int32 */
  numElements?: number
}

export interface OrderLineItemJson {
  action?: 'ADD' | 'UPDATE' | 'REMOVE' | 'RENEWAL' | 'NONE' | 'MISSING_RENEWAL' | 'RESTRUCTURE'
  amount?: number
  annualizedAmount?: number
  arrOverride?: number
  attributeReferences?: AttributeReference[]
  chargeId: string
  currencyConversionRateId?: string
  customFields?: CustomFieldEntry[]
  discountAmount?: number
  discounts?: DiscountDetailJson[]
  dryRunItem?: boolean
  /** @format int64 */
  effectiveDate?: number
  /** @format int64 */
  endDate?: number
  id?: string
  isDryRunItem?: boolean
  isRamp?: boolean
  itemGroupId?: string
  listAmount?: number
  listAmountBeforeOverride?: number
  listPriceOverrideRatio?: number
  listUnitPrice?: number
  listUnitPriceBeforeOverride?: number
  planId?: string
  predefinedDiscounts?: TenantDiscountLineItemJson[]
  pricingOverride?: PricingOverrideJson
  /** @format int64 */
  quantity?: number
  replacedPlanId?: string
  sellUnitPrice?: number
  subscriptionChargeGroupId?: string
  subscriptionChargeId?: string
  taxEstimate?: number
}

export interface OrderLineItemRequestJson {
  action?: 'ADD' | 'UPDATE' | 'REMOVE' | 'RENEWAL' | 'NONE' | 'MISSING_RENEWAL' | 'RESTRUCTURE'
  amount?: number
  arrOverride?: number
  attributeReferences?: AttributeReference[]
  chargeId: string
  customFields?: CustomFieldEntry[]
  discounts?: DiscountJson[]
  dryRunItem?: boolean
  /** @format int64 */
  effectiveDate?: number
  /** @format int64 */
  endDate?: number
  id?: string
  isDryRunItem?: boolean
  isRamp?: boolean
  itemGroupId?: string
  listPriceOverrideRatio?: number
  listUnitPrice?: number
  planId?: string
  predefinedDiscounts?: string[]
  pricingOverride?: PricingOverrideJson
  /** @format int64 */
  quantity?: number
  replacedPlanId?: string
  subscriptionChargeId?: string
}

export interface OrderRequestJson {
  accountId?: string
  approvalSegmentId?: string
  attachmentId?: string
  autoRenew?: boolean
  /** @format int64 */
  billingAnchorDate?: number
  billingContactId?: string
  billingCycle?: RecurrenceJson
  billingTerm?: 'UP_FRONT' | 'IN_ARREARS'
  creditableAmounts?: CreditableAmount[]
  currency?: string
  customBillingSchedule?: CustomBillingScheduleInput
  customFields?: CustomFieldEntry[]
  documentCustomContent?: DocumentCustomContent
  documentMasterTemplateId?: string
  /** @format int64 */
  endDate?: number
  entityId?: string
  /** @format int64 */
  executedOn?: number
  /** @format int64 */
  expiresOn?: number
  externalId?: string
  id?: string
  isPrimaryOrderForSfdcOpportunity?: boolean
  lineItems?: OrderLineItemRequestJson[]
  name?: string
  opportunityCrmType?: 'SALESFORCE' | 'HUBSPOT'
  orderFormTemplateIds?: string[]
  orderType: 'NEW' | 'AMENDMENT' | 'CANCEL' | 'RENEWAL' | 'RESTRUCTURE'
  ownerId?: string
  paymentTerm?: string
  predefinedDiscounts?: TenantDiscountJson[]
  purchaseOrderNumber?: string
  purchaseOrderRequiredForInvoicing?: boolean
  rampInterval?: number[]
  renewalForSubscriptionId?: string
  sfdcOpportunityId?: string
  sfdcOpportunityName?: string
  sfdcOpportunityStage?: string
  sfdcOpportunityType?: string
  shippingContactId?: string
  /** @format int64 */
  startDate: number
  startDateType?: 'FIXED' | 'EXECUTION_DATE'
  subscriptionId?: string
  termLength?: RecurrenceJson
}

export interface OrderTerms {
  deleted?: boolean
  /** @format uuid */
  id?: string
  levelType?: 'ORDER' | 'PLAN'
  orderId?: string
  /** @uniqueItems true */
  planIds?: string[]
  templateGroupId?: string
  /** @format int32 */
  templateGroupVersion?: number
  templateId?: string
}

export interface OutboundEvent {
  comment?: string
  data?: object
  genericType?: Type
  id?: string
  mediaType?: MediaType
  name?: string
  /** @format int64 */
  reconnectDelay?: number
  reconnectDelaySet?: boolean
}

export interface PaginatedAccountsResponse {
  data?: AccountJson[]
  /** @format uuid */
  nextCursor?: string
  /** @format int32 */
  numElements?: number
}

export interface PaginatedBankTransactionPotentialInvoiceResponse {
  data?: BankTransactionPotentialInvoice[]
  /** @format uuid */
  nextCursor?: string
  /** @format int32 */
  numElements?: number
}

export interface PaginatedSubscriptionsResponse {
  data?: SubscriptionJson[]
  /** @format uuid */
  nextCursor?: string
  /** @format int32 */
  numElements?: number
}

export interface PaymentBalanceJson {
  accountId?: string
  balance?: number
  paymentId?: string
  /** @format int64 */
  updatedOn?: number
}

export interface PaymentBankAccountJson {
  cashLedgerAccountId: string
  /** @format int64 */
  createdOn?: number
  currencyCode: string
  description?: string
  entityIds: string[]
  expenseLedgerAccountId: string
  externalId?: string
  hasExistingPayments?: boolean
  id?: string
  name?: string
  status: 'DRAFT' | 'ACTIVE' | 'DEPRECATED'
  /** @format int64 */
  updatedOn?: number
}

export interface PaymentConfiguration {
  /** @uniqueItems true */
  supportedPaymentTypes?: ('ACH' | 'CARD' | 'CHECK' | 'WIRE' | 'INVOICE' | 'DEPOSIT' | 'EXTERNAL')[]
}

export interface PaymentJson {
  accountId: string
  amount?: number
  currencyCode?: string
  exchangeRate?: number
  /** @format int64 */
  exchangeRateDate?: number
  exchangeRateId?: string
  functionalAmount?: number
  functionalAmountCaptured?: number
  functionalBankFee?: number
  /** @format uuid */
  id?: string
  /** @format int64 */
  paymentDate?: number
  paymentId?: string
  /** @format uuid */
  paymentMethodId: string
  state?:
    | 'CREATED'
    | 'CONFIRMED'
    | 'CAPTURED'
    | 'PROCESSING'
    | 'CARD_DECLINED'
    | 'ACH_TRANSFER_FAILED'
    | 'RECONCILED'
    | 'INITIATED'
    | 'FAILED'
    | 'SUCCEED'
    | 'VOIDED'
  status?: string
}

export interface PaymentJsonPaginationResponse {
  data?: PaymentJson[]
  /** @format uuid */
  nextCursor?: string
  /** @format int32 */
  numElements?: number
}

export interface PaymentTermSettingsJson {
  customPaymentTermsAllowed: boolean
  defaultPaymentTerm: string
  defaultPaymentTerms: string[]
}

export interface PlanJson {
  charges?: ChargeJson[]
  currency?: string
  customFields?: Record<string, CustomFieldValue>
  description?: string
  displayName?: string
  /** @uniqueItems true */
  entityIds?: string[]
  externalId?: string
  id?: string
  name?: string
  productId?: string
  replacementPlanIds?: string[]
  status: 'DRAFT' | 'ACTIVE' | 'GRANDFATHERED' | 'ARCHIVED' | 'DEPRECATED'
  templateIds?: string[]
  /** @format int64 */
  updatedOn?: number
}

export interface PlanJsonPaginationResponse {
  data?: PlanJson[]
  /** @format uuid */
  nextCursor?: string
  /** @format int32 */
  numElements?: number
}

export interface PlanMetadataJson {
  /**
   * Entity ids to be updated
   * @uniqueItems true
   */
  entityIds?: string[]
}

export interface PotentialInvoice {
  accountName?: string
  invoiceAmount?: number
  /** @format int64 */
  invoiceDate?: number
  invoiceNumber?: string
  /** @format int32 */
  matchingConfidence?: number
}

export interface PredefinedReportDefChartJson {
  chartType?: string
  crossAxisKey?: string
  crossAxisLabel?: string
  crossAxisScale?: string
  mainAxisKey?: string
  mainAxisLabel?: string
  mainAxisScale?: string
  showLegend?: boolean
  sortBy?: string
  title?: string
}

export interface PredefinedReportDefJson {
  chart?: PredefinedReportDefChartJson
  description?: string
  filters?: PredefinedReportParamObject[]
  name?: string
  reportId?: string
}

export interface PredefinedReportDefsJson {
  reportDefs?: PredefinedReportDefJson[]
}

export interface PredefinedReportJson {
  duration?: ReportDuration
  params?: Record<string, object>
  /** @format int64 */
  reportDate?: number
  reportId?: string
}

export interface PredefinedReportParam {
  allowedValues?: string[]
  datatype: 'date' | 'string' | 'integer'
  defaultValue?: object
  description?: string
  name?: string
  optional?: boolean
  type: 'value' | 'range' | 'selection'
}

export interface PredefinedReportParamObject {
  allowedValues?: string[]
  datatype: 'date' | 'string' | 'integer'
  defaultValue?: object
  description?: string
  name?: string
  optional?: boolean
  type: 'value' | 'range' | 'selection'
}

export interface PrepaidStats {
  accountCrmId?: string
  accountId?: string
  accountName?: string
  consumedQuantity?: number
  drawdownChargeName?: string
  entityDisplayId?: string
  /** @format int64 */
  periodEnd?: number
  /** @format int64 */
  periodStart?: number
  planName?: string
  provisionedQuantity?: number
  remainingQuantity?: number
  startingQuantity?: number
  /** @format int64 */
  subscriptionEndDate?: number
  subscriptionId?: string
  /** @format int64 */
  subscriptionStartDate?: number
}

export interface PriceTierJson {
  amount: number
  overage?: number
  untilQuantity: string
}

export interface PricingOverrideJson {
  /** @format int64 */
  maxQuantity?: number
  /** @format int64 */
  minQuantity?: number
  priceTiers?: PriceTierJson[]
}

export interface ProductCategory {
  description?: string
  /** @uniqueItems true */
  entityIds?: string[]
  inUse?: boolean
  /**
   * @minLength 0
   * @maxLength 100
   */
  name?: string
  /** @format uuid */
  pkId?: string
  productCategoryId?: string
  /** @format int64 */
  updatedOn?: number
}

export interface ProductCategoryPaginationResponse {
  data?: ProductCategory[]
  /** @format uuid */
  nextCursor?: string
  /** @format int32 */
  numElements?: number
}

export interface ProductInputJson {
  description?: string
  displayName?: string
  /** @uniqueItems true */
  entityIds?: string[]
  externalId?: string
  id?: string
  name?: string
  productCategoryId?: string
  sku?: string
}

export interface ProductJson {
  description?: string
  displayName?: string
  /** @uniqueItems true */
  entityIds?: string[]
  externalId?: string
  id?: string
  inUse?: boolean
  name?: string
  productCategory?: ProductCategory
  productCategoryId?: string
  sku?: string
  /** @format int64 */
  updatedOn?: number
}

export interface ProductJsonPaginationResponse {
  data?: ProductJson[]
  /** @format uuid */
  nextCursor?: string
  /** @format int32 */
  numElements?: number
}

export interface PurchaseOrder {
  /** @format int64 */
  addedOn?: number
  originOrderId?: string
  /**
   * @minLength 0
   * @maxLength 255
   */
  purchaseOrderNumber?: string
}

export interface Question {
  id?: string
  multiSelectionAllowed?: boolean
  questionText?: string
  required?: boolean
  schema?: QuestionSchema
}

export interface QuestionDefinition {
  dependsAnd?: Answer[]
  dependsOr?: Answer[][]
  id?: string
  multiSelectionAllowed?: boolean
  questionText?: string
  required?: boolean
  schema?: QuestionSchema
}

export interface QuestionSchema {
  allowedValues?: string[]
  /** @format int32 */
  maxValue?: number
  /** @format int32 */
  minValue?: number
  type: 'ARRAY' | 'STRING' | 'INTEGER'
}

/** A raw usage record */
export interface RawUsage {
  /** Alias ID for an usage based subscription item. This value is required if subscriptionId and subscriptionChargeId are not provided */
  aliasId?: string
  /** List of attribute references to derive the price for the usage record */
  attributeReferences?: AttributeReference[]
  /** ChargeId of an usage based charge to attach usage record to. This value is required if aliasId is not provided */
  chargeId?: string
  /** Unique identifier for a particular usage record */
  id?: string
  /** SubscriptionId to attach usage record to. This value is required if aliasId is not provided */
  subscriptionId?: string
  /** @format int64 */
  usageQuantity?: number
  /**
   * Usage time in unix timestamp (seconds)
   * @format int64
   */
  usageTime?: number
}

/** A list of RawUsage objects */
export interface RawUsagesData {
  data: RawUsage[]
}

export interface RecognitionEventCompletion {
  accountingPeriodId?: string
  aliasId?: string
  /** @format int64 */
  arrivedOn?: number
  chargeId?: string
  /** @format int64 */
  createdOn?: number
  deleted?: boolean
  entityId?: string
  status?: 'ACCEPTED' | 'PROCESSED'
  subscriptionId?: string
  tenantId?: string
  unitOfCompletion: number
  /** @format int64 */
  updatedOn?: number
}

export interface RecognitionRule {
  catchupRequired?: boolean
  deferredRevenueAccountId?: string
  distributionMethod?: 'DAYS' | 'MONTHS_EVEN' | 'MONTHS_PARTIAL_PRORATED'
  /** @uniqueItems true */
  entityIds?: string[]
  id?: string
  inUse?: boolean
  isCatchupRequired?: boolean
  name?: string
  recognitionDateAlignment?: 'INVOICE_START_DATE' | 'INVOICE_END_DATE'
  recognitionEventType?: 'PERCENTAGE_OF_COMPLETION' | 'AMOUNT'
  recognitionType?: 'OVER_TIME' | 'POINT_IN_TIME' | 'EVENT'
  recognizedRevenueAccountId?: string
  source?: 'ORDER' | 'INVOICE'
}

export interface Recurrence {
  cycle?: 'MONTH' | 'QUARTER' | 'SEMI_ANNUAL' | 'YEAR' | 'PAID_IN_FULL' | 'CUSTOM'
  /** @format int32 */
  step?: number
}

export interface RecurrenceJson {
  cycle: 'MONTH' | 'QUARTER' | 'SEMI_ANNUAL' | 'YEAR' | 'PAID_IN_FULL' | 'CUSTOM'
  /**
   * @format int32
   * @min 1
   */
  step: number
}

export interface Refund {
  amount?: number
  createdBy?: string
  /** @format int64 */
  createdOn?: number
  creditMemoNumber?: string
  currencyCode?: string
  entityId?: string
  id?: string
  notes?: string
  paymentId?: string
  paymentMethodType?: string
  referenceId?: string
  /** @format int64 */
  refundDate?: number
  refundId?: string
  tenantId?: string
  /** @format int64 */
  updatedOn?: number
}

export interface RefundDetail {
  amount?: number
  createdBy?: string
  /** @format int64 */
  createdOn?: number
  creditMemoNumber: string
  currency: string
  id: string
  notes?: string
  paymentId: string
  paymentMethodType?: string
  referenceId?: string
  /** @format int64 */
  refundDate: number
  refundId: string
  /** @format int64 */
  updatedOn?: number
}

export interface RefundRequestJson {
  amount: number
  createdBy: string
  creditMemoNumber: string
  notes?: string
  paymentId: string
  paymentMethodType?: string
  referenceId?: string
  /** @format int64 */
  refundDate: number
}

export interface ReportDuration {
  /** @format int64 */
  end?: number
  /** @format int64 */
  start?: number
}

export interface ReportJobResponse {
  reportId?: string
  reportRunId?: string
  status?: 'OK' | 'FAILED'
  uri?: string
}

export interface RevenueEnablementProgress {
  accountingPeriodDefined?: boolean
  billingEnabled?: boolean
  cpqEnabled?: boolean
  entityId?: string
  glBalancesReviewed?: boolean
  /** @format int64 */
  goLiveDate?: number
  historicalInvoicesGenerated?: boolean
  historicalInvoicesReviewedAndClosed?: boolean
  isCompleted?: boolean
  revenueAndAccountingEnabled?: boolean
  revenueRulesAndGlCodeAssigned?: boolean
  revenueScheduleReviewed?: boolean
}

export interface SalesforceAccount {
  CurrencyIsoCode?: string
  billingAddress?: SalesforceAccountBillingAddress
  description?: string
  id?: string
  name?: string
  phone?: string
}

export interface SalesforceAccountBillingAddress {
  city?: string
  country?: string
  postalCode?: string
  state?: string
  street?: string
}

export interface SalesforceClientIntegrationRequestJson {
  baseLoginUrl?: string
  clientId?: string
  clientSecret?: string
  redirectUri?: string
}

export interface SettlementApplication {
  amount?: number
  applicationType?: 'PAYMENT' | 'VOID_PAYMENT' | 'CREDIT' | 'UNAPPLY_CREDIT'
  /** @format int64 */
  appliedOn?: number
  /** @format int64 */
  createdOn?: number
  creditMemoNumber?: string
  customerAccountId?: string
  entityId?: string
  exchangeRate?: number
  /** @format int64 */
  exchangeRateDate?: number
  exchangeRateId?: string
  functionalAmount?: number
  /** @format uuid */
  id?: string
  invoiceNumber?: string
  /** @format uuid */
  negatedSettlementId?: string
  note?: string
  paymentId?: string
  status?: 'ATTEMPTING_PAYMENT_COLLECTION' | 'APPLIED_PAYMENT' | 'FAILED'
}

export interface StandaloneCreditMemoRequest {
  accountId: string
  amount: number
  billingContactId?: string
  /** @format int64 */
  creditMemoDate?: number
  creditReason?: string
  currencyCode: string
  /** @format int64 */
  endDate?: number
  entityId?: string
  lineItems: CreditMemoLineItemRequestJson[]
  notes?: string
  /** @format int64 */
  postedDate?: number
  /** @format int64 */
  startDate?: number
  status: 'DRAFT' | 'POSTED' | 'CLOSED'
}

export interface SubscriptionChargeAlias {
  /**
   * @minLength 5
   * @maxLength 100
   */
  aliasId?: string
  /**
   * @minLength 0
   * @maxLength 36
   */
  chargeId?: string
  /** @format int64 */
  createdOn?: number
  /**
   * @minLength 0
   * @maxLength 36
   */
  subscriptionId?: string
}

export interface SubscriptionChargeJson {
  accountId: string
  attributeReferences?: AttributeReference[]
  chargeId: string
  currencyConversionRateId?: string
  customFields?: Record<string, CustomFieldValue>
  discountAmount?: number
  discounts?: DiscountJson[]
  /** @format int64 */
  endDate: number
  groupId?: string
  id?: string
  isRamp: boolean
  listUnitPrice?: number
  orderLines: string[]
  predefinedDiscounts?: TenantDiscountLineItemJson[]
  pricingOverride?: PricingOverrideJson
  /** @format int64 */
  quantity: number
  sellUnitPrice?: number
  /** @format int64 */
  startDate: number
}

export interface SubscriptionJson {
  accountId: string
  /** @format int64 */
  activationDate?: number
  autoRenew?: boolean
  billingContactId?: string
  billingCycle: RecurrenceJson
  billingTerm?: 'UP_FRONT' | 'IN_ARREARS'
  /** @format int64 */
  canceledDate?: number
  charges: SubscriptionChargeJson[]
  /** @format int64 */
  creationTime: number
  customFields?: Record<string, CustomFieldValue>
  /** @format int64 */
  endDate: number
  entityId: string
  externalId?: string
  id?: string
  name?: string
  orders: string[]
  paymentTerm?: string
  predefinedDiscounts?: TenantDiscountJson[]
  purchaseOrderRequiredForInvoicing?: boolean
  purchaseOrders?: PurchaseOrder[]
  rampInterval?: number[]
  /** @format int64 */
  renewedFromDate?: number
  renewedFromSubscriptionId?: string
  /** @format int64 */
  renewedToDate?: number
  renewedToSubscriptionId?: string
  /** @format int64 */
  restructuredFromDate?: number
  restructuredFromSubscriptionId?: string
  /** @format int64 */
  restructuredToDate?: number
  restructuredToSubscriptionId?: string
  shippingContactId?: string
  /** @format int64 */
  startDate: number
  state: 'ACTIVE' | 'EXPIRED' | 'CANCELLED' | 'PENDING' | 'PENDING_CANCELLATION'
  termLength: RecurrenceJson
  /** @format int32 */
  version: number
}

export interface SubscriptionUpdateJson {
  /** @format int64 */
  activationDate?: number
  autoRenew?: boolean
  billingContactId?: string
  emailNotifiersList?: EmailNotifiersList
  externalId?: string
  /**
   * @minLength 0
   * @maxLength 255
   */
  name?: string
  purchaseOrderNumber?: string
  purchaseOrderRequiredForInvoicing?: boolean
  shippingContactId?: string
}

export interface TaxJarIntegration {
  /** @format int64 */
  createdOn?: number
  integrationId?: string
  isDeleted?: boolean
  isSandbox?: boolean
  maskedApiKey?: string
  tenantId?: string
  /** @format int64 */
  updatedOn?: number
}

export interface TaxJarIntegrationInput {
  apiKey?: string
  isSandbox?: boolean
}

export interface TaxRateJson {
  description?: string
  /** @format uuid */
  id?: string
  inUse?: boolean
  name?: string
  status: 'ACTIVE' | 'DISABLED' | 'EXPIRED'
  taxCode?: string
  taxInclusive?: boolean
  taxPercentage?: number
}

export interface TaxRatePaginationResponseJson {
  data?: TaxRateJson[]
  /** @format uuid */
  nextCursor?: string
  /** @format int32 */
  numElements?: number
}

export interface TemplateScript {
  createdBy?: string
  /** @format int64 */
  createdOn?: number
  enabled?: boolean
  /** @format uuid */
  id?: string
  script?: string
  templateType?: 'ORDER' | 'COMPOSITE_ORDER' | 'INVOICE' | 'CREDIT_MEMO' | 'PREDEFINED_TERM'
  /** @format int64 */
  updatedOn?: number
}

export interface TemporalUnit {
  dateBased?: boolean
  duration?: Duration
  durationEstimated?: boolean
  timeBased?: boolean
}

export interface TenantCreditMemoConfigurationJson {
  /** @format int32 */
  creditMemoNextNumber?: number
  /** @format int32 */
  creditMemoNumberLength?: number
  creditMemoNumberPrefix?: string
  creditMemoNumberScheme?: string
}

export interface TenantDiscountJson {
  id?: string
  percent?: number
}

export interface TenantDiscountLineItemJson {
  amount?: number
  id?: string
  percent?: number
}

export interface TenantInvoiceConfig {
  invoiceConfigId?: string
  /** @format int64 */
  invoiceNextNumber?: number
  /** @format int32 */
  invoiceNumberLength?: number
  invoiceNumberPrefix?: InvoiceNumberPrefix
  invoiceNumberScheme?: 'SEQUENCE' | 'PSEUDO_RANDOM'
}

export interface TenantJson {
  address?: AccountAddressJson
  /** @format int64 */
  createdOn?: number
  email?: string
  hasHubSpotIntegration?: boolean
  hasSalesforceIntegration?: boolean
  hasSso?: boolean
  isDeleted?: boolean
  isTest?: boolean
  name?: string
  phoneNumber?: string
  tenantId?: string
  tenantSetting?: TenantSettingJson
}

export interface TenantSettingJson {
  autoReplacePlans?: boolean
  defaultTimeZone?: string
  globalBccEmail?: string
  isUpdateOrderStartDateEnabled?: boolean
  orderExpiryDurationInDays?: string
  percentDerivedFrom?: 'LIST_AMOUNT' | 'SELL_AMOUNT'
  signingOrder?: 'ACCOUNT_FIRST' | 'TENANT_FIRST' | 'ACCOUNT_ONLY'
  supportedCurrencies?: string[]
  tenantSettingSeal?: 'ON' | 'OFF'
}

export interface TenantUiCustomization {
  customizations?: Customization[]
  version: 'V1_TENANT_LEVEL'
}

export interface TimeSeriesAmountJson {
  amount?: number
  /** @format int64 */
  instant?: number
}

export interface TimeZone {
  displayName?: string
  /** @format int32 */
  dstsavings?: number
  id?: string
  /** @format int32 */
  rawOffset?: number
}

export interface TransactionalExchangeRate {
  /** @format int64 */
  effectiveDate?: number
  exchangeRate?: number
  exchangeSource?: 'QUODD' | 'SAME_CURRENCY' | 'USER' | 'TEST_RANDOM'
  fromCurrency?: string
  id?: string
  isOverridden?: boolean
  toCurrency?: string
  updatedBy?: string
}

export interface Type {
  typeName?: string
}

export interface UnitOfMeasureJson {
  description?: string
  /** @format uuid */
  id?: string
  name?: string
  status: 'DRAFT' | 'ACTIVE' | 'DEPRECATED'
}

export interface UnitOfMeasurePaginationResponseJson {
  data?: UnitOfMeasureJson[]
  /** @format uuid */
  nextCursor?: string
  /** @format int32 */
  numElements?: number
}

export interface UpdateCrmFieldMappingDetail {
  deleted?: boolean
  failed?: boolean
  failureReason?: string
  /** @format uuid */
  id?: string
  previousCrmFieldName?: string
  previousCrmObjectType?: 'OPPORTUNITY' | 'ACCOUNT' | 'ORDER' | 'ORDER_ITEM' | 'SUBSCRIPTION' | 'SUBSCRIPTION_ITEM'
  previousDirection?: 'INBOUND' | 'OUTBOUND'
  previousSubskribeFieldName?: string
  requestedCrmFieldName?: string
  requestedCrmObjectType?: 'OPPORTUNITY' | 'ACCOUNT' | 'ORDER' | 'ORDER_ITEM' | 'SUBSCRIPTION' | 'SUBSCRIPTION_ITEM'
  requestedDirection?: 'INBOUND' | 'OUTBOUND'
  requestedSubskribeFieldName?: string
  /** @format int64 */
  rowNumber?: number
}

export interface UpdateInvoiceRequest {
  billingContactId?: string
  /** @format int64 */
  dueDate?: number
  emailNotifiersList?: EmailNotifiersList
  /** @format int64 */
  invoiceDate: number
  /**
   * @minLength 0
   * @maxLength 1000
   */
  note?: string
  /**
   * @minLength 0
   * @maxLength 255
   */
  purchaseOrderNumber?: string
}

export interface UsageAggregateOutput {
  attributeReferences?: AttributeReference[]
  /** @format int64 */
  endAt?: number
  /** @format int32 */
  observedDataPoints?: number
  /** @format int64 */
  startAt?: number
  subscriptionChargeGroupId?: string
  subscriptionId?: string
  usageMax?: number
  usageMin?: number
  usageSum?: number
}

export interface UsageBatchInsertResult {
  entries?: Entry[]
  /** @format int32 */
  rawUsagesTotal?: number
  /** @format int32 */
  totalDuplicates?: number
  /** @format int32 */
  totalFailed?: number
}

export interface UserGroupJson {
  description?: string
  /** @uniqueItems true */
  entityIds?: string[]
  externalId?: string
  id?: string
  name?: string
  users?: string[]
}

export interface UserGroupRequestJson {
  description?: string
  /** @uniqueItems true */
  entityIds?: string[]
  externalId?: string
  id?: string
  name?: string
  users?: string[]
}

export interface UserInput {
  displayName?: string
  email?: string
  entityIds?: string[]
  externalId?: string
  id?: string
  phoneNumber?: string
  role?:
    | 'ADMIN'
    | 'FINANCE'
    | 'SALES'
    | 'SALES_MANAGER'
    | 'ACCOUNTANT'
    | 'BILLING_CLERK'
    | 'REVENUE_CLERK'
    | 'READ_ONLY'
    | 'EXECUTIVE'
    | 'CRM'
    | 'IMPORT'
    | 'BILLY_ADMIN'
    | 'BILLY_ENGINEER'
    | 'BILLY_JOB'
  ssoOnly?: boolean
  state?: 'ACTIVE' | 'DISABLED' | 'EXPIRED'
  title?: string
}

export interface UserJson {
  approvalSegments?: ApprovalSegmentJson[]
  availableEntities?: EntityRef[]
  cognitoUserStatus?:
    | 'UNCONFIRMED'
    | 'CONFIRMED'
    | 'ARCHIVED'
    | 'COMPROMISED'
    | 'UNKNOWN'
    | 'RESET_REQUIRED'
    | 'FORCE_CHANGE_PASSWORD'
    | 'EXTERNAL_PROVIDER'
    | 'UNKNOWN_TO_SDK_VERSION'
  displayName?: string
  email?: string
  entityIds?: string[]
  externalId?: string
  hasAllEntitiesAccess?: boolean
  id?: string
  phoneNumber?: string
  role?:
    | 'ADMIN'
    | 'FINANCE'
    | 'SALES'
    | 'SALES_MANAGER'
    | 'ACCOUNTANT'
    | 'BILLING_CLERK'
    | 'REVENUE_CLERK'
    | 'READ_ONLY'
    | 'EXECUTIVE'
    | 'CRM'
    | 'IMPORT'
    | 'BILLY_ADMIN'
    | 'BILLY_ENGINEER'
    | 'BILLY_JOB'
  ssoOnly?: boolean
  state?: 'ACTIVE' | 'DISABLED' | 'EXPIRED'
  tenantName?: string
  title?: string
  userGroups?: UserGroupJson[]
}

export interface UserPaginationResponseJson {
  data?: UserJson[]
  /** @format uuid */
  nextCursor?: string
  /** @format int32 */
  numElements?: number
}

export interface UserSsoUpdate {
  ssoOnly?: boolean
}

export interface VoidInvoiceRequest {
  invoiceBalance: number
  /** @format int64 */
  voidDate: number
}

export interface VoidPaymentJson {
  invoiceBalance: number
  invoiceNumber: string
  note?: string
  paymentId?: string
  /** @format int64 */
  voidDate: number
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || 'https://api.dev2.subskribe.net' })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    })
  }
}

import { useErrorHandler } from '@/components/ErrorHandler/ErrorHandler'
import { billyRestClient } from '@/components/data/billyRestClient'
import { useCallback, useState } from 'react'

/**
 * @title Subskribe API* @version 1.0.0* @baseUrl https://api.dev2.subskribe.net*/

// Original API Class
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  accountReceivableContact = {
    /**
     * @description Returns the details of the account receivable contact for your tenant
     *
     * @tags Billing
     * @name GetAccountReceivableContact
     * @summary Get the contact for accounts receivable
     * @request GET:/accountReceivableContact
     */
    getAccountReceivableContact: (params: RequestParams = {}) =>
      this.request<AccountReceivableContactJson, any>({
        path: `/accountReceivableContact`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Sets the details of the account receivable contact for your tenant
     *
     * @tags Billing
     * @name PutAccountReceivableContact
     * @summary Set the contact for accounts receivable
     * @request PUT:/accountReceivableContact
     */,
    putAccountReceivableContact: (
      accountReceivableContactJson: AccountReceivableContactJson,
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/accountReceivableContact`,
        method: 'PUT',
        body: accountReceivableContactJson,
        type: ContentType.Json,
        ...params,
      }),
  }
  accounts = {
    /**
     * @description Create an account with the specified parameters. On success, the id of the newly created account is returned
     *
     * @tags Accounts
     * @name AddAccount
     * @summary Add a new account
     * @request POST:/accounts
     */
    addAccount: (body: AccountJson, params: RequestParams = {}) =>
      this.request<AccountJson, any>({
        path: `/accounts`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Creates and adds a new contact for the specified account and returns the new contact ID.
     *
     * @tags Accounts
     * @name AddAccountContact
     * @summary Add a contact for an account
     * @request POST:/accounts/{accountId}/contacts
     */,
    addAccountContact: (
      accountId: string,
      body: AccountContactJson,
      query?: {
        /** value = perform basic address validation */
        skipAddressValidation?: boolean
        /** value = require the address to match a canonical address, if it exists */
        strictValidation?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<AccountContactJson, any>({
        path: `/accounts/${accountId}/contacts`,
        method: 'POST',
        query: query,
        body: body,
        format: 'json',
        ...params,
      }) /**
     * @description Update ERP details for an account specified by the account id
     *
     * @tags Accounts
     * @name AddErpDetails
     * @summary Update account ERP details
     * @request PUT:/accounts/{accountId}/erp
     */,
    addErpDetails: (
      accountId: string,
      body: ErpInputJson,
      query?: {
        override?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/accounts/${accountId}/erp`,
        method: 'PUT',
        query: query,
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Deletes the account associated with the passed ID
     *
     * @tags Accounts
     * @name DeleteAccount
     * @summary Delete an account
     * @request DELETE:/accounts/{id}
     */,
    deleteAccount: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/accounts/${id}`,
        method: 'DELETE',
        ...params,
      }) /**
     * @description Deletes the contact specified by the account id and contact id
     *
     * @tags Accounts
     * @name DeleteAccountContact
     * @summary Delete a contact
     * @request DELETE:/accounts/{accountId}/contacts/{contactId}
     */,
    deleteAccountContact: (contactId: string, accountId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/accounts/${accountId}/contacts/${contactId}`,
        method: 'DELETE',
        ...params,
      }) /**
     * @description Retrieves detailed information about a specific account using its unique identifier. It could be Account_ID, CRM_ID or External_ID. This endpoint provides comprehensive data for a particular account, enabling users to access full account details.
     *
     * @tags Accounts
     * @name GetAccount
     * @summary Get an account by id
     * @request GET:/accounts/{id}
     */,
    getAccount: (
      id: string,
      query?: {
        /** Specifies the type of ID being used. Allowed values are account_id: The default account ID. crm_id: The CRM (Customer Relationship Management) ID external_id: An external system's ID for the account. Default is account_id */
        idType?: 'ACCOUNT_ID' | 'CRM_ID' | 'EXTERNAL_ID'
      },
      params: RequestParams = {}
    ) =>
      this.request<AccountJson, any>({
        path: `/accounts/${id}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Returns the details of the specified contact
     *
     * @tags Accounts
     * @name GetAccountContact
     * @summary Gets contact details
     * @request GET:/accounts/{accountId}/contacts/{contactId}
     */,
    getAccountContact: (accountId: string, contactId: string, params: RequestParams = {}) =>
      this.request<AccountContactJson, any>({
        path: `/accounts/${accountId}/contacts/${contactId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns a list of contacts associated with the specified account id
     *
     * @tags Accounts
     * @name GetAccountContacts
     * @summary Get contacts for an account
     * @request GET:/accounts/{accountId}/contacts
     */,
    getAccountContacts: (
      accountId: string,
      query?: {
        /** When set to true, expands the response to include additional details about each contact, such as address, external id, erp id, fullName. Default is false. */
        expand?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<AccountContactJson[], any>({
        path: `/accounts/${accountId}/contacts`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Fetches metrics such as ARR, TCV, etc for the specified account as of the specified target date
     *
     * @tags Accounts
     * @name GetAccountMetrics
     * @summary Returns metrics for the specified account
     * @request GET:/accounts/{id}/metrics
     */,
    getAccountMetrics: (
      id: string,
      query?: {
        forceRecalculate?: boolean
        /** @format int64 */
        targetDate?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MetricsJson, any>({
        path: `/accounts/${id}/metrics`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Returns a paginated list of accounts
     *
     * @tags Accounts
     * @name GetAccounts
     * @summary Get all accounts
     * @request GET:/accounts
     */,
    getAccounts: (
      query?: {
        /**
         * A string token is used to fetch next set of results. If not provided, the first page of results will be returned. Use the 'next_cursor' value from the previous response to fetch the next page.
         * @format uuid
         */
        cursor?: string
        /**
         * An integer specifying the maximum number of results to return per page. Defaults to 10 if not provided.
         * @format int32
         */
        limit?: number
        /** The type of accounts to retrieve. Allowed values are:  ALL: Includes all account types. RESELLER: Includes only reseller accounts. NON_RESELLER: Includes only non-reseller accounts. */
        type?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedAccountsResponse, any>({
        path: `/accounts`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Ensures an account exists which matches the passed details.If an account exists that has a matching CRM Id, it will be updated, if not, it will be created
     *
     * @tags Accounts
     * @name ImportCrmAccount
     * @summary Import an account from a CRM
     * @request POST:/accounts/crm/import
     */,
    importCrmAccount: (body: AccountJson, params: RequestParams = {}) =>
      this.request<CrmAccountImportResponse, any>({
        path: `/accounts/crm/import`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Updates an existing account with the specified parameters
     *
     * @tags Accounts
     * @name UpdateAccount
     * @summary Update an account
     * @request PUT:/accounts/{id}
     */,
    updateAccount: (id: string, body: AccountJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/accounts/${id}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Updates the contact specified by the account id and contact id with the passed information
     *
     * @tags Accounts
     * @name UpdateAccountContact
     * @summary Update a contact
     * @request PUT:/accounts/{accountId}/contacts/{contactId}
     */,
    updateAccountContact: (
      accountId: string,
      contactId: string,
      body: AccountContactJson,
      query?: {
        skipAddressValidation?: boolean
        strictValidation?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/accounts/${accountId}/contacts/${contactId}`,
        method: 'PUT',
        query: query,
        body: body,
        ...params,
      }),
  }
  accountingPeriods = {
    /**
     * No description
     *
     * @tags Accounting
     * @name GetCurrentAccountingPeriod
     * @summary Get the current accounting period
     * @request GET:/accountingPeriods/current
     */
    getCurrentAccountingPeriod: (params: RequestParams = {}) =>
      this.request<AccountingPeriod, any>({
        path: `/accountingPeriods/current`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Specify the start date of and open an accounting period, making it current
     *
     * @tags Accounting
     * @name SpecifyCurrentAccountingPeriod
     * @summary Specify the current accounting period
     * @request POST:/accountingPeriods
     */,
    specifyCurrentAccountingPeriod: (startDate: number, params: RequestParams = {}) =>
      this.request<AccountingPeriod, any>({
        path: `/accountingPeriods`,
        method: 'POST',
        body: startDate,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  ai = {
    /**
     * @description The message will be returned in the most recent order, with the latest being the first
     *
     * @tags AI Agent, Experimental
     * @name ChatMessages
     * @summary List the messages belong to this session
     * @request GET:/ai/agents/session/{sessionId}/messages
     */
    chatMessages: (
      sessionId: string,
      query?: {
        /**
         * the number of message to fetch should be a number between 1 to 100 if present
         * @format int32
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<Message[], any>({
        path: `/ai/agents/session/${sessionId}/messages`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description The API responds user message, a session id is required to identify the session this message needs to be posted
     *
     * @tags AI Agent, Experimental
     * @name ChatResponse
     * @summary Chat with a given session id and get back a response for a given message
     * @request PUT:/ai/agents/session/{sessionId}/chat
     */,
    chatResponse: (sessionId: string, body: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/ai/agents/session/${sessionId}/chat`,
        method: 'PUT',
        body: body,
        type: ContentType.Text,
        format: 'json',
        ...params,
      }) /**
     * @description The API responds to user message, a session id is required to identify the session this message needs to be posted
     *
     * @tags AI Agent, Experimental
     * @name ChatResponseAsync
     * @summary Chat with a given session id and get back a response for a given message in a async manner in the form of server side events
     * @request GET:/ai/agents/session/{sessionId}/chatAsync
     */,
    chatResponseAsync: (
      sessionId: string,
      query?: {
        /** the user message to which the AI can respond */
        userMessage?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<OutboundEvent, any>({
        path: `/ai/agents/session/${sessionId}/chatAsync`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Create a new conversation session with Subskribe AI agent, this resource will return a session id which will be used for future conversations
     *
     * @tags AI Agent, Experimental
     * @name CreateAgentSession
     * @summary Create new conversational AI agent session
     * @request POST:/ai/agents/session
     */,
    createAgentSession: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/ai/agents/session`,
        method: 'POST',
        ...params,
      }) /**
     * @description Generate a complete summary of the order with the given id, a full detailed summary will be generated including metrics
     *
     * @tags AI Summary, Experimental
     * @name GenerateOrderSummary
     * @summary Generate a summary of the order in plain english in markdown format
     * @request GET:/ai/summary/order/{orderId}
     */,
    generateOrderSummary: (
      orderId: string,
      query?: {
        /** Force regeneration of the PDF document even if there has been no changes. Defaults to false. */
        type?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<string, any>({
        path: `/ai/summary/order/${orderId}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Generate a complete summary of the subscription with the given id, a full detailed summary will be generated including metrics
     *
     * @tags AI Agent, Experimental
     * @name GenerateSubscriptionSummary
     * @summary Generate a summary of the subscription in plain english in markdown format
     * @request GET:/ai/summary/subscription/{subscriptionId}
     */,
    generateSubscriptionSummary: (subscriptionId: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/ai/summary/subscription/${subscriptionId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Get the agent session given the id, NOTE: at the moment there is no response body, if 200 is returned then session exists
     *
     * @tags AI Agent, Experimental
     * @name GetAgentSession
     * @summary Get the agent session given the id, NOTE: at the moment there is no response body
     * @request GET:/ai/agents/session/{sessionId}
     */,
    getAgentSession: (sessionId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/ai/agents/session/${sessionId}`,
        method: 'GET',
        ...params,
      }),
  }
  alias = {
    /**
     * @description This allows you to specify a string alias for a subscription id and a charge id. This can be useful when, for example, you want to upload usage statistics and would rather specify your own id (or another external id), rather than referring to Subskribe's internal ids.
     *
     * @tags Billing
     * @name AddSubscriptionChargeAlias
     * @summary Create an alias for a subscription id/charge id pair
     * @request PUT:/alias/subscriptionCharge/{aliasId}
     */
    addSubscriptionChargeAlias: (
      aliasId: string,
      query?: {
        /** the charge id */
        chargeId?: string
        /** the subscription id */
        subscriptionId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/alias/subscriptionCharge/${aliasId}`,
        method: 'PUT',
        query: query,
        ...params,
      }) /**
     * @description Deletes the specified alias mapping
     *
     * @tags Billing
     * @name DeleteSubscriptionChargeAlias
     * @summary Delete the specified alias
     * @request DELETE:/alias/subscriptionCharge/{aliasId}
     */,
    deleteSubscriptionChargeAlias: (aliasId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/alias/subscriptionCharge/${aliasId}`,
        method: 'DELETE',
        ...params,
      }) /**
     * @description Returns the details of the specified alias id including the subscription id and the charge id it is mapped to.
     *
     * @tags Billing
     * @name GetSubscriptionChargeAlias
     * @summary Get the details of an alias
     * @request GET:/alias/subscriptionCharge/{aliasId}
     */,
    getSubscriptionChargeAlias: (aliasId: string, params: RequestParams = {}) =>
      this.request<SubscriptionChargeAlias, any>({
        path: `/alias/subscriptionCharge/${aliasId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns all aliases for the specified subscription id.
     *
     * @tags Billing
     * @name ListAliasesForSubscription
     * @summary Get aliases for a subscription
     * @request GET:/alias/subscriptionCharge
     */,
    listAliasesForSubscription: (
      query?: {
        /** id of the subscription */
        subscriptionId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<SubscriptionChargeAlias[], any>({
        path: `/alias/subscriptionCharge`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  anrok = {
    /**
     * @description Returns the integration ID if successful
     *
     * @tags Integrations
     * @name AddIntegration
     * @summary Add a Anrok integration
     * @request POST:/anrok
     */
    addIntegration: (body: AnrokIntegrationInput, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/anrok`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Gets the integration details of the specified integration id
     *
     * @tags Integrations
     * @name GetIntegration
     * @summary Get integration details
     * @request GET:/anrok/{integrationId}
     */,
    getIntegration: (integrationId: string, params: RequestParams = {}) =>
      this.request<Integration, any>({
        path: `/anrok/${integrationId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * No description
     *
     * @tags Integrations
     * @name TestIntegration
     * @summary Test an integration is valid
     * @request PUT:/anrok/test
     */,
    testIntegration: (body: AnrokIntegrationInput, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/anrok/test`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * No description
     *
     * @tags Integrations
     * @name ValidateAddress
     * @summary Validate an address with Anrok
     * @request POST:/anrok/validate
     */,
    validateAddress: (body: AccountAddress, params: RequestParams = {}) =>
      this.request<AccountAddress, any>({
        path: `/anrok/validate`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  apikey = {
    /**
     * @description Create a new api key with the specified parameters. The new key is returned
     *
     * @tags Authentication
     * @name CreateApiKey
     * @summary Create a new api key
     * @request POST:/apikey
     */
    createApiKey: (
      query?: {
        /** Scope of entities that the key has access to. Either specify a single entity or all entities (i.e. *). */
        entityId?: string
        /**
         * Time of expiry in seconds since Epoch (GMT)
         * @format int64
         */
        expiry?: number
        /** Role-based permissions to be associated with the key. Specify this OR userId. */
        role?:
          | 'ADMIN'
          | 'FINANCE'
          | 'SALES'
          | 'DEAL_DESK'
          | 'BILLING_CLERK'
          | 'REVENUE_CLERK'
          | 'READ_ONLY'
          | 'EXECUTIVE'
          | 'CRM'
          | 'IMPORT'
        /** User to associate key with. Specify this OR role. */
        userId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<string, any>({
        path: `/apikey`,
        method: 'POST',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Retrieves a list of (maximum 500 items) all API keys (including expired and deactivated keys)
     *
     * @tags Authentication
     * @name GetAllApiKeys
     * @summary Retrieves all API keys
     * @request GET:/apikey
     */,
    getAllApiKeys: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/apikey`,
        method: 'GET',
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Retrieve an api key referenced by its id
     *
     * @tags Authentication
     * @name GetApiKeyById
     * @summary Retrieve an API key by id
     * @request GET:/apikey/{id}
     */,
    getApiKeyById: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/apikey/${id}`,
        method: 'GET',
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Revokes all keys for your tenant
     *
     * @tags Authentication
     * @name RevokeApiKey
     * @summary revoke all keys
     * @request DELETE:/apikey/revoke
     */,
    revokeApiKey: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/apikey/revoke`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Revoke an api key referenced by its id
     *
     * @tags Authentication
     * @name RevokeApiKeyById
     * @summary revoke an API key by id
     * @request DELETE:/apikey/revoke/{id}
     */,
    revokeApiKeyById: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/apikey/revoke/${id}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),
  }
  approvalFlowNotifications = {
    /**
     * No description
     *
     * @tags Approvals
     * @name FetchNotification
     * @summary make ngrok happy
     * @request GET:/approvalFlowNotifications
     */
    fetchNotification: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/approvalFlowNotifications`,
        method: 'GET',
        ...params,
      }) /**
     * No description
     *
     * @tags Approvals
     * @name ReceiveApprovalFlowNotification
     * @summary Receive approval flow notification
     * @request POST:/approvalFlowNotifications
     */,
    receiveApprovalFlowNotification: (body: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/approvalFlowNotifications`,
        method: 'POST',
        body: body,
        ...params,
      }),
  }
  approvalFlows = {
    /**
     * @description Adds an approval flow to the order execution hierarchy and returns its ID
     *
     * @tags Approvals
     * @name AddApprovalFlow
     * @summary Add an approval flow
     * @request POST:/approvalFlows
     */
    addApprovalFlow: (body: ApprovalFlowJson, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/approvalFlows`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Deletes an approval flow
     *
     * @tags Approvals
     * @name DeleteApprovalFlow
     * @summary Delete an approval flow
     * @request DELETE:/approvalFlows/{approvalFlowId}
     */,
    deleteApprovalFlow: (approvalFlowId: string, params: RequestParams = {}) =>
      this.request<ApprovalFlowJson, any>({
        path: `/approvalFlows/${approvalFlowId}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }) /**
     * @description Returns the details of the specified approval flow
     *
     * @tags Approvals
     * @name GetApprovalFlowById
     * @summary Get Details of an approval flow
     * @request GET:/approvalFlows/{approvalFlowId}
     */,
    getApprovalFlowById: (approvalFlowId: string, params: RequestParams = {}) =>
      this.request<ApprovalFlowJson, any>({
        path: `/approvalFlows/${approvalFlowId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Gets all approval flows
     *
     * @tags Approvals
     * @name GetApprovalFlows
     * @summary Get approval flows
     * @request GET:/approvalFlows
     */,
    getApprovalFlows: (params: RequestParams = {}) =>
      this.request<ApprovalFlowJson[], any>({
        path: `/approvalFlows`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Updates the details of the specified approval flow
     *
     * @tags Approvals
     * @name UpdateApprovalFlow
     * @summary Update an approval flow
     * @request PUT:/approvalFlows/{approvalFlowId}
     */,
    updateApprovalFlow: (approvalFlowId: string, body: ApprovalFlowJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/approvalFlows/${approvalFlowId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  }
  approvalMatrix = {
    /**
     * @description Returns details regarding the approval matrices that have been uploaded
     *
     * @tags Approvals
     * @name GetAllImportDetails
     * @summary Get approval matrix details
     * @request GET:/approvalMatrix/csv
     */
    getAllImportDetails: (params: RequestParams = {}) =>
      this.request<ApprovalMatrixImportDataJson[], any>({
        path: `/approvalMatrix/csv`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Downloads your order approval matrix as a csv
     *
     * @tags Approvals
     * @name GetApprovalMatrixAsCsv
     * @summary Download approval matrix
     * @request GET:/approvalMatrix/csv/download
     */,
    getApprovalMatrixAsCsv: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/approvalMatrix/csv/download`,
        method: 'GET',
        ...params,
      }) /**
     * @description Gets the details of an import specified by the passed ID
     *
     * @tags Approvals
     * @name GetImportDetailsById
     * @summary Get import details
     * @request GET:/approvalMatrix/csv/{importId}
     */,
    getImportDetailsById: (importId: string, params: RequestParams = {}) =>
      this.request<ApprovalMatrixImportDataJson, any>({
        path: `/approvalMatrix/csv/${importId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Preview the changes that a specified approval matrix import will have once applied
     *
     * @tags Approvals
     * @name GetImportPreview
     * @summary Preview import changes
     * @request GET:/approvalMatrix/csv/{importId}/preview
     */,
    getImportPreview: (importId: string, params: RequestParams = {}) =>
      this.request<ApprovalMatrixImportPreview, any>({
        path: `/approvalMatrix/csv/${importId}/preview`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Downloads the CSV for an import activity specified by the passed ID
     *
     * @tags Approvals
     * @name GetImportResult
     * @summary Download the csv for an import
     * @request GET:/approvalMatrix/csv/{importId}/download
     */,
    getImportResult: (importId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/approvalMatrix/csv/${importId}/download`,
        method: 'GET',
        ...params,
      }) /**
     * @description Submit and finalize the import for the specified import operation.
     *
     * @tags Approvals
     * @name SubmitApprovalMatrixCsv
     * @summary Finalize an import
     * @request POST:/approvalMatrix/csv/{importId}/submit
     */,
    submitApprovalMatrixCsv: (importId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/approvalMatrix/csv/${importId}/submit`,
        method: 'POST',
        ...params,
      }) /**
     * @description Upload a csv containing the order approval matrix to be used on order execution. The csv should have the following format: Segment, ApprovalRoleName1, ApprovalRoleName2 ... SegmentName1, UserEmailOrGroupName1, UserEmailOrGroupName2 ... Roles, userEmails, userGroups are expected to be added before putting them in this csv. Any new segment names defined here will add a new segment. If a segment isn't added, it shall be deleted.
     *
     * @tags Approvals
     * @name UploadApprovalMatrixCsv
     * @summary Upload an Approval Matrix
     * @request POST:/approvalMatrix/csv
     */,
    uploadApprovalMatrixCsv: (data: any, params: RequestParams = {}) =>
      this.request<ApprovalMatrixImportDataJson, any>({
        path: `/approvalMatrix/csv`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  }
  approvalRoles = {
    /**
     * @description Adds an approval role to the order execution hierarchy and returns its ID
     *
     * @tags Approvals
     * @name AddApprovalRole
     * @summary Add an approval role
     * @request POST:/approvalRoles
     */
    addApprovalRole: (body: ApprovalRoleJson, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/approvalRoles`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Deletes an approval role
     *
     * @tags Approvals
     * @name DeleteApprovalRole
     * @summary Delete an approval role
     * @request DELETE:/approvalRoles/{approvalRoleId}
     */,
    deleteApprovalRole: (approvalRoleId: string, params: RequestParams = {}) =>
      this.request<ApprovalRoleJson, any>({
        path: `/approvalRoles/${approvalRoleId}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }) /**
     * @description Returns the details of the specified approval role
     *
     * @tags Approvals
     * @name GetApprovalRoleById
     * @summary Get Details of an approval role
     * @request GET:/approvalRoles/{approvalRoleId}
     */,
    getApprovalRoleById: (approvalRoleId: string, params: RequestParams = {}) =>
      this.request<ApprovalRoleJson, any>({
        path: `/approvalRoles/${approvalRoleId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Gets all approval roles
     *
     * @tags Approvals
     * @name GetApprovalRoles
     * @summary Get approval roles
     * @request GET:/approvalRoles
     */,
    getApprovalRoles: (params: RequestParams = {}) =>
      this.request<ApprovalRoleJson[], any>({
        path: `/approvalRoles`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Updates the details of the specified approval role
     *
     * @tags Approvals
     * @name UpdateApprovalRole
     * @summary Update an approval role
     * @request PUT:/approvalRoles/{approvalRoleId}
     */,
    updateApprovalRole: (approvalRoleId: string, body: ApprovalRoleJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/approvalRoles/${approvalRoleId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  }
  approvalSegments = {
    /**
     * @description Define and add an approval segment which can be later specified in an approval matrix. The ID of the segment is returned.
     *
     * @tags Approvals
     * @name AddApprovalSegment
     * @summary Add an approval segment
     * @request POST:/approvalSegments
     */
    addApprovalSegment: (body: ApprovalSegmentJson, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/approvalSegments`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Deletes the segment specified by the id
     *
     * @tags Approvals
     * @name DeleteApprovalSegment
     * @summary Delete a segment
     * @request DELETE:/approvalSegments/{approvalSegmentId}
     */,
    deleteApprovalSegment: (approvalSegmentId: string, params: RequestParams = {}) =>
      this.request<ApprovalSegmentJson, any>({
        path: `/approvalSegments/${approvalSegmentId}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }) /**
     * @description Gets the details of the specified approval segment
     *
     * @tags Approvals
     * @name GetApprovalSegmentById
     * @summary Get approval segment details
     * @request GET:/approvalSegments/{approvalSegmentId}
     */,
    getApprovalSegmentById: (approvalSegmentId: string, params: RequestParams = {}) =>
      this.request<ApprovalSegmentJson, any>({
        path: `/approvalSegments/${approvalSegmentId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Get all approval segments
     *
     * @tags Approvals
     * @name GetApprovalSegments
     * @summary Get approval segments
     * @request GET:/approvalSegments
     */,
    getApprovalSegments: (params: RequestParams = {}) =>
      this.request<ApprovalSegmentJson[], any>({
        path: `/approvalSegments`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Update the details of the specified approval segment
     *
     * @tags Approvals
     * @name UpdateApprovalSegment
     * @summary Update an approval segment
     * @request PUT:/approvalSegments/{approvalSegmentId}
     */,
    updateApprovalSegment: (approvalSegmentId: string, body: ApprovalSegmentJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/approvalSegments/${approvalSegmentId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  }
  attachments = {
    /**
     * @description Attaches a document to the specified account. The post body should contain the body of the document that is to be attached. On success the Id of the attachment is returned.
     *
     * @tags Attachments
     * @name AddAttachmentToAccount
     * @summary Add an attachment
     * @request POST:/attachments/account/{accountId}
     */
    addAttachmentToAccount: (
      accountId: string,
      data: {
        file?: File
      },
      query?: {
        /** description of the document */
        description?: string
        /** name to associate with the attachment */
        fileName?: string
        /**
         * size in bytes of the document
         * @format int64
         */
        size?: number
        /** tag to apply to the attachment */
        tag?: 'MASTER_SUBSCRIPTION_AGREEMENT' | 'STATEMENT_OF_WORK' | 'ORDER_FORM' | 'OTHER'
      },
      params: RequestParams = {}
    ) =>
      this.request<Attachment, any>({
        path: `/attachments/account/${accountId}`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }) /**
     * @description Unattaches and deletes the specified document
     *
     * @tags Attachments
     * @name DeleteAttachmentFromAccount
     * @summary Delete an attachment
     * @request DELETE:/attachments/{attachmentId}
     */,
    deleteAttachmentFromAccount: (attachmentId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/attachments/${attachmentId}`,
        method: 'DELETE',
        ...params,
      }) /**
     * @description Gets the contents of the specified attachment
     *
     * @tags Attachments
     * @name GetAttachment
     * @summary Get attachment contents
     * @request GET:/attachments/{attachmentId}
     */,
    getAttachment: (attachmentId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/attachments/${attachmentId}`,
        method: 'GET',
        ...params,
      }) /**
     * @description Lists all the documents attached to an account
     *
     * @tags Attachments
     * @name ListAccountAttachments
     * @summary Get attachments for an account
     * @request GET:/attachments/account/{accountId}
     */,
    listAccountAttachments: (accountId: string, params: RequestParams = {}) =>
      this.request<Attachment[], any>({
        path: `/attachments/account/${accountId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Updates the details of the specified document
     *
     * @tags Attachments
     * @name ModifyAttachment
     * @summary Update the details of an attachment
     * @request PUT:/attachments
     */,
    modifyAttachment: (body: Attachment, params: RequestParams = {}) =>
      this.request<Attachment, any>({
        path: `/attachments`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  auth = {
    /**
     * @description Adds a saml integration
     *
     * @tags Authentication
     * @name AddSamlIntegration
     * @summary Add a saml integration
     * @request POST:/auth/saml
     */
    addSamlIntegration: (body: AuthSamlIntegrationJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/auth/saml`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  }
  automatedInvoiceRules = {
    /**
     * @description Creates an automated invoice rule with the specified parameters
     *
     * @tags Billing
     * @name AddAutomatedInvoiceRule
     * @summary Create an automated invoice rule
     * @request POST:/automatedInvoiceRules
     */
    addAutomatedInvoiceRule: (body: AutomatedInvoiceRuleRequestJson, params: RequestParams = {}) =>
      this.request<AutomatedInvoiceRule, any>({
        path: `/automatedInvoiceRules`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Returns the details of the specified automated invoice rule
     *
     * @tags Billing
     * @name GetAutomatedInvoiceRule
     * @summary Get automated invoice rule details
     * @request GET:/automatedInvoiceRules/{id}
     */,
    getAutomatedInvoiceRule: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/automatedInvoiceRules/${id}`,
        method: 'GET',
        ...params,
      }) /**
     * No description
     *
     * @name GetAutomatedInvoiceRuleByInternalId
     * @request GET:/automatedInvoiceRules/internal/{id}
     * @deprecated
     */,
    getAutomatedInvoiceRuleByInternalId: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/automatedInvoiceRules/internal/${id}`,
        method: 'GET',
        ...params,
      }) /**
     * @description Returns all the configured automated invoice rules for the tenant
     *
     * @tags Billing
     * @name GetAutomatedInvoiceRules
     * @summary Get automated invoice rules for a tenant
     * @request GET:/automatedInvoiceRules
     */,
    getAutomatedInvoiceRules: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/automatedInvoiceRules`,
        method: 'GET',
        ...params,
      }) /**
     * @description Updates the details of the specified automated invoice rule
     *
     * @tags Billing
     * @name UpdateAutomatedInvoiceRule
     * @summary Update automated invoice rule details
     * @request PUT:/automatedInvoiceRules/{id}
     */,
    updateAutomatedInvoiceRule: (id: string, body: AutomatedInvoiceRuleRequestJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/automatedInvoiceRules/${id}`,
        method: 'PUT',
        body: body,
        ...params,
      }) /**
     * No description
     *
     * @name UpdateAutomatedInvoiceRuleUsingInternalId
     * @request PUT:/automatedInvoiceRules/internal/{id}
     * @deprecated
     */,
    updateAutomatedInvoiceRuleUsingInternalId: (
      id: string,
      body: AutomatedInvoiceRuleRequestJson,
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/automatedInvoiceRules/internal/${id}`,
        method: 'PUT',
        body: body,
        ...params,
      }),
  }
  avalara = {
    /**
     * @description Gets the integration details of the specified integration id
     *
     * @tags Integrations
     * @name GetIntegration1
     * @summary Gets Avalara integration details
     * @request GET:/avalara/{integrationId}
     */
    getIntegration1: (integrationId: string, params: RequestParams = {}) =>
      this.request<AvalaraIntegration, any>({
        path: `/avalara/${integrationId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns the details of the Avalara integration for your tenant
     *
     * @tags Integrations
     * @name GetIntegrationByTenant
     * @summary Gets Avalara integration details
     * @request GET:/avalara
     */,
    getIntegrationByTenant: (params: RequestParams = {}) =>
      this.request<AvalaraIntegration, any>({
        path: `/avalara`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Creates an integration with Avalara according to the specified details. On success the id of the integration is returned
     *
     * @tags Integrations
     * @name Integrate
     * @summary Create an Avalara integration
     * @request POST:/avalara
     */,
    integrate: (body: AvalaraIntegrationInput, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/avalara`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Once an integration is set up Avalara can be pinged to ensure it is working correctly. On success "PONG" is returned.
     *
     * @tags Integrations
     * @name Ping
     * @summary Pings Avalara
     * @request PUT:/avalara/ping
     */,
    ping: (body: AvalaraIntegrationInput, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/avalara/ping`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  }
  bankTransactions = {
    /**
     * @description Import bank transactions via CSV
     *
     * @tags BankTransactions
     * @name BankTransactionsCsv
     * @summary Import bank transactions via CSV
     * @request POST:/bankTransactions/csv
     */
    bankTransactionsCsv: (data: any, params: RequestParams = {}) =>
      this.request<BankTransactionsUploadResult, any>({
        path: `/bankTransactions/csv`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }) /**
     * @description Match bank transactions
     *
     * @tags Usage
     * @name BankTransactionsMatch
     * @summary Match bank transactions
     * @request POST:/bankTransactions/match
     */,
    bankTransactionsMatch: (
      query: {
        bankTransactionIDs?: string[]
        /** ID of the invoice */
        invoiceID: string
      },
      params: RequestParams = {}
    ) =>
      this.request<InvoiceBankTransactionMatchResponse, any>({
        path: `/bankTransactions/match`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Delete given bank transaction record
     *
     * @tags BankTransactions
     * @name DeleteBankTransaction
     * @summary Delete given bank transaction record
     * @request DELETE:/bankTransactions/{id}
     */,
    deleteBankTransaction: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/bankTransactions/${id}`,
        method: 'DELETE',
        ...params,
      }) /**
     * @description Returns all bank transaction records in a paginated fashion
     *
     * @tags BankTransactions
     * @name GetBankTransactionPotentialInvoices
     * @summary Get paginated bank transaction records
     * @request GET:/bankTransactions/potentialInvoices
     */,
    getBankTransactionPotentialInvoices: (
      query?: {
        /** optionally pass in bank account Id, only bank transactions for this bank account will be returned */
        bankAccountId?: string
        /**
         * number of items per page
         * @format int32
         */
        limit?: number
        /** pass this to subsequent calls */
        pageToken?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedBankTransactionPotentialInvoiceResponse, any>({
        path: `/bankTransactions/potentialInvoices`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  charges = {
    /**
     * @description Backfill API to update revenue recognition fields on charges
     *
     * @tags Product Catalog
     * @name BackfillRevRecFields
     * @summary Backfill revenue recognition fields
     * @request POST:/charges/revrec/csv
     */
    backfillRevRecFields: (
      data: {
        file?: File
      },
      query?: {
        isDryRun?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/charges/revrec/csv`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.FormData,
        ...params,
      }) /**
     * @description Gets the details of the specified charge.
     *
     * @tags Product Catalog
     * @name GetCharge
     * @summary Get charge details
     * @request GET:/charges/{chargeId}
     */,
    getCharge: (chargeId: string, params: RequestParams = {}) =>
      this.request<ChargeJson, any>({
        path: `/charges/${chargeId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Downloads your charge revenue recognition fields as a csv
     *
     * @tags Product Catalog
     * @name GetRevRecFieldsAsCsv
     * @summary Download revenue recognition fields
     * @request GET:/charges/revrec/csv/download
     */,
    getRevRecFieldsAsCsv: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/charges/revrec/csv/download`,
        method: 'GET',
        ...params,
      }),
  }
  compositeOrders = {
    /**
     * @description Generates a composite order form PDF. When completed this document can be downloaded via a get to /{id}/pdf.
     *
     * @tags Orders
     * @name CreateCompositeOrderDocument
     * @summary Generate a composite order PDF
     * @request POST:/compositeOrders/{id}/pdf
     */
    createCompositeOrderDocument: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/compositeOrders/${id}/pdf`,
        method: 'POST',
        ...params,
      }) /**
     * @description Downloads the order form PDF for the specified order. This PDF must have been generated via a POST to /{id}/pdf. The response is the PDF document.
     *
     * @tags Orders
     * @name GetCompositeOrderDocument
     * @summary Fetch composite order form PDF
     * @request GET:/compositeOrders/{id}/pdf
     */,
    getCompositeOrderDocument: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/compositeOrders/${id}/pdf`,
        method: 'GET',
        ...params,
      }),
  }
  creditmemos = {
    /**
     * @description Converts the specified invoice to a credit memo. Note the invoice must be in DRAFT status and have a negative balance.
     *
     * @tags Credit Memo
     * @name ConvertNegativeDraftInvoice
     * @summary Convert invoice to credit memo
     * @request PUT:/creditmemos/convert/{invoiceNumber}
     */
    convertNegativeDraftInvoice: (invoiceNumber: string, params: RequestParams = {}) =>
      this.request<CreditMemoJson, any>({
        path: `/creditmemos/convert/${invoiceNumber}`,
        method: 'PUT',
        format: 'json',
        ...params,
      }) /**
     * @description Creates a pdf version of the credit memo
     *
     * @tags Credit Memo
     * @name CreateCreditMemoDocument
     * @summary Create a credit memo document
     * @request POST:/creditmemos/{creditMemoNumber}/pdf
     */,
    createCreditMemoDocument: (creditMemoNumber: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/creditmemos/${creditMemoNumber}/pdf`,
        method: 'POST',
        ...params,
      }) /**
     * @description Creates a standalone credit memo for a specified account. On success the number of the new memo is returned
     *
     * @tags Credit Memo
     * @name CreateStandaloneCreditMemo
     * @summary Create a standalone credit memo
     * @request POST:/creditmemos
     */,
    createStandaloneCreditMemo: (body: StandaloneCreditMemoRequest, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/creditmemos`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Deletes the specified credit memo. The credit memo must be in DRAFT status
     *
     * @tags Credit Memo
     * @name DeleteCreditMemo
     * @summary Delete a credit memo
     * @request DELETE:/creditmemos/{creditMemoNumber}
     */,
    deleteCreditMemo: (creditMemoNumber: string, params: RequestParams = {}) =>
      this.request<CreditMemoJson, any>({
        path: `/creditmemos/${creditMemoNumber}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }) /**
     * @description Gets the balance of the specified credit memo
     *
     * @tags Credit Memo
     * @name GetCreditBalance
     * @summary Get credit memo balance
     * @request GET:/creditmemos/{creditMemoNumber}/balance
     */,
    getCreditBalance: (creditMemoNumber: string, params: RequestParams = {}) =>
      this.request<CreditMemoBalanceJson, any>({
        path: `/creditmemos/${creditMemoNumber}/balance`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns the details of the specified credit memo
     *
     * @tags Credit Memo
     * @name GetCreditMemo
     * @summary Get credit memo details
     * @request GET:/creditmemos/{creditMemoNumber}
     */,
    getCreditMemo: (creditMemoNumber: string, params: RequestParams = {}) =>
      this.request<CreditMemoJson, any>({
        path: `/creditmemos/${creditMemoNumber}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns the credit memo configuration for your tenant
     *
     * @tags Credit Memo
     * @name GetCreditMemoConfiguration
     * @summary Get credit memo configuration for the tenant
     * @request GET:/creditmemos/configuration
     */,
    getCreditMemoConfiguration: (params: RequestParams = {}) =>
      this.request<TenantCreditMemoConfigurationJson, any>({
        path: `/creditmemos/configuration`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Downloads the pdf version of the credit memo. Note the credit memo must already have been created. If the credit memo is still in the process of being created, HTTP 202 is returned.
     *
     * @tags Credit Memo
     * @name GetCreditMemoDocumentPdf
     * @summary Download credit memo pdf
     * @request GET:/creditmemos/{creditMemoNumber}/pdf
     */,
    getCreditMemoDocumentPdf: (creditMemoNumber: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/creditmemos/${creditMemoNumber}/pdf`,
        method: 'GET',
        ...params,
      }) /**
     * @description Returns a paginated list of credit memos for the specified account. Pass the cursor returned to subsequent calls to retrieve all data.
     *
     * @tags Credit Memo
     * @name GetCreditMemoForAccount
     * @summary Get credit memos for an account
     * @request GET:/creditmemos
     */,
    getCreditMemoForAccount: (
      query?: {
        /** id of the account */
        accountId?: string
        /**
         * cursor used to move the pages
         * @format uuid
         */
        cursor?: string
        /**
         * number of results per page
         * @format int32
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<CreditMemoPaginationResponseJson, any>({
        path: `/creditmemos`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Sets the status of the specified credit memo to POSTED
     *
     * @tags Credit Memo
     * @name PostCreditMemo
     * @summary Post a credit memo
     * @request POST:/creditmemos/{creditMemoNumber}/post
     */,
    postCreditMemo: (creditMemoNumber: string, params: RequestParams = {}) =>
      this.request<CreditMemoJson, any>({
        path: `/creditmemos/${creditMemoNumber}/post`,
        method: 'POST',
        format: 'json',
        ...params,
      }) /**
     * @description Updates the credit memo configuration for you tenant.
     *
     * @tags Credit Memo
     * @name UpdateCreditMemoConfiguration
     * @summary Update credit memo configuration
     * @request POST:/creditmemos/configuration
     */,
    updateCreditMemoConfiguration: (body: TenantCreditMemoConfigurationJson, params: RequestParams = {}) =>
      this.request<TenantCreditMemoConfigurationJson, any>({
        path: `/creditmemos/configuration`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Updates the details of a credit memo in DRAFT status
     *
     * @tags Credit Memo
     * @name UpdateDraftCreditMemo
     * @summary Update a credit memo
     * @request PUT:/creditmemos/{creditMemoNumber}
     */,
    updateDraftCreditMemo: (creditMemoNumber: string, body: StandaloneCreditMemoRequest, params: RequestParams = {}) =>
      this.request<CreditMemoJson, any>({
        path: `/creditmemos/${creditMemoNumber}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  crmFieldMapping = {
    /**
     * @description Returns details regarding the CRM field mappings that have been uploaded
     *
     * @tags CRM field mapping
     * @name GetAllImportDetails1
     * @summary Get CRM field mapping upload list
     * @request GET:/crmFieldMapping/csv
     */
    getAllImportDetails1: (params: RequestParams = {}) =>
      this.request<CrmFieldMappingImportDataJson[], any>({
        path: `/crmFieldMapping/csv`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Downloads your CRM field mappings as a csv
     *
     * @tags CRM field mapping
     * @name GetCrmFieldMappingAsCsv
     * @summary Download CRM field mappings
     * @request GET:/crmFieldMapping/csv/download
     */,
    getCrmFieldMappingAsCsv: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/crmFieldMapping/csv/download`,
        method: 'GET',
        ...params,
      }) /**
     * @description Gets the details of an import specified by the passed ID
     *
     * @tags CRM field mapping
     * @name GetImportDetailsById1
     * @summary Get import details
     * @request GET:/crmFieldMapping/csv/{importId}
     */,
    getImportDetailsById1: (importId: string, params: RequestParams = {}) =>
      this.request<CrmFieldMappingImportDataJson, any>({
        path: `/crmFieldMapping/csv/${importId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Preview the changes that a CRM field mapping import will have once applied
     *
     * @tags CRM field mapping
     * @name GetImportPreview1
     * @summary Preview import changes
     * @request GET:/crmFieldMapping/csv/{importId}/preview
     */,
    getImportPreview1: (importId: string, params: RequestParams = {}) =>
      this.request<CrmFieldMappingImportPreview, any>({
        path: `/crmFieldMapping/csv/${importId}/preview`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Downloads the CSV for an import activity specified by the passed ID
     *
     * @tags CRM field mapping
     * @name GetImportResult1
     * @summary Download the csv for an import
     * @request GET:/crmFieldMapping/csv/{importId}/download
     */,
    getImportResult1: (importId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/crmFieldMapping/csv/${importId}/download`,
        method: 'GET',
        ...params,
      }) /**
     * @description Submit and finalize the import for the specified import operation.
     *
     * @tags CRM field mapping
     * @name SubmitCrmFieldMappingImport
     * @summary Finalize an import
     * @request POST:/crmFieldMapping/csv/{importId}/submit
     */,
    submitCrmFieldMappingImport: (importId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/crmFieldMapping/csv/${importId}/submit`,
        method: 'POST',
        ...params,
      }) /**
     * @description crmType: HUBSPOT, SALESFORCE crmObjectType: OPPORTUNITY, ACCOUNT, ORDER direction: INBOUND, OUTBOUND crmFieldName subskribeFieldName
     *
     * @tags CRM field mapping
     * @name UploadCrmFieldMappingCsv
     * @summary Upload of CRM field mappings
     * @request POST:/crmFieldMapping/csv
     */,
    uploadCrmFieldMappingCsv: (data: any, params: RequestParams = {}) =>
      this.request<CrmFieldMappingImportDataJson, any>({
        path: `/crmFieldMapping/csv`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  }
  customFieldDefinition = {
    /**
     * @description Creates a new custom field definition for your tenant. On success the id of the custom field definition is returned.
     *
     * @tags Custom Field
     * @name CreateCustomFieldDefinition
     * @summary Create a custom field definition
     * @request POST:/customFieldDefinition
     */
    createCustomFieldDefinition: (body: CustomFieldDefinitionCreateInput, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/customFieldDefinition`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Deletes an existing custom field definition for your tenant. On success the deleted custom field definition object is returned.
     *
     * @tags Custom Field
     * @name DeleteCustomFieldDefinition
     * @summary Delete a custom field definition
     * @request DELETE:/customFieldDefinition/{id}
     */,
    deleteCustomFieldDefinition: (id: string, params: RequestParams = {}) =>
      this.request<CustomFieldDefinitionJson, any>({
        path: `/customFieldDefinition/${id}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }) /**
     * @description Returns all custom field definitions for a specific parent object type
     *
     * @tags Custom Field
     * @name GetCustomFieldDefinitions
     * @summary Get custom field definitions
     * @request GET:/customFieldDefinition/{parentObjectType}
     */,
    getCustomFieldDefinitions: (parentObjectType: string, params: RequestParams = {}) =>
      this.request<CustomFieldDefinitionJson[], any>({
        path: `/customFieldDefinition/${parentObjectType}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Updates an existing custom field definition for your tenant. On success the update custom field definition is returned.
     *
     * @tags Custom Field
     * @name UpdateCustomFieldDefinition
     * @summary Update an existing custom field definition
     * @request PUT:/customFieldDefinition/{id}
     */,
    updateCustomFieldDefinition: (id: string, body: CustomFieldDefinitionUpdateInput, params: RequestParams = {}) =>
      this.request<CustomFieldDefinitionJson, any>({
        path: `/customFieldDefinition/${id}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  customField = {
    /**
     * @description Updates an existing set of custom fields for a given parent object type and id. On success the update custom fields are returned.
     *
     * @tags Custom Field
     * @name ForceUpdateCustomField
     * @summary Update an existing set of custom fields
     * @request PUT:/customField/{parentObjectType}/{parentObjectId}/{customFieldName}/force
     */
    forceUpdateCustomField: (
      parentObjectType: string,
      parentObjectId: string,
      customFieldName: string,
      body: CustomFieldUpdateInput,
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/customField/${parentObjectType}/${parentObjectId}/${customFieldName}/force`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Updates an existing set of custom fields for a given parent object type and id. On success the update custom fields are returned.
     *
     * @tags Custom Field
     * @name ForceUpdateCustomFields
     * @summary Update an existing set of custom fields
     * @request PUT:/customField/{parentObjectType}/{parentObjectId}/force
     */,
    forceUpdateCustomFields: (
      parentObjectType: string,
      parentObjectId: string,
      body: Record<string, CustomFieldValue>,
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/customField/${parentObjectType}/${parentObjectId}/force`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Returns all custom fields for a specific parent object type and id
     *
     * @tags Custom Field
     * @name GetCustomFields
     * @summary Get custom fields by type and parent object id
     * @request GET:/customField/{parentObjectType}/{parentObjectId}
     */,
    getCustomFields: (parentObjectType: string, parentObjectId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/customField/${parentObjectType}/${parentObjectId}`,
        method: 'GET',
        ...params,
      }) /**
     * @description Updates an existing set of custom fields for a given parent object type and id. On success the update custom fields are returned.
     *
     * @tags Custom Field
     * @name UpdateCustomField
     * @summary Update an existing set of custom fields
     * @request PUT:/customField/{parentObjectType}/{parentObjectId}/{customFieldName}
     */,
    updateCustomField: (
      parentObjectType: string,
      parentObjectId: string,
      customFieldName: string,
      body: CustomFieldUpdateInput,
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/customField/${parentObjectType}/${parentObjectId}/${customFieldName}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Updates an existing set of custom fields for a given parent object type and id. On success the update custom fields are returned.
     *
     * @tags Custom Field
     * @name UpdateCustomFields
     * @summary Update an existing set of custom fields
     * @request PUT:/customField/{parentObjectType}/{parentObjectId}
     */,
    updateCustomFields: (
      parentObjectType: string,
      parentObjectId: string,
      body: Record<string, CustomFieldValue>,
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/customField/${parentObjectType}/${parentObjectId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  }
  beta = {
    /**
     * @description if this endpoint returns OK it means the order creation customization zeppa script has successfully compiled
     *
     * @tags Customization
     * @name CompileOrderCreationCustomizationZeppaScript
     * @summary Compile and Verify a order creation customization for correctness
     * @request PUT:/beta/customization/orderCreationCustomization/compile
     */
    compileOrderCreationCustomizationZeppaScript: (
      query: {
        /** order id against which the compilation should happen */
        orderId: string
        /** order id against which the compilation should happen */
        userId?: string
      },
      body: string,
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/beta/customization/orderCreationCustomization/compile`,
        method: 'PUT',
        query: query,
        body: body,
        type: ContentType.Text,
        ...params,
      }) /**
     * @description if this endpoint returns OK it means the selection customization zeppa script has successfully compiled
     *
     * @tags Customization
     * @name CompileSelectionCustomizationZeppaScript
     * @summary Compile and Verify a  selection customization for correctness
     * @request PUT:/beta/customization/selectionCustomization/compile
     */,
    compileSelectionCustomizationZeppaScript: (
      query: {
        /** account id against which the compilation should happen */
        accountId: string
        /** user id against which the compilation should happen */
        userId?: string
      },
      body: string,
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/beta/customization/selectionCustomization/compile`,
        method: 'PUT',
        query: query,
        body: body,
        type: ContentType.Text,
        ...params,
      }) /**
     * @description runs the provided zeppa script and returns a list of Rule actions that were applicable after zeppa script ran
     *
     * @tags Customization
     * @name TestOrderCreationCustomizationZeppaScript
     * @summary Test a order creation customization zeppa script
     * @request PUT:/beta/customization/orderCreationCustomization/test
     */,
    testOrderCreationCustomizationZeppaScript: (
      query: {
        /** order id against which the test needs to happen */
        orderId: string
        /** the user id against which this test needs to happen, the user should be part of the tenancy */
        userId?: string
      },
      body: string,
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/beta/customization/orderCreationCustomization/test`,
        method: 'PUT',
        query: query,
        body: body,
        type: ContentType.Text,
        ...params,
      }) /**
     * @description runs the provided zeppa script and returns a list of Rule actions that were applicable after zeppa script ran
     *
     * @tags Customization
     * @name TestSelectionCustomizationZeppaScript
     * @summary Test a selection customization zeppa script
     * @request PUT:/beta/customization/selectionCustomization/test
     */,
    testSelectionCustomizationZeppaScript: (
      query: {
        /** account id against which the test needs to happen */
        accountId: string
        /** user id against which the test needs to happen */
        userId?: string
      },
      body: string,
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/beta/customization/selectionCustomization/test`,
        method: 'PUT',
        query: query,
        body: body,
        type: ContentType.Text,
        ...params,
      }),
  }
  discounts = {
    /**
     * @description Creates a discount with the specified details. On success, the ID of the new discount is returned.
     *
     * @tags Discounts
     * @name AddDiscount
     * @summary Create a discount
     * @request POST:/discounts
     */
    addDiscount: (body: DiscountJson, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/discounts`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Deletes a discount. Note that a discount may not be deleted if it is in use.
     *
     * @tags Discounts
     * @name DeleteDiscount
     * @summary Delete a discount
     * @request DELETE:/discounts/{id}
     */,
    deleteDiscount: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/discounts/${id}`,
        method: 'DELETE',
        ...params,
      }) /**
     * @description Returns the details of the specified discount.
     *
     * @tags Discounts
     * @name GetDiscount
     * @summary Get discount
     * @request GET:/discounts/{id}
     */,
    getDiscount: (id: string, params: RequestParams = {}) =>
      this.request<DiscountJson, any>({
        path: `/discounts/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns all discounts that have been defined
     *
     * @tags Discounts
     * @name GetDiscounts
     * @summary Get discounts
     * @request GET:/discounts
     */,
    getDiscounts: (params: RequestParams = {}) =>
      this.request<DiscountJson[], any>({
        path: `/discounts`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Updates the details of the specified discount. Note that a discount cannot be updated once it is in use by an order.
     *
     * @tags Discounts
     * @name UpdateDiscount
     * @summary Update discount details
     * @request PUT:/discounts/{id}
     */,
    updateDiscount: (id: string, body: DiscountJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/discounts/${id}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Updates the status of a discount
     *
     * @tags Discounts
     * @name UpdateDiscountStatus
     * @summary Update discount status
     * @request PUT:/discounts/{id}/status/{status}
     */,
    updateDiscountStatus: (id: string, status: 'ACTIVE' | 'DEPRECATED', params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/discounts/${id}/status/${status}`,
        method: 'PUT',
        type: ContentType.Json,
        ...params,
      }),
  }
  docusign = {
    /**
     * @description Complete the docusign integration. This should be called after the integration has been created and an authorization code has been received from Docusign.
     *
     * @tags Integrations
     * @name CompleteIntegration
     * @summary Complete Docusign integration
     * @request GET:/docusign
     */
    completeIntegration: (
      query?: {
        /** authorization code received from Docusign */
        code?: string
        /** id of the integration */
        state?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<DocuSignIntegrationResponseJson, any>({
        path: `/docusign`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Removes integration with Docusign from your tenant
     *
     * @tags Integrations
     * @name DeleteIntegration
     * @summary Delete Docusign integration
     * @request DELETE:/docusign
     */,
    deleteIntegration: (params: RequestParams = {}) =>
      this.request<DocuSignIntegrationResponseJson, any>({
        path: `/docusign`,
        method: 'DELETE',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Creates an integration with Docusign. On success a redirect URL is returned.
     *
     * @tags Integrations
     * @name InitiateIntegration
     * @summary Create Docusign integration
     * @request POST:/docusign
     */,
    initiateIntegration: (body: DocuSignIntegrationRequestJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/docusign`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  }
  predefinedTerms = {
    /**
     * @description Add a new predefined terms that can be attached to an order as part of PDF document generation.
     *
     * @tags Documents
     * @name AddDocumentTemplate
     * @summary Add new predefined terms
     * @request POST:/predefinedTerms
     */
    addDocumentTemplate: (body: DocumentTemplateRequestJson, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/predefinedTerms`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }) /**
     * @description Deletes the predefined terms specified.
     *
     * @tags Documents
     * @name DeleteDocumentTemplate
     * @summary Delete predefined terms
     * @request DELETE:/predefinedTerms/{id}
     */,
    deleteDocumentTemplate: (id: string, params: RequestParams = {}) =>
      this.request<DocumentTemplateJson, any>({
        path: `/predefinedTerms/${id}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }) /**
     * @description Returns a specific predefined terms by Id.
     *
     * @tags Documents
     * @name GetDocumentTemplate
     * @summary Get predefined terms detail
     * @request GET:/predefinedTerms/{id}
     */,
    getDocumentTemplate: (id: string, params: RequestParams = {}) =>
      this.request<DocumentTemplateJson, any>({
        path: `/predefinedTerms/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns predefined terms by type. These templates can be attached to orders as part of PDF document generation.
     *
     * @tags Documents
     * @name GetDocumentTemplates
     * @summary Get predefined terms
     * @request GET:/predefinedTerms
     */,
    getDocumentTemplates: (
      query?: {
        type?:
          | 'ORDER'
          | 'INVOICE'
          | 'INVOICE_EMAIL'
          | 'CREDIT_MEMO'
          | 'EMAIL'
          | 'UPSELL_EARLY_RENEWAL'
          | 'DUNNING'
          | 'CANCEL_AND_RESTRUCTURE'
          | 'ESIGN'
      },
      params: RequestParams = {}
    ) =>
      this.request<DocumentTemplateJson, any>({
        path: `/predefinedTerms`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Returns a specific predefined terms by Id and version.
     *
     * @tags Documents
     * @name GetDocumentTemplateVersion
     * @summary Get predefined terms version detail
     * @request GET:/predefinedTerms/{id}/versions/{version}
     */,
    getDocumentTemplateVersion: (id: string, version: number, params: RequestParams = {}) =>
      this.request<DocumentTemplateJson, any>({
        path: `/predefinedTerms/${id}/versions/${version}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns predefined terms versions by Id.
     *
     * @tags Documents
     * @name GetDocumentTemplateVersions
     * @summary Get predefined terms versions
     * @request GET:/predefinedTerms/{id}/versions
     */,
    getDocumentTemplateVersions: (id: string, params: RequestParams = {}) =>
      this.request<DocumentTemplateJson, any>({
        path: `/predefinedTerms/${id}/versions`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Updates a predefined terms
     *
     * @tags Documents
     * @name UpdateDocumentTemplate
     * @summary Update predefined terms
     * @request PUT:/predefinedTerms/{id}
     */,
    updateDocumentTemplate: (id: string, body: DocumentTemplateRequestJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/predefinedTerms/${id}`,
        method: 'PUT',
        body: body,
        ...params,
      }) /**
     * @description Updates the status of a predefined terms
     *
     * @tags Documents
     * @name UpdateDocumentTemplateStatus
     * @summary Update predefined terms status
     * @request PUT:/predefinedTerms/{id}/status/{status}
     */,
    updateDocumentTemplateStatus: (id: string, status: 'DRAFT' | 'ACTIVE' | 'DEPRECATED', params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/predefinedTerms/${id}/status/${status}`,
        method: 'PUT',
        type: ContentType.Json,
        ...params,
      }),
  }
  dunning = {
    /**
     * @description Sends a reminder email for the specified invoice
     *
     * @tags Billing
     * @name SendInvoiceReminder
     * @summary Send an Invoice Reminder
     * @request POST:/dunning/sendInvoiceReminder/{invoiceNumber}
     */
    sendInvoiceReminder: (invoiceNumber: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/dunning/sendInvoiceReminder/${invoiceNumber}`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Sends a test email for dunning to the use associated with this API call. Note A user bound api key is required for this operation.
     *
     * @tags Billing
     * @name SendTestDunningEmail
     * @summary Send a test email
     * @request POST:/dunning/sendTestEmail/{reminderType}
     */,
    sendTestDunningEmail: (
      reminderType:
        | 'WEEK_BEFORE_DUE_DATE'
        | 'DUE_DATE'
        | 'WEEK_AFTER_DUE_DATE'
        | 'TWO_WEEKS_AFTER_DUE_DATE'
        | 'MONTH_AFTER_DUE_DATE'
        | 'TWO_MONTHS_AFTER_DUE_DATE',
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/dunning/sendTestEmail/${reminderType}`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }),
  }
  dunningSetting = {
    /**
     * @description Returns the dunning settings for your tenant
     *
     * @tags Billing
     * @name GetDunningSetting
     * @summary Get dunning settings
     * @request GET:/dunningSetting
     */
    getDunningSetting: (params: RequestParams = {}) =>
      this.request<DunningSettingJson, any>({
        path: `/dunningSetting`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Updates the dunning settings for your tenant
     *
     * @tags Billing
     * @name UpdateDunningSetting
     * @summary Update dunning settings
     * @request PUT:/dunningSetting
     */,
    updateDunningSetting: (body: DunningSettingJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/dunningSetting`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  }
  emailSettings = {
    /**
     * No description
     *
     * @tags Email
     * @name AddEmailSetting
     * @summary Add a new email setting
     * @request POST:/emailSettings
     */
    addEmailSetting: (body: EmailSetting, params: RequestParams = {}) =>
      this.request<EmailSetting, any>({
        path: `/emailSettings`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * No description
     *
     * @tags Email
     * @name DeleteEmailSetting
     * @summary Delete an email setting
     * @request DELETE:/emailSettings
     */,
    deleteEmailSetting: (body: EmailSetting, params: RequestParams = {}) =>
      this.request<EmailSetting, any>({
        path: `/emailSettings`,
        method: 'DELETE',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Returns a list of email settings
     *
     * @tags Email
     * @name GetEmailSettings
     * @summary Fetch the email settings
     * @request GET:/emailSettings
     */,
    getEmailSettings: (params: RequestParams = {}) =>
      this.request<EmailSetting[], any>({
        path: `/emailSettings`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * No description
     *
     * @tags Email
     * @name UpdateEmailSetting
     * @summary Update an existing email setting
     * @request PUT:/emailSettings
     */,
    updateEmailSetting: (body: EmailSetting, params: RequestParams = {}) =>
      this.request<EmailSetting, any>({
        path: `/emailSettings`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  entities = {
    /**
     * @description Move account along with its subscriptions, orders, invoices and other associated transactions to a target entity
     *
     * @tags Entities
     * @name AccountEntityMigration
     * @summary Move account to a target entity
     * @request POST:/entities/migrate/account
     */
    accountEntityMigration: (
      query?: {
        accountId?: string
        targetEntityId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/entities/migrate/account`,
        method: 'POST',
        query: query,
        ...params,
      }) /**
     * @description Move composite order along with its subscriptions, orders and other associated transactions to a target entity
     *
     * @tags Entities
     * @name CompositeOrderEntityMigration
     * @summary Move composite order to a target entity
     * @request POST:/entities/migrate/compositeOrder
     */,
    compositeOrderEntityMigration: (
      query?: {
        compositeOrderId?: string
        targetEntityId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/entities/migrate/compositeOrder`,
        method: 'POST',
        query: query,
        ...params,
      }) /**
     * @description Create an entity. On success return the created entity.
     *
     * @tags Entities
     * @name Create
     * @summary Create an entity
     * @request POST:/entities
     */,
    create: (body: EntityJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/entities`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Delete the entity if there is no data associated with it.
     *
     * @tags Entities
     * @name DeleteEntity
     * @summary Delete entity
     * @request DELETE:/entities/{id}
     */,
    deleteEntity: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/entities/${id}`,
        method: 'DELETE',
        ...params,
      }) /**
     * @description Gets all entities
     *
     * @tags Entities
     * @name GetEntities
     * @summary Gets entities
     * @request GET:/entities
     */,
    getEntities: (params: RequestParams = {}) =>
      this.request<EntityJson[], any>({
        path: `/entities`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Gets the entity details of the specified entity id
     *
     * @tags Entities
     * @name GetEntityById
     * @summary Gets entity details
     * @request GET:/entities/{id}
     */,
    getEntityById: (id: string, params: RequestParams = {}) =>
      this.request<Entity, any>({
        path: `/entities/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Get the current logo stored for the given entity
     *
     * @tags Entities
     * @name GetLogo
     * @summary Get entity logo
     * @request GET:/entities/logo/{entityId}
     */,
    getLogo: (entityId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/entities/logo/${entityId}`,
        method: 'GET',
        ...params,
      }) /**
     * @description Move order to a target entity
     *
     * @tags Entities
     * @name OrderEntityMigration
     * @summary Update order entity
     * @request POST:/entities/migrate/order
     */,
    orderEntityMigration: (
      query?: {
        orderId?: string
        targetEntityId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/entities/migrate/order`,
        method: 'POST',
        query: query,
        ...params,
      }) /**
     * @description Move subscription and associated transactions to a target entity
     *
     * @tags Entities
     * @name SubscriptionEntityMigration
     * @summary Move subscription to a target entity
     * @request POST:/entities/migrate/subscription
     */,
    subscriptionEntityMigration: (
      query?: {
        subscriptionId?: string
        targetEntityId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/entities/migrate/subscription`,
        method: 'POST',
        query: query,
        ...params,
      }) /**
     * @description Updates the logo used in external facing communication such as order forms and invoices
     *
     * @tags Entities
     * @name UploadLogo
     * @summary Update entity logo
     * @request PUT:/entities/logo/{entityId}
     */,
    uploadLogo: (
      entityId: string,
      data: {
        file?: File
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/entities/logo/${entityId}`,
        method: 'PUT',
        body: data,
        type: ContentType.FormData,
        ...params,
      }),
  }
  erp = {
    /**
     * @description Trigger ERP journal entry deletion task for the given accounting period id
     *
     * @tags ERP
     * @name ProcessDeleteTasks
     * @summary Trigger ERP journal entry deletion task
     * @request POST:/erp/processDeleteTasks/{accountingPeriodId}
     */
    processDeleteTasks: (accountingPeriodId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/erp/processDeleteTasks/${accountingPeriodId}`,
        method: 'POST',
        ...params,
      }) /**
     * @description Trigger ERP journal entry sync task for given accounting period id
     *
     * @tags ERP
     * @name ProcessSyncTasks
     * @summary Trigger ERP journal entry sync task
     * @request POST:/erp/processSyncTasks/{accountingPeriodId}
     */,
    processSyncTasks: (accountingPeriodId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/erp/processSyncTasks/${accountingPeriodId}`,
        method: 'POST',
        ...params,
      }) /**
     * @description Trigger ERP credit memo sync task for the given credit memo number
     *
     * @tags ERP
     * @name SyncCreditMemoToErp
     * @summary Trigger ERP credit memo sync task
     * @request POST:/erp/syncCreditMemo/{creditMemoNumber}
     */,
    syncCreditMemoToErp: (creditMemoNumber: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/erp/syncCreditMemo/${creditMemoNumber}`,
        method: 'POST',
        ...params,
      }) /**
     * @description Trigger ERP invoice sync task for the given invoice id
     *
     * @tags ERP
     * @name SyncInvoiceToErp
     * @summary Trigger ERP invoice sync task
     * @request POST:/erp/syncInvoice/{invoiceId}
     */,
    syncInvoiceToErp: (invoiceId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/erp/syncInvoice/${invoiceId}`,
        method: 'POST',
        ...params,
      }) /**
     * @description Trigger ERP void invoice sync task for the given invoice number
     *
     * @tags ERP
     * @name SyncVoidInvoiceToErp
     * @summary Trigger ERP void invoice sync task
     * @request POST:/erp/syncVoidInvoice/{invoiceNumber}
     */,
    syncVoidInvoiceToErp: (invoiceNumber: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/erp/syncVoidInvoice/${invoiceNumber}`,
        method: 'POST',
        ...params,
      }),
  }
  guidedSelling = {
    /**
     * No description
     *
     * @name AddUsecase
     * @request POST:/guidedSelling/usecase
     */
    addUsecase: (body: GuidedSellingUsecase, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/guidedSelling/usecase`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * No description
     *
     * @name DeleteUsecase
     * @request DELETE:/guidedSelling/usecase/{id}
     */,
    deleteUsecase: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/guidedSelling/usecase/${id}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Get the next question given a set of answers to questions so far
     *
     * @tags Experimental
     * @name FetchNextQuestion
     * @summary Get Next Question for Guided Selling
     * @request PUT:/guidedSelling/usecase/{usecase}/nextQuestion
     */,
    fetchNextQuestion: (usecase: string, body: Answer[], params: RequestParams = {}) =>
      this.request<NextQuestion, any>({
        path: `/guidedSelling/usecase/${usecase}/nextQuestion`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Get the answer to guided selling questions form the deal desk AI, when all questions are answered then return the Answers for the guided selling
     *
     * @tags Experimental
     * @name GetAnswersFromAi
     * @summary Use AI to get answers for guided selling questions
     * @request PUT:/guidedSelling/ai/answers
     */,
    getAnswersFromAi: (body: GuidedSellingInput, params: RequestParams = {}) =>
      this.request<MessagesAndAnswer, any>({
        path: `/guidedSelling/ai/answers`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * No description
     *
     * @name GetUsecase
     * @request GET:/guidedSelling/usecase/{id}
     */,
    getUsecase: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/guidedSelling/usecase/${id}`,
        method: 'GET',
        type: ContentType.Json,
        ...params,
      }) /**
     * No description
     *
     * @name GetUsecaseScript
     * @request GET:/guidedSelling/usecase/{id}/qscript
     */,
    getUsecaseScript: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/guidedSelling/usecase/${id}/qscript`,
        method: 'GET',
        type: ContentType.Json,
        ...params,
      }) /**
     * No description
     *
     * @name ListUsecases
     * @request GET:/guidedSelling/usecase
     */,
    listUsecases: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/guidedSelling/usecase`,
        method: 'GET',
        ...params,
      }) /**
     * No description
     *
     * @name PutQScript
     * @request PUT:/guidedSelling/usecase/{id}/qscript
     */,
    putQScript: (id: string, body: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/guidedSelling/usecase/${id}/qscript`,
        method: 'PUT',
        body: body,
        type: ContentType.Text,
        ...params,
      }) /**
     * @description Given the use case, list of answers and Zeppa Q script try creating the order in dryrun mode
     *
     * @tags Experimental
     * @name TestQScript
     * @summary Test quote building Zeppa Q script
     * @request PUT:/guidedSelling/usecase/{usecase}/testQscript
     */,
    testQScript: (
      usecase: string,
      query: {
        accountId: string
      },
      data: {
        answers: any[]
        qscript: string
      },
      params: RequestParams = {}
    ) =>
      this.request<OrderJson, any>({
        path: `/guidedSelling/usecase/${usecase}/testQscript`,
        method: 'PUT',
        query: query,
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }) /**
     * No description
     *
     * @name UpdateUsecase
     * @request PUT:/guidedSelling/usecase/{id}
     */,
    updateUsecase: (id: string, body: GuidedSellingUsecase, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/guidedSelling/usecase/${id}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  }
  hubspot = {
    /**
     * @description Endpoint to handle and process a HubSpot authorization code
     *
     * @tags Integrations
     * @name AuthorizationCodeCallback
     * @summary Handle HubSpot authorization code
     * @request GET:/hubspot
     */
    authorizationCodeCallback: (
      query: {
        /** authorization code */
        code: string
        /** uri to redirect to HubSpot */
        redirect_uri: string
        /** HubSpot integration id */
        state: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/hubspot`,
        method: 'GET',
        query: query,
        ...params,
      }) /**
     * @description Deletes your integration with HubSpot.
     *
     * @tags Integrations
     * @name DeleteIntegration1
     * @summary Delete HubSpot Integration
     * @request DELETE:/hubspot
     */,
    deleteIntegration1: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/hubspot`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Initiates an integration with HubSpot. On success redirect URL is returned.
     *
     * @tags Integrations
     * @name InitiateIntegration1
     * @summary Initiate a HubSpot integration
     * @request POST:/hubspot
     */,
    initiateIntegration1: (email: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/hubspot`,
        method: 'POST',
        body: email,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Add custom properties to HubSpot
     *
     * @tags Integrations
     * @name SetupHubSpot
     * @summary Add custom properties to HubSpot
     * @request POST:/hubspot/setup
     */,
    setupHubSpot: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/hubspot/setup`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }) /**
     * No description
     *
     * @tags Integrations
     * @name SyncEsignDetailsForOrderToHubSpot
     * @summary Sync esign details for order id
     * @request POST:/hubspot/esign/{orderId}
     */,
    syncEsignDetailsForOrderToHubSpot: (orderId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/hubspot/esign/${orderId}`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Verify custom objects and properties
     *
     * @tags Integrations
     * @name VerifySetup
     * @summary Verify HubSpot setup
     * @request POST:/hubspot/setup/verify
     */,
    verifySetup: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/hubspot/setup/verify`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }),
  }
  import = {
    /**
     * @description Creates a Flatfile workbook and adds it to a space
     *
     * @tags Import
     * @name CreateFlatfileWorkbook
     * @summary Create a Flatfile workbook
     * @request POST:/import/flatfile/{domain}
     */
    createFlatfileWorkbook: (domain: 'ORDER', params: RequestParams = {}) =>
      this.request<FlatfileWorkbookResponse, any>({
        path: `/import/flatfile/${domain}`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Gets account contacts in a format appropriate for re-import. On success, the output is a csv containing the account and contacts.
     *
     * @tags Import
     * @name GetAccountContactsInImportFormat
     * @summary Get account contacts for export
     * @request GET:/import/export/accountContact
     */,
    getAccountContactsInImportFormat: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/import/export/accountContact`,
        method: 'GET',
        ...params,
      }) /**
     * @description Gets amendment orders in a format appropriate for re-import for the given generation. On success, the output is a csv containing the orders.
     *
     * @tags Import
     * @name GetAmendmentOrderExportInImportFormat
     * @summary Get amendment orders in import CSV format
     * @request GET:/import/export/amendmentOrders
     */,
    getAmendmentOrderExportInImportFormat: (
      query?: {
        /**
         * the generation of amendments to include in the export, generations are defined as 1 based index of number of amendments applied to subscription
         * @format int32
         */
        generation?: number
        /** use the subskribe ID instead of external ID for exported objects */
        useRealIds?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/import/export/amendmentOrders`,
        method: 'GET',
        query: query,
        ...params,
      }) /**
     * @description Returns the set of schemas available for import
     *
     * @tags Import
     * @name GetAvailableSchemas
     * @summary Return available schemas
     * @request GET:/import/schemas
     */,
    getAvailableSchemas: (params: RequestParams = {}) =>
      this.request<Record<string, object[]>, any>({
        path: `/import/schemas`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Gets catalog data in a format appropriate for re-import. On success, the output is a csv containing the catalog data containing Product, Plan and Charge data.
     *
     * @tags Import
     * @name GetCatalogDataInImportFormat
     * @summary Get catalog data for export
     * @request GET:/import/export/catalog
     */,
    getCatalogDataInImportFormat: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/import/export/catalog`,
        method: 'GET',
        ...params,
      }) /**
     * @description Gets an item that was imported by its ID
     *
     * @tags Import
     * @name GetDataImportById
     * @summary Gets an import item
     * @request GET:/import/{importId}
     */,
    getDataImportById: (importId: string, params: RequestParams = {}) =>
      this.request<DataImport, any>({
        path: `/import/${importId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Gets all items that was imported
     *
     * @tags Import
     * @name GetDataImports
     * @summary Gets all import items
     * @request GET:/import
     */,
    getDataImports: (params: RequestParams = {}) =>
      this.request<DataImport, any>({
        path: `/import`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns the details of an import by its ID
     *
     * @tags Import
     * @name GetImportResult2
     * @summary Get import details
     * @request GET:/import/{importId}/result
     */,
    getImportResult2: (importId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/import/${importId}/result`,
        method: 'GET',
        ...params,
      }) /**
     * @description Gets new orders in a format appropriate for re-import. On success, the output is a csv containing the orders.
     *
     * @tags Import
     * @name GetNewOrderExportInImportFormat
     * @summary Get new orders in import CSV format
     * @request GET:/import/export/newOrders
     */,
    getNewOrderExportInImportFormat: (
      query?: {
        /** use the subskribe ID instead of external ID for exported objects */
        useRealIds?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/import/export/newOrders`,
        method: 'GET',
        query: query,
        ...params,
      }) /**
     * @description Processes the import specified.
     *
     * @tags Import
     * @name ProcessImport
     * @summary Process an import by ID
     * @request PUT:/import/{importId}/process
     */,
    processImport: (importId: string, params: RequestParams = {}) =>
      this.request<DataImport, any>({
        path: `/import/${importId}/process`,
        method: 'PUT',
        format: 'json',
        ...params,
      }) /**
     * @description Validates a multi-part import file. Returns an import ID on success.
     *
     * @tags Import
     * @name ValidateMultiPartFileImport
     * @summary Validate import file
     * @request POST:/import
     */,
    validateMultiPartFileImport: (data: any, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/import`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  }
  invoices = {
    /**
     * @description Creates a new billing schedule entry for an event based charge
     *
     * @tags Billing
     * @name AddBillingSchedule
     * @summary Add a new billing schedule for subscription line item
     * @request POST:/invoices/schedule
     */
    addBillingSchedule: (body: BillingEventInput, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/invoices/schedule`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Creates a bulk invoice run as specified by the input parameters. On success the id of the run is returned.
     *
     * @tags Billing
     * @name CreateBulkInvoiceRun
     * @summary Create a bulk invoice run
     * @request POST:/invoices/bulk
     */,
    createBulkInvoiceRun: (body: BulkInvoiceRunInput, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/invoices/bulk`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Generates a PDF version of the specified invoice. Once generated, PDF can be later fetched via a call to /{invoiceNumber}/pdf.
     *
     * @tags Billing
     * @name CreateInvoiceDocument
     * @summary Generate invoice PDF
     * @request POST:/invoices/{invoiceNumber}/pdf
     */,
    createInvoiceDocument: (
      invoiceNumber: string,
      query?: {
        /** Force regeneration of the PDF document even if there has been no changes. Defaults to false. */
        force?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/invoices/${invoiceNumber}/pdf`,
        method: 'POST',
        query: query,
        ...params,
      }) /**
     * @description Billing schedules must be deleted in reverse chronological order.
     *
     * @tags Billing
     * @name DeleteBillingSchedule
     * @summary Delete an existing billing schedule
     * @request DELETE:/invoices/schedule/{id}
     */,
    deleteBillingSchedule: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/invoices/schedule/${id}`,
        method: 'DELETE',
        ...params,
      }) /**
     * @description Deletes the specified invoice
     *
     * @tags Billing
     * @name DeleteInvoice
     * @summary Delete invoice
     * @request DELETE:/invoices/{number}
     */,
    deleteInvoice: (number: string, params: RequestParams = {}) =>
      this.request<InvoiceJson, any>({
        path: `/invoices/${number}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }) /**
     * @description Deletes the specified invoice
     *
     * @tags Billing
     * @name DeleteVoidedInvoice
     * @summary Delete voided invoice
     * @request DELETE:/invoices/{number}/voided
     */,
    deleteVoidedInvoice: (number: string, params: RequestParams = {}) =>
      this.request<InvoiceJson, any>({
        path: `/invoices/${number}/voided`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }) /**
     * @description Send the invoice to recipients via email with invoice PDF as attachment.
     *
     * @tags Billing
     * @name EmailInvoice
     * @summary Send invoice email to contacts
     * @request POST:/invoices/{invoiceNumber}/email
     */,
    emailInvoice: (invoiceNumber: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/invoices/${invoiceNumber}/email`,
        method: 'POST',
        ...params,
      }) /**
     * @description Generates the invoices for the given subscription
     *
     * @tags Billing
     * @name GenerateInvoice
     * @summary Generate subscription invoices
     * @request POST:/invoices/generate
     */,
    generateInvoice: (
      query: {
        /** types of charges to include */
        invoiceChargeInclusionOption: 'INCLUDE_USAGE' | 'EXCLUDE_USAGE' | 'ONLY_USAGE'
        /**
         * Time in seconds since Epoch (GMT) to set invoice date to
         * @format int64
         */
        invoiceDate?: number
        /** id of the subscription */
        subscriptionId: string
        /**
         * Time in seconds since Epoch (GMT) to generate invoice from
         * @format int64
         */
        targetDate: number
      },
      params: RequestParams = {}
    ) =>
      this.request<InvoiceJson[], any>({
        path: `/invoices/generate`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Retrieve all billing schedules for a subscription line item
     *
     * @tags Billing
     * @name GetBillingSchedules
     * @summary Get billing schedules for a subscription line item
     * @request GET:/invoices/schedule
     */,
    getBillingSchedules: (
      query: {
        subscriptionChargeId: string
        subscriptionId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<BillingEventEntry[], any>({
        path: `/invoices/schedule`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Returns the details of the specified bulk invoice run
     *
     * @tags Billing
     * @name GetBulkInvoiceRun
     * @summary Get bulk invoice run details
     * @request GET:/invoices/bulk/{bulkInvoiceRunId}
     */,
    getBulkInvoiceRun: (bulkInvoiceRunId: string, params: RequestParams = {}) =>
      this.request<BulkInvoiceRun, any>({
        path: `/invoices/bulk/${bulkInvoiceRunId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns the items associated with the specified bulk invoice run
     *
     * @tags Billing
     * @name GetBulkInvoiceRunItems
     * @summary Get items for bulk invoice run
     * @request GET:/invoices/bulk/{bulkInvoiceRunId}/runItems
     */,
    getBulkInvoiceRunItems: (bulkInvoiceRunId: string, params: RequestParams = {}) =>
      this.request<BulkInvoiceRunItem[], any>({
        path: `/invoices/bulk/${bulkInvoiceRunId}/runItems`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Response contains flag to indicate if the invoice can be deleted and reason if it cannot be deleted
     *
     * @tags Billing
     * @name GetCanDeleteInvoice
     * @summary Check if invoice can be deleted
     * @request GET:/invoices/{number}/canDelete
     */,
    getCanDeleteInvoice: (number: string, params: RequestParams = {}) =>
      this.request<InvoiceDeletableResponse, any>({
        path: `/invoices/${number}/canDelete`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns the details of the specified invoice number
     *
     * @tags Billing
     * @name GetInvoice
     * @summary Get invoice details
     * @request GET:/invoices/{number}
     */,
    getInvoice: (
      number: string,
      query?: {
        /** include deleted invoice items */
        includeDeleted?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<InvoiceJson, any>({
        path: `/invoices/${number}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Returns the balance of the specified invoice number
     *
     * @tags Billing
     * @name GetInvoiceBalance
     * @summary Get invoice balance
     * @request GET:/invoices/{number}/balance
     */,
    getInvoiceBalance: (number: string, params: RequestParams = {}) =>
      this.request<InvoiceBalanceJson, any>({
        path: `/invoices/${number}/balance`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Downloads the PDF for an invoice after it has been generated via a POST to /{invoiceNumber}/pdf. The data return will be the PDF document contents.
     *
     * @tags Billing
     * @name GetInvoiceDocumentPdf
     * @summary Download invoice PDF
     * @request GET:/invoices/{invoiceNumber}/pdf
     */,
    getInvoiceDocumentPdf: (invoiceNumber: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/invoices/${invoiceNumber}/pdf`,
        method: 'GET',
        ...params,
      }) /**
     * @description Returns all invoices for a subscription. The result is paginated. Use the cursor returned from a request in subsequent calls to retrieve all results.
     *
     * @tags Billing
     * @name GetInvoices
     * @summary Get all invoices for a subscription
     * @request GET:/invoices
     */,
    getInvoices: (
      query?: {
        /**
         * used to iterate through all results
         * @format uuid
         */
        cursor?: string
        /**
         * number of items per page
         * @format int32
         */
        limit?: number
        /** status filter for invoices */
        status?: 'DRAFT' | 'POSTED' | 'PAID' | 'CONVERTED' | 'VOIDED'
        subscriptionId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<InvoiceJsonPaginationResponse, any>({
        path: `/invoices`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Invoice document JSON that contains all details required to render full Invoice document
     *
     * @tags Billing
     * @name GetRawInvoiceDocumentJson
     * @summary Get the invoice document JSON that is used to render invoice PDF
     * @request GET:/invoices/{invoiceNumber}/documentJson
     */,
    getRawInvoiceDocumentJson: (invoiceNumber: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/invoices/${invoiceNumber}/documentJson`,
        method: 'GET',
        ...params,
      }) /**
     * @description Returns the usage invoice items for the specified subscription and charge
     *
     * @tags Billing
     * @name GetUsageForSubscriptionCharge
     * @summary Get usage
     * @request GET:/invoices/usage
     */,
    getUsageForSubscriptionCharge: (
      query: {
        /** id of the charge */
        chargeId: string
        /** id of the subscription */
        subscriptionId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<InvoiceItemJson[], any>({
        path: `/invoices/usage`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Marks the specified invoice as posted
     *
     * @tags Billing
     * @name PostInvoice
     * @summary Mark invoice as posted
     * @request POST:/invoices/{number}/post
     */,
    postInvoice: (number: string, params: RequestParams = {}) =>
      this.request<InvoiceJson, any>({
        path: `/invoices/${number}/post`,
        method: 'POST',
        format: 'json',
        ...params,
      }) /**
     * @description Marks all invoices associated with the specified bulk run as posted
     *
     * @tags Billing
     * @name PostInvoicesForBulkInvoiceRun
     * @summary Posts invoices for a bulk run
     * @request PUT:/invoices/bulk/{bulkInvoiceRunId}/post
     */,
    postInvoicesForBulkInvoiceRun: (bulkInvoiceRunId: string, body: string[], params: RequestParams = {}) =>
      this.request<BulkInvoiceRun, any>({
        path: `/invoices/bulk/${bulkInvoiceRunId}/post`,
        method: 'PUT',
        body: body,
        format: 'json',
        ...params,
      }) /**
     * @description Returns the unbilled usage invoice items for the specified subscription
     *
     * @tags Billing
     * @name PreviewInvoiceByOrderPeriod
     * @summary Get unbilled usage
     * @request GET:/invoices/unbilledUsage
     */,
    previewInvoiceByOrderPeriod: (
      query: {
        /** id of the subscription */
        subscriptionId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<InvoiceItemJson[], any>({
        path: `/invoices/unbilledUsage`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Returns a preview of invoice for the specified order id OR subscription id
     *
     * @tags Billing
     * @name PreviewInvoiceByOrderPeriod1
     * @summary Preview invoices
     * @request GET:/invoices/preview
     */,
    previewInvoiceByOrderPeriod1: (
      query?: {
        /** id of order */
        orderId?: string
        /** id of subscription */
        subscriptionId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<InvoicePreviewJson[], any>({
        path: `/invoices/preview`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Updates the details of the specified invoice.
     *
     * @tags Billing
     * @name UpdateInvoice
     * @summary Update invoice details
     * @request PUT:/invoices/{number}
     */,
    updateInvoice: (number: string, body: UpdateInvoiceRequest, params: RequestParams = {}) =>
      this.request<InvoiceJson, any>({
        path: `/invoices/${number}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Updates the invoice sequence by invoice config id.
     *
     * @tags Billing
     * @name UpdateInvoiceSequence
     * @summary Update invoice sequence
     * @request POST:/invoices/sequence/{invoiceConfigId}
     */,
    updateInvoiceSequence: (
      invoiceConfigId: string,
      query?: {
        /**
         * long integer value
         * @format int64
         */
        'next invoice number'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<TenantInvoiceConfig, any>({
        path: `/invoices/sequence/${invoiceConfigId}`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Marks the specified invoice as voided
     *
     * @tags Billing
     * @name VoidInvoice
     * @summary Mark invoice as voided
     * @request PUT:/invoices/{number}/void
     */,
    voidInvoice: (number: string, body: VoidInvoiceRequest, params: RequestParams = {}) =>
      this.request<InvoiceJson, any>({
        path: `/invoices/${number}/void`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  settlements = {
    /**
     * @description Bulk upload payments to invoices in CSV file
     *
     * @tags Usage
     * @name AddAndApplyBulkPaymentsCsv
     * @summary Add and apply bulk payments to invoices CSV
     * @request POST:/settlements/addAndApplyPaymentsInBulk/csv
     */
    addAndApplyBulkPaymentsCsv: (data: any, params: RequestParams = {}) =>
      this.request<BulkPaymentUploadResult, any>({
        path: `/settlements/addAndApplyPaymentsInBulk/csv`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }) /**
     * @description Apply a payment per the specified parameters.
     *
     * @tags Billing
     * @name AddAndApplyPayment
     * @summary Apply a payment on a specific invoice
     * @request POST:/settlements/addAndApplyPayment
     */,
    addAndApplyPayment: (body: ApplyPaymentRequest, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settlements/addAndApplyPayment`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Applies a credit memo per the specified parameters
     *
     * @tags Billing
     * @name ApplyCreditMemo
     * @summary Apply a credit memo
     * @request POST:/settlements/applyCreditMemo
     */,
    applyCreditMemo: (body: CreditMemoApplicationJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settlements/applyCreditMemo`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Returns a list of applicable payment bank accounts that can be used for payment for a given invoice number
     *
     * @tags Billing
     * @name GetApplicablePaymentBankAccountsForInvoicePayment
     * @summary Get applicable payment bank accounts for invoice payment
     * @request GET:/settlements/applicablePaymentBankAccounts
     */,
    getApplicablePaymentBankAccountsForInvoicePayment: (
      query: {
        /** Invoice number */
        invoiceNumber: string
      },
      params: RequestParams = {}
    ) =>
      this.request<PaymentBankAccountJson[], any>({
        path: `/settlements/applicablePaymentBankAccounts`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Gets the details of specified settlement application.
     *
     * @tags Billing
     * @name GetSettlementApplication
     * @summary Get settlement application details
     * @request GET:/settlements/{id}
     */,
    getSettlementApplication: (id: string, params: RequestParams = {}) =>
      this.request<SettlementApplication, any>({
        path: `/settlements/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns the settlement applications for the specified invoice number or payment object.
     *
     * @tags Billing
     * @name GetSettlementApplications
     * @summary Get settlement applications
     * @request GET:/settlements
     */,
    getSettlementApplications: (
      query?: {
        /** number of the invoice */
        invoiceNumber?: string
        /** Id of payment */
        paymentId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<SettlementApplication[], any>({
        path: `/settlements`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Unapplies a credit memo per the specified parameters
     *
     * @tags Billing
     * @name UnapplyCreditMemo
     * @summary Unapply a credit memo
     * @request POST:/settlements/unapplyCreditMemo
     */,
    unapplyCreditMemo: (body: CreditMemoUnapplicationJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settlements/unapplyCreditMemo`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  }
  accounting = {
    /**
     * @description Returns all accounting related events between the from and to dates. Since there can be a large number of these, the results are paginated. To retrieve subsequent pages of events, pass in the pageToken returned from the prior call.
     *
     * @tags Accounting
     * @name GetAccountingEvents
     * @summary Get accounting events for the specified dates
     * @request GET:/accounting/journalEntry/events
     */
    getAccountingEvents: (
      query: {
        /**
         * start date in seconds since Epoch(GMT)
         * @format int64
         */
        from: number
        /**
         * number of items per page
         * @format int32
         */
        limit?: number
        /** pass this to subsequent calls */
        pageToken?: string
        /**
         * end date in seconds since Epoch(GMT)
         * @format int64
         */
        to: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AccountingEventPaginatedResponse, any>({
        path: `/accounting/journalEntry/events`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Returns all Journal Entries for the specified accounting period as text/csv
     *
     * @tags Accounting
     * @name GetJournalEntries
     * @summary Return Journal Entries for period
     * @request GET:/accounting/journalEntry
     */,
    getJournalEntries: (
      query: {
        /** Id of the period */
        accountingPeriodId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/accounting/journalEntry`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Returns deferred revenue and contract asset balances for the given revenue schedule, as of the given date.If no date is provided, the balances are returned as of the current date.
     *
     * @tags Accounting
     * @name GetRunningBalances
     * @summary Get running balances of revenue schedule
     * @request GET:/accounting/journalEntry/runningBalances
     */,
    getRunningBalances: (
      query: {
        /**
         * Date in seconds since Epoch(GMT)
         * @format int64
         */
        asOf?: number
        /** Revenue schedule id */
        scheduleId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/accounting/journalEntry/runningBalances`,
        method: 'GET',
        query: query,
        ...params,
      }),
  }
  metricsReporting = {
    /**
     * @description returns the request with its captured id. Arr metrics will be generated as a backend job
     *
     * @tags MetricsReporting
     * @name AddExternalArrMetrics
     * @summary submit request to generate external arr metrics
     * @request POST:/metricsReporting/externalArrMetrics
     */
    addExternalArrMetrics: (body: ExternalArrScheduleJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/metricsReporting/externalArrMetrics`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description returns the external arr metrics request for the provided id
     *
     * @tags MetricsReporting
     * @name GetExternalArrMetrics
     * @summary gets external arr metrics request for the provided id
     * @request GET:/metricsReporting/externalArrMetrics/{id}
     */,
    getExternalArrMetrics: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/metricsReporting/externalArrMetrics/${id}`,
        method: 'GET',
        ...params,
      }) /**
     * @description returns the paginated list of external arr metrics requests
     *
     * @tags MetricsReporting
     * @name GetExternalArrMetrics1
     * @summary gets external arr metrics requests submitted
     * @request GET:/metricsReporting/externalArrMetrics
     */,
    getExternalArrMetrics1: (
      query?: {
        /** @format uuid */
        cursor?: string
        /** @format int32 */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/metricsReporting/externalArrMetrics`,
        method: 'GET',
        query: query,
        ...params,
      }) /**
     * @description returns 200 if the result is successfully submitted to a background task
     *
     * @tags MetricsReporting
     * @name PopulateArrMetricsForSubscription
     * @summary submit request to generate/regenerate arr metrics for a subscription
     * @request PUT:/metricsReporting/populateArrMetrics/subscriptions/{subscriptionId}
     */,
    populateArrMetricsForSubscription: (subscriptionId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/metricsReporting/populateArrMetrics/subscriptions/${subscriptionId}`,
        method: 'PUT',
        ...params,
      }),
  }
  notifications = {
    /**
     * @description Adds a notification target and events according to the specified parameters
     *
     * @tags Notifications
     * @name AddTargetAndEventsSubscriptions
     * @summary Add a notification target
     * @request POST:/notifications
     */
    addTargetAndEventsSubscriptions: (body: NotificationTargetAndSubscriptions, params: RequestParams = {}) =>
      this.request<NotificationTargetAndSubscriptions, any>({
        path: `/notifications`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Returns all notification subscriptions for your tenant
     *
     * @tags Notifications
     * @name GetAllNotificationSubscriptionsForTenant
     * @summary Get all notifications
     * @request GET:/notifications
     */,
    getAllNotificationSubscriptionsForTenant: (params: RequestParams = {}) =>
      this.request<NotificationTargetAndSubscriptions[], any>({
        path: `/notifications`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Attaches a notification event to the target specified by the notification id
     *
     * @tags Notifications
     * @name SubscribeExistingNotificationTargetToEvent
     * @summary Attach an event to a notification
     * @request POST:/notifications/{notificationId}
     */,
    subscribeExistingNotificationTargetToEvent: (
      notificationId: string,
      query: {
        /** type of event */
        notificationEventType: 'INVOICE_POSTED' | 'SUBSCRIPTION_CREATED' | 'ORDER_SUBMITTED' | 'ORDER_EXECUTED'
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/notifications/${notificationId}`,
        method: 'POST',
        query: query,
        ...params,
      }) /**
     * @description Unsubscribes the specified notification target from the specified event
     *
     * @tags Notifications
     * @name UnsubscribeTargetOrEvent
     * @summary Unsubscribe from an event
     * @request POST:/notifications/unsubscribe/{notificationId}
     */,
    unsubscribeTargetOrEvent: (
      notificationId: string,
      query: {
        /** type of event */
        notificationEventType: 'INVOICE_POSTED' | 'SUBSCRIPTION_CREATED' | 'ORDER_SUBMITTED' | 'ORDER_EXECUTED'
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/notifications/unsubscribe/${notificationId}`,
        method: 'POST',
        query: query,
        ...params,
      }),
  }
  opportunity = {
    /**
     * @description Returns the details of a specified opportunity
     *
     * @tags Opportunity
     * @name CreateOpportunity
     * @summary Creates a standalone opportunity
     * @request POST:/opportunity
     */
    createOpportunity: (body: OpportunityRestJson, params: RequestParams = {}) =>
      this.request<OpportunityJson, any>({
        path: `/opportunity`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }) /**
     * @description Delete an opportunity with given CRM ID if there are no associated orders
     *
     * @tags Orders
     * @name DeleteOpportunityByCrmId
     * @summary Delete an opportunity with given CRM ID
     * @request DELETE:/opportunity/crm/{id}
     */,
    deleteOpportunityByCrmId: (id: string, params: RequestParams = {}) =>
      this.request<object[], any>({
        path: `/opportunity/crm/${id}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }) /**
     * @description Returns all opportunities associated with the specified account. The results are paginated. To fetch all results, pass the cursor returned from a call to subsequent calls.
     *
     * @tags Orders
     * @name GetOpportunities
     * @summary Return all opportunities
     * @request GET:/opportunity
     */,
    getOpportunities: (
      query: {
        /** id of account */
        accountId: string
        /** CRM ID of the opportunity */
        crmId?: string
        /**
         * pass the cursor returned from a call to to subsequent calls until all values are fetched
         * @format uuid
         */
        cursor?: string
        /**
         * number of results per page
         * @format int32
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<OpportunityPaginationResponse, any>({
        path: `/opportunity`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Returns the details of a specified opportunity
     *
     * @tags Orders
     * @name GetOpportunityByCrmOpportunityId
     * @summary Return details of an opportunity
     * @request GET:/opportunity/crm/{id}
     */,
    getOpportunityByCrmOpportunityId: (id: string, params: RequestParams = {}) =>
      this.request<OpportunityJson, any>({
        path: `/opportunity/crm/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns the details of a specified opportunity
     *
     * @tags Orders
     * @name GetOpportunityByOpportunityId
     * @summary Return details of an opportunity
     * @request GET:/opportunity/{id}
     */,
    getOpportunityByOpportunityId: (id: string, params: RequestParams = {}) =>
      this.request<OpportunityJson, any>({
        path: `/opportunity/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns the details of orders
     *
     * @tags Orders
     * @name GetOrdersByCrmOpportunityId
     * @summary Return a list of orders associated with a CRM opportunity id
     * @request GET:/opportunity/crm/{id}/orders
     */,
    getOrdersByCrmOpportunityId: (id: string, params: RequestParams = {}) =>
      this.request<object[], any>({
        path: `/opportunity/crm/${id}/orders`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns the details of the updated opportunity
     *
     * @tags Opportunity
     * @name ResetOpportunityClosedState
     * @summary Opens an opportunity
     * @request POST:/opportunity/{id}/open
     */,
    resetOpportunityClosedState: (id: string, params: RequestParams = {}) =>
      this.request<OpportunityJson, any>({
        path: `/opportunity/${id}/open`,
        method: 'POST',
        format: 'json',
        ...params,
      }) /**
     * @description Returns the details of the updated opportunity
     *
     * @tags Opportunity
     * @name UpdateOpportunity
     * @summary Updates a standalone opportunity
     * @request PUT:/opportunity/{id}
     */,
    updateOpportunity: (id: string, body: OpportunityRestJson, params: RequestParams = {}) =>
      this.request<OpportunityJson, any>({
        path: `/opportunity/${id}`,
        method: 'PUT',
        body: body,
        format: 'json',
        ...params,
      }),
  }
  orders = {
    /**
     * @description creates an order with the specified parameters. On success the order id is returned.
     *
     * @tags Orders
     * @name AddOrder
     * @summary Create an order
     * @request POST:/orders
     */
    addOrder: (
      body: OrderRequestJson,
      query?: {
        /** indicates whether this order should be persisted. */
        isDryRun?: boolean
        /** indicates whether an amendment should populate missing lines not provided here. */
        populateMissingLines?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<OrderJson, any>({
        path: `/orders`,
        method: 'POST',
        query: query,
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Generate and retrieve a PDF representation of the order details for a specific order by its ID.
     *
     * @tags Orders
     * @name CreateOrderDocument
     * @summary Generate an order PDF
     * @request POST:/orders/{id}/pdf
     */,
    createOrderDocument: (
      id: string,
      query?: {
        /** Force regeneration of the PDF document even if there has been no changes. Defaults to false. */
        force?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/orders/${id}/pdf`,
        method: 'POST',
        query: query,
        ...params,
      }) /**
     * @description Delete a specific order by its ID.
     *
     * @tags Orders
     * @name DeleteOrder
     * @summary Delete an order
     * @request DELETE:/orders/{id}
     */,
    deleteOrder: (id: string, params: RequestParams = {}) =>
      this.request<OrderJson, any>({
        path: `/orders/${id}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }) /**
     * @description Marks the order as executed. Optionally, the execution time can be specified using the executedOn query parameter.
     *
     * @tags Orders
     * @name ExecuteOrder
     * @summary Mark order as executed.
     * @request PUT:/orders/{id}/execute
     */,
    executeOrder: (
      id: string,
      query?: {
        /** Bypass approval flows by admin */
        adminApprovalFlowBypass?: boolean
        /**
         * The date and time when the order was executed.
         * @format int64
         */
        executedOn?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<OrderJson, any>({
        path: `/orders/${id}/execute`,
        method: 'PUT',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Forcefully executes an order. This endpoint bypasses approval checks and immediately executes the order.
     *
     * @tags Orders
     * @name ForceExecuteOrder
     * @summary Mark order as executed
     * @request PUT:/orders/{id}/execute/force
     */,
    forceExecuteOrder: (
      id: string,
      query?: {
        /**
         * The date and time when the order was executed.
         * @format int64
         */
        executedOn?: number
        /** Indicates whether to skip the approval check. Default is false. */
        skipApprovalCheck?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<OrderJson, any>({
        path: `/orders/${id}/execute/force`,
        method: 'PUT',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Get the custom billing schedule for the order
     *
     * @tags Orders
     * @name GetCustomBillingSchedule
     * @summary Get custom billing schedule for the order
     * @request GET:/orders/{orderId}/billing/custom
     */,
    getCustomBillingSchedule: (orderId: string, params: RequestParams = {}) =>
      this.request<CustomBillingScheduleOutput, any>({
        path: `/orders/${orderId}/billing/custom`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Retrieve details of a specific order by its ID.
     *
     * @tags Orders
     * @name GetOrder
     * @summary Get order details
     * @request GET:/orders/{id}
     */,
    getOrder: (id: string, params: RequestParams = {}) =>
      this.request<OrderJson, any>({
        path: `/orders/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Retrieves the PDF version of the order form for a specific order identified by its ID.
     *
     * @tags Orders
     * @name GetOrderDocument
     * @summary Fetch order form PDF
     * @request GET:/orders/{id}/pdf
     */,
    getOrderDocument: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/orders/${id}/pdf`,
        method: 'GET',
        ...params,
      }) /**
     * @description Download a Microsoft Word document of the order form for a specific order by its ID.
     *
     * @tags Orders
     * @name GetOrderDocumentDoc
     * @summary Download word doc version of order form
     * @request GET:/orders/{id}/doc
     */,
    getOrderDocumentDoc: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/orders/${id}/doc`,
        method: 'GET',
        ...params,
      }) /**
     * @description Download a Microsoft Word document of the order form for a specific order by its ID.
     *
     * @tags Orders
     * @name GetOrderDocumentDocx
     * @summary Download word docx version of order form
     * @request GET:/orders/{id}/docx
     */,
    getOrderDocumentDocx: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/orders/${id}/docx`,
        method: 'GET',
        ...params,
      }) /**
     * @description Retrieves metrics for all line items associated with the specified order.
     *
     * @tags Orders
     * @name GetOrderLineMetrics
     * @summary Get order line metrics
     * @request GET:/orders/{id}/lineItems/metrics
     */,
    getOrderLineMetrics: (id: string, params: RequestParams = {}) =>
      this.request<Record<string, MetricsJson>, any>({
        path: `/orders/${id}/lineItems/metrics`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Retrieve the metrics for a specific order by its ID. Metrics can be filtered by a target date.
     *
     * @tags Orders
     * @name GetOrderMetrics
     * @summary Get order metrics
     * @request GET:/orders/{id}/metrics
     */,
    getOrderMetrics: (
      id: string,
      query?: {
        /**
         * The target date for filtering metrics.
         * @format int64
         */
        targetDate?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MetricsJson, any>({
        path: `/orders/${id}/metrics`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Gets all orders for your tenant. The results are paginated. To fetch all results, take the cursor returned from a call and pass it to subsequent calls.
     *
     * @tags Orders
     * @name GetOrders
     * @summary Get all Orders
     * @request GET:/orders
     */,
    getOrders: (
      query?: {
        /**
         * A string token is used to fetch next set of results. If not provided, the first page of results will be returned. Use the 'next_cursor' value from the previous response to fetch the next page.
         * @format uuid
         */
        cursor?: string
        /**
         * An integer specifying the maximum number of results to return per page. Defaults to 10 if not provided. Limit is capped to 50 orders
         * @format int32
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<OrderJsonPaginationResponse, any>({
        path: `/orders`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Retrieves specific PDF of the order form for a specific order identified by its orderId.
     *
     * @tags Orders
     * @name GetSpecificOrderDocument
     * @summary Fetch specific order form PDF on order
     * @request GET:/orders/{orderId}/pdf/{id}
     */,
    getSpecificOrderDocument: (orderId: string, id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/orders/${orderId}/pdf/${id}`,
        method: 'GET',
        ...params,
      }) /**
     * @description Rebase a specific order by its ID. Rebasing an order involves recalculating its metrics or values based on updated data or criteria.
     *
     * @tags Orders
     * @name RebaseAmendment
     * @summary Rebase amendment against latest subscription version
     * @request PUT:/orders/{orderId}/rebase
     */,
    rebaseAmendment: (orderId: string, params: RequestParams = {}) =>
      this.request<OrderJson, any>({
        path: `/orders/${orderId}/rebase`,
        method: 'PUT',
        format: 'json',
        ...params,
      }) /**
     * @description Updates the details of the specified order.
     *
     * @tags Orders
     * @name UpdateOrder
     * @summary Update order details
     * @request PUT:/orders
     */,
    updateOrder: (
      body: OrderRequestJson,
      query?: {
        /** true if the order should not be persisted. */
        isDryRun?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/orders`,
        method: 'PUT',
        query: query,
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Update order attributes for non-draft orders
     *
     * @tags Orders
     * @name UpdateOrderAttributes
     * @summary Update order attributes for non-draft orders
     * @request PUT:/orders/{orderId}/attributes
     */,
    updateOrderAttributes: (orderId: string, body: OrderAttributesUpdateRequest, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/orders/${orderId}/attributes`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Update the status of a specific order by its ID.
     *
     * @tags Orders
     * @name UpdateOrderStatus
     * @summary Update order status
     * @request PUT:/orders/{id}/status/{status}
     */,
    updateOrderStatus: (
      id: string,
      status: 'DRAFT' | 'SUBMITTED' | 'EXECUTED' | 'CANCELLED',
      query?: {
        /** Admin approval to bypass the approval flow */
        adminApprovalFlowByPass?: boolean
        /**
         * The timestamp when order status was updated.
         * @format int64
         */
        statusUpdatedOn?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/orders/${id}/status/${status}`,
        method: 'PUT',
        query: query,
        type: ContentType.Json,
        ...params,
      }),
  }
  payments = {
    /**
     * No description
     *
     * @tags Payments
     * @name DeleteBankAccount
     * @summary Get a payment bank account by id
     * @request DELETE:/payments/bank-account/{id}
     */
    deleteBankAccount: (id: string, params: RequestParams = {}) =>
      this.request<PaymentBankAccountJson, any>({
        path: `/payments/bank-account/${id}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }) /**
     * @description Returns the payments for the specified account
     *
     * @tags Payments
     * @name GetAccountPayment
     * @summary Get payments
     * @request GET:/payments/account/{id}
     */,
    getAccountPayment: (id: string, params: RequestParams = {}) =>
      this.request<PaymentJson[], any>({
        path: `/payments/account/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns a payment management link for an account
     *
     * @tags Payments
     * @name GetAccountPaymentManagementLink
     * @summary Get account payment management link
     * @request GET:/payments/account-payment/{id}
     */,
    getAccountPaymentManagementLink: (id: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/payments/account-payment/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * No description
     *
     * @tags Payments
     * @name GetBankAccount
     * @summary Get a payment bank account by id
     * @request GET:/payments/bank-account/{id}
     */,
    getBankAccount: (id: string, params: RequestParams = {}) =>
      this.request<PaymentBankAccountJson, any>({
        path: `/payments/bank-account/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description The bank account would be mapped to a cash and an expense ledger account from this list
     *
     * @tags Payments
     * @name GetLedgerAccountsForPaymentBankAccount
     * @summary Gets cash and expense ledger accounts for bank account creation
     * @request GET:/payments/bank-account/accounts/ledger
     */,
    getLedgerAccountsForPaymentBankAccount: (
      query?: {
        id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<LedgerAccount[], any>({
        path: `/payments/bank-account/accounts/ledger`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Gets the details of the specified payment
     *
     * @tags Payments
     * @name GetPayment
     * @summary Get payment details
     * @request GET:/payments/{id}
     */,
    getPayment: (id: string, params: RequestParams = {}) =>
      this.request<PaymentJson, any>({
        path: `/payments/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Gets the balance of a payment
     *
     * @tags Payments
     * @name GetPaymentBalance
     * @summary Get payment balance
     * @request GET:/payments/{id}/balance
     */,
    getPaymentBalance: (id: string, params: RequestParams = {}) =>
      this.request<PaymentBalanceJson, any>({
        path: `/payments/${id}/balance`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns the payment configuration for your tenant
     *
     * @tags Payments
     * @name GetPaymentConfiguration
     * @summary Get payment configuration
     * @request GET:/payments/configuration
     */,
    getPaymentConfiguration: (params: RequestParams = {}) =>
      this.request<PaymentConfiguration, any>({
        path: `/payments/configuration`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns all payments for you tenant. The results are paginated. To fetch all take the cursor returned from a call and pass it to a subsequent call.
     *
     * @tags Payments
     * @name GetPayments
     * @summary Get all payments
     * @request GET:/payments
     */,
    getPayments: (
      query?: {
        /**
         * cursor returned from previous call
         * @format uuid
         */
        cursor?: string
        /**
         * number of results per page
         * @format int32
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<PaymentJsonPaginationResponse, any>({
        path: `/payments`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Updates the payment configuration for your tenant.
     *
     * @tags Payments
     * @name UpdatePaymentConfiguration
     * @summary Update payment configuration
     * @request POST:/payments/configuration
     */,
    updatePaymentConfiguration: (
      body: ('ACH' | 'CARD' | 'CHECK' | 'WIRE' | 'INVOICE' | 'DEPOSIT' | 'EXTERNAL')[],
      params: RequestParams = {}
    ) =>
      this.request<PaymentConfiguration, any>({
        path: `/payments/configuration`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description The bank account would be mapped to a cash and an expense ledger account
     *
     * @tags Payments
     * @name UpsertBankAccount
     * @summary Adds a new payment bank account
     * @request POST:/payments/bank-account
     */,
    upsertBankAccount: (body: PaymentBankAccountJson, params: RequestParams = {}) =>
      this.request<PaymentBankAccountJson, any>({
        path: `/payments/bank-account`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Voids the specified payment per the specified parameters
     *
     * @tags Payments
     * @name VoidPayment
     * @summary Voids a payment
     * @request PUT:/payments/{id}/void
     */,
    voidPayment: (id: string, body: VoidPaymentJson, params: RequestParams = {}) =>
      this.request<PaymentJson, any>({
        path: `/payments/${id}/void`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  plans = {
    /**
     * @description Marks a plan as active
     *
     * @tags Product Catalog
     * @name ActivatePlan
     * @summary Activate a plan
     * @request PUT:/plans/{planId}/activate
     */
    activatePlan: (planId: string, params: RequestParams = {}) =>
      this.request<PlanJson, any>({
        path: `/plans/${planId}/activate`,
        method: 'PUT',
        format: 'json',
        ...params,
      }) /**
     * @description Adds a charge to the specified plan.  On success the id of the new charge is returned
     *
     * @tags Product Catalog
     * @name AddCharge
     * @summary Add charge to plan
     * @request POST:/plans/{planId}/charges
     */,
    addCharge: (planId: string, body: ChargeJson, params: RequestParams = {}) =>
      this.request<ChargeJson, any>({
        path: `/plans/${planId}/charges`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Creates a plan. On success the id of the new plan is returned.
     *
     * @tags Product Catalog
     * @name AddPlan
     * @summary Create a plan
     * @request POST:/plans
     */,
    addPlan: (body: PlanJson, params: RequestParams = {}) =>
      this.request<PlanJson, any>({
        path: `/plans`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Marks a plan as draft
     *
     * @tags Product Catalog
     * @name DeactivatePlan
     * @summary Revert a plan to draft
     * @request PUT:/plans/{planId}/revertToDraft
     */,
    deactivatePlan: (planId: string, params: RequestParams = {}) =>
      this.request<PlanJson, any>({
        path: `/plans/${planId}/revertToDraft`,
        method: 'PUT',
        format: 'json',
        ...params,
      }) /**
     * @description Removes a charge from a plan.
     *
     * @tags Product Catalog
     * @name DeleteCharge
     * @summary Delete a charge
     * @request DELETE:/plans/{planId}/charges/{chargeId}
     */,
    deleteCharge: (planId: string, chargeId: string, params: RequestParams = {}) =>
      this.request<ChargeJson, any>({
        path: `/plans/${planId}/charges/${chargeId}`,
        method: 'DELETE',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Deletes a plan. Note you can't delete a plan that's in use.
     *
     * @tags Product Catalog
     * @name DeletePlan
     * @summary Delete a plan
     * @request DELETE:/plans/{planId}
     */,
    deletePlan: (planId: string, params: RequestParams = {}) =>
      this.request<PlanJson, any>({
        path: `/plans/${planId}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }) /**
     * @description Marks a plan as deprecated. Once deprecated a plan may not be attached to new orders.
     *
     * @tags Product Catalog
     * @name DeprecatePlan
     * @summary Deprecate a plan
     * @request PUT:/plans/{planId}/deprecate
     */,
    deprecatePlan: (planId: string, params: RequestParams = {}) =>
      this.request<PlanJson, any>({
        path: `/plans/${planId}/deprecate`,
        method: 'PUT',
        format: 'json',
        ...params,
      }) /**
     * @description Duplicates the specified plan. On success the new plan id is returned.
     *
     * @tags Product Catalog
     * @name DuplicatePlan
     * @summary Duplicate a plan
     * @request POST:/plans/{planId}/duplicate
     */,
    duplicatePlan: (planId: string, params: RequestParams = {}) =>
      this.request<PlanJson, any>({
        path: `/plans/${planId}/duplicate`,
        method: 'POST',
        format: 'json',
        ...params,
      }) /**
     * @description Gets the details of the specified charge on the specified plan.
     *
     * @tags Product Catalog
     * @name GetCharge1
     * @summary Get charge details
     * @request GET:/plans/{planId}/charges/{chargeId}
     */,
    getCharge1: (planId: string, chargeId: string, params: RequestParams = {}) =>
      this.request<ChargeJson, any>({
        path: `/plans/${planId}/charges/${chargeId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Gets the ledger accounts mapped to the specified charge
     *
     * @tags Product Catalog
     * @name GetChargeLedgerAccounts
     * @summary Get ledger accounts
     * @request GET:/plans/{planId}/charges/{chargeId}/ledgerAccounts
     */,
    getChargeLedgerAccounts: (planId: string, chargeId: string, params: RequestParams = {}) =>
      this.request<LedgerAccount[], any>({
        path: `/plans/${planId}/charges/${chargeId}/ledgerAccounts`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns the details of the specified plan.
     *
     * @tags Product Catalog
     * @name GetPlan
     * @summary Get plan details
     * @request GET:/plans/{planId}
     */,
    getPlan: (planId: string, params: RequestParams = {}) =>
      this.request<PlanJson, any>({
        path: `/plans/${planId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns all plans for a product. The result is paginated. To retrieve all results pass the cursor returned from a call to the next call until all results are returned.
     *
     * @tags Product Catalog
     * @name GetPlans
     * @summary Get plans
     * @request GET:/plans
     */,
    getPlans: (
      query?: {
        /**
         * cursor from the last call
         * @format uuid
         */
        cursor?: string
        /**
         * number of results per page
         * @format int32
         */
        limit?: number
        /** id of the product */
        productId?: string
        /** filter by plan status */
        status?: 'DRAFT' | 'ACTIVE' | 'GRANDFATHERED' | 'ARCHIVED' | 'DEPRECATED'
      },
      params: RequestParams = {}
    ) =>
      this.request<PlanJsonPaginationResponse, any>({
        path: `/plans`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * No description
     *
     * @tags Product Catalog
     * @name GetReplacedPlans
     * @summary Get replaced plans
     * @request GET:/plans/{planId}/replacedPlans
     */,
    getReplacedPlans: (planId: string, params: RequestParams = {}) =>
      this.request<PlanJson[], any>({
        path: `/plans/${planId}/replacedPlans`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Map ledger accounts to the specified charge for the specified plan.
     *
     * @tags Product Catalog
     * @name MapLedgerAccountsToCharge
     * @summary Map ledger accounts
     * @request PUT:/plans/{planId}/charges/{chargeId}/ledgerAccounts
     */,
    mapLedgerAccountsToCharge: (planId: string, chargeId: string, body: string[], params: RequestParams = {}) =>
      this.request<LedgerAccount[], any>({
        path: `/plans/${planId}/charges/${chargeId}/ledgerAccounts`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Updates certain details of the specified charge which won't impact its financial treatment
     *
     * @tags Product Catalog
     * @name PatchCharge
     * @summary Update non-financial charge details
     * @request PUT:/plans/{planId}/charges/{chargeId}/partial
     */,
    patchCharge: (planId: string, chargeId: string, body: ChargePartialJson, params: RequestParams = {}) =>
      this.request<ChargeJson, any>({
        path: `/plans/${planId}/charges/${chargeId}/partial`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Reactivates a deprecated plan.
     *
     * @tags Product Catalog
     * @name ReactivatePlan
     * @summary Reactivate a plan
     * @request PUT:/plans/{planId}/reactivate
     */,
    reactivatePlan: (planId: string, params: RequestParams = {}) =>
      this.request<PlanJson, any>({
        path: `/plans/${planId}/reactivate`,
        method: 'PUT',
        format: 'json',
        ...params,
      }) /**
     * @description Updates the details of the specified charge on the specified plan.
     *
     * @tags Product Catalog
     * @name UpdateCharge
     * @summary Update charge details
     * @request PUT:/plans/{planId}/charges/{chargeId}
     */,
    updateCharge: (planId: string, chargeId: string, body: ChargeJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/plans/${planId}/charges/${chargeId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Updates the details of the specified plan. Note you can't update the details of a plan once it's in use.
     *
     * @tags Product Catalog
     * @name UpdatePlan
     * @summary Update plan
     * @request PUT:/plans/{planId}
     */,
    updatePlan: (planId: string, body: PlanJson, params: RequestParams = {}) =>
      this.request<PlanJson, any>({
        path: `/plans/${planId}`,
        method: 'PUT',
        body: body,
        format: 'json',
        ...params,
      }) /**
     * @description Update plan metadata. For now, this can be used only to change the entities assigned to a plan
     *
     * @tags Product Catalog
     * @name UpdatePlanMetadata
     * @summary Update plan metadata
     * @request PUT:/plans/{planId}/metadata
     */,
    updatePlanMetadata: (planId: string, body: PlanMetadataJson, params: RequestParams = {}) =>
      this.request<PlanJson, any>({
        path: `/plans/${planId}/metadata`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Updates predefined terms associated with the specified plan.
     *
     * @tags Product Catalog
     * @name UpdatePlanTerms
     * @summary Update plan terms
     * @request PUT:/plans/{planId}/terms
     */,
    updatePlanTerms: (planId: string, body: string[], params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/plans/${planId}/terms`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  }
  termsections = {
    /**
     * @description Adds a predefined terms section.
     *
     * @tags Settings
     * @name AddTermSection
     * @summary Add predefined terms section
     * @request POST:/termsections
     */
    addTermSection: (body: DocumentSection, params: RequestParams = {}) =>
      this.request<DocumentSection, any>({
        path: `/termsections`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }) /**
     * @description Deletes the predefined terms section specified.
     *
     * @tags Settings
     * @name DeleteTermSection
     * @summary Delete predefined terms section
     * @request DELETE:/termsections/{id}
     */,
    deleteTermSection: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/termsections/${id}`,
        method: 'DELETE',
        ...params,
      }) /**
     * @description Returns predefined terms section detail.
     *
     * @tags Settings
     * @name GetTermSection
     * @summary Get predefined terms section detail
     * @request GET:/termsections/{id}
     */,
    getTermSection: (id: string, params: RequestParams = {}) =>
      this.request<DocumentSection, any>({
        path: `/termsections/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns predefined terms sections.
     *
     * @tags Settings
     * @name GetTermSections
     * @summary Get predefined terms sections
     * @request GET:/termsections
     */,
    getTermSections: (params: RequestParams = {}) =>
      this.request<DocumentSection, any>({
        path: `/termsections`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Updates the predefined terms section specified.
     *
     * @tags Settings
     * @name UpdateTermSection
     * @summary Update predefined terms section
     * @request PUT:/termsections/{id}
     */,
    updateTermSection: (id: string, body: DocumentSection, params: RequestParams = {}) =>
      this.request<DocumentSection, any>({
        path: `/termsections/${id}`,
        method: 'PUT',
        body: body,
        format: 'json',
        ...params,
      }),
  }
  product = {
    /**
     * @description Creates a product category. On success the id of the category is returned.
     *
     * @tags Product Catalog
     * @name AddProductCategory
     * @summary Create a product category
     * @request POST:/product/categories
     */
    addProductCategory: (body: ProductCategory, params: RequestParams = {}) =>
      this.request<ProductCategory, any>({
        path: `/product/categories`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Deletes the specified product category. Note that a product category can't be delete once it's in use.
     *
     * @tags Product Catalog
     * @name DeleteProductCategory
     * @summary Delete a product category
     * @request DELETE:/product/categories/{id}
     */,
    deleteProductCategory: (id: string, params: RequestParams = {}) =>
      this.request<ProductCategory, any>({
        path: `/product/categories/${id}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }) /**
     * @description Gets all product categories for your tenant. The results are paginated. To fetch all results pass the cursor returned from a call to the subsequent calls until all results are returned. Initially the cursor should not be specified.
     *
     * @tags Product Catalog
     * @name GetProductCategories
     * @summary Get product Categories
     * @request GET:/product/categories
     */,
    getProductCategories: (
      query?: {
        /**
         * cursor from prior call
         * @format uuid
         */
        cursor?: string
        /**
         * number of results per page
         * @format int32
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ProductCategoryPaginationResponse, any>({
        path: `/product/categories`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Gets the details of the specified product category
     *
     * @tags Product Catalog
     * @name GetProductCategory
     * @summary Get product category details
     * @request GET:/product/categories/{id}
     */,
    getProductCategory: (id: string, params: RequestParams = {}) =>
      this.request<ProductCategory, any>({
        path: `/product/categories/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Updates the details of a product category
     *
     * @tags Product Catalog
     * @name UpdateProductCategory
     * @summary Update product category details
     * @request PUT:/product/categories/{id}
     */,
    updateProductCategory: (id: string, body: ProductCategory, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/product/categories/${id}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  }
  products = {
    /**
     * @description Creates a product for your tenant. On success the id of the product is returned.
     *
     * @tags Product Catalog
     * @name AddProduct
     * @summary Create a product
     * @request POST:/products
     */
    addProduct: (body: ProductInputJson, params: RequestParams = {}) =>
      this.request<ProductJson, any>({
        path: `/products`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Delete the specified product.
     *
     * @tags Product Catalog
     * @name DeleteProduct
     * @summary Delete a product
     * @request DELETE:/products/{id}
     */,
    deleteProduct: (id: string, params: RequestParams = {}) =>
      this.request<ProductJson, any>({
        path: `/products/${id}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }) /**
     * @description Export the product catalog in CSV format.
     *
     * @tags Product Catalog
     * @name ExportProductCatalog
     * @summary Export product catalog
     * @request GET:/products/export
     */,
    exportProductCatalog: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/products/export`,
        method: 'GET',
        ...params,
      }) /**
     * @description Gets the details of the specified product.
     *
     * @tags Product Catalog
     * @name GetProduct
     * @summary Get product details.
     * @request GET:/products/{id}
     */,
    getProduct: (id: string, params: RequestParams = {}) =>
      this.request<ProductJson, any>({
        path: `/products/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns all products for your tenant. The results on paginated. To fetch them all pass the cursor returned from a call to the subsequent call until all results are fetched. Initially the cursor should not be specified.
     *
     * @tags Product Catalog
     * @name GetProducts
     * @summary Get products
     * @request GET:/products
     */,
    getProducts: (
      query?: {
        /**
         * cursor received from prior call
         * @format uuid
         */
        cursor?: string
        /**
         * number of results per page
         * @format int32
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ProductJsonPaginationResponse, any>({
        path: `/products`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Updates the details of the specified product
     *
     * @tags Product Catalog
     * @name UpdateProduct
     * @summary Update product details
     * @request PUT:/products/{id}
     */,
    updateProduct: (id: string, body: ProductInputJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/products/${id}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  }
  quotes = {
    /**
     * No description
     *
     * @name BuildQuote
     * @request PUT:/quotes
     */
    buildQuote: (
      body: Answer[],
      query?: {
        accountId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/quotes`,
        method: 'PUT',
        query: query,
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Get the next question given a set of answers to questions so far
     *
     * @tags Experimental
     * @name NextQuestion
     * @summary Get Next Question for Guided Selling
     * @request PUT:/quotes/v0/nextQuestion
     */,
    nextQuestion: (
      body: Answer[],
      query?: {
        usecase?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<NextQuestion, any>({
        path: `/quotes/v0/nextQuestion`,
        method: 'PUT',
        query: query,
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Given the use case, list of answers and Zeppa Q script try creating the order in dryrun mode
     *
     * @tags Experimental
     * @name TestZeppaQScript
     * @summary Test quote building Zeppa Q script
     * @request PUT:/quotes/v0/testQuoteBuilder
     */,
    testZeppaQScript: (
      query: {
        accountId: string
        usecase: string
      },
      data: {
        answers: any[]
        qscript: string
      },
      params: RequestParams = {}
    ) =>
      this.request<OrderJson, any>({
        path: `/quotes/v0/testQuoteBuilder`,
        method: 'PUT',
        query: query,
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  }
  ratecards = {
    /**
     * @description create a rate card using the multipart form data, where a CSV can be passed in for the price table
     *
     * @tags RateCard
     * @name AddRateCardCsv
     * @summary create a rate card using CSV price table multipart form data
     * @request POST:/ratecards/csv
     */
    addRateCardCsv: (data: any, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/ratecards/csv`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        ...params,
      }) /**
     * @description Get all the price attributes in the system in CSV format one per row
     *
     * @tags RateCard
     * @name GetPriceAttributesCsv
     * @summary Fetch the price attributes defined in the system in CSV format
     * @request GET:/ratecards/attributes/csv
     */,
    getPriceAttributesCsv: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/ratecards/attributes/csv`,
        method: 'GET',
        ...params,
      }) /**
     * @description Get the price table for the rate card given the id
     *
     * @tags RateCard
     * @name GetRateCardPriceTable
     * @summary Fetch the price table for the rate card given the id
     * @request GET:/ratecards/{id}/priceTable
     */,
    getRateCardPriceTable: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/ratecards/${id}/priceTable`,
        method: 'GET',
        ...params,
      }) /**
     * @description Fetch the list of rate cards stored in the system
     *
     * @tags RateCard
     * @name GetRateCards
     * @summary Fetch the list of rate cards stored in the system
     * @request GET:/ratecards
     */,
    getRateCards: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/ratecards`,
        method: 'GET',
        ...params,
      }) /**
     * @description Import the price attributes from an input CSV file, the output provides details on each row of the input
     *
     * @tags RateCard
     * @name ImportPriceAttributes
     * @summary Import price attributes into the system
     * @request POST:/ratecards/attributes/csv
     */,
    importPriceAttributes: (data: any, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/ratecards/attributes/csv`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        ...params,
      }) /**
     * @description create a rate card using the multipart form data, where a CSV can be passed in for the price table
     *
     * @tags RateCard
     * @name UpdateRateCardCsv
     * @summary create a rate card using CSV price table multipart form data
     * @request PUT:/ratecards/{id}/csv
     */,
    updateRateCardCsv: (
      id: string,
      data: {
        currency?: string
        description?: string
        file?: File
        name?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/ratecards/${id}/csv`,
        method: 'PUT',
        body: data,
        type: ContentType.FormData,
        ...params,
      }),
  }
  refunds = {
    /**
     * @description Creates and applies a refund per the specified parameters. On success the id of the refund is returned.
     *
     * @tags Refunds
     * @name CreateAndApplyRefund
     * @summary Create and apply refund
     * @request POST:/refunds
     */
    createAndApplyRefund: (body: RefundRequestJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/refunds`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Get the details of the specified refund.
     *
     * @tags Refunds
     * @name GetRefundById
     * @summary Get refund details
     * @request GET:/refunds/{id}
     */,
    getRefundById: (
      id: string,
      query: {
        /** id of the account */
        accountId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<Refund, any>({
        path: `/refunds/${id}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Get all refunds for the specified account, OR if a credit memo number is specified in addition to the account id, get the details of only that.
     *
     * @tags Refunds
     * @name GetRefunds
     * @summary Get refunds
     * @request GET:/refunds
     */,
    getRefunds: (
      query: {
        /** id of the account */
        accountId: string
        /** id of a credit memo */
        creditMemoNumber?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<RefundDetail[], any>({
        path: `/refunds`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  reports = {
    /**
     * @description Generates a report with the specified parameters. This report can later be downloaded via /reports/{reportRunId}/result
     *
     * @tags Reports
     * @name Generate
     * @summary Generate a report
     * @request POST:/reports/generate
     */
    generate: (body: PredefinedReportJson, params: RequestParams = {}) =>
      this.request<ReportJobResponse, any>({
        path: `/reports/generate`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Returns the definitions of the reports defined for your tenant.
     *
     * @tags Reports
     * @name GetPredefinedReportDefs
     * @summary Get report definitions
     * @request GET:/reports
     */,
    getPredefinedReportDefs: (params: RequestParams = {}) =>
      this.request<PredefinedReportDefsJson, any>({
        path: `/reports`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Runs a report generated with /generate. On success a csv of the report is returned.
     *
     * @tags Reports
     * @name GetReportOutput
     * @summary Run a generated report
     * @request GET:/reports/{reportRunId}/result
     */,
    getReportOutput: (reportRunId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/reports/${reportRunId}/result`,
        method: 'GET',
        ...params,
      }) /**
     * @description Runs the specified report and returns the result as a csv.
     *
     * @tags Reports
     * @name Run
     * @summary Run a report
     * @request POST:/reports/run
     */,
    run: (body: PredefinedReportJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/reports/run`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  }
  revenueEnablement = {
    /**
     * @description This will check if accounting events are present for all transaction types
     *
     * @tags Revenue Enablement
     * @name AreAccountingEventsPresentForAllTransactionTypes
     * @summary Check if accounting events are present for all transaction types
     * @request GET:/revenueEnablement/readiness/areAccountingEventsPresentForAllTransactionTypes
     */
    areAccountingEventsPresentForAllTransactionTypes: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/revenueEnablement/readiness/areAccountingEventsPresentForAllTransactionTypes`,
        method: 'GET',
        ...params,
      }) /**
     * @description This will check if all charges are tagged with GL accounts
     *
     * @tags Revenue Enablement
     * @name AreAllChargesTaggedWithGlAccounts
     * @summary Check if all charges are tagged with GL accounts
     * @request GET:/revenueEnablement/readiness/areAllChargesTaggedWithGLAccounts
     */,
    areAllChargesTaggedWithGlAccounts: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/revenueEnablement/readiness/areAllChargesTaggedWithGLAccounts`,
        method: 'GET',
        ...params,
      }) /**
     * @description This will check if all charges are tagged with revenue rules
     *
     * @tags Revenue Enablement
     * @name AreAllChargesTaggedWithRevenueRules
     * @summary Check if all charges are tagged with revenue rules
     * @request GET:/revenueEnablement/readiness/areAllChargesTaggedWithRevenueRules
     */,
    areAllChargesTaggedWithRevenueRules: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/revenueEnablement/readiness/areAllChargesTaggedWithRevenueRules`,
        method: 'GET',
        ...params,
      }) /**
     * @description This will check if all order lines have schedules
     *
     * @tags Revenue Enablement
     * @name DoAllOrderLinesHaveSchedules
     * @summary Check if all order lines have schedules
     * @request GET:/revenueEnablement/readiness/doAllOrderLinesHaveSchedules
     */,
    doAllOrderLinesHaveSchedules: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/revenueEnablement/readiness/doAllOrderLinesHaveSchedules`,
        method: 'GET',
        ...params,
      }) /**
     * @description This will check if an open accounting period exists
     *
     * @tags Revenue Enablement
     * @name DoesOpenAccountingPeriodExist
     * @summary Check if an open accounting period exists
     * @request GET:/revenueEnablement/readiness/doesOpenAccountingPeriodExist
     */,
    doesOpenAccountingPeriodExist: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/revenueEnablement/readiness/doesOpenAccountingPeriodExist`,
        method: 'GET',
        ...params,
      }) /**
     * @description This will enable revenue recognition
     *
     * @tags Revenue Enablement
     * @name EnableRevenueRecognition
     * @summary Enable revenue recognition
     * @request PUT:/revenueEnablement/enable
     */,
    enableRevenueRecognition: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/revenueEnablement/enable`,
        method: 'PUT',
        ...params,
      }) /**
     * @description This will get revenue enablement progress
     *
     * @tags Revenue Enablement
     * @name GetRevenueEnablementProgress
     * @summary Get revenue enablement progress
     * @request GET:/revenueEnablement/progress
     */,
    getRevenueEnablementProgress: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/revenueEnablement/progress`,
        method: 'GET',
        ...params,
      }) /**
     * @description This will update revenue enablement progress
     *
     * @tags Revenue Enablement
     * @name UpsertRevenueEnablementProgress
     * @summary Update revenue enablement progress
     * @request PUT:/revenueEnablement/progress
     */,
    upsertRevenueEnablementProgress: (body: RevenueEnablementProgress, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/revenueEnablement/progress`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  }
  revrec = {
    /**
     * @description Creates a revenue recognition rule.
     *
     * @tags Revenue Recognition
     * @name AddRecognitionRule
     * @summary Create a revenue recognition rule
     * @request POST:/revrec/rules
     */
    addRecognitionRule: (body: RecognitionRule, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/revrec/rules`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Creates a bulk revenue recognition as specified by the input parameters. On success the id of the bulk revenue recognition is returned.
     *
     * @tags Revenue Recognition
     * @name CreateBulkRevenueRecognition
     * @summary Create a bulk revenue recognition
     * @request POST:/revrec/bulk
     */,
    createBulkRevenueRecognition: (body: BulkRevenueRecognitionInput, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/revrec/bulk`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Creates a revenue recognition event, only if it is different from the previous event for same subscription and charge.
     *
     * @tags Revenue Recognition
     * @name CreateRecognitionEvent
     * @summary Create a revenue recognition event
     * @request POST:/revrec/events
     */,
    createRecognitionEvent: (body: RecognitionEventCompletion, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/revrec/events`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Deletes a recognition rule. Note you can't delete a recognition rule that's in use.
     *
     * @tags Revenue Recognition
     * @name DeleteRule
     * @summary Delete a recognition rule
     * @request DELETE:/revrec/rules/{ruleId}
     */,
    deleteRule: (ruleId: string, params: RequestParams = {}) =>
      this.request<RecognitionRule, any>({
        path: `/revrec/rules/${ruleId}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }) /**
     * @description Returns the details of the specified bulk revenue recognition
     *
     * @tags Revenue Recognition
     * @name GetBulkRevenueRecognition
     * @summary Get bulk revenue recognition details
     * @request GET:/revrec/bulk/{bulkRevenueRecognitionId}
     */,
    getBulkRevenueRecognition: (bulkRevenueRecognitionId: string, params: RequestParams = {}) =>
      this.request<BulkRevenueRecognition, any>({
        path: `/revrec/bulk/${bulkRevenueRecognitionId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns the items associated with the specified bulk revenue recognition
     *
     * @tags Revenue Recognition
     * @name GetBulkRevenueRecognitionItems
     * @summary Get items for bulk revenue recognition
     * @request GET:/revrec/bulk/{bulkRevenueRecognitionId}/revrecItems
     */,
    getBulkRevenueRecognitionItems: (bulkRevenueRecognitionId: string, params: RequestParams = {}) =>
      this.request<BulkRevenueRecognitionItem[], any>({
        path: `/revrec/bulk/${bulkRevenueRecognitionId}/revrecItems`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Gets revenue recognition events for the specified subscription and charge.
     *
     * @tags Revenue Recognition
     * @name GetRecognitionEventsBySubscriptionIdChargeId
     * @summary Get revenue recognition events
     * @request GET:/revrec/events
     */,
    getRecognitionEventsBySubscriptionIdChargeId: (
      query: {
        /** id of the accounting period to limit events to */
        accountingPeriodId?: string
        /** id of the charge */
        chargeId: string
        /** id of the subscription */
        subscriptionId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<RecognitionEventCompletion[], any>({
        path: `/revrec/events`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Get revenue recognition rules.
     *
     * @tags Revenue Recognition
     * @name GetRecognitionRuleById
     * @summary Get revenue recognition rules
     * @request GET:/revrec/rules
     */,
    getRecognitionRuleById: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/revrec/rules`,
        method: 'GET',
        ...params,
      }) /**
     * @description Get a revenue recognition rule using id.
     *
     * @tags Revenue Recognition
     * @name GetRecognitionRuleById1
     * @summary Get revenue recognition rule
     * @request GET:/revrec/rules/{id}
     */,
    getRecognitionRuleById1: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/revrec/rules/${id}`,
        method: 'GET',
        ...params,
      }) /**
     * @description Downloads a revenue waterfall report in csv format.
     *
     * @tags Revenue Recognition
     * @name GetRevenueWaterfall
     * @summary Download waterfall report
     * @request GET:/revrec/waterfall
     */,
    getRevenueWaterfall: (
      query?: {
        /**
         * report end date as unix timestamp
         * @format int64
         */
        endDate?: number
        /**
         * report start date as unix timestamp
         * @format int64
         */
        startDate?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/revrec/waterfall`,
        method: 'GET',
        query: query,
        ...params,
      }) /**
     * @description Uploads revenue events from a csv file. The format of the file is Subscription.Id,Charge.Id,Alias.Id,RevrecEvent.PercentComplete Percent complete should be a decimal.
     *
     * @tags Revenue Recognition
     * @name UploadCompletionEvents
     * @summary Upload revenue events
     * @request POST:/revrec/events/upload
     */,
    uploadCompletionEvents: (data: any, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/revrec/events/upload`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        ...params,
      }),
  }
  sfdc = {
    /**
     * @description Handles the authorization code callback from Salesforce
     *
     * @tags Integrations
     * @name AuthorizationCodeCallback1
     * @summary Callback for the authorization code
     * @request GET:/sfdc
     */
    authorizationCodeCallback1: (
      query: {
        /** authorization code */
        code: string
        /** uri to redirect to */
        redirect_uri?: string
        /** id of the integration */
        state: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/sfdc`,
        method: 'GET',
        query: query,
        ...params,
      }) /**
     * @description Removes the Salesforce integration for the tenant.
     *
     * @tags Integrations
     * @name DeleteIntegration2
     * @summary Delete Salesforce integration
     * @request DELETE:/sfdc
     */,
    deleteIntegration2: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/sfdc`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Gets a Salesforce account by its id.
     *
     * @tags Integrations
     * @name GetAccountById
     * @summary Get Salesforce account
     * @request GET:/sfdc/account/{id}
     */,
    getAccountById: (id: string, params: RequestParams = {}) =>
      this.request<SalesforceAccount, any>({
        path: `/sfdc/account/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns Salesforce accounts matching the specified name.
     *
     * @tags Integrations
     * @name GetAccountsByName
     * @summary Get Salesforce accounts
     * @request GET:/sfdc/account
     */,
    getAccountsByName: (
      query: {
        /** account name */
        name: string
      },
      params: RequestParams = {}
    ) =>
      this.request<SalesforceAccount[], any>({
        path: `/sfdc/account`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Returns the Salesforce opportunities associated withe the specified account.
     *
     * @tags Integrations
     * @name GetOpportunitiesByAccountId
     * @summary Get Salesforce opportunities
     * @request GET:/sfdc/opportunity
     */,
    getOpportunitiesByAccountId: (
      query: {
        /** id of the account */
        accountId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<Opportunity[], any>({
        path: `/sfdc/opportunity`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Imports an account from Salesforce. On success a redirect uri is returned.
     *
     * @tags Integrations
     * @name ImportAccount
     * @summary Import account from Salesforce
     * @request POST:/sfdc/account
     */,
    importAccount: (body: SalesforceAccount, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/sfdc/account`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Initiate a Salesforce integration. On success a redirect url is returned.
     *
     * @tags Integrations
     * @name InitiateIntegration2
     * @summary Initiate integration with Salesforce
     * @request POST:/sfdc
     */,
    initiateIntegration2: (body: SalesforceClientIntegrationRequestJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/sfdc`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Syncs the account ARR and its orders to Salesforce.
     *
     * @tags Integrations
     * @name SyncAccountToSalesforce
     * @summary Sync an account to Salesforce
     * @request PUT:/sfdc/syncAccount/{id}
     */,
    syncAccountToSalesforce: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/sfdc/syncAccount/${id}`,
        method: 'PUT',
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Syncs the specified order to Salesforce.
     *
     * @tags Integrations
     * @name SyncDeletedOrdersToSalesforce
     * @summary Sync orders to Salesforce
     * @request PUT:/sfdc/syncDeletedOrders
     */,
    syncDeletedOrdersToSalesforce: (ids: string[], params: RequestParams = {}) =>
      this.request<OrderJson, any>({
        path: `/sfdc/syncDeletedOrders`,
        method: 'PUT',
        body: ids,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Syncs the specified order to Salesforce.
     *
     * @tags Integrations
     * @name SyncOrdersToSalesforce
     * @summary Sync orders to Salesforce
     * @request PUT:/sfdc/syncOrders
     */,
    syncOrdersToSalesforce: (ids: string[], params: RequestParams = {}) =>
      this.request<OrderJson, any>({
        path: `/sfdc/syncOrders`,
        method: 'PUT',
        body: ids,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Syncs the specified order to Salesforce.
     *
     * @tags Integrations
     * @name SyncOrderToSalesforce
     * @summary Sync order to Salesforce
     * @request PUT:/sfdc/sync/{id}
     */,
    syncOrderToSalesforce: (id: string, params: RequestParams = {}) =>
      this.request<OrderJson, any>({
        path: `/sfdc/sync/${id}`,
        method: 'PUT',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Syncs the specified tenant to Salesforce. Returns paginated JSON of accounts which were synced
     *
     * @tags Integrations
     * @name SyncTenantToSalesforce
     * @summary Sync whole tenant to Salesforce in a paginated fashion
     * @request PUT:/sfdc/syncTenant
     */,
    syncTenantToSalesforce: (
      query: {
        /** @format uuid */
        cursor?: string
        /** @format int32 */
        limit?: number
        tenantId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/sfdc/syncTenant`,
        method: 'PUT',
        query: query,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Marks an order as the primary order for its opportunity.
     *
     * @tags Integrations
     * @name UpdatePrimaryOrderIdForOpportunity
     * @summary Make an order primary
     * @request PUT:/sfdc/order/{id}
     */,
    updatePrimaryOrderIdForOpportunity: (id: string, params: RequestParams = {}) =>
      this.request<OrderJson, any>({
        path: `/sfdc/order/${id}`,
        method: 'PUT',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  slack = {
    /**
     * No description
     *
     * @tags slack quote builder
     * @name InitiateQuoteBuilding
     * @summary Get the quote building process started in slack
     * @request POST:/slack/quotes/initiate
     */
    initiateQuoteBuilding: (body: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/slack/quotes/initiate`,
        method: 'POST',
        body: body,
        ...params,
      }) /**
     * No description
     *
     * @tags slack quote builder
     * @name ProcessUserReplyToThread
     * @summary Get the communication going for quote building process started in slack
     * @request POST:/slack/quotes/event/process
     */,
    processUserReplyToThread: (body: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/slack/quotes/event/process`,
        method: 'POST',
        body: body,
        ...params,
      }),
  }
  subscriptions = {
    /**
     * @description Deletes the subscription for given subscription Id if invoices have not been generated and revenue has not been recognized
     *
     * @tags Subscriptions
     * @name DeleteSubscription
     * @summary Delete subscription
     * @request DELETE:/subscriptions/{id}/{version}
     */
    deleteSubscription: (id: string, version: number, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/subscriptions/${id}/${version}`,
        method: 'DELETE',
        ...params,
      }) /**
     * @description Gets the billing periods for the specified subscription.
     *
     * @tags Subscriptions
     * @name GetSubscriptionBillingPeriods
     * @summary Get billing periods
     * @request GET:/subscriptions/{id}/billingPeriods
     */,
    getSubscriptionBillingPeriods: (id: string, params: RequestParams = {}) =>
      this.request<number[], any>({
        path: `/subscriptions/${id}/billingPeriods`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Gets the details of the specified subscription.
     *
     * @tags Subscriptions
     * @name GetSubscriptionById
     * @summary Get subscription details
     * @request GET:/subscriptions/{id}
     */,
    getSubscriptionById: (id: string, params: RequestParams = {}) =>
      this.request<SubscriptionJson, any>({
        path: `/subscriptions/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns the metrics associated with the specified subscription. Metrics include ACV, ARR, etc.
     *
     * @tags Subscriptions
     * @name GetSubscriptionMetrics
     * @summary Get subscription metrics
     * @request GET:/subscriptions/{id}/metrics
     */,
    getSubscriptionMetrics: (
      id: string,
      query?: {
        /**
         * As of date for the metrics. If omitted defaults to now.
         * @format int64
         */
        targetDate?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MetricsJson, any>({
        path: `/subscriptions/${id}/metrics`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description returns all Subscriptions in the system in a paginated fashion
     *
     * @tags Subscriptions
     * @name GetSubscriptions
     * @summary Get paginated subscriptions
     * @request GET:/subscriptions
     */,
    getSubscriptions: (
      query?: {
        /** optionally pass in account Id, only subscriptions for this account will will returned */
        accountId?: string
        /**
         * number of items per page
         * @format int32
         */
        limit?: number
        /** pass this to subsequent calls */
        pageToken?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedSubscriptionsResponse, any>({
        path: `/subscriptions`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Reverts the subscription for given subscription Id and version to it's previous version. Operation is allowed only if invoices have not been generated and revenue has not been recognized
     *
     * @tags Subscriptions
     * @name RevertSubscription
     * @summary Revert subscription
     * @request PUT:/subscriptions/{id}/{version}/revert
     */,
    revertSubscription: (id: string, version: number, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/subscriptions/${id}/${version}/revert`,
        method: 'PUT',
        ...params,
      }) /**
     * @description Returns true if subscription can be deleted.
     *
     * @tags Subscriptions
     * @name SubscriptionModifiable
     * @summary Subscription can be modified
     * @request GET:/subscriptions/{id}/modifiable
     */,
    subscriptionModifiable: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/subscriptions/${id}/modifiable`,
        method: 'GET',
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Returns true if subscription can be reverted to a prior version.
     *
     * @tags Subscriptions
     * @name SubscriptionReversible
     * @summary Subscription can be reverted
     * @request GET:/subscriptions/{id}/reversible
     */,
    subscriptionReversible: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/subscriptions/${id}/reversible`,
        method: 'GET',
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Update renewal opportunity CRM id on subscription
     *
     * @tags Subscriptions
     * @name UpdateRenewalOpportunity
     * @summary Link renewal opportunity to subscription
     * @request POST:/subscriptions/{id}/renewalOpportunity
     */,
    updateRenewalOpportunity: (
      id: string,
      query: {
        /** renewal opportunity CRM id */
        renewalOpportunityCrmId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/subscriptions/${id}/renewalOpportunity`,
        method: 'POST',
        query: query,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Updates the details of the specified subscription.
     *
     * @tags Subscriptions
     * @name UpdateSubscription
     * @summary Update subscription details
     * @request PUT:/subscriptions/{id}
     */,
    updateSubscription: (id: string, body: SubscriptionUpdateJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/subscriptions/${id}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  }
  taxjar = {
    /**
     * @description Returns the integration ID if successful
     *
     * @tags Integrations
     * @name AddIntegration1
     * @summary Add a TaxJar integration
     * @request POST:/taxjar
     */
    addIntegration1: (body: TaxJarIntegrationInput, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/taxjar`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Gets the integration details of the specified integration id
     *
     * @tags Integrations
     * @name GetIntegration2
     * @summary Get integration details
     * @request GET:/taxjar/{integrationId}
     */,
    getIntegration2: (integrationId: string, params: RequestParams = {}) =>
      this.request<TaxJarIntegration, any>({
        path: `/taxjar/${integrationId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * No description
     *
     * @tags Integrations
     * @name TestIntegration1
     * @summary Test an integration is valid
     * @request PUT:/taxjar/test
     */,
    testIntegration1: (body: TaxJarIntegrationInput, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/taxjar/test`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Returns a suggested addresses if found
     *
     * @tags Integrations
     * @name ValidateAddress1
     * @summary Validate an address with TaxJar
     * @request POST:/taxjar/validate
     */,
    validateAddress1: (body: AccountAddress, params: RequestParams = {}) =>
      this.request<AccountAddress, any>({
        path: `/taxjar/validate`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  taxrates = {
    /**
     * @description Add a new tax rate object
     *
     * @tags Settings
     * @name AddTaxRate
     * @summary Add tax rate
     * @request POST:/taxrates
     */
    addTaxRate: (body: TaxRateJson, params: RequestParams = {}) =>
      this.request<TaxRateJson, any>({
        path: `/taxrates`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Deletes the tax rate object by Id. If successful the deleted tax rate object is returned
     *
     * @tags Settings
     * @name DeleteTaxRate
     * @summary Delete tax rate
     * @request DELETE:/taxrates/{id}
     */,
    deleteTaxRate: (id: string, params: RequestParams = {}) =>
      this.request<TaxRateJson, any>({
        path: `/taxrates/${id}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }) /**
     * @description Retrieves the tax rate object by Id
     *
     * @tags Settings
     * @name GetTaxRate
     * @summary Get tax rate by Id
     * @request GET:/taxrates/{id}
     */,
    getTaxRate: (id: string, params: RequestParams = {}) =>
      this.request<TaxRatePaginationResponseJson, any>({
        path: `/taxrates/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Get all available tax rates. The result is paginated. To retrieve all results pass the cursor returned from a call to the next call until all results are returned.
     *
     * @tags Settings
     * @name GetTaxRates
     * @summary Get tax rates
     * @request GET:/taxrates
     */,
    getTaxRates: (
      query?: {
        /** @format uuid */
        cursor?: string
        /** @format int32 */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<TaxRatePaginationResponseJson, any>({
        path: `/taxrates`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Updates the specific tax rate object
     *
     * @tags Settings
     * @name UpdateTaxRate
     * @summary Update tax rate
     * @request PUT:/taxrates/{id}
     */,
    updateTaxRate: (id: string, body: TaxRateJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/taxrates/${id}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  }
  templateScript = {
    /**
     * No description
     *
     * @tags TemplateScript
     * @name AddTemplateScript
     * @summary Add a new template script
     * @request POST:/templateScript
     */
    addTemplateScript: (body: TemplateScript, params: RequestParams = {}) =>
      this.request<TemplateScript, any>({
        path: `/templateScript`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * No description
     *
     * @tags TemplateScript
     * @name DisableTemplateScript
     * @summary Disable a template script
     * @request DELETE:/templateScript
     */,
    disableTemplateScript: (body: TemplateScript, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/templateScript`,
        method: 'DELETE',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Returns a list of template scripts of a certain type
     *
     * @tags TemplateScript
     * @name GetTemplateScripts
     * @summary Fetch template scripts
     * @request GET:/templateScript
     */,
    getTemplateScripts: (
      query?: {
        templateType?: 'ORDER' | 'COMPOSITE_ORDER' | 'INVOICE' | 'CREDIT_MEMO' | 'PREDEFINED_TERM'
      },
      params: RequestParams = {}
    ) =>
      this.request<TemplateScript[], any>({
        path: `/templateScript`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  tenantJobs = {
    /**
     * @description Executes a tenant job
     *
     * @tags Jobs
     * @name Dispatch
     * @summary Dispatch tenant job
     * @request POST:/tenantJobs/dispatch
     */
    dispatch: (
      query?: {
        entityIds?: string[]
        jobType?:
          | 'HUBSPOT_ACCOUNT_SYNC'
          | 'HUBSPOT_ORDER_SYNC'
          | 'HUBSPOT_COMPOSITE_ORDER_SYNC'
          | 'SALESFORCE_ACCOUNT_SYNC'
          | 'SALESFORCE_ACCOUNT_ARR_SYNC'
          | 'SALESFORCE_ORDER_SYNC'
          | 'SALESFORCE_COMPOSITE_ORDER_SYNC'
          | 'SALESFORCE_SUBSCRIPTION_SYNC'
          | 'CRM_ORDER_DELETION_SYNC'
          | 'QBO_JOURNAL_ENTRY_CREATION'
          | 'QBO_JOURNAL_ENTRY_DELETION'
          | 'ERP_JOURNAL_ENTRY_CREATION'
          | 'ERP_JOURNAL_ENTRY_DELETION'
          | 'ERP_INVOICE_SYNC'
          | 'ERP_CREDIT_MEMO_SYNC'
          | 'ERP_VOID_INVOICE'
          | 'REV_GENERATE_SCHEDULE'
          | 'REV_REGENERATE_SCHEDULE'
          | 'REV_GENERATE_SCHEDULES_FOR_TENANT'
          | 'REV_REGENERATE_SCHEDULES_FOR_TENANT'
          | 'RECOGNIZE_REVENUE'
          | 'CRM_COMPOSITE_ORDER_DELETION_SYNC'
          | 'HUBSPOT_SUBSCRIPTION_SYNC'
          | 'HUBSPOT_ELECTRONIC_SIGNATURE_SYNC'
          | 'CRM_SUBSCRIPTION_DELETION_SYNC'
          | 'CREATE_JOURNAL_ENTRIES'
          | 'HUBSPOT_ORDER_ANNUAL_AMOUNT_SYNC'
          | 'SALESFORCE_TRANSACTIONAL_ARR_METRICS_SYNC'
          | 'EXCHANGE_RATE_REFRESH'
          | 'AUTOMATED_INVOICE_RULE_TRIGGER'
          | 'REPROCESS_EVENTS_FOR_TENANT'
        module?:
          | 'HUBSPOT'
          | 'SALESFORCE'
          | 'QUICKBOOKS'
          | 'ERP'
          | 'REVENUE_RECOGNITION'
          | 'ACCOUNTING'
          | 'AUTOMATIC_INVOICE_GENERATION'
          | 'FOREIGN_EXCHANGE'
          | 'EVENT'
        objectId?: string
        objectModel?:
          | 'ACCOUNT'
          | 'ORDER'
          | 'COMPOSITE_ORDER'
          | 'INVOICE'
          | 'CREDIT_MEMO'
          | 'SUBSCRIPTION'
          | 'CRM_OPPORTUNITY'
          | 'ACCOUNTING_PERIOD'
          | 'AUTOMATED_INVOICE_RULE'
          | 'EXCHANGE_RATE_REFRESH_DATE'
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/tenantJobs/dispatch`,
        method: 'POST',
        query: query,
        ...params,
      }) /**
     * @description Executes a tenant job
     *
     * @tags Jobs
     * @name ExecuteTenantJob
     * @summary Retry tenant job
     * @request POST:/tenantJobs/retry
     */,
    executeTenantJob: (
      query?: {
        jobId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/tenantJobs/retry`,
        method: 'POST',
        query: query,
        ...params,
      }) /**
     * @description Get tenant job by id
     *
     * @tags Jobs
     * @name GetTenantJob
     * @summary Get tenant job
     * @request GET:/tenantJobs/{jobId}
     */,
    getTenantJob: (jobId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/tenantJobs/${jobId}`,
        method: 'GET',
        ...params,
      }),
  }
  tenants = {
    /**
     * @description Get the details of the current tenant
     *
     * @tags Settings
     * @name GetTenant
     * @summary Get tenant details
     * @request GET:/tenants
     */
    getTenant: (params: RequestParams = {}) =>
      this.request<TenantJson, any>({
        path: `/tenants`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Get the current logo stored
     *
     * @tags Settings
     * @name GetTenantLogo
     * @summary Get tenant logo
     * @request GET:/tenants/logo
     */,
    getTenantLogo: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/tenants/logo`,
        method: 'GET',
        ...params,
      }) /**
     * @description Updates the details of the current tenant based on the input
     *
     * @tags Settings
     * @name UpdateTenant
     * @summary Update tenant details
     * @request PUT:/tenants
     */,
    updateTenant: (body: TenantJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/tenants`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Updates the logo used in external facing communication such as order forms and invoices
     *
     * @tags Settings
     * @name UploadTenantLogo
     * @summary Update tenant logo
     * @request PUT:/tenants/logo
     */,
    uploadTenantLogo: (data: any, params: RequestParams = {}) =>
      this.request<TenantJson, any>({
        path: `/tenants/logo`,
        method: 'PUT',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  }
  settings = {
    /**
     * No description
     *
     * @tags Settings
     * @name DeleteTenantUiCustomizationConfig
     * @summary Remove UI customization config for the tenant
     * @request DELETE:/settings/ui/customizations
     */
    deleteTenantUiCustomizationConfig: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settings/ui/customizations`,
        method: 'DELETE',
        ...params,
      }) /**
     * @description Gets plan replacement settings
     *
     * @tags Settings
     * @name GetAutoReplacePlans
     * @summary Get plan replacement settings
     * @request GET:/settings/autoReplacePlans
     */,
    getAutoReplacePlans: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settings/autoReplacePlans`,
        method: 'GET',
        ...params,
      }) /**
     * No description
     *
     * @tags Settings
     * @name GetPaymentTermSettings
     * @summary Get payment term settings
     * @request GET:/settings/paymentTerms
     */,
    getPaymentTermSettings: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settings/paymentTerms`,
        method: 'GET',
        ...params,
      }) /**
     * @description Get supported currencies for your tenant
     *
     * @tags Settings
     * @name GetSupportedCurrencies
     * @summary Get supported currencies
     * @request GET:/settings/supportedCurrencies
     */,
    getSupportedCurrencies: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settings/supportedCurrencies`,
        method: 'GET',
        ...params,
      }) /**
     * @description Returns all tenant settings for your tenant.
     *
     * @tags Settings
     * @name GetTenantSetting
     * @summary Get tenant settings
     * @request GET:/settings
     */,
    getTenantSetting: (params: RequestParams = {}) =>
      this.request<TenantSettingJson, any>({
        path: `/settings`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * No description
     *
     * @tags Settings
     * @name GetTenantUiCustomizationConfig
     * @summary Fetch UI customization config for the tenant
     * @request GET:/settings/ui/customizations
     */,
    getTenantUiCustomizationConfig: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settings/ui/customizations`,
        method: 'GET',
        ...params,
      }) /**
     * @description Update plan replacement settings
     *
     * @tags Settings
     * @name UpdateAutoReplacePlans
     * @summary Update plan replacement settings
     * @request PUT:/settings/autoReplacePlans
     */,
    updateAutoReplacePlans: (body: boolean, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settings/autoReplacePlans`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * No description
     *
     * @tags Settings
     * @name UpdatePaymentTermSettings
     * @summary Update payment term settings
     * @request PUT:/settings/paymentTerms
     */,
    updatePaymentTermSettings: (body: PaymentTermSettingsJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settings/paymentTerms`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Update supported currencies for your tenant
     *
     * @tags Settings
     * @name UpdateSupportedCurrencies
     * @summary Update supported currencies
     * @request PUT:/settings/supportedCurrencies
     */,
    updateSupportedCurrencies: (body: string[], params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settings/supportedCurrencies`,
        method: 'PUT',
        body: body,
        ...params,
      }) /**
     * @description Updates settings for your tenant.
     *
     * @tags Settings
     * @name UpdateTenantSetting
     * @summary Update tenant settings
     * @request PUT:/settings
     */,
    updateTenantSetting: (body: TenantSettingJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settings`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * No description
     *
     * @tags Settings
     * @name UpdateTenantUiCustomizationConfig
     * @summary Update UI customization config for the tenant
     * @request POST:/settings/ui/customizations
     */,
    updateTenantUiCustomizationConfig: (body: TenantUiCustomization, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settings/ui/customizations`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  }
  fx = {
    /**
     * @description Get transactional exchange rate for a currency pair as of a specific date
     *
     * @tags Foreign Exchange
     * @name GetExchangeRateAsOf
     * @summary Get as of transactional exchange rate for a currency pair
     * @request GET:/fx/transactional/asof
     */
    getExchangeRateAsOf: (
      query: {
        /** @format int64 */
        asOf: number
        fromCurrency?: string
        toCurrency?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<TransactionalExchangeRate, any>({
        path: `/fx/transactional/asof`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Get latest transactional exchange rates for all currency pairs of supported currencies to functional currencies
     *
     * @tags Foreign Exchange
     * @name GetLatestExchangeRates
     * @summary Get latest transactional exchange rates
     * @request GET:/fx/transactional/latest
     */,
    getLatestExchangeRates: (params: RequestParams = {}) =>
      this.request<TransactionalExchangeRate[], any>({
        path: `/fx/transactional/latest`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Fetch and store transactional exchange rate for given currency pair and effective date from exchange rate provider
     *
     * @tags Foreign Exchange
     * @name RefreshExchangeRatePair
     * @summary Refresh transactional exchange rate by currency pair
     * @request POST:/fx/transactional/refreshPair
     */,
    refreshExchangeRatePair: (
      query: {
        /** @format int64 */
        effectiveDate: number
        fromCurrency?: string
        toCurrency?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/fx/transactional/refreshPair`,
        method: 'POST',
        query: query,
        ...params,
      }) /**
     * @description Refresh transactional exchange rates for all supported currencies to functional currencies for the given effective date
     *
     * @tags Foreign Exchange
     * @name RefreshExchangeRates
     * @summary Refresh transactional exchange rates
     * @request POST:/fx/transactional/refresh
     */,
    refreshExchangeRates: (
      query: {
        /** @format int64 */
        effectiveDate: number
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/fx/transactional/refresh`,
        method: 'POST',
        query: query,
        ...params,
      }),
  }
  unitsOfMeasure = {
    /**
     * @description Activates the specified unit of measure making it available to be attached to charges
     *
     * @tags Settings
     * @name ActivateUnitOfMeasure
     * @summary Activate unit of measure
     * @request POST:/unitsOfMeasure/{id}/activate
     */
    activateUnitOfMeasure: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/unitsOfMeasure/${id}/activate`,
        method: 'POST',
        ...params,
      }) /**
     * @description Add a new instance of unit of measure as specified by the input
     *
     * @tags Settings
     * @name AddUnitOfMeasure
     * @summary Add unit of measure
     * @request POST:/unitsOfMeasure
     */,
    addUnitOfMeasure: (body: UnitOfMeasureJson, params: RequestParams = {}) =>
      this.request<UnitOfMeasureJson, any>({
        path: `/unitsOfMeasure`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Delete the unit of measure by Id provided. Returns the deleted unit of measure object if successful.
     *
     * @tags Settings
     * @name DeleteUnitOfMeasure
     * @summary Delete unit of measure
     * @request DELETE:/unitsOfMeasure/{id}
     */,
    deleteUnitOfMeasure: (id: string, params: RequestParams = {}) =>
      this.request<UnitOfMeasureJson, any>({
        path: `/unitsOfMeasure/${id}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }) /**
     * @description Deprecates the specified unit of measure making it unavailable to be attached to charges going forward
     *
     * @tags Settings
     * @name DeprecateUnitOfMeasure
     * @summary Deprecate unit of measure
     * @request POST:/unitsOfMeasure/{id}/deprecate
     */,
    deprecateUnitOfMeasure: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/unitsOfMeasure/${id}/deprecate`,
        method: 'POST',
        ...params,
      }) /**
     * @description Returns a paginated list of units of measure
     *
     * @tags Settings
     * @name GetUnitsOfMeasure
     * @summary Get units of measure
     * @request GET:/unitsOfMeasure
     */,
    getUnitsOfMeasure: (
      query?: {
        /** @format uuid */
        cursor?: string
        /** @format int32 */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<UnitOfMeasurePaginationResponseJson, any>({
        path: `/unitsOfMeasure`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Update the unit of measure by Id provided.
     *
     * @tags Settings
     * @name UpdateUnitOfMeasure
     * @summary Update unit of measure
     * @request PUT:/unitsOfMeasure/{id}
     */,
    updateUnitOfMeasure: (id: string, body: UnitOfMeasureJson, params: RequestParams = {}) =>
      this.request<UnitOfMeasureJson, any>({
        path: `/unitsOfMeasure/${id}`,
        method: 'PUT',
        body: body,
        format: 'json',
        ...params,
      }),
  }
  v2 = {
    /**
     * @description Load usage records into the system
     *
     * @tags Usage
     * @name AddUsage
     * @summary Add usage record
     * @request POST:/v2/usage
     */
    addUsage: (body: RawUsagesData, params: RequestParams = {}) =>
      this.request<UsageBatchInsertResult, any>({
        path: `/v2/usage`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Retrieve the current aggregated usage data for a subscription between 2 instants
     *
     * @tags Usage
     * @name GetUsageAggregatesForSubscription
     * @summary Get aggregated usage
     * @request GET:/v2/usage/{subscriptionId}
     */,
    getUsageAggregatesForSubscription: (
      subscriptionId: string,
      query: {
        /** @format int64 */
        from: number
        /** @format int64 */
        to: number
      },
      params: RequestParams = {}
    ) =>
      this.request<UsageAggregateOutput[], any>({
        path: `/v2/usage/${subscriptionId}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Retrieve the latest usage record upload to the system as epoch seconds
     *
     * @tags Usage
     * @name GetUsageArrivalTimeCheckpoint
     * @summary Get usage arrival checkpoint
     * @request GET:/v2/usage/aggregate/usageArrivalTimeCheckpoint
     */,
    getUsageArrivalTimeCheckpoint: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/v2/usage/aggregate/usageArrivalTimeCheckpoint`,
        method: 'GET',
        ...params,
      }) /**
     * @description Retrieve the current prepaid drawdown statistics for all subscriptions for a time range
     *
     * @tags Usage
     * @name GetUsageStatsForAllSubscriptions
     * @summary Get all prepaid drawdown usage stats
     * @request GET:/v2/usage/stats
     */,
    getUsageStatsForAllSubscriptions: (
      query: {
        /** @format int64 */
        from: number
        /** @format int64 */
        to: number
      },
      params: RequestParams = {}
    ) =>
      this.request<PrepaidStats[], any>({
        path: `/v2/usage/stats`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Retrieve the current prepaid drawdown statistics for all subscriptions for a time range in CSV format
     *
     * @tags Usage
     * @name GetUsageStatsForAllSubscriptionsCsv
     * @summary Get all prepaid drawdown usage stats CSV
     * @request GET:/v2/usage/stats/csv
     */,
    getUsageStatsForAllSubscriptionsCsv: (
      query: {
        /** @format int64 */
        from: number
        /** @format int64 */
        to: number
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/v2/usage/stats/csv`,
        method: 'GET',
        query: query,
        ...params,
      }) /**
     * @description Retrieve the current prepaid drawdown statistics for a subscription
     *
     * @tags Usage
     * @name GetUsageStatsForSubscription
     * @summary Get prepaid drawdown usage stats for subscription
     * @request GET:/v2/usage/stats/{subscriptionId}
     */,
    getUsageStatsForSubscription: (subscriptionId: string, params: RequestParams = {}) =>
      this.request<PrepaidStats[], any>({
        path: `/v2/usage/stats/${subscriptionId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Retrieve the current prepaid drawdown statistics for a subscription in CSV format
     *
     * @tags Usage
     * @name GetUsageStatsForSubscriptionCsv
     * @summary Get prepaid drawdown usage stats CSV
     * @request GET:/v2/usage/stats/{subscriptionId}/csv
     */,
    getUsageStatsForSubscriptionCsv: (subscriptionId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/v2/usage/stats/${subscriptionId}/csv`,
        method: 'GET',
        ...params,
      }) /**
     * @description Retrieve the current prepaid drawdown statistics for a subscription in PDF format
     *
     * @tags Usage
     * @name GetUsageStatsForSubscriptionPdf
     * @summary Get prepaid drawdown usage stats PDF
     * @request GET:/v2/usage/stats/{subscriptionId}/pdf
     */,
    getUsageStatsForSubscriptionPdf: (subscriptionId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/v2/usage/stats/${subscriptionId}/pdf`,
        method: 'GET',
        ...params,
      }) /**
     * @description Trigger the process to aggregate any remaining raw usage records
     *
     * @tags Usage
     * @name PerformOnDemandUsageAggregation
     * @summary Aggregate raw usage
     * @request PUT:/v2/usage/aggregate
     */,
    performOnDemandUsageAggregation: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/v2/usage/aggregate`,
        method: 'PUT',
        ...params,
      }) /**
     * @description Upload usage records in CSV file. Each row of the file represents a single usage record
     *
     * @tags Usage
     * @name UploadSubscriptionUsageCsv
     * @summary Upload usage record CSV
     * @request POST:/v2/usage/csv
     */,
    uploadSubscriptionUsageCsv: (data: any, params: RequestParams = {}) =>
      this.request<UsageBatchInsertResult, any>({
        path: `/v2/usage/csv`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  }
  userGroups = {
    /**
     * @description Create a new user group based on the parameters in the input
     *
     * @tags Users
     * @name AddUserGroup
     * @summary Add user group
     * @request POST:/userGroups
     */
    addUserGroup: (body: UserGroupRequestJson, params: RequestParams = {}) =>
      this.request<UserGroupJson, any>({
        path: `/userGroups`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }) /**
     * @description Delete the user group by Id
     *
     * @tags Users
     * @name DeleteUserGroup
     * @summary Delete user group
     * @request DELETE:/userGroups/{userGroupId}
     */,
    deleteUserGroup: (userGroupId: string, params: RequestParams = {}) =>
      this.request<UserGroupJson, any>({
        path: `/userGroups/${userGroupId}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }) /**
     * @description Retrieve a user group by Id
     *
     * @tags Users
     * @name GetUserGroup
     * @summary Get user group
     * @request GET:/userGroups/{userGroupId}
     */,
    getUserGroup: (userGroupId: string, params: RequestParams = {}) =>
      this.request<UserGroupJson, any>({
        path: `/userGroups/${userGroupId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Get all available user groups
     *
     * @tags Users
     * @name GetUserGroups
     * @summary Get user groups
     * @request GET:/userGroups
     */,
    getUserGroups: (params: RequestParams = {}) =>
      this.request<UserGroupJson[], any>({
        path: `/userGroups`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Updates the specified user group according to the input
     *
     * @tags Users
     * @name UpdateUserGroup
     * @summary Update user group
     * @request PUT:/userGroups
     */,
    updateUserGroup: (body: UserGroupRequestJson, params: RequestParams = {}) =>
      this.request<UserGroupJson, any>({
        path: `/userGroups`,
        method: 'PUT',
        body: body,
        format: 'json',
        ...params,
      }),
  }
  users = {
    /**
     * @description Before a user gains access to the application, they must accept Subskribe's terms and conditions. This operation requires user bound access tokens.
     *
     * @tags Users
     * @name AcceptTermsForCurrentUser
     * @summary Accept terms and conditions
     * @request POST:/users/terms-and-conditions
     */
    acceptTermsForCurrentUser: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/users/terms-and-conditions`,
        method: 'POST',
        ...params,
      }) /**
     * @description Add a new user to the system. Users in the system must have unique emails. If successful, the path to the new user object is returned and a welcome email containing a temporary password will be sent to the email associated with the user. The login credentials expires in 24 hours. If the user does not login to the system within that time, a new invitation is required.
     *
     * @tags Users
     * @name AddUser
     * @summary Add a new user
     * @request POST:/users
     */,
    addUser: (body: UserInput, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/users`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }) /**
     * @description Disables a user by Id. If successful, returns the user just disabled
     *
     * @tags Users
     * @name DisableUser
     * @summary Disable user
     * @request PUT:/users/disable/{id}
     */,
    disableUser: (id: string, params: RequestParams = {}) =>
      this.request<UserJson, any>({
        path: `/users/disable/${id}`,
        method: 'PUT',
        format: 'json',
        ...params,
      }) /**
     * @description Enables a user by Id. If successful, returns the user just enabled
     *
     * @tags Users
     * @name EnableUser
     * @summary Enable user
     * @request PUT:/users/enable/{id}
     */,
    enableUser: (id: string, params: RequestParams = {}) =>
      this.request<UserJson, any>({
        path: `/users/enable/${id}`,
        method: 'PUT',
        format: 'json',
        ...params,
      }) /**
     * @description Returns a specific user by Id
     *
     * @tags Users
     * @name GetUser
     * @summary Get user by Id
     * @request GET:/users/{id}
     */,
    getUser: (id: string, params: RequestParams = {}) =>
      this.request<UserJson, any>({
        path: `/users/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }) /**
     * @description Returns a paginated list of users
     *
     * @tags Users
     * @name GetUsers
     * @summary Get users list
     * @request GET:/users
     */,
    getUsers: (
      query?: {
        /** @format uuid */
        cursor?: string
        /** @format int32 */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<UserPaginationResponseJson, any>({
        path: `/users`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }) /**
     * @description Resend welcome email conditioning temporary credentials. This is required if the user does not register within 24 hours of user activation
     *
     * @tags Users
     * @name ResendEmailForExistingUser
     * @summary Resend welcome email
     * @request POST:/users/resend-email/{email}
     */,
    resendEmailForExistingUser: (email: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/users/resend-email/${email}`,
        method: 'POST',
        format: 'json',
        ...params,
      }) /**
     * @description Updates the user information. Email cannot be updated.
     *
     * @tags Users
     * @name UpdateUser
     * @summary Update user
     * @request PUT:/users/{id}
     */,
    updateUser: (id: string, body: UserInput, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/users/${id}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Toggle user SSO configuration by user Id.
     *
     * @tags Users
     * @name UpdateUserSsoConfig
     * @summary Update user SSO configuration
     * @request PUT:/users/{id}/sso
     */,
    updateUserSsoConfig: (id: string, body: UserSsoUpdate, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/users/${id}/sso`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }) /**
     * @description Loads a list of users to be added to in CSV format.
     *
     * @tags Users
     * @name UploadCsv
     * @summary Bulk user upload
     * @request POST:/users/upload
     */,
    uploadCsv: (body: InputStream, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/users/upload`,
        method: 'POST',
        body: body,
        type: ContentType.Text,
        ...params,
      }),
  }
}

// React Hooks

/**
 * React hook for getAccountReceivableContact       * Returns the details of the account receivable contact for your tenant       * @path /accountReceivableContact       */
export const useGetAccountReceivableContact = () => {
  const [data, setData] = useState<AccountReceivableContactJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.accountReceivableContact.getAccountReceivableContact>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.accountReceivableContact.getAccountReceivableContact(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for putAccountReceivableContact       * Sets the details of the account receivable contact for your tenant       * @path /accountReceivableContact       */
export const usePutAccountReceivableContact = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.accountReceivableContact.putAccountReceivableContact>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.accountReceivableContact.putAccountReceivableContact(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addAccount       * Create an account with the specified parameters. On success, the id of the newly created account is returned       * @path /accounts       */
export const useAddAccount = () => {
  const [data, setData] = useState<AccountJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.accounts.addAccount>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.accounts.addAccount(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addAccountContact       * Creates and adds a new contact for the specified account and returns the new contact ID.       * @path /accounts/${accountId}/contacts       */
export const useAddAccountContact = () => {
  const [data, setData] = useState<AccountContactJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.accounts.addAccountContact>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.accounts.addAccountContact(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addErpDetails       * Update ERP details for an account specified by the account id       * @path /accounts/${accountId}/erp       */
export const useAddErpDetails = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.accounts.addErpDetails>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.accounts.addErpDetails(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteAccount       * Deletes the account associated with the passed ID       * @path /accounts/${id}       */
export const useDeleteAccount = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.accounts.deleteAccount>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.accounts.deleteAccount(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteAccountContact       * Deletes the contact specified by the account id and contact id       * @path /accounts/${accountId}/contacts/${contactId}       */
export const useDeleteAccountContact = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.accounts.deleteAccountContact>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.accounts.deleteAccountContact(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getAccount       * Retrieves detailed information about a specific account using its unique identifier. It could be Account_ID, CRM_ID or External_ID. This endpoint provides comprehensive data for a particular account, enabling users to access full account details.       * @path /accounts/${id}       */
export const useGetAccount = () => {
  const [data, setData] = useState<AccountJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.accounts.getAccount>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.accounts.getAccount(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getAccountContact       * Returns the details of the specified contact       * @path /accounts/${accountId}/contacts/${contactId}       */
export const useGetAccountContact = () => {
  const [data, setData] = useState<AccountContactJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.accounts.getAccountContact>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.accounts.getAccountContact(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getAccountContacts       * Returns a list of contacts associated with the specified account id       * @path /accounts/${accountId}/contacts       */
export const useGetAccountContacts = () => {
  const [data, setData] = useState<AccountContactJson[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.accounts.getAccountContacts>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.accounts.getAccountContacts(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getAccountMetrics       * Fetches metrics such as ARR, TCV, etc for the specified account as of the specified target date       * @path /accounts/${id}/metrics       */
export const useGetAccountMetrics = () => {
  const [data, setData] = useState<MetricsJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.accounts.getAccountMetrics>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.accounts.getAccountMetrics(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getAccounts       * Returns a paginated list of accounts       * @path /accounts       */
export const useGetAccounts = () => {
  const [data, setData] = useState<PaginatedAccountsResponse | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.accounts.getAccounts>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.accounts.getAccounts(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for importCrmAccount       * Ensures an account exists which matches the passed details.If an account exists that has a matching CRM Id, it will be updated, if not, it will be created       * @path /accounts/crm/import       */
export const useImportCrmAccount = () => {
  const [data, setData] = useState<CrmAccountImportResponse | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.accounts.importCrmAccount>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.accounts.importCrmAccount(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updateAccount       * Updates an existing account with the specified parameters       * @path /accounts/${id}       */
export const useUpdateAccount = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.accounts.updateAccount>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.accounts.updateAccount(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updateAccountContact       * Updates the contact specified by the account id and contact id with the passed information       * @path /accounts/${accountId}/contacts/${contactId}       */
export const useUpdateAccountContact = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.accounts.updateAccountContact>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.accounts.updateAccountContact(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getCurrentAccountingPeriod       *        * @path /accountingPeriods/current       */
export const useGetCurrentAccountingPeriod = () => {
  const [data, setData] = useState<AccountingPeriod | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.accountingPeriods.getCurrentAccountingPeriod>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.accountingPeriods.getCurrentAccountingPeriod(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for specifyCurrentAccountingPeriod       * Specify the start date of and open an accounting period, making it current       * @path /accountingPeriods       */
export const useSpecifyCurrentAccountingPeriod = () => {
  const [data, setData] = useState<AccountingPeriod | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.accountingPeriods.specifyCurrentAccountingPeriod>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.accountingPeriods.specifyCurrentAccountingPeriod(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for chatMessages       * The message will be returned in the most recent order, with the latest being the first       * @path /ai/agents/session/${sessionId}/messages       */
export const useChatMessages = () => {
  const [data, setData] = useState<Message[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.ai.chatMessages>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.ai.chatMessages(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for chatResponse       * The API responds user message, a session id is required to identify the session this message needs to be posted       * @path /ai/agents/session/${sessionId}/chat       */
export const useChatResponse = () => {
  const [data, setData] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.ai.chatResponse>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.ai.chatResponse(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for chatResponseAsync       * The API responds to user message, a session id is required to identify the session this message needs to be posted       * @path /ai/agents/session/${sessionId}/chatAsync       */
export const useChatResponseAsync = () => {
  const [data, setData] = useState<OutboundEvent | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.ai.chatResponseAsync>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.ai.chatResponseAsync(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for createAgentSession       * Create a new conversation session with Subskribe AI agent, this resource will return a session id which will be used for future conversations       * @path /ai/agents/session       */
export const useCreateAgentSession = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.ai.createAgentSession>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.ai.createAgentSession(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for generateOrderSummary       * Generate a complete summary of the order with the given id, a full detailed summary will be generated including metrics       * @path /ai/summary/order/${orderId}       */
export const useGenerateOrderSummary = () => {
  const [data, setData] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.ai.generateOrderSummary>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.ai.generateOrderSummary(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for generateSubscriptionSummary       * Generate a complete summary of the subscription with the given id, a full detailed summary will be generated including metrics       * @path /ai/summary/subscription/${subscriptionId}       */
export const useGenerateSubscriptionSummary = () => {
  const [data, setData] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.ai.generateSubscriptionSummary>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.ai.generateSubscriptionSummary(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getAgentSession       * Get the agent session given the id, NOTE: at the moment there is no response body, if 200 is returned then session exists       * @path /ai/agents/session/${sessionId}       */
export const useGetAgentSession = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.ai.getAgentSession>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.ai.getAgentSession(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for addSubscriptionChargeAlias       * This allows you to specify a string alias for a subscription id and a charge id. This can be useful when, for example, you want to upload usage statistics and would rather specify your own id (or another external id), rather than referring to Subskribe&#39;s internal ids.       * @path /alias/subscriptionCharge/${aliasId}       */
export const useAddSubscriptionChargeAlias = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.alias.addSubscriptionChargeAlias>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.alias.addSubscriptionChargeAlias(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteSubscriptionChargeAlias       * Deletes the specified alias mapping       * @path /alias/subscriptionCharge/${aliasId}       */
export const useDeleteSubscriptionChargeAlias = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.alias.deleteSubscriptionChargeAlias>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.alias.deleteSubscriptionChargeAlias(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getSubscriptionChargeAlias       * Returns the details of the specified alias id including the subscription id and the charge id it is mapped to.       * @path /alias/subscriptionCharge/${aliasId}       */
export const useGetSubscriptionChargeAlias = () => {
  const [data, setData] = useState<SubscriptionChargeAlias | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.alias.getSubscriptionChargeAlias>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.alias.getSubscriptionChargeAlias(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for listAliasesForSubscription       * Returns all aliases for the specified subscription id.       * @path /alias/subscriptionCharge       */
export const useListAliasesForSubscription = () => {
  const [data, setData] = useState<SubscriptionChargeAlias[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.alias.listAliasesForSubscription>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.alias.listAliasesForSubscription(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for addIntegration       * Returns the integration ID if successful       * @path /anrok       */
export const useAddIntegration = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.anrok.addIntegration>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.anrok.addIntegration(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getIntegration       * Gets the integration details of the specified integration id       * @path /anrok/${integrationId}       */
export const useGetIntegration = () => {
  const [data, setData] = useState<Integration | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.anrok.getIntegration>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.anrok.getIntegration(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for testIntegration       *        * @path /anrok/test       */
export const useTestIntegration = () => {
  const [data, setData] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.anrok.testIntegration>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.anrok.testIntegration(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for validateAddress       *        * @path /anrok/validate       */
export const useValidateAddress = () => {
  const [data, setData] = useState<AccountAddress | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.anrok.validateAddress>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.anrok.validateAddress(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for createApiKey       * Create a new api key with the specified parameters. The new key is returned       * @path /apikey       */
export const useCreateApiKey = () => {
  const [data, setData] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.apikey.createApiKey>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.apikey.createApiKey(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getAllApiKeys       * Retrieves a list of (maximum 500 items) all API keys (including expired and deactivated keys)       * @path /apikey       */
export const useGetAllApiKeys = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.apikey.getAllApiKeys>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.apikey.getAllApiKeys(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getApiKeyById       * Retrieve an api key referenced by its id       * @path /apikey/${id}       */
export const useGetApiKeyById = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.apikey.getApiKeyById>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.apikey.getApiKeyById(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for revokeApiKey       * Revokes all keys for your tenant       * @path /apikey/revoke       */
export const useRevokeApiKey = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.apikey.revokeApiKey>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.apikey.revokeApiKey(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for revokeApiKeyById       * Revoke an api key referenced by its id       * @path /apikey/revoke/${id}       */
export const useRevokeApiKeyById = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.apikey.revokeApiKeyById>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.apikey.revokeApiKeyById(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for fetchNotification       *        * @path /approvalFlowNotifications       */
export const useFetchNotification = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.approvalFlowNotifications.fetchNotification>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.approvalFlowNotifications.fetchNotification(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for receiveApprovalFlowNotification       *        * @path /approvalFlowNotifications       */
export const useReceiveApprovalFlowNotification = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.approvalFlowNotifications.receiveApprovalFlowNotification>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.approvalFlowNotifications.receiveApprovalFlowNotification(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addApprovalFlow       * Adds an approval flow to the order execution hierarchy and returns its ID       * @path /approvalFlows       */
export const useAddApprovalFlow = () => {
  const [data, setData] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.approvalFlows.addApprovalFlow>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.approvalFlows.addApprovalFlow(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteApprovalFlow       * Deletes an approval flow       * @path /approvalFlows/${approvalFlowId}       */
export const useDeleteApprovalFlow = () => {
  const [data, setData] = useState<ApprovalFlowJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.approvalFlows.deleteApprovalFlow>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.approvalFlows.deleteApprovalFlow(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getApprovalFlowById       * Returns the details of the specified approval flow       * @path /approvalFlows/${approvalFlowId}       */
export const useGetApprovalFlowById = () => {
  const [data, setData] = useState<ApprovalFlowJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.approvalFlows.getApprovalFlowById>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.approvalFlows.getApprovalFlowById(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getApprovalFlows       * Gets all approval flows       * @path /approvalFlows       */
export const useGetApprovalFlows = () => {
  const [data, setData] = useState<ApprovalFlowJson[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.approvalFlows.getApprovalFlows>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.approvalFlows.getApprovalFlows(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for updateApprovalFlow       * Updates the details of the specified approval flow       * @path /approvalFlows/${approvalFlowId}       */
export const useUpdateApprovalFlow = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.approvalFlows.updateApprovalFlow>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.approvalFlows.updateApprovalFlow(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getAllImportDetails       * Returns details regarding the approval matrices that have been uploaded       * @path /approvalMatrix/csv       */
export const useGetAllImportDetails = () => {
  const [data, setData] = useState<ApprovalMatrixImportDataJson[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.approvalMatrix.getAllImportDetails>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.approvalMatrix.getAllImportDetails(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getApprovalMatrixAsCsv       * Downloads your order approval matrix as a csv       * @path /approvalMatrix/csv/download       */
export const useGetApprovalMatrixAsCsv = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.approvalMatrix.getApprovalMatrixAsCsv>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.approvalMatrix.getApprovalMatrixAsCsv(...variables)
        setData(resp.data)
        const link = document.createElement('a')
        const file = new Blob([resp.data], { type: 'text/csv' })
        link.href = URL.createObjectURL(file)
        link.download = 'getApprovalMatrixAsCsv.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getImportDetailsById       * Gets the details of an import specified by the passed ID       * @path /approvalMatrix/csv/${importId}       */
export const useGetImportDetailsById = () => {
  const [data, setData] = useState<ApprovalMatrixImportDataJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.approvalMatrix.getImportDetailsById>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.approvalMatrix.getImportDetailsById(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getImportPreview       * Preview the changes that a specified approval matrix import will have once applied       * @path /approvalMatrix/csv/${importId}/preview       */
export const useGetImportPreview = () => {
  const [data, setData] = useState<ApprovalMatrixImportPreview | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.approvalMatrix.getImportPreview>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.approvalMatrix.getImportPreview(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getImportResult       * Downloads the CSV for an import activity specified by the passed ID       * @path /approvalMatrix/csv/${importId}/download       */
export const useGetImportResult = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.approvalMatrix.getImportResult>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.approvalMatrix.getImportResult(...variables)
        setData(resp.data)
        const link = document.createElement('a')
        const file = new Blob([resp.data], { type: 'text/csv' })
        link.href = URL.createObjectURL(file)
        link.download = 'getImportResult.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for submitApprovalMatrixCSV       * Submit and finalize the import for the specified import operation.       * @path /approvalMatrix/csv/${importId}/submit       */
export const useSubmitApprovalMatrixCSV = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.approvalMatrix.submitApprovalMatrixCsv>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.approvalMatrix.submitApprovalMatrixCsv(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
       * React hook for uploadApprovalMatrixCSV       * Upload a csv containing the order approval matrix to be used on order execution. The csv should have the following format: 
Segment, ApprovalRoleName1, ApprovalRoleName2 ...
SegmentName1, UserEmailOrGroupName1, UserEmailOrGroupName2 ...
Roles, userEmails, userGroups are expected to be added before putting them in this csv. Any new segment names defined here will add a new segment. If a segment isn&#39;t added, it shall be deleted.       * @path /approvalMatrix/csv       */
export const useUploadApprovalMatrixCSV = () => {
  const [data, setData] = useState<ApprovalMatrixImportDataJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.approvalMatrix.uploadApprovalMatrixCsv>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.approvalMatrix.uploadApprovalMatrixCsv(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addApprovalRole       * Adds an approval role to the order execution hierarchy and returns its ID       * @path /approvalRoles       */
export const useAddApprovalRole = () => {
  const [data, setData] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.approvalRoles.addApprovalRole>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.approvalRoles.addApprovalRole(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteApprovalRole       * Deletes an approval role       * @path /approvalRoles/${approvalRoleId}       */
export const useDeleteApprovalRole = () => {
  const [data, setData] = useState<ApprovalRoleJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.approvalRoles.deleteApprovalRole>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.approvalRoles.deleteApprovalRole(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getApprovalRoleById       * Returns the details of the specified approval role       * @path /approvalRoles/${approvalRoleId}       */
export const useGetApprovalRoleById = () => {
  const [data, setData] = useState<ApprovalRoleJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.approvalRoles.getApprovalRoleById>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.approvalRoles.getApprovalRoleById(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getApprovalRoles       * Gets all approval roles       * @path /approvalRoles       */
export const useGetApprovalRoles = () => {
  const [data, setData] = useState<ApprovalRoleJson[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.approvalRoles.getApprovalRoles>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.approvalRoles.getApprovalRoles(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for updateApprovalRole       * Updates the details of the specified approval role       * @path /approvalRoles/${approvalRoleId}       */
export const useUpdateApprovalRole = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.approvalRoles.updateApprovalRole>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.approvalRoles.updateApprovalRole(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addApprovalSegment       * Define and add an approval segment which can be later specified in an approval matrix. The ID of the segment is returned.       * @path /approvalSegments       */
export const useAddApprovalSegment = () => {
  const [data, setData] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.approvalSegments.addApprovalSegment>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.approvalSegments.addApprovalSegment(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteApprovalSegment       * Deletes the segment specified by the id       * @path /approvalSegments/${approvalSegmentId}       */
export const useDeleteApprovalSegment = () => {
  const [data, setData] = useState<ApprovalSegmentJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.approvalSegments.deleteApprovalSegment>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.approvalSegments.deleteApprovalSegment(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getApprovalSegmentById       * Gets the details of the specified approval segment       * @path /approvalSegments/${approvalSegmentId}       */
export const useGetApprovalSegmentById = () => {
  const [data, setData] = useState<ApprovalSegmentJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.approvalSegments.getApprovalSegmentById>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.approvalSegments.getApprovalSegmentById(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getApprovalSegments       * Get all approval segments       * @path /approvalSegments       */
export const useGetApprovalSegments = () => {
  const [data, setData] = useState<ApprovalSegmentJson[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.approvalSegments.getApprovalSegments>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.approvalSegments.getApprovalSegments(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for updateApprovalSegment       * Update the details of the specified approval segment       * @path /approvalSegments/${approvalSegmentId}       */
export const useUpdateApprovalSegment = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.approvalSegments.updateApprovalSegment>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.approvalSegments.updateApprovalSegment(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addAttachmentToAccount       * Attaches a document to the specified account. The post body should contain the body of the document that is to be attached. On success the Id of the attachment is returned.       * @path /attachments/account/${accountId}       */
export const useAddAttachmentToAccount = () => {
  const [data, setData] = useState<Attachment | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.attachments.addAttachmentToAccount>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.attachments.addAttachmentToAccount(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteAttachmentFromAccount       * Unattaches and deletes the specified document       * @path /attachments/${attachmentId}       */
export const useDeleteAttachmentFromAccount = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.attachments.deleteAttachmentFromAccount>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.attachments.deleteAttachmentFromAccount(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getAttachment       * Gets the contents of the specified attachment       * @path /attachments/${attachmentId}       */
export const useGetAttachment = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.attachments.getAttachment>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.attachments.getAttachment(...variables)
        setData(resp.data)
        const link = document.createElement('a')
        const file = new Blob([resp.data], { type: 'text/csv' })
        link.href = URL.createObjectURL(file)
        link.download = 'getAttachment.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for listAccountAttachments       * Lists all the documents attached to an account       * @path /attachments/account/${accountId}       */
export const useListAccountAttachments = () => {
  const [data, setData] = useState<Attachment[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.attachments.listAccountAttachments>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.attachments.listAccountAttachments(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for modifyAttachment       * Updates the details of the specified document       * @path /attachments       */
export const useModifyAttachment = () => {
  const [data, setData] = useState<Attachment | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.attachments.modifyAttachment>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.attachments.modifyAttachment(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addSamlIntegration       * Adds a saml integration       * @path /auth/saml       */
export const useAddSamlIntegration = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.auth.addSamlIntegration>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.auth.addSamlIntegration(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addAutomatedInvoiceRule       * Creates an automated invoice rule with the specified parameters       * @path /automatedInvoiceRules       */
export const useAddAutomatedInvoiceRule = () => {
  const [data, setData] = useState<AutomatedInvoiceRule | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.automatedInvoiceRules.addAutomatedInvoiceRule>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.automatedInvoiceRules.addAutomatedInvoiceRule(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getAutomatedInvoiceRule       * Returns the details of the specified automated invoice rule       * @path /automatedInvoiceRules/${id}       */
export const useGetAutomatedInvoiceRule = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.automatedInvoiceRules.getAutomatedInvoiceRule>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.automatedInvoiceRules.getAutomatedInvoiceRule(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getAutomatedInvoiceRuleByInternalId       *        * @path /automatedInvoiceRules/internal/${id}       */
export const useGetAutomatedInvoiceRuleByInternalId = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.automatedInvoiceRules.getAutomatedInvoiceRuleByInternalId>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.automatedInvoiceRules.getAutomatedInvoiceRuleByInternalId(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getAutomatedInvoiceRules       * Returns all the configured automated invoice rules for the tenant       * @path /automatedInvoiceRules       */
export const useGetAutomatedInvoiceRules = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.automatedInvoiceRules.getAutomatedInvoiceRules>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.automatedInvoiceRules.getAutomatedInvoiceRules(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for updateAutomatedInvoiceRule       * Updates the details of the specified automated invoice rule       * @path /automatedInvoiceRules/${id}       */
export const useUpdateAutomatedInvoiceRule = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.automatedInvoiceRules.updateAutomatedInvoiceRule>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.automatedInvoiceRules.updateAutomatedInvoiceRule(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updateAutomatedInvoiceRuleUsingInternalId       *        * @path /automatedInvoiceRules/internal/${id}       */
export const useUpdateAutomatedInvoiceRuleUsingInternalId = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (
      variables: Parameters<typeof billyRestClient.automatedInvoiceRules.updateAutomatedInvoiceRuleUsingInternalId>
    ) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.automatedInvoiceRules.updateAutomatedInvoiceRuleUsingInternalId(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getIntegration_1       * Gets the integration details of the specified integration id       * @path /avalara/${integrationId}       */
export const useGetIntegration1 = () => {
  const [data, setData] = useState<AvalaraIntegration | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.avalara.getIntegration1>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.avalara.getIntegration1(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getIntegrationByTenant       * Returns the details of the Avalara integration for your tenant       * @path /avalara       */
export const useGetIntegrationByTenant = () => {
  const [data, setData] = useState<AvalaraIntegration | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.avalara.getIntegrationByTenant>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.avalara.getIntegrationByTenant(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for integrate       * Creates an integration with Avalara according to the specified details. On success the id of the integration is returned       * @path /avalara       */
export const useIntegrate = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.avalara.integrate>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.avalara.integrate(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for ping       * Once an integration is set up Avalara can be pinged to ensure it is working correctly. On success &quot;PONG&quot; is returned.       * @path /avalara/ping       */
export const usePing = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.avalara.ping>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.avalara.ping(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for bankTransactionsCsv       * Import bank transactions via CSV       * @path /bankTransactions/csv       */
export const useBankTransactionsCsv = () => {
  const [data, setData] = useState<BankTransactionsUploadResult | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.bankTransactions.bankTransactionsCsv>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.bankTransactions.bankTransactionsCsv(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for bankTransactionsMatch       * Match bank transactions       * @path /bankTransactions/match       */
export const useBankTransactionsMatch = () => {
  const [data, setData] = useState<InvoiceBankTransactionMatchResponse | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.bankTransactions.bankTransactionsMatch>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.bankTransactions.bankTransactionsMatch(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteBankTransaction       * Delete given bank transaction record       * @path /bankTransactions/${id}       */
export const useDeleteBankTransaction = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.bankTransactions.deleteBankTransaction>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.bankTransactions.deleteBankTransaction(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getBankTransactionPotentialInvoices       * Returns all bank transaction records in a paginated fashion       * @path /bankTransactions/potentialInvoices       */
export const useGetBankTransactionPotentialInvoices = () => {
  const [data, setData] = useState<PaginatedBankTransactionPotentialInvoiceResponse | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.bankTransactions.getBankTransactionPotentialInvoices>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.bankTransactions.getBankTransactionPotentialInvoices(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for backfillRevRecFields       * Backfill API to update revenue recognition fields on charges       * @path /charges/revrec/csv       */
export const useBackfillRevRecFields = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.charges.backfillRevRecFields>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.charges.backfillRevRecFields(...variables)
        setData(resp.data)
        const link = document.createElement('a')
        const file = new Blob([resp.data], { type: 'text/csv' })
        link.href = URL.createObjectURL(file)
        link.download = 'backfillRevRecFields.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getCharge       * Gets the details of the specified charge.       * @path /charges/${chargeId}       */
export const useGetCharge = () => {
  const [data, setData] = useState<ChargeJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.charges.getCharge>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.charges.getCharge(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getRevRecFieldsAsCsv       * Downloads your charge revenue recognition fields as a csv       * @path /charges/revrec/csv/download       */
export const useGetRevRecFieldsAsCsv = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.charges.getRevRecFieldsAsCsv>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.charges.getRevRecFieldsAsCsv(...variables)
        setData(resp.data)
        const link = document.createElement('a')
        const file = new Blob([resp.data], { type: 'text/csv' })
        link.href = URL.createObjectURL(file)
        link.download = 'getRevRecFieldsAsCsv.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for createCompositeOrderDocument       * Generates a composite order form PDF. When completed this document can be downloaded via a get to /{id}/pdf.       * @path /compositeOrders/${id}/pdf       */
export const useCreateCompositeOrderDocument = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.compositeOrders.createCompositeOrderDocument>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.compositeOrders.createCompositeOrderDocument(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getCompositeOrderDocument       * Downloads the order form PDF for the specified order. This PDF must have been generated via a POST to /{id}/pdf. The response is the PDF document.       * @path /compositeOrders/${id}/pdf       */
export const useGetCompositeOrderDocument = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.compositeOrders.getCompositeOrderDocument>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.compositeOrders.getCompositeOrderDocument(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for convertNegativeDraftInvoice       * Converts the specified invoice to a credit memo. Note the invoice must be in DRAFT status and have a negative balance.       * @path /creditmemos/convert/${invoiceNumber}       */
export const useConvertNegativeDraftInvoice = () => {
  const [data, setData] = useState<CreditMemoJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.creditmemos.convertNegativeDraftInvoice>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.creditmemos.convertNegativeDraftInvoice(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for createCreditMemoDocument       * Creates a pdf version of the credit memo       * @path /creditmemos/${creditMemoNumber}/pdf       */
export const useCreateCreditMemoDocument = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.creditmemos.createCreditMemoDocument>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.creditmemos.createCreditMemoDocument(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for createStandaloneCreditMemo       * Creates a standalone credit memo for a specified account. On success the number of the new memo is returned       * @path /creditmemos       */
export const useCreateStandaloneCreditMemo = () => {
  const [data, setData] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.creditmemos.createStandaloneCreditMemo>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.creditmemos.createStandaloneCreditMemo(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteCreditMemo       * Deletes the specified credit memo. The credit memo must be in DRAFT status       * @path /creditmemos/${creditMemoNumber}       */
export const useDeleteCreditMemo = () => {
  const [data, setData] = useState<CreditMemoJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.creditmemos.deleteCreditMemo>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.creditmemos.deleteCreditMemo(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getCreditBalance       * Gets the balance of the specified credit memo       * @path /creditmemos/${creditMemoNumber}/balance       */
export const useGetCreditBalance = () => {
  const [data, setData] = useState<CreditMemoBalanceJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.creditmemos.getCreditBalance>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.creditmemos.getCreditBalance(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getCreditMemo       * Returns the details of the specified credit memo       * @path /creditmemos/${creditMemoNumber}       */
export const useGetCreditMemo = () => {
  const [data, setData] = useState<CreditMemoJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.creditmemos.getCreditMemo>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.creditmemos.getCreditMemo(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getCreditMemoConfiguration       * Returns the credit memo configuration for your tenant       * @path /creditmemos/configuration       */
export const useGetCreditMemoConfiguration = () => {
  const [data, setData] = useState<TenantCreditMemoConfigurationJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.creditmemos.getCreditMemoConfiguration>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.creditmemos.getCreditMemoConfiguration(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getCreditMemoDocumentPdf       * Downloads the pdf version of the credit memo. Note the credit memo must already have been created. If the credit memo is still in the process of being created, HTTP 202 is returned.       * @path /creditmemos/${creditMemoNumber}/pdf       */
export const useGetCreditMemoDocumentPdf = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.creditmemos.getCreditMemoDocumentPdf>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.creditmemos.getCreditMemoDocumentPdf(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getCreditMemoForAccount       * Returns a paginated list of credit memos for the specified account. Pass the cursor returned to subsequent calls to retrieve all data.       * @path /creditmemos       */
export const useGetCreditMemoForAccount = () => {
  const [data, setData] = useState<CreditMemoPaginationResponseJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.creditmemos.getCreditMemoForAccount>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.creditmemos.getCreditMemoForAccount(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for postCreditMemo       * Sets the status of the specified credit memo to POSTED       * @path /creditmemos/${creditMemoNumber}/post       */
export const usePostCreditMemo = () => {
  const [data, setData] = useState<CreditMemoJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.creditmemos.postCreditMemo>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.creditmemos.postCreditMemo(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updateCreditMemoConfiguration       * Updates the credit memo configuration for you tenant.       * @path /creditmemos/configuration       */
export const useUpdateCreditMemoConfiguration = () => {
  const [data, setData] = useState<TenantCreditMemoConfigurationJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.creditmemos.updateCreditMemoConfiguration>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.creditmemos.updateCreditMemoConfiguration(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updateDraftCreditMemo       * Updates the details of a credit memo in DRAFT status       * @path /creditmemos/${creditMemoNumber}       */
export const useUpdateDraftCreditMemo = () => {
  const [data, setData] = useState<CreditMemoJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.creditmemos.updateDraftCreditMemo>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.creditmemos.updateDraftCreditMemo(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getAllImportDetails_1       * Returns details regarding the CRM field mappings that have been uploaded       * @path /crmFieldMapping/csv       */
export const useGetAllImportDetails1 = () => {
  const [data, setData] = useState<CrmFieldMappingImportDataJson[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.crmFieldMapping.getAllImportDetails1>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.crmFieldMapping.getAllImportDetails1(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getCrmFieldMappingAsCsv       * Downloads your CRM field mappings as a csv       * @path /crmFieldMapping/csv/download       */
export const useGetCrmFieldMappingAsCsv = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.crmFieldMapping.getCrmFieldMappingAsCsv>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.crmFieldMapping.getCrmFieldMappingAsCsv(...variables)
        setData(resp.data)
        const link = document.createElement('a')
        const file = new Blob([resp.data], { type: 'text/csv' })
        link.href = URL.createObjectURL(file)
        link.download = 'getCrmFieldMappingAsCsv.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getImportDetailsById_1       * Gets the details of an import specified by the passed ID       * @path /crmFieldMapping/csv/${importId}       */
export const useGetImportDetailsById1 = () => {
  const [data, setData] = useState<CrmFieldMappingImportDataJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.crmFieldMapping.getImportDetailsById1>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.crmFieldMapping.getImportDetailsById1(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getImportPreview_1       * Preview the changes that a CRM field mapping import will have once applied       * @path /crmFieldMapping/csv/${importId}/preview       */
export const useGetImportPreview1 = () => {
  const [data, setData] = useState<CrmFieldMappingImportPreview | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.crmFieldMapping.getImportPreview1>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.crmFieldMapping.getImportPreview1(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getImportResult_1       * Downloads the CSV for an import activity specified by the passed ID       * @path /crmFieldMapping/csv/${importId}/download       */
export const useGetImportResult1 = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.crmFieldMapping.getImportResult1>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.crmFieldMapping.getImportResult1(...variables)
        setData(resp.data)
        const link = document.createElement('a')
        const file = new Blob([resp.data], { type: 'text/csv' })
        link.href = URL.createObjectURL(file)
        link.download = 'getImportResult_1.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for submitCrmFieldMappingImport       * Submit and finalize the import for the specified import operation.       * @path /crmFieldMapping/csv/${importId}/submit       */
export const useSubmitCrmFieldMappingImport = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.crmFieldMapping.submitCrmFieldMappingImport>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.crmFieldMapping.submitCrmFieldMappingImport(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
       * React hook for uploadCrmFieldMappingCsv       *     crmType: HUBSPOT, SALESFORCE
    crmObjectType: OPPORTUNITY, ACCOUNT, ORDER
    direction: INBOUND, OUTBOUND
    crmFieldName
    subskribeFieldName
       * @path /crmFieldMapping/csv       */
export const useUploadCrmFieldMappingCsv = () => {
  const [data, setData] = useState<CrmFieldMappingImportDataJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.crmFieldMapping.uploadCrmFieldMappingCsv>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.crmFieldMapping.uploadCrmFieldMappingCsv(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for createCustomFieldDefinition       * Creates a new custom field definition for your tenant. On success the id of the custom field definition is returned.       * @path /customFieldDefinition       */
export const useCreateCustomFieldDefinition = () => {
  const [data, setData] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.customFieldDefinition.createCustomFieldDefinition>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.customFieldDefinition.createCustomFieldDefinition(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteCustomFieldDefinition       * Deletes an existing custom field definition for your tenant. On success the deleted custom field definition object is returned.       * @path /customFieldDefinition/${id}       */
export const useDeleteCustomFieldDefinition = () => {
  const [data, setData] = useState<CustomFieldDefinitionJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.customFieldDefinition.deleteCustomFieldDefinition>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.customFieldDefinition.deleteCustomFieldDefinition(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getCustomFieldDefinitions       * Returns all custom field definitions for a specific parent object type       * @path /customFieldDefinition/${parentObjectType}       */
export const useGetCustomFieldDefinitions = () => {
  const [data, setData] = useState<CustomFieldDefinitionJson[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.customFieldDefinition.getCustomFieldDefinitions>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.customFieldDefinition.getCustomFieldDefinitions(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for updateCustomFieldDefinition       * Updates an existing custom field definition for your tenant. On success the update custom field definition is returned.       * @path /customFieldDefinition/${id}       */
export const useUpdateCustomFieldDefinition = () => {
  const [data, setData] = useState<CustomFieldDefinitionJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.customFieldDefinition.updateCustomFieldDefinition>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.customFieldDefinition.updateCustomFieldDefinition(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for forceUpdateCustomField       * Updates an existing set of custom fields for a given parent object type and id. On success the update custom fields are returned.       * @path /customField/${parentObjectType}/${parentObjectId}/${customFieldName}/force       */
export const useForceUpdateCustomField = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.customField.forceUpdateCustomField>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.customField.forceUpdateCustomField(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for forceUpdateCustomFields       * Updates an existing set of custom fields for a given parent object type and id. On success the update custom fields are returned.       * @path /customField/${parentObjectType}/${parentObjectId}/force       */
export const useForceUpdateCustomFields = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.customField.forceUpdateCustomFields>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.customField.forceUpdateCustomFields(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getCustomFields       * Returns all custom fields for a specific parent object type and id       * @path /customField/${parentObjectType}/${parentObjectId}       */
export const useGetCustomFields = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.customField.getCustomFields>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.customField.getCustomFields(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for updateCustomField       * Updates an existing set of custom fields for a given parent object type and id. On success the update custom fields are returned.       * @path /customField/${parentObjectType}/${parentObjectId}/${customFieldName}       */
export const useUpdateCustomField = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.customField.updateCustomField>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.customField.updateCustomField(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updateCustomFields       * Updates an existing set of custom fields for a given parent object type and id. On success the update custom fields are returned.       * @path /customField/${parentObjectType}/${parentObjectId}       */
export const useUpdateCustomFields = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.customField.updateCustomFields>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.customField.updateCustomFields(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for compileOrderCreationCustomizationZeppaScript       * if this endpoint returns OK it means the order creation customization zeppa script has successfully compiled       * @path /beta/customization/orderCreationCustomization/compile       */
export const useCompileOrderCreationCustomizationZeppaScript = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.beta.compileOrderCreationCustomizationZeppaScript>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.beta.compileOrderCreationCustomizationZeppaScript(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for compileSelectionCustomizationZeppaScript       * if this endpoint returns OK it means the selection customization zeppa script has successfully compiled       * @path /beta/customization/selectionCustomization/compile       */
export const useCompileSelectionCustomizationZeppaScript = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.beta.compileSelectionCustomizationZeppaScript>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.beta.compileSelectionCustomizationZeppaScript(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for testOrderCreationCustomizationZeppaScript       * runs the provided zeppa script and returns a list of Rule actions that were applicable after zeppa script ran       * @path /beta/customization/orderCreationCustomization/test       */
export const useTestOrderCreationCustomizationZeppaScript = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.beta.testOrderCreationCustomizationZeppaScript>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.beta.testOrderCreationCustomizationZeppaScript(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for testSelectionCustomizationZeppaScript       * runs the provided zeppa script and returns a list of Rule actions that were applicable after zeppa script ran       * @path /beta/customization/selectionCustomization/test       */
export const useTestSelectionCustomizationZeppaScript = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.beta.testSelectionCustomizationZeppaScript>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.beta.testSelectionCustomizationZeppaScript(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addDiscount       * Creates a discount with the specified details. On success, the ID of the new discount is returned.       * @path /discounts       */
export const useAddDiscount = () => {
  const [data, setData] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.discounts.addDiscount>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.discounts.addDiscount(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteDiscount       * Deletes a discount. Note that a discount may not be deleted if it is in use.       * @path /discounts/${id}       */
export const useDeleteDiscount = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.discounts.deleteDiscount>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.discounts.deleteDiscount(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getDiscount       * Returns the details of the specified discount.       * @path /discounts/${id}       */
export const useGetDiscount = () => {
  const [data, setData] = useState<DiscountJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.discounts.getDiscount>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.discounts.getDiscount(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getDiscounts       * Returns all discounts that have been defined       * @path /discounts       */
export const useGetDiscounts = () => {
  const [data, setData] = useState<DiscountJson[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.discounts.getDiscounts>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.discounts.getDiscounts(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for updateDiscount       * Updates the details of the specified discount. Note that a discount cannot be updated once it is in use by an order.       * @path /discounts/${id}       */
export const useUpdateDiscount = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.discounts.updateDiscount>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.discounts.updateDiscount(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updateDiscountStatus       * Updates the status of a discount       * @path /discounts/${id}/status/${status}       */
export const useUpdateDiscountStatus = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.discounts.updateDiscountStatus>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.discounts.updateDiscountStatus(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for completeIntegration       * Complete the docusign integration. This should be called after the integration has been created and an authorization code has been received from Docusign.       * @path /docusign       */
export const useCompleteIntegration = () => {
  const [data, setData] = useState<DocuSignIntegrationResponseJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.docusign.completeIntegration>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.docusign.completeIntegration(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for deleteIntegration       * Removes integration with Docusign from your tenant       * @path /docusign       */
export const useDeleteIntegration = () => {
  const [data, setData] = useState<DocuSignIntegrationResponseJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.docusign.deleteIntegration>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.docusign.deleteIntegration(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for initiateIntegration       * Creates an integration with Docusign. On success a redirect URL is returned.       * @path /docusign       */
export const useInitiateIntegration = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.docusign.initiateIntegration>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.docusign.initiateIntegration(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addDocumentTemplate       * Add a new predefined terms that can be attached to an order as part of PDF document generation.       * @path /predefinedTerms       */
export const useAddDocumentTemplate = () => {
  const [data, setData] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.predefinedTerms.addDocumentTemplate>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.predefinedTerms.addDocumentTemplate(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteDocumentTemplate       * Deletes the predefined terms specified.       * @path /predefinedTerms/${id}       */
export const useDeleteDocumentTemplate = () => {
  const [data, setData] = useState<DocumentTemplateJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.predefinedTerms.deleteDocumentTemplate>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.predefinedTerms.deleteDocumentTemplate(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getDocumentTemplate       * Returns a specific predefined terms by Id.       * @path /predefinedTerms/${id}       */
export const useGetDocumentTemplate = () => {
  const [data, setData] = useState<DocumentTemplateJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.predefinedTerms.getDocumentTemplate>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.predefinedTerms.getDocumentTemplate(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getDocumentTemplates       * Returns predefined terms by type. These templates can be attached to orders as part of PDF document generation.       * @path /predefinedTerms       */
export const useGetDocumentTemplates = () => {
  const [data, setData] = useState<DocumentTemplateJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.predefinedTerms.getDocumentTemplates>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.predefinedTerms.getDocumentTemplates(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getDocumentTemplateVersion       * Returns a specific predefined terms by Id and version.       * @path /predefinedTerms/${id}/versions/${version}       */
export const useGetDocumentTemplateVersion = () => {
  const [data, setData] = useState<DocumentTemplateJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.predefinedTerms.getDocumentTemplateVersion>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.predefinedTerms.getDocumentTemplateVersion(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getDocumentTemplateVersions       * Returns predefined terms versions by Id.       * @path /predefinedTerms/${id}/versions       */
export const useGetDocumentTemplateVersions = () => {
  const [data, setData] = useState<DocumentTemplateJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.predefinedTerms.getDocumentTemplateVersions>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.predefinedTerms.getDocumentTemplateVersions(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for updateDocumentTemplate       * Updates a predefined terms       * @path /predefinedTerms/${id}       */
export const useUpdateDocumentTemplate = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.predefinedTerms.updateDocumentTemplate>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.predefinedTerms.updateDocumentTemplate(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updateDocumentTemplateStatus       * Updates the status of a predefined terms       * @path /predefinedTerms/${id}/status/${status}       */
export const useUpdateDocumentTemplateStatus = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.predefinedTerms.updateDocumentTemplateStatus>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.predefinedTerms.updateDocumentTemplateStatus(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for sendInvoiceReminder       * Sends a reminder email for the specified invoice       * @path /dunning/sendInvoiceReminder/${invoiceNumber}       */
export const useSendInvoiceReminder = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.dunning.sendInvoiceReminder>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.dunning.sendInvoiceReminder(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for sendTestDunningEmail       * Sends a test email for dunning to the use associated with this API call. Note A user bound api key is required for this operation.       * @path /dunning/sendTestEmail/${reminderType}       */
export const useSendTestDunningEmail = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.dunning.sendTestDunningEmail>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.dunning.sendTestDunningEmail(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getDunningSetting       * Returns the dunning settings for your tenant       * @path /dunningSetting       */
export const useGetDunningSetting = () => {
  const [data, setData] = useState<DunningSettingJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.dunningSetting.getDunningSetting>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.dunningSetting.getDunningSetting(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for updateDunningSetting       * Updates the dunning settings for your tenant       * @path /dunningSetting       */
export const useUpdateDunningSetting = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.dunningSetting.updateDunningSetting>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.dunningSetting.updateDunningSetting(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addEmailSetting       *        * @path /emailSettings       */
export const useAddEmailSetting = () => {
  const [data, setData] = useState<EmailSetting | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.emailSettings.addEmailSetting>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.emailSettings.addEmailSetting(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteEmailSetting       *        * @path /emailSettings       */
export const useDeleteEmailSetting = () => {
  const [data, setData] = useState<EmailSetting | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.emailSettings.deleteEmailSetting>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.emailSettings.deleteEmailSetting(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getEmailSettings       * Returns a list of email settings       * @path /emailSettings       */
export const useGetEmailSettings = () => {
  const [data, setData] = useState<EmailSetting[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.emailSettings.getEmailSettings>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.emailSettings.getEmailSettings(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for updateEmailSetting       *        * @path /emailSettings       */
export const useUpdateEmailSetting = () => {
  const [data, setData] = useState<EmailSetting | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.emailSettings.updateEmailSetting>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.emailSettings.updateEmailSetting(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for accountEntityMigration       * Move account along with its subscriptions, orders, invoices and other associated transactions to a target entity       * @path /entities/migrate/account       */
export const useAccountEntityMigration = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.entities.accountEntityMigration>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.entities.accountEntityMigration(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for compositeOrderEntityMigration       * Move composite order along with its subscriptions, orders and other associated transactions to a target entity       * @path /entities/migrate/compositeOrder       */
export const useCompositeOrderEntityMigration = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.entities.compositeOrderEntityMigration>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.entities.compositeOrderEntityMigration(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for create       * Create an entity. On success return the created entity.       * @path /entities       */
export const useCreate = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.entities.create>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.entities.create(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteEntity       * Delete the entity if there is no data associated with it.       * @path /entities/${id}       */
export const useDeleteEntity = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.entities.deleteEntity>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.entities.deleteEntity(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getEntities       * Gets all entities       * @path /entities       */
export const useGetEntities = () => {
  const [data, setData] = useState<EntityJson[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.entities.getEntities>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.entities.getEntities(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getEntityById       * Gets the entity details of the specified entity id       * @path /entities/${id}       */
export const useGetEntityById = () => {
  const [data, setData] = useState<Entity | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.entities.getEntityById>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.entities.getEntityById(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getLogo       * Get the current logo stored for the given entity       * @path /entities/logo/${entityId}       */
export const useGetLogo = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.entities.getLogo>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.entities.getLogo(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for orderEntityMigration       * Move order to a target entity       * @path /entities/migrate/order       */
export const useOrderEntityMigration = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.entities.orderEntityMigration>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.entities.orderEntityMigration(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for subscriptionEntityMigration       * Move subscription and associated transactions to a target entity       * @path /entities/migrate/subscription       */
export const useSubscriptionEntityMigration = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.entities.subscriptionEntityMigration>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.entities.subscriptionEntityMigration(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for uploadLogo       * Updates the logo used in external facing communication such as order forms and invoices       * @path /entities/logo/${entityId}       */
export const useUploadLogo = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.entities.uploadLogo>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.entities.uploadLogo(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for processDeleteTasks       * Trigger ERP journal entry deletion task for the given accounting period id       * @path /erp/processDeleteTasks/${accountingPeriodId}       */
export const useProcessDeleteTasks = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.erp.processDeleteTasks>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.erp.processDeleteTasks(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for processSyncTasks       * Trigger ERP journal entry sync task for given accounting period id       * @path /erp/processSyncTasks/${accountingPeriodId}       */
export const useProcessSyncTasks = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.erp.processSyncTasks>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.erp.processSyncTasks(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for syncCreditMemoToErp       * Trigger ERP credit memo sync task for the given credit memo number       * @path /erp/syncCreditMemo/${creditMemoNumber}       */
export const useSyncCreditMemoToErp = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.erp.syncCreditMemoToErp>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.erp.syncCreditMemoToErp(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for syncInvoiceToErp       * Trigger ERP invoice sync task for the given invoice id       * @path /erp/syncInvoice/${invoiceId}       */
export const useSyncInvoiceToErp = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.erp.syncInvoiceToErp>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.erp.syncInvoiceToErp(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for syncVoidInvoiceToErp       * Trigger ERP void invoice sync task for the given invoice number       * @path /erp/syncVoidInvoice/${invoiceNumber}       */
export const useSyncVoidInvoiceToErp = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.erp.syncVoidInvoiceToErp>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.erp.syncVoidInvoiceToErp(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addUsecase       *        * @path /guidedSelling/usecase       */
export const useAddUsecase = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.guidedSelling.addUsecase>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.guidedSelling.addUsecase(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteUsecase       *        * @path /guidedSelling/usecase/${id}       */
export const useDeleteUsecase = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.guidedSelling.deleteUsecase>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.guidedSelling.deleteUsecase(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for fetchNextQuestion       * Get the next question given a set of answers to questions so far       * @path /guidedSelling/usecase/${usecase}/nextQuestion       */
export const useFetchNextQuestion = () => {
  const [data, setData] = useState<NextQuestion | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.guidedSelling.fetchNextQuestion>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.guidedSelling.fetchNextQuestion(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getAnswersFromAi       * Get the answer to guided selling questions form the deal desk AI, when all questions are answered then return the Answers for the guided selling       * @path /guidedSelling/ai/answers       */
export const useGetAnswersFromAi = () => {
  const [data, setData] = useState<MessagesAndAnswer | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.guidedSelling.getAnswersFromAi>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.guidedSelling.getAnswersFromAi(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getUsecase       *        * @path /guidedSelling/usecase/${id}       */
export const useGetUsecase = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.guidedSelling.getUsecase>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.guidedSelling.getUsecase(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getUsecaseScript       *        * @path /guidedSelling/usecase/${id}/qscript       */
export const useGetUsecaseScript = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.guidedSelling.getUsecaseScript>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.guidedSelling.getUsecaseScript(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for listUsecases       *        * @path /guidedSelling/usecase       */
export const useListUsecases = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.guidedSelling.listUsecases>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.guidedSelling.listUsecases(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for putQScript       *        * @path /guidedSelling/usecase/${id}/qscript       */
export const usePutQScript = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.guidedSelling.putQScript>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.guidedSelling.putQScript(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for testQScript       * Given the use case, list of answers and Zeppa Q script try creating the order in dryrun mode       * @path /guidedSelling/usecase/${usecase}/testQscript       */
export const useTestQScript = () => {
  const [data, setData] = useState<OrderJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.guidedSelling.testQScript>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.guidedSelling.testQScript(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updateUsecase       *        * @path /guidedSelling/usecase/${id}       */
export const useUpdateUsecase = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.guidedSelling.updateUsecase>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.guidedSelling.updateUsecase(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for authorizationCodeCallback       * Endpoint to handle and process a HubSpot authorization code       * @path /hubspot       */
export const useAuthorizationCodeCallback = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.hubspot.authorizationCodeCallback>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.hubspot.authorizationCodeCallback(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for deleteIntegration_1       * Deletes your integration with HubSpot.       * @path /hubspot       */
export const useDeleteIntegration1 = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.hubspot.deleteIntegration1>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.hubspot.deleteIntegration1(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for initiateIntegration_1       * Initiates an integration with HubSpot. On success redirect URL is returned.       * @path /hubspot       */
export const useInitiateIntegration1 = () => {
  const [data, setData] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.hubspot.initiateIntegration1>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.hubspot.initiateIntegration1(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for setupHubSpot       * Add custom properties to HubSpot       * @path /hubspot/setup       */
export const useSetupHubSpot = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.hubspot.setupHubSpot>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.hubspot.setupHubSpot(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for syncEsignDetailsForOrderToHubSpot       *        * @path /hubspot/esign/${orderId}       */
export const useSyncEsignDetailsForOrderToHubSpot = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.hubspot.syncEsignDetailsForOrderToHubSpot>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.hubspot.syncEsignDetailsForOrderToHubSpot(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for verifySetup       * Verify custom objects and properties       * @path /hubspot/setup/verify       */
export const useVerifySetup = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.hubspot.verifySetup>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.hubspot.verifySetup(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for createFlatfileWorkbook       * Creates a Flatfile workbook and adds it to a space       * @path /import/flatfile/${domain}       */
export const useCreateFlatfileWorkbook = () => {
  const [data, setData] = useState<FlatfileWorkbookResponse | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.import.createFlatfileWorkbook>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.import.createFlatfileWorkbook(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getAccountContactsInImportFormat       * Gets account contacts in a format appropriate for re-import. On success, the output is a csv containing the account and contacts.       * @path /import/export/accountContact       */
export const useGetAccountContactsInImportFormat = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.import.getAccountContactsInImportFormat>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.import.getAccountContactsInImportFormat(...variables)
        setData(resp.data)
        const link = document.createElement('a')
        const file = new Blob([resp.data], { type: 'text/csv' })
        link.href = URL.createObjectURL(file)
        link.download = 'getAccountContactsInImportFormat.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getAmendmentOrderExportInImportFormat       * Gets amendment orders in a format appropriate for re-import for the given generation. On success, the output is a csv containing the orders.       * @path /import/export/amendmentOrders       */
export const useGetAmendmentOrderExportInImportFormat = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.import.getAmendmentOrderExportInImportFormat>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.import.getAmendmentOrderExportInImportFormat(...variables)
        setData(resp.data)
        const link = document.createElement('a')
        const file = new Blob([resp.data], { type: 'text/csv' })
        link.href = URL.createObjectURL(file)
        link.download = 'getAmendmentOrderExportInImportFormat.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getAvailableSchemas       * Returns the set of schemas available for import       * @path /import/schemas       */
export const useGetAvailableSchemas = () => {
  const [data, setData] = useState<Record<string, object[]> | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.import.getAvailableSchemas>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.import.getAvailableSchemas(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getCatalogDataInImportFormat       * Gets catalog data in a format appropriate for re-import. On success, the output is a csv containing the catalog data containing Product, Plan and Charge data.       * @path /import/export/catalog       */
export const useGetCatalogDataInImportFormat = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.import.getCatalogDataInImportFormat>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.import.getCatalogDataInImportFormat(...variables)
        setData(resp.data)
        const link = document.createElement('a')
        const file = new Blob([resp.data], { type: 'text/csv' })
        link.href = URL.createObjectURL(file)
        link.download = 'getCatalogDataInImportFormat.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getDataImportById       * Gets an item that was imported by its ID       * @path /import/${importId}       */
export const useGetDataImportById = () => {
  const [data, setData] = useState<DataImport | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.import.getDataImportById>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.import.getDataImportById(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getDataImports       * Gets all items that was imported       * @path /import       */
export const useGetDataImports = () => {
  const [data, setData] = useState<DataImport | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.import.getDataImports>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.import.getDataImports(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getImportResult_2       * Returns the details of an import by its ID       * @path /import/${importId}/result       */
export const useGetImportResult2 = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.import.getImportResult2>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.import.getImportResult2(...variables)
        setData(resp.data)
        const link = document.createElement('a')
        const file = new Blob([resp.data], { type: 'text/csv' })
        link.href = URL.createObjectURL(file)
        link.download = 'getImportResult_2.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getNewOrderExportInImportFormat       * Gets new orders in a format appropriate for re-import. On success, the output is a csv containing the orders.       * @path /import/export/newOrders       */
export const useGetNewOrderExportInImportFormat = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.import.getNewOrderExportInImportFormat>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.import.getNewOrderExportInImportFormat(...variables)
        setData(resp.data)
        const link = document.createElement('a')
        const file = new Blob([resp.data], { type: 'text/csv' })
        link.href = URL.createObjectURL(file)
        link.download = 'getNewOrderExportInImportFormat.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for processImport       * Processes the import specified.       * @path /import/${importId}/process       */
export const useProcessImport = () => {
  const [data, setData] = useState<DataImport | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.import.processImport>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.import.processImport(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for validateMultiPartFileImport       * Validates a multi-part import file. Returns an import ID on success.       * @path /import       */
export const useValidateMultiPartFileImport = () => {
  const [data, setData] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.import.validateMultiPartFileImport>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.import.validateMultiPartFileImport(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addBillingSchedule       * Creates a new billing schedule entry for an event based charge       * @path /invoices/schedule       */
export const useAddBillingSchedule = () => {
  const [data, setData] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.addBillingSchedule>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.addBillingSchedule(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for createBulkInvoiceRun       * Creates a bulk invoice run as specified by the input parameters. On success the id of the run is returned.       * @path /invoices/bulk       */
export const useCreateBulkInvoiceRun = () => {
  const [data, setData] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.createBulkInvoiceRun>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.createBulkInvoiceRun(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for createInvoiceDocument       * Generates a PDF version of the specified invoice. Once generated, PDF can be later fetched via a call to /{invoiceNumber}/pdf.       * @path /invoices/${invoiceNumber}/pdf       */
export const useCreateInvoiceDocument = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.createInvoiceDocument>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.createInvoiceDocument(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteBillingSchedule       * Billing schedules must be deleted in reverse chronological order.       * @path /invoices/schedule/${id}       */
export const useDeleteBillingSchedule = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.deleteBillingSchedule>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.deleteBillingSchedule(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteInvoice       * Deletes the specified invoice       * @path /invoices/${number}       */
export const useDeleteInvoice = () => {
  const [data, setData] = useState<InvoiceJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.deleteInvoice>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.deleteInvoice(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteVoidedInvoice       * Deletes the specified invoice       * @path /invoices/${number}/voided       */
export const useDeleteVoidedInvoice = () => {
  const [data, setData] = useState<InvoiceJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.deleteVoidedInvoice>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.deleteVoidedInvoice(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for emailInvoice       * Send the invoice to recipients via email with invoice PDF as attachment.       * @path /invoices/${invoiceNumber}/email       */
export const useEmailInvoice = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.emailInvoice>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.emailInvoice(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for generateInvoice       * Generates the invoices for the given subscription       * @path /invoices/generate       */
export const useGenerateInvoice = () => {
  const [data, setData] = useState<InvoiceJson[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.generateInvoice>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.generateInvoice(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getBillingSchedules       * Retrieve all billing schedules for a subscription line item       * @path /invoices/schedule       */
export const useGetBillingSchedules = () => {
  const [data, setData] = useState<BillingEventEntry[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.getBillingSchedules>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.getBillingSchedules(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getBulkInvoiceRun       * Returns the details of the specified bulk invoice run       * @path /invoices/bulk/${bulkInvoiceRunId}       */
export const useGetBulkInvoiceRun = () => {
  const [data, setData] = useState<BulkInvoiceRun | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.getBulkInvoiceRun>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.getBulkInvoiceRun(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getBulkInvoiceRunItems       * Returns the items associated with the specified bulk invoice run       * @path /invoices/bulk/${bulkInvoiceRunId}/runItems       */
export const useGetBulkInvoiceRunItems = () => {
  const [data, setData] = useState<BulkInvoiceRunItem[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.getBulkInvoiceRunItems>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.getBulkInvoiceRunItems(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getCanDeleteInvoice       * Response contains flag to indicate if the invoice can be deleted and reason if it cannot be deleted       * @path /invoices/${number}/canDelete       */
export const useGetCanDeleteInvoice = () => {
  const [data, setData] = useState<InvoiceDeletableResponse | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.getCanDeleteInvoice>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.getCanDeleteInvoice(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getInvoice       * Returns the details of the specified invoice number       * @path /invoices/${number}       */
export const useGetInvoice = () => {
  const [data, setData] = useState<InvoiceJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.getInvoice>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.getInvoice(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getInvoiceBalance       * Returns the balance of the specified invoice number       * @path /invoices/${number}/balance       */
export const useGetInvoiceBalance = () => {
  const [data, setData] = useState<InvoiceBalanceJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.getInvoiceBalance>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.getInvoiceBalance(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getInvoiceDocumentPdf       * Downloads the PDF for an invoice after it has been generated via a POST to /{invoiceNumber}/pdf. The data return will be the PDF document contents.       * @path /invoices/${invoiceNumber}/pdf       */
export const useGetInvoiceDocumentPdf = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.getInvoiceDocumentPdf>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.getInvoiceDocumentPdf(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getInvoices       * Returns all invoices for a subscription. The result is paginated. Use the cursor returned from a request in subsequent calls to retrieve all results.       * @path /invoices       */
export const useGetInvoices = () => {
  const [data, setData] = useState<InvoiceJsonPaginationResponse | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.getInvoices>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.getInvoices(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getRawInvoiceDocumentJson       * Invoice document JSON that contains all details required to render full Invoice document       * @path /invoices/${invoiceNumber}/documentJson       */
export const useGetRawInvoiceDocumentJson = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.getRawInvoiceDocumentJson>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.getRawInvoiceDocumentJson(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getUsageForSubscriptionCharge       * Returns the usage invoice items for the specified subscription and charge       * @path /invoices/usage       */
export const useGetUsageForSubscriptionCharge = () => {
  const [data, setData] = useState<InvoiceItemJson[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.getUsageForSubscriptionCharge>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.getUsageForSubscriptionCharge(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for postInvoice       * Marks the specified invoice as posted       * @path /invoices/${number}/post       */
export const usePostInvoice = () => {
  const [data, setData] = useState<InvoiceJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.postInvoice>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.postInvoice(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for postInvoicesForBulkInvoiceRun       * Marks all invoices associated with the specified bulk run as posted       * @path /invoices/bulk/${bulkInvoiceRunId}/post       */
export const usePostInvoicesForBulkInvoiceRun = () => {
  const [data, setData] = useState<BulkInvoiceRun | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.postInvoicesForBulkInvoiceRun>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.postInvoicesForBulkInvoiceRun(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for previewInvoiceByOrderPeriod       * Returns the unbilled usage invoice items for the specified subscription       * @path /invoices/unbilledUsage       */
export const usePreviewInvoiceByOrderPeriod = () => {
  const [data, setData] = useState<InvoiceItemJson[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.previewInvoiceByOrderPeriod>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.previewInvoiceByOrderPeriod(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for previewInvoiceByOrderPeriod_1       * Returns a preview of invoice for the specified order id OR subscription id       * @path /invoices/preview       */
export const usePreviewInvoiceByOrderPeriod1 = () => {
  const [data, setData] = useState<InvoicePreviewJson[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.previewInvoiceByOrderPeriod1>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.previewInvoiceByOrderPeriod1(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for updateInvoice       * Updates the details of the specified invoice.       * @path /invoices/${number}       */
export const useUpdateInvoice = () => {
  const [data, setData] = useState<InvoiceJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.updateInvoice>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.updateInvoice(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updateInvoiceSequence       * Updates the invoice sequence by invoice config id.       * @path /invoices/sequence/${invoiceConfigId}       */
export const useUpdateInvoiceSequence = () => {
  const [data, setData] = useState<TenantInvoiceConfig | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.updateInvoiceSequence>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.updateInvoiceSequence(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for voidInvoice       * Marks the specified invoice as voided       * @path /invoices/${number}/void       */
export const useVoidInvoice = () => {
  const [data, setData] = useState<InvoiceJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.invoices.voidInvoice>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.invoices.voidInvoice(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addAndApplyBulkPaymentsCsv       * Bulk upload payments to invoices in CSV file       * @path /settlements/addAndApplyPaymentsInBulk/csv       */
export const useAddAndApplyBulkPaymentsCsv = () => {
  const [data, setData] = useState<BulkPaymentUploadResult | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.settlements.addAndApplyBulkPaymentsCsv>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.settlements.addAndApplyBulkPaymentsCsv(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addAndApplyPayment       * Apply a payment per the specified parameters.       * @path /settlements/addAndApplyPayment       */
export const useAddAndApplyPayment = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.settlements.addAndApplyPayment>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.settlements.addAndApplyPayment(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for applyCreditMemo       * Applies a credit memo per the specified parameters       * @path /settlements/applyCreditMemo       */
export const useApplyCreditMemo = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.settlements.applyCreditMemo>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.settlements.applyCreditMemo(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getApplicablePaymentBankAccountsForInvoicePayment       * Returns a list of applicable payment bank accounts that can be used for payment for a given invoice number       * @path /settlements/applicablePaymentBankAccounts       */
export const useGetApplicablePaymentBankAccountsForInvoicePayment = () => {
  const [data, setData] = useState<PaymentBankAccountJson[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (
      variables: Parameters<typeof billyRestClient.settlements.getApplicablePaymentBankAccountsForInvoicePayment>
    ) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.settlements.getApplicablePaymentBankAccountsForInvoicePayment(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getSettlementApplication       * Gets the details of specified settlement application.       * @path /settlements/${id}       */
export const useGetSettlementApplication = () => {
  const [data, setData] = useState<SettlementApplication | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.settlements.getSettlementApplication>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.settlements.getSettlementApplication(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getSettlementApplications       * Returns the settlement applications for the specified invoice number or payment object.       * @path /settlements       */
export const useGetSettlementApplications = () => {
  const [data, setData] = useState<SettlementApplication[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.settlements.getSettlementApplications>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.settlements.getSettlementApplications(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for unapplyCreditMemo       * Unapplies a credit memo per the specified parameters       * @path /settlements/unapplyCreditMemo       */
export const useUnapplyCreditMemo = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.settlements.unapplyCreditMemo>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.settlements.unapplyCreditMemo(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getAccountingEvents       * Returns all accounting related events between the from and to dates. Since there can be a large number of these, the results are paginated. To retrieve subsequent pages of events, pass in the pageToken returned from the prior call.       * @path /accounting/journalEntry/events       */
export const useGetAccountingEvents = () => {
  const [data, setData] = useState<AccountingEventPaginatedResponse | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.accounting.getAccountingEvents>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.accounting.getAccountingEvents(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getJournalEntries       * Returns all Journal Entries for the specified accounting period as text/csv       * @path /accounting/journalEntry       */
export const useGetJournalEntries = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.accounting.getJournalEntries>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.accounting.getJournalEntries(...variables)
        setData(resp.data)
        const link = document.createElement('a')
        const file = new Blob([resp.data], { type: 'text/csv' })
        link.href = URL.createObjectURL(file)
        link.download = 'getJournalEntries.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getRunningBalances       * Returns deferred revenue and contract asset balances for the given revenue schedule, as of the given date.If no date is provided, the balances are returned as of the current date.       * @path /accounting/journalEntry/runningBalances       */
export const useGetRunningBalances = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.accounting.getRunningBalances>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.accounting.getRunningBalances(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for addExternalArrMetrics       * returns the request with its captured id. Arr metrics will be generated as a backend job       * @path /metricsReporting/externalArrMetrics       */
export const useAddExternalArrMetrics = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.metricsReporting.addExternalArrMetrics>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.metricsReporting.addExternalArrMetrics(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getExternalArrMetrics       * returns the external arr metrics request for the provided id       * @path /metricsReporting/externalArrMetrics/${id}       */
export const useGetExternalArrMetrics = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.metricsReporting.getExternalArrMetrics>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.metricsReporting.getExternalArrMetrics(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getExternalArrMetrics_1       * returns the paginated list of external arr metrics requests       * @path /metricsReporting/externalArrMetrics       */
export const useGetExternalArrMetrics1 = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.metricsReporting.getExternalArrMetrics1>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.metricsReporting.getExternalArrMetrics1(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for populateArrMetricsForSubscription       * returns 200 if the result is successfully submitted to a background task       * @path /metricsReporting/populateArrMetrics/subscriptions/${subscriptionId}       */
export const usePopulateArrMetricsForSubscription = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.metricsReporting.populateArrMetricsForSubscription>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.metricsReporting.populateArrMetricsForSubscription(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addTargetAndEventsSubscriptions       * Adds a notification target and events according to the specified parameters       * @path /notifications       */
export const useAddTargetAndEventsSubscriptions = () => {
  const [data, setData] = useState<NotificationTargetAndSubscriptions | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.notifications.addTargetAndEventsSubscriptions>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.notifications.addTargetAndEventsSubscriptions(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getAllNotificationSubscriptionsForTenant       * Returns all notification subscriptions for your tenant       * @path /notifications       */
export const useGetAllNotificationSubscriptionsForTenant = () => {
  const [data, setData] = useState<NotificationTargetAndSubscriptions[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.notifications.getAllNotificationSubscriptionsForTenant>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.notifications.getAllNotificationSubscriptionsForTenant(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for subscribeExistingNotificationTargetToEvent       * Attaches a notification event to the target specified by the notification id       * @path /notifications/${notificationId}       */
export const useSubscribeExistingNotificationTargetToEvent = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.notifications.subscribeExistingNotificationTargetToEvent>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.notifications.subscribeExistingNotificationTargetToEvent(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for unsubscribeTargetOrEvent       * Unsubscribes the specified notification target from the specified event       * @path /notifications/unsubscribe/${notificationId}       */
export const useUnsubscribeTargetOrEvent = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.notifications.unsubscribeTargetOrEvent>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.notifications.unsubscribeTargetOrEvent(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for createOpportunity       * Returns the details of a specified opportunity       * @path /opportunity       */
export const useCreateOpportunity = () => {
  const [data, setData] = useState<OpportunityJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.opportunity.createOpportunity>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.opportunity.createOpportunity(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteOpportunityByCrmId       * Delete an opportunity with given CRM ID if there are no associated orders       * @path /opportunity/crm/${id}       */
export const useDeleteOpportunityByCrmId = () => {
  const [data, setData] = useState<object[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.opportunity.deleteOpportunityByCrmId>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.opportunity.deleteOpportunityByCrmId(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getOpportunities       * Returns all opportunities associated with the specified account. The results are paginated. To fetch all results, pass the cursor returned from a call to subsequent calls.       * @path /opportunity       */
export const useGetOpportunities = () => {
  const [data, setData] = useState<OpportunityPaginationResponse | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.opportunity.getOpportunities>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.opportunity.getOpportunities(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getOpportunityByCrmOpportunityId       * Returns the details of a specified opportunity       * @path /opportunity/crm/${id}       */
export const useGetOpportunityByCrmOpportunityId = () => {
  const [data, setData] = useState<OpportunityJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.opportunity.getOpportunityByCrmOpportunityId>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.opportunity.getOpportunityByCrmOpportunityId(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getOpportunityByOpportunityId       * Returns the details of a specified opportunity       * @path /opportunity/${id}       */
export const useGetOpportunityByOpportunityId = () => {
  const [data, setData] = useState<OpportunityJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.opportunity.getOpportunityByOpportunityId>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.opportunity.getOpportunityByOpportunityId(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getOrdersByCrmOpportunityId       * Returns the details of orders       * @path /opportunity/crm/${id}/orders       */
export const useGetOrdersByCrmOpportunityId = () => {
  const [data, setData] = useState<object[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.opportunity.getOrdersByCrmOpportunityId>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.opportunity.getOrdersByCrmOpportunityId(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for resetOpportunityClosedState       * Returns the details of the updated opportunity       * @path /opportunity/${id}/open       */
export const useResetOpportunityClosedState = () => {
  const [data, setData] = useState<OpportunityJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.opportunity.resetOpportunityClosedState>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.opportunity.resetOpportunityClosedState(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updateOpportunity       * Returns the details of the updated opportunity       * @path /opportunity/${id}       */
export const useUpdateOpportunity = () => {
  const [data, setData] = useState<OpportunityJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.opportunity.updateOpportunity>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.opportunity.updateOpportunity(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addOrder       * creates an order with the specified parameters. On success the order id is returned.       * @path /orders       */
export const useAddOrder = () => {
  const [data, setData] = useState<OrderJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.orders.addOrder>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.orders.addOrder(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for createOrderDocument       * Generate and retrieve a PDF representation of the order details for a specific order by its ID.       * @path /orders/${id}/pdf       */
export const useCreateOrderDocument = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.orders.createOrderDocument>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.orders.createOrderDocument(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteOrder       * Delete a specific order by its ID.       * @path /orders/${id}       */
export const useDeleteOrder = () => {
  const [data, setData] = useState<OrderJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.orders.deleteOrder>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.orders.deleteOrder(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for executeOrder       * Marks the order as executed. Optionally, the execution time can be specified using the executedOn query parameter.       * @path /orders/${id}/execute       */
export const useExecuteOrder = () => {
  const [data, setData] = useState<OrderJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.orders.executeOrder>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.orders.executeOrder(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for forceExecuteOrder       * Forcefully executes an order. This endpoint bypasses approval checks and immediately executes the order.       * @path /orders/${id}/execute/force       */
export const useForceExecuteOrder = () => {
  const [data, setData] = useState<OrderJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.orders.forceExecuteOrder>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.orders.forceExecuteOrder(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getCustomBillingSchedule       * Get the custom billing schedule for the order       * @path /orders/${orderId}/billing/custom       */
export const useGetCustomBillingSchedule = () => {
  const [data, setData] = useState<CustomBillingScheduleOutput | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.orders.getCustomBillingSchedule>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.orders.getCustomBillingSchedule(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getOrder       * Retrieve details of a specific order by its ID.       * @path /orders/${id}       */
export const useGetOrder = () => {
  const [data, setData] = useState<OrderJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.orders.getOrder>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.orders.getOrder(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getOrderDocument       * Retrieves the PDF version of the order form for a specific order identified by its ID.       * @path /orders/${id}/pdf       */
export const useGetOrderDocument = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.orders.getOrderDocument>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.orders.getOrderDocument(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getOrderDocumentDoc       * Download a Microsoft Word document of the order form for a specific order by its ID.       * @path /orders/${id}/doc       */
export const useGetOrderDocumentDoc = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.orders.getOrderDocumentDoc>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.orders.getOrderDocumentDoc(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getOrderDocumentDocx       * Download a Microsoft Word document of the order form for a specific order by its ID.       * @path /orders/${id}/docx       */
export const useGetOrderDocumentDocx = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.orders.getOrderDocumentDocx>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.orders.getOrderDocumentDocx(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getOrderLineMetrics       * Retrieves metrics for all line items associated with the specified order.       * @path /orders/${id}/lineItems/metrics       */
export const useGetOrderLineMetrics = () => {
  const [data, setData] = useState<Record<string, MetricsJson> | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.orders.getOrderLineMetrics>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.orders.getOrderLineMetrics(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getOrderMetrics       * Retrieve the metrics for a specific order by its ID. Metrics can be filtered by a target date.       * @path /orders/${id}/metrics       */
export const useGetOrderMetrics = () => {
  const [data, setData] = useState<MetricsJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.orders.getOrderMetrics>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.orders.getOrderMetrics(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getOrders       * Gets all orders for your tenant. The results are paginated. To fetch all results, take the cursor returned from a call and pass it to subsequent calls.       * @path /orders       */
export const useGetOrders = () => {
  const [data, setData] = useState<OrderJsonPaginationResponse | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.orders.getOrders>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.orders.getOrders(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getSpecificOrderDocument       * Retrieves specific PDF of the order form for a specific order identified by its orderId.       * @path /orders/${orderId}/pdf/${id}       */
export const useGetSpecificOrderDocument = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.orders.getSpecificOrderDocument>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.orders.getSpecificOrderDocument(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for rebaseAmendment       * Rebase a specific order by its ID. Rebasing an order involves recalculating its metrics or values based on updated data or criteria.       * @path /orders/${orderId}/rebase       */
export const useRebaseAmendment = () => {
  const [data, setData] = useState<OrderJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.orders.rebaseAmendment>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.orders.rebaseAmendment(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updateOrder       * Updates the details of the specified order.       * @path /orders       */
export const useUpdateOrder = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.orders.updateOrder>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.orders.updateOrder(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updateOrderAttributes       * Update order attributes for non-draft orders       * @path /orders/${orderId}/attributes       */
export const useUpdateOrderAttributes = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.orders.updateOrderAttributes>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.orders.updateOrderAttributes(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updateOrderStatus       * Update the status of a specific order by its ID.       * @path /orders/${id}/status/${status}       */
export const useUpdateOrderStatus = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.orders.updateOrderStatus>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.orders.updateOrderStatus(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteBankAccount       *        * @path /payments/bank-account/${id}       */
export const useDeleteBankAccount = () => {
  const [data, setData] = useState<PaymentBankAccountJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.payments.deleteBankAccount>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.payments.deleteBankAccount(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getAccountPayment       * Returns the payments for the specified account       * @path /payments/account/${id}       */
export const useGetAccountPayment = () => {
  const [data, setData] = useState<PaymentJson[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.payments.getAccountPayment>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.payments.getAccountPayment(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getAccountPaymentManagementLink       * Returns a payment management link for an account       * @path /payments/account-payment/${id}       */
export const useGetAccountPaymentManagementLink = () => {
  const [data, setData] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.payments.getAccountPaymentManagementLink>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.payments.getAccountPaymentManagementLink(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getBankAccount       *        * @path /payments/bank-account/${id}       */
export const useGetBankAccount = () => {
  const [data, setData] = useState<PaymentBankAccountJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.payments.getBankAccount>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.payments.getBankAccount(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getLedgerAccountsForPaymentBankAccount       * The bank account would be mapped to a cash and an expense ledger account from this list       * @path /payments/bank-account/accounts/ledger       */
export const useGetLedgerAccountsForPaymentBankAccount = () => {
  const [data, setData] = useState<LedgerAccount[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.payments.getLedgerAccountsForPaymentBankAccount>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.payments.getLedgerAccountsForPaymentBankAccount(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getPayment       * Gets the details of the specified payment       * @path /payments/${id}       */
export const useGetPayment = () => {
  const [data, setData] = useState<PaymentJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.payments.getPayment>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.payments.getPayment(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getPaymentBalance       * Gets the balance of a payment       * @path /payments/${id}/balance       */
export const useGetPaymentBalance = () => {
  const [data, setData] = useState<PaymentBalanceJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.payments.getPaymentBalance>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.payments.getPaymentBalance(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getPaymentConfiguration       * Returns the payment configuration for your tenant       * @path /payments/configuration       */
export const useGetPaymentConfiguration = () => {
  const [data, setData] = useState<PaymentConfiguration | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.payments.getPaymentConfiguration>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.payments.getPaymentConfiguration(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getPayments       * Returns all payments for you tenant. The results are paginated. To fetch all take the cursor returned from a call and pass it to a subsequent call.       * @path /payments       */
export const useGetPayments = () => {
  const [data, setData] = useState<PaymentJsonPaginationResponse | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.payments.getPayments>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.payments.getPayments(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for updatePaymentConfiguration       * Updates the payment configuration for your tenant.       * @path /payments/configuration       */
export const useUpdatePaymentConfiguration = () => {
  const [data, setData] = useState<PaymentConfiguration | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.payments.updatePaymentConfiguration>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.payments.updatePaymentConfiguration(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for upsertBankAccount       * The bank account would be mapped to a cash and an expense ledger account       * @path /payments/bank-account       */
export const useUpsertBankAccount = () => {
  const [data, setData] = useState<PaymentBankAccountJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.payments.upsertBankAccount>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.payments.upsertBankAccount(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for voidPayment       * Voids the specified payment per the specified parameters       * @path /payments/${id}/void       */
export const useVoidPayment = () => {
  const [data, setData] = useState<PaymentJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.payments.voidPayment>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.payments.voidPayment(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for activatePlan       * Marks a plan as active       * @path /plans/${planId}/activate       */
export const useActivatePlan = () => {
  const [data, setData] = useState<PlanJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.plans.activatePlan>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.plans.activatePlan(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addCharge       * Adds a charge to the specified plan.  On success the id of the new charge is returned       * @path /plans/${planId}/charges       */
export const useAddCharge = () => {
  const [data, setData] = useState<ChargeJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.plans.addCharge>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.plans.addCharge(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addPlan       * Creates a plan. On success the id of the new plan is returned.       * @path /plans       */
export const useAddPlan = () => {
  const [data, setData] = useState<PlanJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.plans.addPlan>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.plans.addPlan(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deactivatePlan       * Marks a plan as draft       * @path /plans/${planId}/revertToDraft       */
export const useDeactivatePlan = () => {
  const [data, setData] = useState<PlanJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.plans.deactivatePlan>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.plans.deactivatePlan(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteCharge       * Removes a charge from a plan.       * @path /plans/${planId}/charges/${chargeId}       */
export const useDeleteCharge = () => {
  const [data, setData] = useState<ChargeJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.plans.deleteCharge>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.plans.deleteCharge(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deletePlan       * Deletes a plan. Note you can&#39;t delete a plan that&#39;s in use.       * @path /plans/${planId}       */
export const useDeletePlan = () => {
  const [data, setData] = useState<PlanJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.plans.deletePlan>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.plans.deletePlan(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deprecatePlan       * Marks a plan as deprecated. Once deprecated a plan may not be attached to new orders.       * @path /plans/${planId}/deprecate       */
export const useDeprecatePlan = () => {
  const [data, setData] = useState<PlanJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.plans.deprecatePlan>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.plans.deprecatePlan(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for duplicatePlan       * Duplicates the specified plan. On success the new plan id is returned.       * @path /plans/${planId}/duplicate       */
export const useDuplicatePlan = () => {
  const [data, setData] = useState<PlanJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.plans.duplicatePlan>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.plans.duplicatePlan(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getCharge_1       * Gets the details of the specified charge on the specified plan.       * @path /plans/${planId}/charges/${chargeId}       */
export const useGetCharge1 = () => {
  const [data, setData] = useState<ChargeJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.plans.getCharge1>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.plans.getCharge1(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getChargeLedgerAccounts       * Gets the ledger accounts mapped to the specified charge       * @path /plans/${planId}/charges/${chargeId}/ledgerAccounts       */
export const useGetChargeLedgerAccounts = () => {
  const [data, setData] = useState<LedgerAccount[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.plans.getChargeLedgerAccounts>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.plans.getChargeLedgerAccounts(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getPlan       * Returns the details of the specified plan.       * @path /plans/${planId}       */
export const useGetPlan = () => {
  const [data, setData] = useState<PlanJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.plans.getPlan>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.plans.getPlan(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getPlans       * Returns all plans for a product. The result is paginated. To retrieve all results pass the cursor returned from a call to the next call until all results are returned.       * @path /plans       */
export const useGetPlans = () => {
  const [data, setData] = useState<PlanJsonPaginationResponse | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.plans.getPlans>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.plans.getPlans(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getReplacedPlans       *        * @path /plans/${planId}/replacedPlans       */
export const useGetReplacedPlans = () => {
  const [data, setData] = useState<PlanJson[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.plans.getReplacedPlans>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.plans.getReplacedPlans(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for mapLedgerAccountsToCharge       * Map ledger accounts to the specified charge for the specified plan.       * @path /plans/${planId}/charges/${chargeId}/ledgerAccounts       */
export const useMapLedgerAccountsToCharge = () => {
  const [data, setData] = useState<LedgerAccount[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.plans.mapLedgerAccountsToCharge>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.plans.mapLedgerAccountsToCharge(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for patchCharge       * Updates certain details of the specified charge which won&#39;t impact its financial treatment       * @path /plans/${planId}/charges/${chargeId}/partial       */
export const usePatchCharge = () => {
  const [data, setData] = useState<ChargeJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.plans.patchCharge>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.plans.patchCharge(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for reactivatePlan       * Reactivates a deprecated plan.       * @path /plans/${planId}/reactivate       */
export const useReactivatePlan = () => {
  const [data, setData] = useState<PlanJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.plans.reactivatePlan>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.plans.reactivatePlan(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updateCharge       * Updates the details of the specified charge on the specified plan.       * @path /plans/${planId}/charges/${chargeId}       */
export const useUpdateCharge = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.plans.updateCharge>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.plans.updateCharge(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updatePlan       * Updates the details of the specified plan. Note you can&#39;t update the details of a plan once it&#39;s in use.       * @path /plans/${planId}       */
export const useUpdatePlan = () => {
  const [data, setData] = useState<PlanJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.plans.updatePlan>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.plans.updatePlan(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updatePlanMetadata       * Update plan metadata. For now, this can be used only to change the entities assigned to a plan       * @path /plans/${planId}/metadata       */
export const useUpdatePlanMetadata = () => {
  const [data, setData] = useState<PlanJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.plans.updatePlanMetadata>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.plans.updatePlanMetadata(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updatePlanTerms       * Updates predefined terms associated with the specified plan.       * @path /plans/${planId}/terms       */
export const useUpdatePlanTerms = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.plans.updatePlanTerms>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.plans.updatePlanTerms(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addTermSection       * Adds a predefined terms section.       * @path /termsections       */
export const useAddTermSection = () => {
  const [data, setData] = useState<DocumentSection | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.termsections.addTermSection>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.termsections.addTermSection(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteTermSection       * Deletes the predefined terms section specified.       * @path /termsections/${id}       */
export const useDeleteTermSection = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.termsections.deleteTermSection>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.termsections.deleteTermSection(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getTermSection       * Returns predefined terms section detail.       * @path /termsections/${id}       */
export const useGetTermSection = () => {
  const [data, setData] = useState<DocumentSection | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.termsections.getTermSection>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.termsections.getTermSection(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getTermSections       * Returns predefined terms sections.       * @path /termsections       */
export const useGetTermSections = () => {
  const [data, setData] = useState<DocumentSection | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.termsections.getTermSections>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.termsections.getTermSections(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for updateTermSection       * Updates the predefined terms section specified.       * @path /termsections/${id}       */
export const useUpdateTermSection = () => {
  const [data, setData] = useState<DocumentSection | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.termsections.updateTermSection>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.termsections.updateTermSection(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addProductCategory       * Creates a product category. On success the id of the category is returned.       * @path /product/categories       */
export const useAddProductCategory = () => {
  const [data, setData] = useState<ProductCategory | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.product.addProductCategory>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.product.addProductCategory(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteProductCategory       * Deletes the specified product category. Note that a product category can&#39;t be delete once it&#39;s in use.       * @path /product/categories/${id}       */
export const useDeleteProductCategory = () => {
  const [data, setData] = useState<ProductCategory | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.product.deleteProductCategory>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.product.deleteProductCategory(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getProductCategories       * Gets all product categories for your tenant. The results are paginated. To fetch all results pass the cursor returned from a call to the subsequent calls until all results are returned. Initially the cursor should not be specified.       * @path /product/categories       */
export const useGetProductCategories = () => {
  const [data, setData] = useState<ProductCategoryPaginationResponse | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.product.getProductCategories>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.product.getProductCategories(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getProductCategory       * Gets the details of the specified product category       * @path /product/categories/${id}       */
export const useGetProductCategory = () => {
  const [data, setData] = useState<ProductCategory | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.product.getProductCategory>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.product.getProductCategory(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for updateProductCategory       * Updates the details of a product category       * @path /product/categories/${id}       */
export const useUpdateProductCategory = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.product.updateProductCategory>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.product.updateProductCategory(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addProduct       * Creates a product for your tenant. On success the id of the product is returned.       * @path /products       */
export const useAddProduct = () => {
  const [data, setData] = useState<ProductJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.products.addProduct>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.products.addProduct(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteProduct       * Delete the specified product.       * @path /products/${id}       */
export const useDeleteProduct = () => {
  const [data, setData] = useState<ProductJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.products.deleteProduct>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.products.deleteProduct(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for exportProductCatalog       * Export the product catalog in CSV format.       * @path /products/export       */
export const useExportProductCatalog = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.products.exportProductCatalog>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.products.exportProductCatalog(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getProduct       * Gets the details of the specified product.       * @path /products/${id}       */
export const useGetProduct = () => {
  const [data, setData] = useState<ProductJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.products.getProduct>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.products.getProduct(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getProducts       * Returns all products for your tenant. The results on paginated. To fetch them all pass the cursor returned from a call to the subsequent call until all results are fetched. Initially the cursor should not be specified.       * @path /products       */
export const useGetProducts = () => {
  const [data, setData] = useState<ProductJsonPaginationResponse | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.products.getProducts>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.products.getProducts(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for updateProduct       * Updates the details of the specified product       * @path /products/${id}       */
export const useUpdateProduct = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.products.updateProduct>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.products.updateProduct(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for buildQuote       *        * @path /quotes       */
export const useBuildQuote = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.quotes.buildQuote>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.quotes.buildQuote(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for nextQuestion       * Get the next question given a set of answers to questions so far       * @path /quotes/v0/nextQuestion       */
export const useNextQuestion = () => {
  const [data, setData] = useState<NextQuestion | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.quotes.nextQuestion>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.quotes.nextQuestion(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for testZeppaQScript       * Given the use case, list of answers and Zeppa Q script try creating the order in dryrun mode       * @path /quotes/v0/testQuoteBuilder       */
export const useTestZeppaQScript = () => {
  const [data, setData] = useState<OrderJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.quotes.testZeppaQScript>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.quotes.testZeppaQScript(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addRateCardCsv       * create a rate card using the multipart form data, where a CSV can be passed in for the price table       * @path /ratecards/csv       */
export const useAddRateCardCsv = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.ratecards.addRateCardCsv>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.ratecards.addRateCardCsv(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getPriceAttributesCsv       * Get all the price attributes in the system in CSV format one per row       * @path /ratecards/attributes/csv       */
export const useGetPriceAttributesCsv = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.ratecards.getPriceAttributesCsv>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.ratecards.getPriceAttributesCsv(...variables)
        setData(resp.data)
        const link = document.createElement('a')
        const file = new Blob([resp.data], { type: 'text/csv' })
        link.href = URL.createObjectURL(file)
        link.download = 'getPriceAttributesCsv.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getRateCardPriceTable       * Get the price table for the rate card given the id       * @path /ratecards/${id}/priceTable       */
export const useGetRateCardPriceTable = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.ratecards.getRateCardPriceTable>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.ratecards.getRateCardPriceTable(...variables)
        setData(resp.data)
        const link = document.createElement('a')
        const file = new Blob([resp.data], { type: 'text/csv' })
        link.href = URL.createObjectURL(file)
        link.download = 'getRateCardPriceTable.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getRateCards       * Fetch the list of rate cards stored in the system       * @path /ratecards       */
export const useGetRateCards = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.ratecards.getRateCards>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.ratecards.getRateCards(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for importPriceAttributes       * Import the price attributes from an input CSV file, the output provides details on each row of the input       * @path /ratecards/attributes/csv       */
export const useImportPriceAttributes = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.ratecards.importPriceAttributes>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.ratecards.importPriceAttributes(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updateRateCardCsv       * create a rate card using the multipart form data, where a CSV can be passed in for the price table       * @path /ratecards/${id}/csv       */
export const useUpdateRateCardCsv = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.ratecards.updateRateCardCsv>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.ratecards.updateRateCardCsv(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for createAndApplyRefund       * Creates and applies a refund per the specified parameters. On success the id of the refund is returned.       * @path /refunds       */
export const useCreateAndApplyRefund = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.refunds.createAndApplyRefund>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.refunds.createAndApplyRefund(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getRefundById       * Get the details of the specified refund.       * @path /refunds/${id}       */
export const useGetRefundById = () => {
  const [data, setData] = useState<Refund | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.refunds.getRefundById>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.refunds.getRefundById(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getRefunds       * Get all refunds for the specified account, OR if a credit memo number is specified in addition to the account id, get the details of only that.       * @path /refunds       */
export const useGetRefunds = () => {
  const [data, setData] = useState<RefundDetail[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.refunds.getRefunds>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.refunds.getRefunds(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for generate       * Generates a report with the specified parameters. This report can later be downloaded via /reports/{reportRunId}/result       * @path /reports/generate       */
export const useGenerate = () => {
  const [data, setData] = useState<ReportJobResponse | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.reports.generate>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.reports.generate(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getPredefinedReportDefs       * Returns the definitions of the reports defined for your tenant.       * @path /reports       */
export const useGetPredefinedReportDefs = () => {
  const [data, setData] = useState<PredefinedReportDefsJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.reports.getPredefinedReportDefs>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.reports.getPredefinedReportDefs(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getReportOutput       * Runs a report generated with /generate. On success a csv of the report is returned.       * @path /reports/${reportRunId}/result       */
export const useGetReportOutput = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.reports.getReportOutput>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.reports.getReportOutput(...variables)
        setData(resp.data)
        const link = document.createElement('a')
        const file = new Blob([resp.data], { type: 'text/csv' })
        link.href = URL.createObjectURL(file)
        link.download = 'getReportOutput.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for run       * Runs the specified report and returns the result as a csv.       * @path /reports/run       */
export const useRun = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.reports.run>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.reports.run(...variables)
        setData(resp.data)
        const link = document.createElement('a')
        const file = new Blob([resp.data], { type: 'text/csv' })
        link.href = URL.createObjectURL(file)
        link.download = 'run.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for areAccountingEventsPresentForAllTransactionTypes       * This will check if accounting events are present for all transaction types       * @path /revenueEnablement/readiness/areAccountingEventsPresentForAllTransactionTypes       */
export const useAreAccountingEventsPresentForAllTransactionTypes = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (
      variables: Parameters<typeof billyRestClient.revenueEnablement.areAccountingEventsPresentForAllTransactionTypes>
    ) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.revenueEnablement.areAccountingEventsPresentForAllTransactionTypes(
          ...variables
        )
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for areAllChargesTaggedWithGLAccounts       * This will check if all charges are tagged with GL accounts       * @path /revenueEnablement/readiness/areAllChargesTaggedWithGLAccounts       */
export const useAreAllChargesTaggedWithGLAccounts = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.revenueEnablement.areAllChargesTaggedWithGlAccounts>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.revenueEnablement.areAllChargesTaggedWithGlAccounts(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for areAllChargesTaggedWithRevenueRules       * This will check if all charges are tagged with revenue rules       * @path /revenueEnablement/readiness/areAllChargesTaggedWithRevenueRules       */
export const useAreAllChargesTaggedWithRevenueRules = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.revenueEnablement.areAllChargesTaggedWithRevenueRules>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.revenueEnablement.areAllChargesTaggedWithRevenueRules(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for doAllOrderLinesHaveSchedules       * This will check if all order lines have schedules       * @path /revenueEnablement/readiness/doAllOrderLinesHaveSchedules       */
export const useDoAllOrderLinesHaveSchedules = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.revenueEnablement.doAllOrderLinesHaveSchedules>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.revenueEnablement.doAllOrderLinesHaveSchedules(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for doesOpenAccountingPeriodExist       * This will check if an open accounting period exists       * @path /revenueEnablement/readiness/doesOpenAccountingPeriodExist       */
export const useDoesOpenAccountingPeriodExist = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.revenueEnablement.doesOpenAccountingPeriodExist>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.revenueEnablement.doesOpenAccountingPeriodExist(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for enableRevenueRecognition       * This will enable revenue recognition       * @path /revenueEnablement/enable       */
export const useEnableRevenueRecognition = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.revenueEnablement.enableRevenueRecognition>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.revenueEnablement.enableRevenueRecognition(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getRevenueEnablementProgress       * This will get revenue enablement progress       * @path /revenueEnablement/progress       */
export const useGetRevenueEnablementProgress = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.revenueEnablement.getRevenueEnablementProgress>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.revenueEnablement.getRevenueEnablementProgress(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for upsertRevenueEnablementProgress       * This will update revenue enablement progress       * @path /revenueEnablement/progress       */
export const useUpsertRevenueEnablementProgress = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.revenueEnablement.upsertRevenueEnablementProgress>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.revenueEnablement.upsertRevenueEnablementProgress(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addRecognitionRule       * Creates a revenue recognition rule.       * @path /revrec/rules       */
export const useAddRecognitionRule = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.revrec.addRecognitionRule>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.revrec.addRecognitionRule(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for createBulkRevenueRecognition       * Creates a bulk revenue recognition as specified by the input parameters. On success the id of the bulk revenue recognition is returned.       * @path /revrec/bulk       */
export const useCreateBulkRevenueRecognition = () => {
  const [data, setData] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.revrec.createBulkRevenueRecognition>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.revrec.createBulkRevenueRecognition(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for createRecognitionEvent       * Creates a revenue recognition event, only if it is different from the previous event for same subscription and charge.       * @path /revrec/events       */
export const useCreateRecognitionEvent = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.revrec.createRecognitionEvent>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.revrec.createRecognitionEvent(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteRule       * Deletes a recognition rule. Note you can&#39;t delete a recognition rule that&#39;s in use.       * @path /revrec/rules/${ruleId}       */
export const useDeleteRule = () => {
  const [data, setData] = useState<RecognitionRule | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.revrec.deleteRule>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.revrec.deleteRule(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getBulkRevenueRecognition       * Returns the details of the specified bulk revenue recognition       * @path /revrec/bulk/${bulkRevenueRecognitionId}       */
export const useGetBulkRevenueRecognition = () => {
  const [data, setData] = useState<BulkRevenueRecognition | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.revrec.getBulkRevenueRecognition>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.revrec.getBulkRevenueRecognition(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getBulkRevenueRecognitionItems       * Returns the items associated with the specified bulk revenue recognition       * @path /revrec/bulk/${bulkRevenueRecognitionId}/revrecItems       */
export const useGetBulkRevenueRecognitionItems = () => {
  const [data, setData] = useState<BulkRevenueRecognitionItem[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.revrec.getBulkRevenueRecognitionItems>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.revrec.getBulkRevenueRecognitionItems(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getRecognitionEventsBySubscriptionIdChargeId       * Gets revenue recognition events for the specified subscription and charge.       * @path /revrec/events       */
export const useGetRecognitionEventsBySubscriptionIdChargeId = () => {
  const [data, setData] = useState<RecognitionEventCompletion[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.revrec.getRecognitionEventsBySubscriptionIdChargeId>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.revrec.getRecognitionEventsBySubscriptionIdChargeId(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getRecognitionRuleById       * Get revenue recognition rules.       * @path /revrec/rules       */
export const useGetRecognitionRuleById = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.revrec.getRecognitionRuleById>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.revrec.getRecognitionRuleById(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getRecognitionRuleById_1       * Get a revenue recognition rule using id.       * @path /revrec/rules/${id}       */
export const useGetRecognitionRuleById1 = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.revrec.getRecognitionRuleById1>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.revrec.getRecognitionRuleById1(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getRevenueWaterfall       * Downloads a revenue waterfall report in csv format.       * @path /revrec/waterfall       */
export const useGetRevenueWaterfall = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.revrec.getRevenueWaterfall>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.revrec.getRevenueWaterfall(...variables)
        setData(resp.data)
        const link = document.createElement('a')
        const file = new Blob([resp.data], { type: 'text/csv' })
        link.href = URL.createObjectURL(file)
        link.download = 'getRevenueWaterfall.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
       * React hook for uploadCompletionEvents       * Uploads revenue events from a csv file. The format of the file is
Subscription.Id,Charge.Id,Alias.Id,RevrecEvent.PercentComplete
Percent complete should be a decimal.       * @path /revrec/events/upload       */
export const useUploadCompletionEvents = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.revrec.uploadCompletionEvents>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.revrec.uploadCompletionEvents(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for authorizationCodeCallback_1       * Handles the authorization code callback from Salesforce       * @path /sfdc       */
export const useAuthorizationCodeCallback1 = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.sfdc.authorizationCodeCallback1>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.sfdc.authorizationCodeCallback1(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for deleteIntegration_2       * Removes the Salesforce integration for the tenant.       * @path /sfdc       */
export const useDeleteIntegration2 = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.sfdc.deleteIntegration2>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.sfdc.deleteIntegration2(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getAccountById       * Gets a Salesforce account by its id.       * @path /sfdc/account/${id}       */
export const useGetAccountById = () => {
  const [data, setData] = useState<SalesforceAccount | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.sfdc.getAccountById>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.sfdc.getAccountById(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getAccountsByName       * Returns Salesforce accounts matching the specified name.       * @path /sfdc/account       */
export const useGetAccountsByName = () => {
  const [data, setData] = useState<SalesforceAccount[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.sfdc.getAccountsByName>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.sfdc.getAccountsByName(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getOpportunitiesByAccountId       * Returns the Salesforce opportunities associated withe the specified account.       * @path /sfdc/opportunity       */
export const useGetOpportunitiesByAccountId = () => {
  const [data, setData] = useState<Opportunity[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.sfdc.getOpportunitiesByAccountId>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.sfdc.getOpportunitiesByAccountId(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for importAccount       * Imports an account from Salesforce. On success a redirect uri is returned.       * @path /sfdc/account       */
export const useImportAccount = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.sfdc.importAccount>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.sfdc.importAccount(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for initiateIntegration_2       * Initiate a Salesforce integration. On success a redirect url is returned.       * @path /sfdc       */
export const useInitiateIntegration2 = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.sfdc.initiateIntegration2>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.sfdc.initiateIntegration2(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for syncAccountToSalesforce       * Syncs the account ARR and its orders to Salesforce.       * @path /sfdc/syncAccount/${id}       */
export const useSyncAccountToSalesforce = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.sfdc.syncAccountToSalesforce>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.sfdc.syncAccountToSalesforce(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for syncDeletedOrdersToSalesforce       * Syncs the specified order to Salesforce.       * @path /sfdc/syncDeletedOrders       */
export const useSyncDeletedOrdersToSalesforce = () => {
  const [data, setData] = useState<OrderJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.sfdc.syncDeletedOrdersToSalesforce>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.sfdc.syncDeletedOrdersToSalesforce(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for syncOrdersToSalesforce       * Syncs the specified order to Salesforce.       * @path /sfdc/syncOrders       */
export const useSyncOrdersToSalesforce = () => {
  const [data, setData] = useState<OrderJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.sfdc.syncOrdersToSalesforce>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.sfdc.syncOrdersToSalesforce(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for syncOrderToSalesforce       * Syncs the specified order to Salesforce.       * @path /sfdc/sync/${id}       */
export const useSyncOrderToSalesforce = () => {
  const [data, setData] = useState<OrderJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.sfdc.syncOrderToSalesforce>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.sfdc.syncOrderToSalesforce(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for syncTenantToSalesforce       * Syncs the specified tenant to Salesforce. Returns paginated JSON of accounts which were synced       * @path /sfdc/syncTenant       */
export const useSyncTenantToSalesforce = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.sfdc.syncTenantToSalesforce>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.sfdc.syncTenantToSalesforce(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updatePrimaryOrderIdForOpportunity       * Marks an order as the primary order for its opportunity.       * @path /sfdc/order/${id}       */
export const useUpdatePrimaryOrderIdForOpportunity = () => {
  const [data, setData] = useState<OrderJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.sfdc.updatePrimaryOrderIdForOpportunity>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.sfdc.updatePrimaryOrderIdForOpportunity(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for initiateQuoteBuilding       *        * @path /slack/quotes/initiate       */
export const useInitiateQuoteBuilding = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.slack.initiateQuoteBuilding>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.slack.initiateQuoteBuilding(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for processUserReplyToThread       *        * @path /slack/quotes/event/process       */
export const useProcessUserReplyToThread = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.slack.processUserReplyToThread>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.slack.processUserReplyToThread(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteSubscription       * Deletes the subscription for given subscription Id if invoices have not been generated and revenue has not been recognized       * @path /subscriptions/${id}/${version}       */
export const useDeleteSubscription = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.subscriptions.deleteSubscription>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.subscriptions.deleteSubscription(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getSubscriptionBillingPeriods       * Gets the billing periods for the specified subscription.       * @path /subscriptions/${id}/billingPeriods       */
export const useGetSubscriptionBillingPeriods = () => {
  const [data, setData] = useState<number[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.subscriptions.getSubscriptionBillingPeriods>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.subscriptions.getSubscriptionBillingPeriods(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getSubscriptionById       * Gets the details of the specified subscription.       * @path /subscriptions/${id}       */
export const useGetSubscriptionById = () => {
  const [data, setData] = useState<SubscriptionJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.subscriptions.getSubscriptionById>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.subscriptions.getSubscriptionById(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getSubscriptionMetrics       * Returns the metrics associated with the specified subscription. Metrics include ACV, ARR, etc.       * @path /subscriptions/${id}/metrics       */
export const useGetSubscriptionMetrics = () => {
  const [data, setData] = useState<MetricsJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.subscriptions.getSubscriptionMetrics>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.subscriptions.getSubscriptionMetrics(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getSubscriptions       * returns all Subscriptions in the system in a paginated fashion       * @path /subscriptions       */
export const useGetSubscriptions = () => {
  const [data, setData] = useState<PaginatedSubscriptionsResponse | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.subscriptions.getSubscriptions>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.subscriptions.getSubscriptions(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for revertSubscription       * Reverts the subscription for given subscription Id and version to it&#39;s previous version. Operation is allowed only if invoices have not been generated and revenue has not been recognized       * @path /subscriptions/${id}/${version}/revert       */
export const useRevertSubscription = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.subscriptions.revertSubscription>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.subscriptions.revertSubscription(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for subscriptionModifiable       * Returns true if subscription can be deleted.       * @path /subscriptions/${id}/modifiable       */
export const useSubscriptionModifiable = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.subscriptions.subscriptionModifiable>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.subscriptions.subscriptionModifiable(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for subscriptionReversible       * Returns true if subscription can be reverted to a prior version.       * @path /subscriptions/${id}/reversible       */
export const useSubscriptionReversible = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.subscriptions.subscriptionReversible>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.subscriptions.subscriptionReversible(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for updateRenewalOpportunity       * Update renewal opportunity CRM id on subscription       * @path /subscriptions/${id}/renewalOpportunity       */
export const useUpdateRenewalOpportunity = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.subscriptions.updateRenewalOpportunity>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.subscriptions.updateRenewalOpportunity(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updateSubscription       * Updates the details of the specified subscription.       * @path /subscriptions/${id}       */
export const useUpdateSubscription = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.subscriptions.updateSubscription>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.subscriptions.updateSubscription(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addIntegration_1       * Returns the integration ID if successful       * @path /taxjar       */
export const useAddIntegration1 = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.taxjar.addIntegration1>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.taxjar.addIntegration1(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getIntegration_2       * Gets the integration details of the specified integration id       * @path /taxjar/${integrationId}       */
export const useGetIntegration2 = () => {
  const [data, setData] = useState<TaxJarIntegration | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.taxjar.getIntegration2>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.taxjar.getIntegration2(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for testIntegration_1       *        * @path /taxjar/test       */
export const useTestIntegration1 = () => {
  const [data, setData] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.taxjar.testIntegration1>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.taxjar.testIntegration1(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for validateAddress_1       * Returns a suggested addresses if found       * @path /taxjar/validate       */
export const useValidateAddress1 = () => {
  const [data, setData] = useState<AccountAddress | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.taxjar.validateAddress1>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.taxjar.validateAddress1(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addTaxRate       * Add a new tax rate object       * @path /taxrates       */
export const useAddTaxRate = () => {
  const [data, setData] = useState<TaxRateJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.taxrates.addTaxRate>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.taxrates.addTaxRate(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteTaxRate       * Deletes the tax rate object by Id. If successful the deleted tax rate object is returned       * @path /taxrates/${id}       */
export const useDeleteTaxRate = () => {
  const [data, setData] = useState<TaxRateJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.taxrates.deleteTaxRate>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.taxrates.deleteTaxRate(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getTaxRate       * Retrieves the tax rate object by Id       * @path /taxrates/${id}       */
export const useGetTaxRate = () => {
  const [data, setData] = useState<TaxRatePaginationResponseJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.taxrates.getTaxRate>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.taxrates.getTaxRate(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getTaxRates       * Get all available tax rates. The result is paginated. To retrieve all results pass the cursor returned from a call to the next call until all results are returned.       * @path /taxrates       */
export const useGetTaxRates = () => {
  const [data, setData] = useState<TaxRatePaginationResponseJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.taxrates.getTaxRates>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.taxrates.getTaxRates(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for updateTaxRate       * Updates the specific tax rate object       * @path /taxrates/${id}       */
export const useUpdateTaxRate = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.taxrates.updateTaxRate>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.taxrates.updateTaxRate(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addTemplateScript       *        * @path /templateScript       */
export const useAddTemplateScript = () => {
  const [data, setData] = useState<TemplateScript | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.templateScript.addTemplateScript>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.templateScript.addTemplateScript(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for disableTemplateScript       *        * @path /templateScript       */
export const useDisableTemplateScript = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.templateScript.disableTemplateScript>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.templateScript.disableTemplateScript(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getTemplateScripts       * Returns a list of template scripts of a certain type       * @path /templateScript       */
export const useGetTemplateScripts = () => {
  const [data, setData] = useState<TemplateScript[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.templateScript.getTemplateScripts>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.templateScript.getTemplateScripts(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for dispatch       * Executes a tenant job       * @path /tenantJobs/dispatch       */
export const useDispatch = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.tenantJobs.dispatch>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.tenantJobs.dispatch(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for executeTenantJob       * Executes a tenant job       * @path /tenantJobs/retry       */
export const useExecuteTenantJob = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.tenantJobs.executeTenantJob>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.tenantJobs.executeTenantJob(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getTenantJob       * Get tenant job by id       * @path /tenantJobs/${jobId}       */
export const useGetTenantJob = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.tenantJobs.getTenantJob>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.tenantJobs.getTenantJob(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getTenant       * Get the details of the current tenant       * @path /tenants       */
export const useGetTenant = () => {
  const [data, setData] = useState<TenantJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.tenants.getTenant>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.tenants.getTenant(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getTenantLogo       * Get the current logo stored       * @path /tenants/logo       */
export const useGetTenantLogo = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.tenants.getTenantLogo>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.tenants.getTenantLogo(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for updateTenant       * Updates the details of the current tenant based on the input       * @path /tenants       */
export const useUpdateTenant = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.tenants.updateTenant>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.tenants.updateTenant(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for uploadTenantLogo       * Updates the logo used in external facing communication such as order forms and invoices       * @path /tenants/logo       */
export const useUploadTenantLogo = () => {
  const [data, setData] = useState<TenantJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.tenants.uploadTenantLogo>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.tenants.uploadTenantLogo(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteTenantUICustomizationConfig       *        * @path /settings/ui/customizations       */
export const useDeleteTenantUICustomizationConfig = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.settings.deleteTenantUiCustomizationConfig>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.settings.deleteTenantUiCustomizationConfig(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getAutoReplacePlans       * Gets plan replacement settings       * @path /settings/autoReplacePlans       */
export const useGetAutoReplacePlans = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.settings.getAutoReplacePlans>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.settings.getAutoReplacePlans(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getPaymentTermSettings       *        * @path /settings/paymentTerms       */
export const useGetPaymentTermSettings = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.settings.getPaymentTermSettings>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.settings.getPaymentTermSettings(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getSupportedCurrencies       * Get supported currencies for your tenant       * @path /settings/supportedCurrencies       */
export const useGetSupportedCurrencies = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.settings.getSupportedCurrencies>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.settings.getSupportedCurrencies(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getTenantSetting       * Returns all tenant settings for your tenant.       * @path /settings       */
export const useGetTenantSetting = () => {
  const [data, setData] = useState<TenantSettingJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.settings.getTenantSetting>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.settings.getTenantSetting(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getTenantUICustomizationConfig       *        * @path /settings/ui/customizations       */
export const useGetTenantUICustomizationConfig = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.settings.getTenantUiCustomizationConfig>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.settings.getTenantUiCustomizationConfig(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for updateAutoReplacePlans       * Update plan replacement settings       * @path /settings/autoReplacePlans       */
export const useUpdateAutoReplacePlans = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.settings.updateAutoReplacePlans>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.settings.updateAutoReplacePlans(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updatePaymentTermSettings       *        * @path /settings/paymentTerms       */
export const useUpdatePaymentTermSettings = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.settings.updatePaymentTermSettings>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.settings.updatePaymentTermSettings(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updateSupportedCurrencies       * Update supported currencies for your tenant       * @path /settings/supportedCurrencies       */
export const useUpdateSupportedCurrencies = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.settings.updateSupportedCurrencies>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.settings.updateSupportedCurrencies(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updateTenantSetting       * Updates settings for your tenant.       * @path /settings       */
export const useUpdateTenantSetting = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.settings.updateTenantSetting>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.settings.updateTenantSetting(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updateTenantUICustomizationConfig       *        * @path /settings/ui/customizations       */
export const useUpdateTenantUICustomizationConfig = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.settings.updateTenantUiCustomizationConfig>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.settings.updateTenantUiCustomizationConfig(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getExchangeRateAsOf       * Get transactional exchange rate for a currency pair as of a specific date       * @path /fx/transactional/asof       */
export const useGetExchangeRateAsOf = () => {
  const [data, setData] = useState<TransactionalExchangeRate | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.fx.getExchangeRateAsOf>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.fx.getExchangeRateAsOf(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getLatestExchangeRates       * Get latest transactional exchange rates for all currency pairs of supported currencies to functional currencies       * @path /fx/transactional/latest       */
export const useGetLatestExchangeRates = () => {
  const [data, setData] = useState<TransactionalExchangeRate[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.fx.getLatestExchangeRates>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.fx.getLatestExchangeRates(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for refreshExchangeRatePair       * Fetch and store transactional exchange rate for given currency pair and effective date from exchange rate provider       * @path /fx/transactional/refreshPair       */
export const useRefreshExchangeRatePair = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.fx.refreshExchangeRatePair>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.fx.refreshExchangeRatePair(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for refreshExchangeRates       * Refresh transactional exchange rates for all supported currencies to functional currencies for the given effective date       * @path /fx/transactional/refresh       */
export const useRefreshExchangeRates = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.fx.refreshExchangeRates>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.fx.refreshExchangeRates(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for activateUnitOfMeasure       * Activates the specified unit of measure making it available to be attached to charges       * @path /unitsOfMeasure/${id}/activate       */
export const useActivateUnitOfMeasure = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.unitsOfMeasure.activateUnitOfMeasure>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.unitsOfMeasure.activateUnitOfMeasure(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addUnitOfMeasure       * Add a new instance of unit of measure as specified by the input       * @path /unitsOfMeasure       */
export const useAddUnitOfMeasure = () => {
  const [data, setData] = useState<UnitOfMeasureJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.unitsOfMeasure.addUnitOfMeasure>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.unitsOfMeasure.addUnitOfMeasure(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteUnitOfMeasure       * Delete the unit of measure by Id provided. Returns the deleted unit of measure object if successful.       * @path /unitsOfMeasure/${id}       */
export const useDeleteUnitOfMeasure = () => {
  const [data, setData] = useState<UnitOfMeasureJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.unitsOfMeasure.deleteUnitOfMeasure>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.unitsOfMeasure.deleteUnitOfMeasure(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deprecateUnitOfMeasure       * Deprecates the specified unit of measure making it unavailable to be attached to charges going forward       * @path /unitsOfMeasure/${id}/deprecate       */
export const useDeprecateUnitOfMeasure = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.unitsOfMeasure.deprecateUnitOfMeasure>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.unitsOfMeasure.deprecateUnitOfMeasure(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getUnitsOfMeasure       * Returns a paginated list of units of measure       * @path /unitsOfMeasure       */
export const useGetUnitsOfMeasure = () => {
  const [data, setData] = useState<UnitOfMeasurePaginationResponseJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.unitsOfMeasure.getUnitsOfMeasure>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.unitsOfMeasure.getUnitsOfMeasure(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for updateUnitOfMeasure       * Update the unit of measure by Id provided.       * @path /unitsOfMeasure/${id}       */
export const useUpdateUnitOfMeasure = () => {
  const [data, setData] = useState<UnitOfMeasureJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.unitsOfMeasure.updateUnitOfMeasure>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.unitsOfMeasure.updateUnitOfMeasure(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addUsage       * Load usage records into the system       * @path /v2/usage       */
export const useAddUsage = () => {
  const [data, setData] = useState<UsageBatchInsertResult | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.v2.addUsage>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.v2.addUsage(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getUsageAggregatesForSubscription       * Retrieve the current aggregated usage data for a subscription between 2 instants       * @path /v2/usage/${subscriptionId}       */
export const useGetUsageAggregatesForSubscription = () => {
  const [data, setData] = useState<UsageAggregateOutput[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.v2.getUsageAggregatesForSubscription>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.v2.getUsageAggregatesForSubscription(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getUsageArrivalTimeCheckpoint       * Retrieve the latest usage record upload to the system as epoch seconds       * @path /v2/usage/aggregate/usageArrivalTimeCheckpoint       */
export const useGetUsageArrivalTimeCheckpoint = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.v2.getUsageArrivalTimeCheckpoint>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.v2.getUsageArrivalTimeCheckpoint(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getUsageStatsForAllSubscriptions       * Retrieve the current prepaid drawdown statistics for all subscriptions for a time range       * @path /v2/usage/stats       */
export const useGetUsageStatsForAllSubscriptions = () => {
  const [data, setData] = useState<PrepaidStats[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.v2.getUsageStatsForAllSubscriptions>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.v2.getUsageStatsForAllSubscriptions(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getUsageStatsForAllSubscriptionsCsv       * Retrieve the current prepaid drawdown statistics for all subscriptions for a time range in CSV format       * @path /v2/usage/stats/csv       */
export const useGetUsageStatsForAllSubscriptionsCsv = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.v2.getUsageStatsForAllSubscriptionsCsv>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.v2.getUsageStatsForAllSubscriptionsCsv(...variables)
        setData(resp.data)
        const link = document.createElement('a')
        const file = new Blob([resp.data], { type: 'text/csv' })
        link.href = URL.createObjectURL(file)
        link.download = 'getUsageStatsForAllSubscriptionsCsv.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getUsageStatsForSubscription       * Retrieve the current prepaid drawdown statistics for a subscription       * @path /v2/usage/stats/${subscriptionId}       */
export const useGetUsageStatsForSubscription = () => {
  const [data, setData] = useState<PrepaidStats[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.v2.getUsageStatsForSubscription>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.v2.getUsageStatsForSubscription(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getUsageStatsForSubscriptionCsv       * Retrieve the current prepaid drawdown statistics for a subscription in CSV format       * @path /v2/usage/stats/${subscriptionId}/csv       */
export const useGetUsageStatsForSubscriptionCsv = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.v2.getUsageStatsForSubscriptionCsv>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.v2.getUsageStatsForSubscriptionCsv(...variables)
        setData(resp.data)
        const link = document.createElement('a')
        const file = new Blob([resp.data], { type: 'text/csv' })
        link.href = URL.createObjectURL(file)
        link.download = 'getUsageStatsForSubscriptionCsv.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getUsageStatsForSubscriptionPdf       * Retrieve the current prepaid drawdown statistics for a subscription in PDF format       * @path /v2/usage/stats/${subscriptionId}/pdf       */
export const useGetUsageStatsForSubscriptionPdf = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.v2.getUsageStatsForSubscriptionPdf>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.v2.getUsageStatsForSubscriptionPdf(...variables)
        setData(resp.data)
        const link = document.createElement('a')
        const file = new Blob([resp.data], { type: 'text/csv' })
        link.href = URL.createObjectURL(file)
        link.download = 'getUsageStatsForSubscriptionPdf.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for performOnDemandUsageAggregation       * Trigger the process to aggregate any remaining raw usage records       * @path /v2/usage/aggregate       */
export const usePerformOnDemandUsageAggregation = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.v2.performOnDemandUsageAggregation>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.v2.performOnDemandUsageAggregation(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for uploadSubscriptionUsageCSV       * Upload usage records in CSV file. Each row of the file represents a single usage record       * @path /v2/usage/csv       */
export const useUploadSubscriptionUsageCSV = () => {
  const [data, setData] = useState<UsageBatchInsertResult | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.v2.uploadSubscriptionUsageCsv>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.v2.uploadSubscriptionUsageCsv(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addUserGroup       * Create a new user group based on the parameters in the input       * @path /userGroups       */
export const useAddUserGroup = () => {
  const [data, setData] = useState<UserGroupJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.userGroups.addUserGroup>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.userGroups.addUserGroup(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for deleteUserGroup       * Delete the user group by Id       * @path /userGroups/${userGroupId}       */
export const useDeleteUserGroup = () => {
  const [data, setData] = useState<UserGroupJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.userGroups.deleteUserGroup>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.userGroups.deleteUserGroup(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getUserGroup       * Retrieve a user group by Id       * @path /userGroups/${userGroupId}       */
export const useGetUserGroup = () => {
  const [data, setData] = useState<UserGroupJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.userGroups.getUserGroup>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.userGroups.getUserGroup(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getUserGroups       * Get all available user groups       * @path /userGroups       */
export const useGetUserGroups = () => {
  const [data, setData] = useState<UserGroupJson[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.userGroups.getUserGroups>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.userGroups.getUserGroups(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for updateUserGroup       * Updates the specified user group according to the input       * @path /userGroups       */
export const useUpdateUserGroup = () => {
  const [data, setData] = useState<UserGroupJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.userGroups.updateUserGroup>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.userGroups.updateUserGroup(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for acceptTermsForCurrentUser       * Before a user gains access to the application, they must accept Subskribe&#39;s terms and conditions. This operation requires user bound access tokens.       * @path /users/terms-and-conditions       */
export const useAcceptTermsForCurrentUser = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.users.acceptTermsForCurrentUser>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.users.acceptTermsForCurrentUser(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for addUser       * Add a new user to the system. Users in the system must have unique emails. If successful, the path to the new user object is returned and a welcome email containing a temporary password will be sent to the email associated with the user. The login credentials expires in 24 hours. If the user does not login to the system within that time, a new invitation is required.       * @path /users       */
export const useAddUser = () => {
  const [data, setData] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.users.addUser>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.users.addUser(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for disableUser       * Disables a user by Id. If successful, returns the user just disabled       * @path /users/disable/${id}       */
export const useDisableUser = () => {
  const [data, setData] = useState<UserJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.users.disableUser>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.users.disableUser(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for enableUser       * Enables a user by Id. If successful, returns the user just enabled       * @path /users/enable/${id}       */
export const useEnableUser = () => {
  const [data, setData] = useState<UserJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.users.enableUser>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.users.enableUser(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for getUser       * Returns a specific user by Id       * @path /users/${id}       */
export const useGetUser = () => {
  const [data, setData] = useState<UserJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.users.getUser>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.users.getUser(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for getUsers       * Returns a paginated list of users       * @path /users       */
export const useGetUsers = () => {
  const [data, setData] = useState<UserPaginationResponseJson | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const queryFn = useCallback(
    async (variables: Parameters<typeof billyRestClient.users.getUsers>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.users.getUsers(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    queryFn,
  }
}

/**
 * React hook for resendEmailForExistingUser       * Resend welcome email conditioning temporary credentials. This is required if the user does not register within 24 hours of user activation       * @path /users/resend-email/${email}       */
export const useResendEmailForExistingUser = () => {
  const [data, setData] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.users.resendEmailForExistingUser>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.users.resendEmailForExistingUser(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updateUser       * Updates the user information. Email cannot be updated.       * @path /users/${id}       */
export const useUpdateUser = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.users.updateUser>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.users.updateUser(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for updateUserSSOConfig       * Toggle user SSO configuration by user Id.       * @path /users/${id}/sso       */
export const useUpdateUserSSOConfig = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.users.updateUserSsoConfig>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.users.updateUserSsoConfig(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}

/**
 * React hook for uploadCSV       * Loads a list of users to be added to in CSV format.       * @path /users/upload       */
export const useUploadCSV = () => {
  const [data, setData] = useState<any | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const errorHandler = useErrorHandler()

  const mutate = useCallback(
    async (variables: Parameters<typeof billyRestClient.users.uploadCsv>) => {
      try {
        setIsLoading(true)
        setIsError(false)
        setData(undefined)

        const resp = await billyRestClient.users.uploadCsv(...variables)
        setData(resp.data)
        return resp
      } catch (err) {
        setIsError(true)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [errorHandler]
  )

  return {
    data,
    isError,
    isLoading,
    mutate,
  }
}
